export const GatewayIcon = ({google,iconColor="#FF2015"})=>{
    return({
            url: 'data:image/svg+xml;charset=utf-8,' + (encodeURIComponent('<svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title/><defs/><g fill="none" fill-rule="evenodd" id="Icons with numbers" stroke="none" stroke-width="1"><g fill="' +iconColor +'" stroke="#000000" stroke-width="1" id="Group" transform="translate(-480.000000, -480.000000)"><path d="M488,480 L496,496 L480,496 L488,480 Z M488,480" id="Triangle 155"/></g></g></svg>')),
            size: new google.maps.Size(32, 32),
            scaledSize: new google.maps.Size(32, 32),
            anchor: new google.maps.Point(16, 16),
            labelOrigin: new google.maps.Point(16, 20)
    }
)};

export const MeterIcon = ({google,meterColor="#FF2015",meterHeadColor="#FF2015"})=>{
    return({
        url:'data:image/svg+xml;charset=utf-8,'+(encodeURIComponent('<svg enable-background="new 0 0 64 64" height="64px" id="Layer_1" version="1.1" viewBox="0 0 64 64" width="64px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M33.224,10.658c-1.287-1.286-2.429-0.364-2.725-0.079l-19.51,19.512h-0.002c-2.564,2.565,0,2.72,0,2.72   h0.245c0.386,0.013,1.908,0.211,1.908,2.554v23.602c0.049,1.259,0.525,4.612,4.204,4.612h28.269c4.162,0,4.346-4.356,4.353-5.018   V35.617c0-2.936,2.404-2.807,2.404-2.807h0.512c0.37-0.003,2.345-0.129-0.057-2.536L33.224,10.658z" fill="'+meterColor+'"/><path d="M62.729,29.392L36.023,2.322c-0.016-0.016-0.025-0.023-0.041-0.039c-3.378-3.423-6.434-1.251-7.437-0.344   L1.114,29.003c0,0-2.11,1.967-0.571,3.526l0.188,0.19c0,0,1.26,1.461,3.475-0.725L29.659,6.884l0,0l0.139-0.138   c0.533-0.498,2.562-2.092,4.761,0.135l25.152,25.494c0,0,2.028,2.097,3.436,0.708l0.56-0.55c0-0.002,0-0.004,0-0.004   C64.785,31.382,62.729,29.392,62.729,29.392z" fill="' + meterHeadColor + '" stroke="'+meterHeadColor+'" stroke-width="3"/></g></svg>')),
            size: new google.maps.Size(32, 32),
            scaledSize: new google.maps.Size(32, 32),
            anchor: new google.maps.Point(16, 16),
            labelOrigin: new google.maps.Point(16, 20)
    }
)};

export const GatewayText = ({text,fontWeight='850',color='white'})=>{
    return({text: text , fontWeight: fontWeight, color: color}
    )};

export const GetColorByPerformance = (performancePercent)=>{
    return performancePercent>=90? '#77DD77':
        (performancePercent <90 && performancePercent >= 70? '#FDFD96' :
            (performancePercent <70 && performancePercent >=50 ? '#FFB347' :
                '#FF2015' )  ) ;
}
