import React from 'react';
import ErrorIcon from "../../assets/images/erroricon.jpg"
import WarninIcon from "../../assets/images/warningicon.png"
import {Modal, ModalBody, Row, Button, Col} from 'reactstrap';

export const ModalCustom = ({toggleModal, toggleModalFunc, error = ""}) => {
    let errores='';
    if (error.errors !== undefined && Object.keys(error.errors)[0].length > 0) {
        errores = Object.entries(error.errors).map((x, i) => {
            return <ul key={i}>
                <li key={i}>{x[0] + ": " + x[1][0]}</li>
            </ul>
        });
    } else if (error.errors !== undefined && Object.keys(error.errors)[0].length === 0) {
        errores = Object.entries(error.errors).map(x => {
            return x[1].map((y, i) => {
                return <ul key={i}>
                    <li key={i}> {y} </li>
                </ul>
            })
        });
    }
    return <Modal size={"lg"} isOpen={toggleModal} centered scrollable={true} >
        <ModalBody >
            <Row className={"justify-content-center"}>
                <Col className={"text-center"} md={12}>
                    <img src={error.status < 499 ? WarninIcon : ErrorIcon } alt={"warningicon"} height={150} width={150}/>
                </Col>
            </Row>
            <br/>
            <Row className={"justify-content-center"}>
                <Col style={{fontSize:"30px", fontWeight:"2px" }} className={"text-center"} md={12}>
                    {error.status >=500 ? "Error interno, favor de contactar a soporte" : error.title   }
                </Col>
            </Row>
            <br/>
            <Row style={error.errors ===undefined? {display:"none"} : {display:"" } } className="justify-content-center">
                <Col style={{fontSize:"18px", fontWeight:"20px", wordWrap:'break-word' }}   md={12}>
                    {errores}
                </Col>
            </Row>

            <Row  style={error.errors !==undefined? {display:"none"} : {display:""} } className={"justify-content-center"}>
                <Col style={{fontSize:"25px", fontWeight:"bold" }} className={"text-center text-break"} md={12}>
                    {error.detail}
                </Col>
            </Row>
            <br/>
            <Row className={"justify-content-center"}>
                <Col className={"text-center"} md={3}>
                    <Button style={{
                        backgroundColor: "#2979ff",
                        fontColor: "#fff",
                        border: '5px',
                        borderColor: "#eeeeee",
                        borderStyle: "groove",
                        borderRadius: "7px"
                    }}
                            size={"lg"}
                            onClick={toggleModalFunc}
                            block>
                        OK
                    </Button>
                </Col>
            </Row>

        </ModalBody>
    </Modal>
};
