import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import {Time} from "../../constants/DateTime";
import Select from "react-select";
import {Card, Row, Col, CardTitle, CardBody, FormGroup, Modal, ModalHeader, ModalBody, Collapse, Button} from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import "react-table/react-table.css";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import TableLayout from "../../components/custom/TableLayout";
import {Response} from "../../constants/PetitionResponse";
import 'moment/locale/es-do';
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import {ModalCustom} from "../../components/custom/ModalCustom";
import Autosuggest from 'react-autosuggest';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import Trans from '../../translate/Translate';
import notificationsManager from "../../services/NotificationsManager";
const t =(text)=><Trans text={text}/>;
const Handle = Slider.Handle;

const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

class Petitions extends React.Component {
    constructor(props) {
        super(props);
        this.petition = [
            {label: 'Conectar', value: 6},
            {label: 'Desconectar', value: 7},
            {label: 'Estados', value: 5},
            {label: 'Eventos', value: 8},
            {label: 'Perfil de carga', value: 1},
            {label: 'Potencia Maxima', value: 10},
            {label: 'Registros', value: 0},
            {label: 'Registros en el colector', value: 2},
            {label: 'Reinicio de demanda', value: 3},
            {label: 'Voltajes', value: 9},

        ];
        this.intervalID = 0;
        this.state = {
            loading: false,
            petition: '',
            selectMeter:'',
            getUserRequests: [],
            modal: false,
            response: {columns:[], data:[]},
            responseRecords:[],
            collapse: false,
            autocompleteData: [],
            value: "",
            suggestions: [],
            day:1,
            toggleModalStatusCode:false,
            error:"",
        };
    }

    collapseReady=()=> {
        this.setState({ collapse: !this.state.collapse });
    };

    toggle =()=> {
        this.setState({
            modal: !this.state.modal
        });
    };
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };

    componentDidMount() {
        this.getUserRequestsData();
        this.intervalID = setInterval(
            () => this.getUserRequestsData(),
            40000
        );
        notificationsManager.onNotification(notification => {
            if (notification.action !== undefined && notification.action === 'RequestStatusChanged') {
                let data = Response(notification.data);
                let updateSerial = [...this.state.getUserRequests];
                updateSerial.splice(this.state.getUserRequests.findIndex(x => x.id === data.id), 1, data);
                this.setState({getUserRequests: updateSerial});
            }
        });
        // notificationsManager.start();
    }

    getUserRequestsData() {
        this.setState({loading: true});
        apiClient.requests.getUserRequests().then( response=> {
            let data=[], quantity=0;
            response.data.map(x=>{
                if (x.parameters !==null ){
                    if ( x.parameters.cmd1!==76){
                        quantity = quantity+1;
                        data.push(x)
                    }
                }
                return true
            });
            this.setState({
                getUserRequests: Response(data),
                loading: false,
            });
        }).catch(error=>{
             this.setState({toggleModalStatusCode:true, error:error, loading: false})
        });
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        // notificationsManager.stop();
    }

    getPetition=(e)=>{
        this.setState({petition:e})
    };

    onChange = (event,{newValue})=> {
        apiClient.meters.getInstalledMetersFiltered({serial:newValue})
            .then(response=>{
                this.setState({
                    value: newValue,
                    autocompleteData: response.data.map(meter=>{meter.value=meter.serial; meter.label=meter.serial; return meter;}).slice(0, 7)
                });
            });
    };

    onSelect = (val) => {
        this.setState({value: val, selectMeter:val});
        return val
    };

    sendRequests=()=>{
        let {petition, getUserRequests, selectMeter, day } =this.state;
        switch(petition.value) {
            case 0:
                apiClient.requests.registers({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift( Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 1:
                apiClient.requests.loadProfile({meter:selectMeter, day:day})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift( Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 2:
                apiClient.requests.readingsMeter({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 3:
                apiClient.requests.resetDemand({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 9:
                apiClient.requests.instrumentation({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 5:
                apiClient.requests.metersByMeter({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 6:
                apiClient.requests.closeRelay({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 7:
                apiClient.requests.openRelay({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 8:
                apiClient.requests.eventsMeter({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift(  Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            case 10:
                apiClient.requests.demand({meter:selectMeter})
                    .then(response=>{
                        let newRequests= getUserRequests.slice();
                        newRequests.unshift( Response( response.request));
                        this.setState({getUserRequests: newRequests })
                    }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                break;
            default: return 0
        }
    };

    requestResponseHttp = (data) => {
        this.setState({collapse: false});
        switch (data.requestType.id) {
            case 2: //events
            case 16://meters // status
            case 3://readings
            case 9://utility
                let events = [];
                let RecordsEvents = JSON.parse(data.requestResponse).Records;
                let HeaderEvents = RecordsEvents.map(x => {
                    return Object.keys(x)
                });
                HeaderEvents[0].map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                        events.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'LastCommunication' && x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: events, data: RecordsEvents};

            case 5: //instrumentation
                let columnsInstrumentation = [], dataInstrumentation = [];
                dataInstrumentation.push(JSON.parse(data.requestResponse));
                let HeaderInstrumentation = dataInstrumentation.map(x => {
                    return Object.keys(x)
                });
                HeaderInstrumentation[0].map(x => {
                    if (x === "DateTime" ||x === "VoltageA" || x === "VoltageB" || x === "VoltageB" || x === "CurrentA"|| x === "CurrentB"|| x === "CurrentC" || x === "PowerFactorA"|| x === "PowerFactorB"|| x === "PowerFactorC"|| x === "PowerFactor") {
                        columnsInstrumentation.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsInstrumentation, data: dataInstrumentation};
            case 28: //voltages
                let columnsMeterRecord = [], dataMeterRecord = [];
                dataMeterRecord.push(JSON.parse(data.requestResponse).MeterRecord);
                let HeaderMeterRecord = dataMeterRecord.map(x => {
                    return Object.keys(x)
                });
                HeaderMeterRecord[0].map(x => {
                    if (x === "Voltage" || x === "Current" || x === "PowerFactor" || x === "LastCommunication") {
                        columnsMeterRecord.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'LastCommunication'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsMeterRecord, data: dataMeterRecord};
            case 4://Demand

                let columnsDemand = [], dataDemand = [];
                dataDemand.push(JSON.parse(data.requestResponse));
                let HeaderDemand = dataDemand.map(x => {
                    return Object.keys(x)
                });
                HeaderDemand[0].map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                        columnsDemand.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'ReadingDate' && x !== 'LastCommunication' && x !== 'LastOperation'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsDemand, data: dataDemand};
            case 27://Registers

                let columnsRegisters = [], dataRegisters = [];
                dataRegisters.push(JSON.parse(data.requestResponse).ReadingRecord);
                let HeaderRegisters = dataRegisters.map(x => {
                    return Object.keys(x)
                });
                HeaderRegisters[0].map(x => {
                    if (x === "ActiveEnergy" || x === "ReverseEnergy" || x === "ReactiveEnergy" || x === "ReadingDate") {
                        columnsRegisters.push({
                            Header: t(x), accessor: x, minWidth: 150, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsRegisters, data: dataRegisters};
            default:
                return 0
        }
    };

    requestResponseTcp = (data) => {
        this.setState({collapse: false});
        let parse =JSON.parse(data.requestResponse);
        let type='';
        if( typeof (parse.Records)==='undefined' ){
            type = 'true';
        }else if ( parse.Records===null){
            type = 'true';
        }
        switch (data.requestType.id) {
            case 2: //events
            case 3://readings
            case 9://readings
            case 16://meters
                let meters = [], dataReading=[], HeaderEvents;
                if (type !== 'true') {
                    dataReading = parse.Records;
                    HeaderEvents = dataReading.map(x => {return Object.keys(x)});
                } else {
                    dataReading = parse;
                    HeaderEvents = Object.keys(dataReading);
                }
                (type !=='true' ? HeaderEvents[0] : HeaderEvents).map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway" && x !== "Key" && x !== "FrameLength" && x !== "Error" && x !== "Dyn" && x !== "InvalidData" && x !== "MeterNotFound" && x !== "Active" && x !== "Temperature" && x !== "MeterSerial" && x !== "RequestType") {
                        meters.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'LastCommunication' && x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] =
                                                                <i style={{color: 'green', fontSize: '15px'}}
                                                                   className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: meters, data: type ==='true' ? [dataReading] : dataReading };

            case 5: //instrumentation
                let columnsInstrumentation = [], dataInstrumentation = [];
                if (JSON.parse(data.requestResponse).length!==0) {
                    dataInstrumentation.push(JSON.parse(data.requestResponse));
                    let HeaderInstrumentation = dataInstrumentation.map(x => {
                        return Object.keys(x)
                    });
                    HeaderInstrumentation[0].map(x => {
                        if (x === "DateTime" || x === "VoltageA" || x === "VoltageB" || x === "VoltageB" || x === "CurrentA" || x === "CurrentB" || x === "CurrentC" || x === "PowerFactorA" || x === "PowerFactorB" || x === "PowerFactorC" || x === "PowerFactor") {
                            columnsInstrumentation.push({
                                Header: t(x), accessor: x, Cell: props =>
                                    props.value !== null
                                        ?
                                        x !== 'DateTime'
                                            ?
                                            typeof (props.value) === 'object'
                                                ? <div>
                                                    {Object.keys(props.value).map((x, i) => {
                                                        if (props.value[x] !== null) {
                                                            if (props.value[x] === false) {
                                                                props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                    className="fas fa-times"> </i>;
                                                            } else if (props.value[x] === true) {
                                                                props.value[x] =
                                                                    <i style={{color: 'green', fontSize: '15px'}}
                                                                       className="fas fa-check"> </i>;
                                                            }
                                                            return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                                key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                        } else {
                                                            return 'N/D'
                                                        }
                                                    })}
                                                    {this.state.collapse === true ? <br/> : ''}
                                                    <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                            onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                            <i style={{color: 'green', fontSize: '25px'}}
                                                               className="fas fa-check"> </i> :
                                                            <i style={{color: 'red', fontSize: '25px'}}
                                                               className="fas fa-times"> </i> : props.value : 'N/D'
                                                    }
                                                </div>
                                            :
                                            Time(props.value)
                                        :
                                        'N/D'
                            })
                        }
                        return true
                    });
                }
                return {columns: columnsInstrumentation, data: dataInstrumentation};

            case 28: //voltages
                let columnsMeterRecord = [], dataMeterRecord = [];
                if (JSON.parse(data.requestResponse).length!==0) {
                    dataMeterRecord.push(JSON.parse(data.requestResponse));
                    let HeaderMeterRecord = dataMeterRecord.map(x => {
                        return Object.keys(x)
                    });
                    HeaderMeterRecord[0].map(x => {
                        if (x === "VoltageA" || x === "VoltageB" || x === "VoltageC" || x === "CurrentA" || x === "CurrentB" || x === "CurrentC" || x === "PowerFactorA" || x === "PowerFactorB" || x === "PowerFactorC" || x === "DateTime") {
                            columnsMeterRecord.push({
                                Header: t(x), accessor: x, Cell: props =>
                                    props.value !== null
                                        ?
                                        x !== 'LastCommunication'
                                            ?
                                            typeof (props.value) === 'object'
                                                ? <div>
                                                    {Object.keys(props.value).map((x, i) => {
                                                        if (props.value[x] !== null) {
                                                            if (props.value[x] === false) {
                                                                props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                    className="fas fa-times"> </i>;
                                                            } else if (props.value[x] === true) {
                                                                props.value[x] =
                                                                    <i style={{color: 'green', fontSize: '15px'}}
                                                                       className="fas fa-check"> </i>;
                                                            }
                                                            return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                                key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                        } else {
                                                            return 'N/D'
                                                        }
                                                    })}
                                                    {this.state.collapse === true ? <br/> : ''}
                                                    <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                            onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                            <i style={{color: 'green', fontSize: '25px'}}
                                                               className="fas fa-check"> </i> :
                                                            <i style={{color: 'red', fontSize: '25px'}}
                                                               className="fas fa-times"> </i> : props.value : 'N/D'
                                                    }
                                                </div>
                                            :
                                            Time(props.value)
                                        :
                                        'N/D'
                            })
                        }
                        return true
                    });
                }
                return {columns: columnsMeterRecord, data: dataMeterRecord};
            case 4://Demand
                let columnsDemand = [], dataDemand = [];
                if (JSON.parse(data.requestResponse).length!==0) {
                    dataDemand.push(JSON.parse(data.requestResponse));
                    let HeaderDemand = dataDemand.map(x => {
                        return Object.keys(x)
                    });
                    HeaderDemand[0].map(x => {
                        if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                            columnsDemand.push({
                                Header: t(x), accessor: x, Cell: props =>
                                    props.value !== null
                                        ?
                                        x !== "DateTime" && x !== 'ReadingDate' && x !== 'LastCommunication' && x !== 'LastOperation'
                                            ?
                                            typeof (props.value) === 'object'
                                                ? <div>
                                                    {Object.keys(props.value).map((x, i) => {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    })}
                                                    {this.state.collapse === true ? <br/> : ''}
                                                    <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                            onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        typeof (props.value) === 'boolean' ? props.value === true ?
                                                            <i style={{color: 'green', fontSize: '25px'}}
                                                               className="fas fa-check"> </i> :
                                                            <i style={{color: 'red', fontSize: '25px'}}
                                                               className="fas fa-times"> </i> : props.value
                                                    }
                                                </div>
                                            :
                                            Time(props.value)
                                        :
                                        'N/D'
                            })
                        }
                        return true
                    });
                }
                return {columns: columnsDemand, data: dataDemand};
            case 27://Registers
                let columnsRegisters = [], dataRegisters = [];
                if (JSON.parse(data.requestResponse).length!==0) {
                    dataRegisters.push(JSON.parse(data.requestResponse));
                    let HeaderRegisters = dataRegisters.map(x => {
                        return Object.keys(x)
                    });
                    HeaderRegisters[0].map(x => {
                        if (x === "ActiveEnergy" || x === "ReverseEnergy" || x === "ReactiveEnergy" || x === "ReadingDate") {
                            columnsRegisters.push({
                                Header: t(x), accessor: x, minWidth: 150, Cell: props =>
                                    props.value !== null
                                        ?
                                        x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                            ?
                                            typeof (props.value) === 'object'
                                                ? <div>
                                                    {Object.keys(props.value).map((x, i) => {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    })}
                                                    {this.state.collapse === true ? <br/> : ''}
                                                    <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                            onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        typeof (props.value) === 'boolean' ? props.value === true ?
                                                            <i style={{color: 'green', fontSize: '25px'}}
                                                               className="fas fa-check"> </i> :
                                                            <i style={{color: 'red', fontSize: '25px'}}
                                                               className="fas fa-times"> </i> : props.value
                                                    }
                                                </div>
                                            :
                                            Time(props.value)
                                        :
                                        'N/D'
                            })
                        }
                        return true
                    });
                }
                return {columns: columnsRegisters, data: dataRegisters};
            default:
                return 0
        }
    };

    onAfterChange=(e)=>{
        this.setState({day:e})
    };

    cancelRequest = (requestId) => {
        apiClient.requests.cancelRequest({requestId: requestId})
            .then(()=> {
                let changeCancel = [...this.state.getUserRequests];
                let Requests = this.state.getUserRequests.find(x => x.id === requestId);
                Requests['status'] = 'Cancelled';
                changeCancel.splice(this.state.getUserRequests.findIndex(x => x.id === requestId), 1, Requests);
                this.setState({getUserRequests: changeCancel});
            }).catch((error) => {
            this.setState({toggleModalStatusCode: true, error: error, loading: false})
        })
    };

    parameter = (petition) => {
        /* eslint-disable no-unused-vars */
        let parameter;
        switch (petition) {
            case "":
                break;
            case 1:
                return parameter =
                    <Col  md={2} >
                        <label>Parametros: día</label>
                        <Slider style={{paddingTop:"18px"}} marks={{ 1: 1,30: 30 }}  onAfterChange={this.onAfterChange} defaultValue={1} min={1} max={30}  handle={handle}/>
                    </Col>;
            case 2:
                break;
            default: return 0
        }
        /* eslint-enable no-unused-vars */
    };

    render() {
        const { value, suggestions } = this.state;
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <LoadingComponent loading={this.state.loading}/>
                <Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Detalles</ModalHeader>
                    <ModalBody>
                        {this.state.response.data.length!==0
                            ?
                            <TableLayout
                                data={this.state.response.data}
                                columns={this.state.response.columns}
                                defaultPageSize={5}
                                filterable={true}
                                className={''}
                                showPaginationBottom={true}
                                showPaginationTop={true}
                                noDataText={false}
                            />
                            :
                            <h2> No hay información</h2>
                        }
                        <br/>
                        <Row>
                            <Col md={2}>
                                <ButtonCustom color={'success'} className={''} name={'Cerrar'} id={'Cerrar'} Click={this.toggle} title={"Cerrar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>Peticiones</CardTitle>
                    <CardBody className="border-top">
                        <Row className={"justify-content-center"}>
                            <Col md={2}>
                                <label > Medidor </label>
                                <FormGroup>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={()=>this.setState({suggestions: this.state.autocompleteData})}
                                        onSuggestionsClearRequested={()=> this.setState({suggestions: []})}
                                        getSuggestionValue={suggestion =>  this.onSelect(suggestion.value)   }
                                        renderSuggestion={(suggestion)=> <div>{suggestion.value}</div>}
                                        inputProps={{placeholder: 'Ingrese medidor', value, onChange: this.onChange}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2} >
                                <label >Tipos de peticiones </label>
                                <Select
                                    name="selectPetition"
                                    id="selectPetition"
                                    options={this.petition}
                                    value={this.state.petition}
                                    placeholder={"Petición"}
                                    onChange={this.getPetition}
                                />
                            </Col>
                            {this.parameter(this.state.petition.value) !==0 ?
                                this.parameter(this.state.petition.value)
                                : ''
                            }
                            <Col md={2}>
                                <label > <br/> </label>
                                <ButtonCustom
                                    name={'generate'}
                                    id={'generate'}
                                    title={'Ejecutar'}
                                    type={'add'}
                                    disabled={!(this.state.value && Object.keys(this.state.petition).length===2)}
                                    Click={this.sendRequests}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <TableLayout
                                    data={this.state.getUserRequests}
                                    showPaginationBottom={true}
                                    showPaginationTop={true}
                                    className={'affix disabled text-center'}
                                    defaultPageSize={10}
                                    columns={[
                                        {Header: "Medidor", accessor:'serial'},
                                        {Header: "Petición", accessor: "requestType", Cell:(props)=>props.value ==='Voltages'? 'Voltajes' : props.value},
                                        {Header: "Fecha",  accessor: "createdAt"},
                                        {Header: "Duración",  accessor: "duration"},
                                        {Header: "Estado",  accessor: "status", Cell:(props)=>t(props.value)},
                                        {Header: "Detalles",  accessor:"id", filterable:false,  Cell: (props) =>
                                                <div className="text-center">
                                                    <ButtonCustom
                                                        name={'viewParameters'}
                                                        id={'viewParameters'}
                                                        title={'Ver'}
                                                        size={'sm'}
                                                        disabled={ props.original.status==='Failed' || props.original.status==='Pending'|| props.original.status==='Cancelled'|| props.original.status==='Processing' || props.original.status==='Timeout' || props.original.status==='WaitingResponse'}
                                                        className={''}
                                                        Click={() => {
                                                            apiClient.requests.getRequestResponse({requestId: props.value})
                                                                .then(response => {
                                                                    // console.log(response)
                                                                    if (response.gateway.communicationType.name ==='Http'){
                                                                        // console.log('Http', response.requestType.name );
                                                                        this.setState({
                                                                            modal: !this.state.modal,
                                                                            response: this.requestResponseHttp(response),
                                                                        });
                                                                    }else if(response.gateway.communicationType.name ==='Tcp') {
                                                                        // console.log('TCP', response.requestType.name);
                                                                        this.setState({
                                                                            modal: !this.state.modal,
                                                                            response: this.requestResponseTcp(response),
                                                                        });
                                                                    }
                                                                }).catch((error) => {this.setState({toggleModalStatusCode:true, error:error, loading: false})  })
                                                        }}
                                                    />
                                                    {' '}
                                                    <ButtonCustom
                                                        name={'cancel'+ props.value.toString()}
                                                        title={'Cancelar'}
                                                        color={'danger'}
                                                        size={'sm'}
                                                        disabled={!(props.original.status==='Pending'|| props.original.status==='Processing')}
                                                        className={''}
                                                        Click={()=>{this.cancelRequest(props.value)}}
                                                    />
                                                </div>
                                        },
                                    ]}
                                    onFetchData={(state) => {
                                        this.setState({
                                            pageSize: state.pageSize
                                        });
                                    }}

                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
export default Petitions;
