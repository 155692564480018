import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { ModalCustom } from "../../components/custom/ModalCustom";
import classnames from "classnames";
import MeterInstallation from "./components/MeterInstallation";
import MeterWithProblem from "./components/MeterWithProblem";

const METER_TABS = {
   meterInstallation: 1,
   meterWithProblem: 2,
};

export const Meter = () => {
   const [error, setError] = useState("");
   const [activeTab, setActiveTab] = useState(METER_TABS.meterInstallation);
   const [openStatusCodeModal, setOpenStatusCodeModal] = useState(false);

   const handleOnTabChange = (tab) => {
      if (activeTab !== tab) {
         setActiveTab(tab);
      }
   };

   const toggleStatusCodeModal = () => {
      setOpenStatusCodeModal((old) => !old);
   };

   const handleErrorModal = (error) => {
      setError(error);
      toggleStatusCodeModal();
   };

   return (
      <div>
         <ModalCustom
            error={error}
            toggleModal={openStatusCodeModal}
            toggleModalFunc={toggleStatusCodeModal}
         />
         <Nav tabs>
            <NavItem>
               <NavLink
                  className={classnames({
                     active: activeTab === METER_TABS.meterInstallation,
                  })}
                  onClick={() => {
                     handleOnTabChange(METER_TABS.meterInstallation);
                  }}
               >
                  <span>Instalación de medidores</span>
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink
                  className={classnames({
                     active: activeTab === METER_TABS.meterWithProblem,
                  })}
                  onClick={() => {
                     handleOnTabChange(METER_TABS.meterWithProblem);
                  }}
               >
                  <span>Medidores con problemas</span>
               </NavLink>
            </NavItem>
         </Nav>
         <TabContent activeTab={activeTab}>
            <TabPane tabId={METER_TABS.meterInstallation}>
               <MeterInstallation handleError={handleErrorModal} />
            </TabPane>
            <TabPane tabId={METER_TABS.meterWithProblem}>
               <MeterWithProblem handleError={handleErrorModal} />
            </TabPane>
         </TabContent>
      </div>
   );
};

export default Meter;
