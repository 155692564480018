export const columns = [
  "Serial",
  "Tipo",
  "Fecha Gateway ",
  "Fecha Meter",
  "Performance",
];

export const columnWidthConfigs = [
  { column: "A", width: 15 },
  { column: "B", width: 15 },
  { column: "C", width: 20 },
  { column: "D", width: 20 },
  { column: "E", width: 13 },
];
