/* eslint-disable */
import React from "react";
import {Card, Row, Col, CardTitle, CardBody, FormGroup, Input, Button, FormText, Spinner} from "reactstrap";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import apiClient from '../../api/apiClient';

export default function Dashboard() {

    const [fileName, setFileName] = React.useState("");
    const [isFile, setIsFile] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState({
        name: "",
        fileContent: "",
        fileType: ""
    });
    const refInput = React.useRef(null);


    const uploadFile = () => {
        refInput.current.click();
    };


    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFileInput = async (e) => {
        try {
            let file = e.target.files[0];
            if (!file) return
            let fileName = file.name;
            setFileName(fileName);
            setIsFile(true);
            let type = "application/json";
            let base64String = await toBase64(file);
            const fileContent = base64String
                .replace("data:", "")
                .replace(/^.+,/, "");

            setFile({
                name: fileName,
                fileContent: fileContent,
                fileType: type
            });
        } catch (e) {
            Swal.fire("¡Error!", "No hay archivo", "error");
        }
    };


    function b64toBlob(dataURI) {
        let byteString = atob(dataURI.split(",")[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: "application/zip" });
    }

    const sendFile = () => {
        setLoading(true);
        apiClient.readings.getBillingReadings(file).then(response => {
            let data = response
            let mime = `data:application/zip;base64,`;
            let fileContent = data.fileContent
            let base64 = `${mime}${fileContent}`;
            let blob = b64toBlob(base64);
            saveAs(blob, `${data.name}.zip`);
        }).catch(error => {
            console.log(error)
            Swal.fire("¡Error!", "Hubo un error al enviar su archivo. Vuelva a intentarlo más tarde.", "error");
        }).finally(() => {
            setLoading(false);
        });
    };


    return (
        <div>
            <Card>
                <CardTitle style={{fontSize: "18px"}} className="bg-light border-bottom p-3 mb-0"><i
                    className="mdi mdi-hamburger mr-2"> </i>Facturación</CardTitle>
                <CardBody className="border-top">
                    <Row className="justify-content-center pb-2">
                        <Col md={4}>
                            <Input name="fileName" id="fileName" placeholder={"Selecione archivo"}
                                   defaultValue={fileName} onClick={uploadFile}/>
                            <FormGroup>
                                <input accept=".jees" ref={refInput} style={{display: "none"}}
                                       onChange={handleFileInput} type="file"
                                       name="file" id="exampleFile"/>
                                <FormText color="muted">
                                    Tipos de archivo permitidos: <b style={{fontWeight: "bold"}}>jees</b>
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={4} className="align-self-center">
                            <Button disabled={!(isFile)} size={"sm"} onClick={sendFile}
                                    color="primary">{loading ? "Enviando archivo..." : "Enviar archivo "} {loading &&
                            <Spinner style={{marginLeft: 5}} size={'sm'}/>}</Button>

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

