import React from 'react';
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Form,
    Input,
    FormGroup,
} from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import "react-table/react-table.css";
import apiClient from "../../api/apiClient";
import ReactJson from 'react-json-view';
import ButtonCustom from "../../components/custom/ButtonCustom";
import {ModalCustom} from "../../components/custom/ModalCustom";

import Trans from "../../translate/Translate";
const t =(text)=><Trans text={text}/>;


class Tcp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Json:{},
            tctFrame:''
        };
    }

    tcpFrameSubmit=()=>{
        let tctFrame= this.state.tctFrame;
        apiClient.tcp.parseFrame({frame:tctFrame}).then((response)=>{
            this.setState({Json:response});
        }).catch(error => {
            console.log(error);
            this.setState({toggleModalStatusCode: true, error: error, loading: false})
        });
    };

    tcpFrameOnchange=(e)=>{
        this.setState({tctFrame:e.target.value})
    };


    render() {
        let component ='';
        let json = this.state.Json;
        if (json !== undefined) {
            component =  <ReactJson style={{fontSize:18}} src={json} name={false} displayDataTypes={false} displayObjectSize={true} sortKeys={false}/>
        }
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={()=>this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode})}/>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-access-point"> </i>Tcp</CardTitle>
                    <CardBody className="border-top">

                        <Row className={"justify-content-center"}>
                            <Col md={12}>
                                <Form>
                                    <FormGroup>
                                        <label> Frame </label>
                                        <Input type="textarea" rows="4" onChange={this.tcpFrameOnchange}
                                               value={this.state.tctFrame} name="tcp" id="tctFrame"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={10}>{component}</Col>
                            <Col className="text-right" md={2}>
                                <ButtonCustom
                                    Click={this.tcpFrameSubmit}
                                    style={{paddingLeft:20}}
                                    // className={''}
                                    // disabled={!(this.state.masterId && Object.keys(this.state.requestTypes).length===2)}
                                    name={'submit'} id={'submit'}
                                    title={'Enviar'} color={'success'}/>

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Tcp;
