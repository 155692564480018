import React from "react";
import {
   Card,
   Row,
   Col,
   CardTitle,
   CardBody,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
   Input,
   Modal,
   ModalFooter,
   ModalHeader,
   ModalBody,
   Button,
} from "reactstrap";
import apiClient from "../../api/apiClient";
import Swal from "sweetalert2";
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import classnames from "classnames";
import CustomDate from "../../components/custom/Date";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
   currentDate,
   customDateFrom,
   inputDateProblemTypes,
   Time,
} from "../../constants/DateTime";
import Select from "react-select";
import { EditFilling } from "../../models/FillingModel";

class Fillings extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpen: false,
         activeTab: "2",
         dateFrom: customDateFrom(),
         dateFromAdd: customDateFrom(),
         dateTo: currentDate(),
         dateToAdd: currentDate(),
         meter: "",
         getMeterReadingsGaps: [],
         getMeterReadingsGapsQueue: [],
         toggleModalStatusCode: false,
         error: "",
         modalAddFillings: false,
         modalEditFillings: false,
         selectedMeter: "",
         metersData: [],
         status: [],
         editFillingItem: new EditFilling(),
      };
   }

   toggle = (tab) => {
      this.setState({
         isOpen: !this.state.isOpen,
      });

      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab,
         });
      }
   };

   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };

   mouseOver = (e) => {
      if (e.clientY > 90 && e.clientY < 125) {
         this.setState({ dateOpen: undefined });
      }
   };

   componentDidMount = () => {
      apiClient.readings
         .getReadingGapStatuses()
         .then((response) => {
            this.setState({
               status: response.data.map((x) => {
                  return { label: x.name, value: x.id };
               }),
            });
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
         });
   };

   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateProblemTypes(moment) });
   };

   getMeter = (e) => {
      this.setState({ meter: e.target.value });
   };

   getGaps = () => {
      let ad = this.state;
      if (ad.meter && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.readings
            .getMeterReadingsGaps({
               serial: ad.meter,
               from: ad.dateFrom,
               to: ad.dateTo,
            })
            .then((res) => {
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  this.setState({
                     getMeterReadingsGaps: res.data,
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   getGapsQueue = () => {
      let ad = this.state;
      if (ad.meter && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.readings
            .getMeterReadingsGapsQueue({
               serial: ad.meter,
               from: ad.dateFrom,
               to: ad.dateTo,
            })
            .then((res) => {
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  this.setState({
                     getMeterReadingsGapsQueue: res.data,
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   getDataMeters = (serial) => {
      apiClient.meters
         .getInstalledMetersFiltered({ serial: serial })
         .then((response) => {
            this.setState({
               metersData: response.data
                  .map((meter) => {
                     meter.value = meter.serial;
                     meter.label = meter.serial;
                     return meter;
                  })
                  .slice(0, 7),
            });
         });
   };

   onChangeMeter = (event) => {
      this.setState({ selectedMeter: event });
   };

   addNewFilling = () => {
      let { dateFromAdd, dateToAdd, selectedMeter } = this.state;
      apiClient.readings
         .registerMeterReadingGap({
            serial: selectedMeter.serial,
            from: dateFromAdd,
            to: dateToAdd,
         })
         .then((response) => {
            this.state.getMeterReadingsGapsQueue.splice(0, 0, response);
            let newFilling = this.state.getMeterReadingsGapsQueue.slice();
            this.setState({
               getMeterReadingsGapsQueue: newFilling,
               modalAddFillings: false,
            });
            Swal.fire({
               type: "success",
               title: "Relleno agregado",
               timer: 1500,
            });
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
         });
   };

   ediFilling = (id) => {
      let { editFillingItem } = this.state;
      let serial = this.state.getMeterReadingsGapsQueue.find(
         (item) => item.id === id
      );
      editFillingItem["id"] = serial.id;
      editFillingItem["serial"] = serial.meter.serial;
      editFillingItem["status"] = {
         label: serial.status.name,
         value: serial.status.id,
      };
      editFillingItem["retries"] = serial.retries;
      editFillingItem["from"] = serial.from;
      editFillingItem["to"] = serial.to;
      this.setState({ modalEditFillings: true, editFillingItem });
   };

   resetSerial = (id) => {
      apiClient.readings
         .reQueueMeterReadingGap({ id: id })
         .then((response) => {
            let fillings = [...this.state.getMeterReadingsGapsQueue];
            fillings.splice(
               this.state.getMeterReadingsGapsQueue.findIndex(
                  (x) => x.id === id
               ),
               1,
               response
            );
            this.setState({ getMeterReadingsGapsQueue: fillings });
            Swal.fire({
               type: "success",
               title: "Serial reiniciado",
               timer: 1500,
            });
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
         });
   };

   onChangeFilling = (e, type) => {
      let { editFillingItem } = this.state;
      if (type === "serial") {
      }
      if (type === "status") {
         editFillingItem["status"] = e;
      }
      if (type === "retries") {
         editFillingItem["retries"] = e.target.value;
      }
      if (type === "from") {
         editFillingItem["from"] = inputDateProblemTypes(e);
      }
      if (type === "to") {
         editFillingItem["to"] = inputDateProblemTypes(e);
      }
      this.setState({ editFillingItem });
   };

   UpdateFilling = () => {
      let { editFillingItem } = this.state;
      apiClient.readings
         .editMeterReadingGap({
            id: editFillingItem.id,
            serial: editFillingItem.serial,
            status: editFillingItem.status.value,
            retries: editFillingItem.retries,
            from: editFillingItem.from,
            to: editFillingItem.to,
         })
         .then((response) => {
            let fillings = [...this.state.getMeterReadingsGapsQueue];
            fillings.splice(
               this.state.getMeterReadingsGapsQueue.findIndex(
                  (x) => x.id === editFillingItem.id
               ),
               1,
               response
            );
            this.setState({
               getMeterReadingsGapsQueue: fillings,
               modalEditFillings: false,
            });
            Swal.fire({
               type: "success",
               title: "Relleno actualizado",
               timer: 1500,
            });
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
         });
   };

   render() {
      let {
         modalAddFillings,
         activeTab,
         dateFrom,
         dateFromAdd,
         dateTo,
         dateToAdd,
         error,
         toggleModalStatusCode,
         selectedMeter,
         metersData,
         getMeterReadingsGapsQueue,
         modalEditFillings,
         editFillingItem,
         status,
      } = this.state;
      const {
         onChangeMeter,
         getDataMeters,
         addNewFilling,
         resetSerial,
         ediFilling,
         onChangeFilling,
         UpdateFilling,
      } = this;
      let filterOption = (
         <Row className={"justify-content-center"}>
            <Col md={2}>
               <label>Medidor</label>
               <Input
                  type="text"
                  name="meter"
                  id="meter"
                  placeholder="Medidor"
                  onChange={this.getMeter}
               />
            </Col>
            <Col md={2}>
               <label> Desde </label>
               <CustomDate
                  id="DateFrom"
                  timeFormat={"HH:mm"}
                  value={new Date(dateFrom)}
                  onChange={(moment) => this.onDateChange(moment, "dateFrom")}
               />
            </Col>
            <Col md={2}>
               <label> Hasta </label>
               <CustomDate
                  id="DateTo"
                  timeFormat={"HH:mm"}
                  value={new Date(dateTo)}
                  onChange={(moment) => this.onDateChange(moment, "dateTo")}
               />
            </Col>
            <Col md={2}>
               <label>
                  {" "}
                  <br />{" "}
               </label>
               <ButtonCustom
                  name={"generate"}
                  id={"generate"}
                  title={"Generar"}
                  Click={activeTab === "1" ? this.getGaps : this.getGapsQueue}
               />
            </Col>
            {activeTab === "2" && (
               <Col md={2}>
                  <label>
                     {" "}
                     <br />{" "}
                  </label>
                  <ButtonCustom
                     name={"search"}
                     id={"search"}
                     title={"Agregar"}
                     icon={<i className="mdi mdi-plus"> </i>}
                     color={"orange"}
                     Click={() =>
                        this.setState({ modalAddFillings: !modalAddFillings })
                     }
                  />
               </Col>
            )}
         </Row>
      );

      return (
         <div>
            <ModalCustom
               error={error}
               toggleModal={toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <Modal isOpen={modalAddFillings}>
               <ModalHeader
                  toggle={() =>
                     this.setState({ modalAddFillings: !modalAddFillings })
                  }
               >
                  {" "}
                  Agregar relleno{" "}
               </ModalHeader>
               <ModalBody className="justify-content-center">
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label htmlFor="serial">Serial</label>
                        <Select
                           onInputChange={getDataMeters}
                           name="searchMeter"
                           value={selectedMeter}
                           id="searchMeter"
                           options={metersData}
                           placeholder="Buscar medidor"
                           className="text-dark"
                           onChange={onChangeMeter}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label> Desde </label>
                        <CustomDate
                           id="DateFromAdd"
                           timeFormat={"HH:mm"}
                           value={new Date(dateFromAdd)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateFromAdd")
                           }
                        />
                     </Col>
                  </Row>
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label> Hasta </label>
                        <CustomDate
                           id="DateToAdd"
                           timeFormat={"HH:mm"}
                           value={new Date(dateToAdd)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateToAdd")
                           }
                        />
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <ButtonCustom
                     color={"success"}
                     className={""}
                     name={"send"}
                     id={"send"}
                     Click={addNewFilling}
                     title={"Enviar"}
                  />{" "}
                  <ButtonCustom
                     color={"danger"}
                     className={""}
                     name={"cancel"}
                     id={"cancel"}
                     Click={() =>
                        this.setState({ modalAddFillings: !modalAddFillings })
                     }
                     title={"Cerrar"}
                  />
               </ModalFooter>
            </Modal>
            <Modal isOpen={modalEditFillings}>
               <ModalHeader
                  toggle={() =>
                     this.setState({ modalEditFillings: !modalEditFillings })
                  }
               >
                  {" "}
                  Agregar relleno{" "}
               </ModalHeader>
               <ModalBody className="justify-content-center">
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label>Serial</label>
                        <Input
                           value={editFillingItem.serial}
                           readOnly
                           id={"serial"}
                           name={"serial"}
                           onChange={(e) => onChangeFilling(e, "serial")}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label>Estados</label>
                        <Select
                           value={editFillingItem.status}
                           options={status}
                           placeholder="Estados"
                           name="status"
                           id="status"
                           className="text-dark"
                           onChange={(e) => onChangeFilling(e, "status")}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label>Intentos</label>
                        <Input
                           value={editFillingItem.retries}
                           id={"retries"}
                           name={"retries"}
                           onChange={(e) => onChangeFilling(e, "retries")}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label> Desde </label>
                        <CustomDate
                           id="from"
                           timeFormat={"HH:mm"}
                           value={new Date(editFillingItem.from)}
                           onChange={(moment) =>
                              onChangeFilling(moment, "from")
                           }
                        />
                     </Col>
                  </Row>
                  <Row className="justify-content-center">
                     <Col md={8}>
                        <label> Hasta </label>
                        <CustomDate
                           id="to"
                           timeFormat={"HH:mm"}
                           value={new Date(editFillingItem.to)}
                           onChange={(moment) => onChangeFilling(moment, "to")}
                        />
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <ButtonCustom
                     color={"success"}
                     className={""}
                     name={"update"}
                     id={"update"}
                     Click={UpdateFilling}
                     title={"Actualizar"}
                  />{" "}
                  <ButtonCustom
                     color={"danger"}
                     className={""}
                     name={"cancel"}
                     id={"cancel"}
                     Click={() =>
                        this.setState({ modalEditFillings: !modalEditFillings })
                     }
                     title={"Cerrar"}
                  />
               </ModalFooter>
            </Modal>
            <Nav tabs>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "1",
                     })}
                     onClick={() => {
                        this.toggle("1");
                     }}
                  >
                     <span>Faltantes</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "2",
                     })}
                     onClick={() => {
                        this.toggle("2");
                     }}
                  >
                     <span>En cola</span>
                  </NavLink>
               </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
               <TabPane tabId="1">
                  <Card>
                     <CardTitle
                        style={{ fontSize: "18px" }}
                        className="bg-light border-bottom p-3 mb-0"
                     >
                        <i className="mdi mdi-hamburger mr-2"> </i>Rellenos
                        faltantes
                     </CardTitle>
                     <CardBody>
                        <br />
                        {filterOption}
                        <br />
                        <TableLayout
                           data={this.state.getMeterReadingsGaps}
                           defaultPageSize={10}
                           filterable={true}
                           columns={[
                              { Header: "Medidor", accessor: "serial" },
                              {
                                 Header: "Fecha",
                                 accessor: "readingDate",
                                 Cell: (props) => Time(props.value),
                              },
                           ]}
                        />
                     </CardBody>
                  </Card>
               </TabPane>
               <TabPane tabId="2">
                  <Card>
                     <CardTitle
                        style={{ fontSize: "18px" }}
                        className="bg-light border-bottom p-3 mb-0"
                     >
                        <i className="mdi mdi-hamburger mr-2"> </i>Rellenos en
                        cola
                     </CardTitle>
                     <CardBody>
                        <br />
                        {filterOption}
                        <br />
                        <TableLayout
                           data={getMeterReadingsGapsQueue}
                           defaultPageSize={10}
                           filterable={true}
                           columns={[
                              {
                                 Header: "Medidor",
                                 accessor: "meter.serial",
                              },
                              { Header: "Estado", accessor: "status.name" },
                              {
                                 Header: "Desde",
                                 accessor: "from",
                                 Cell: (props) => Time(props.value),
                              },
                              {
                                 Header: "Hasta",
                                 accessor: "to",
                                 Cell: (props) => Time(props.value),
                              },
                              { Header: "Intentos", accessor: "retries" },
                              {
                                 Header: "Calculado en",
                                 accessor: "calculatedAt",
                                 Cell: (props) => Time(props.value),
                              },
                              {
                                 Header: "Actualizado en",
                                 accessor: "updatedAt",
                                 Cell: (props) => Time(props.value),
                              },
                              {
                                 Header: "Acción",
                                 accessor: "id",
                                 Cell: (props) => (
                                    <div>
                                       <Button
                                          size={"sm"}
                                          color={"success"}
                                          onClick={() =>
                                             ediFilling(props.value)
                                          }
                                       >
                                          Editar
                                       </Button>{" "}
                                       <Button
                                          size={"sm"}
                                          color={"warning"}
                                          onClick={() =>
                                             resetSerial(props.value)
                                          }
                                       >
                                          Reiniciar
                                       </Button>
                                    </div>
                                 ),
                              },
                           ]}
                        />
                     </CardBody>
                  </Card>
               </TabPane>
            </TabContent>
         </div>
      );
   }
}

export default Fillings;
