import * as React from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import GenericTable from "../../components/custom/GenericTable";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import {
   customDateFrom,
   customDateTo,
   formatDate,
   inputDateTime,
} from "../../constants/DateTime";
import {
   maxRecordToShowConfirmation,
   paginationModel,
} from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/meterRequest";
import { saveAsExcel } from "../../utilities/exportToExcel";

const MeterPLC = () => {
   const [dateFrom, setDateFrom] = React.useState(customDateFrom());
   const [dateTo, setDateTo] = React.useState(customDateTo());
   const [data, setData] = React.useState([]);
   const [headers, setHeaders] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);
   const [pages, setPages] = React.useState(0);
   const [pagination, setPagination] = React.useState(paginationModel);
   const [typeFilter, setTypeFilter] = React.useState(1);
   const [masterData, setMasterData] = React.useState([]);
   const [filterValue, setFilterValue] = React.useState({
      meter: "",
      gatewayId: 0,
      masterName: "",
   });

   //Handle pagination manually
   const [triggerOnFetchData, setTriggerOnFetchData] = React.useState(false);
   const [tableState, setTableState] = React.useState();

   React.useEffect(() => {
      if (triggerOnFetchData) {
         handleSearch({
            page: tableState.page + 1,
            length: pagination.pageSize,
            sorted: tableState.sorted,
            filtered: tableState.filtered,
         });
      }
   }, [triggerOnFetchData]);

   React.useEffect(() => {
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            setMasterData(items);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   }, []);
   const handleConsult = () => {
      if (typeFilter === 1) {
         if (filterValue.gatewayId === 0) {
            Swal.fire({
               type: "warning",
               title: "Campos vacios",
               text: "Debes seleccionar un master",
            });
            return;
         }
      } else {
         if (filterValue.meter === "") {
            Swal.fire({
               type: "warning",
               title: "Campos vacios",
               text: "Debes ingresar un serial",
            });
            return;
         }
      }
      handleSearch({
         length: pagination.pageSize,
         pageSize: pagination.pageSize,
      });
   };
   const handleOnDateChange = (moment, value) => {
      if (value === "DateFrom") {
         setDateFrom(inputDateTime(moment));
      } else {
         setDateTo(inputDateTime(moment));
      }
   };
   const TypeEntry = [
      { value: 2, label: "Meter" },
      { value: 1, label: "Master" },
   ];

   const getMeter = (e) => {
      setFilterValue({
         meter: e.target.value,
         gatewayId: 0,
      });
   };
   const getMasterId = (e) => {
      setFilterValue({
         gatewayId: e.value,
         masterName: masterData.find((master) => master.value === e.value)
            .label,
         meter: "",
      });
   };
   const handleSearch = ({ draw = 0, page = 1, length, sorted, filtered }) => {
      setIsLoading(true);
      if (typeFilter === 1) {
         apiClient.meters
            .getMeterByGatewayIdListingSinceUntil({
               gatewayId: filterValue.gatewayId,
               from: formatDate(dateFrom),
               to: formatDate(dateTo),
               draw,
               page,
               length,
               sorted,
               filtered,
            })
            .then((result) => {
               if (result.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     setData([]);
                     setIsLoading(false);
                  });
               } else {
                  setData(result.data);
                  setIsLoading(false);
                  setPages(
                     Math.ceil(result.recordsTotal / pagination.pageSize)
                  );
                  setPagination((old) => ({
                     ...old,
                     recordsTotal: result.recordsTotal,
                     filtered,
                     sorted,
                  }));
               }
               setHeaders(result.headers);
            })
            .catch((error) => {
               setIsLoading(false);
               if (error.detail === "The specified gateway does not exists") {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "El Master especificado no existe.!",
                  });
               } else if (error.detail === "It's not authorized") {
                  Swal.fire({
                     type: "warning",
                     title: "Autorización",
                     text: "No esta autorizado!",
                  });
               } else {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "Ohhhs, ocurrio un error.",
                  });
               }
            })
            .finally(() => setTriggerOnFetchData(false));
      } else {
         apiClient.meters
            .getMeterBySerialListingSinceUntil({
               meter: filterValue.meter,
               from: formatDate(dateFrom),
               to: formatDate(dateTo),
               draw,
               page,
               length,
               sorted,
               filtered,
            })
            .then((result) => {
               if (result.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     setData([]);
                     setIsLoading(false);
                  });
               } else {
                  setData(result.data);
                  setIsLoading(false);
                  setPages(
                     Math.ceil(result.recordsTotal / pagination.pageSize)
                  );
                  setPagination((old) => ({
                     ...old,
                     recordsTotal: result.recordsTotal,
                     filtered,
                     sorted,
                  }));
               }
               setHeaders(result.headers);
            })
            .catch((error) => {
               setIsLoading(false);
               if (error.detail === "The specified meter does not exists") {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "El medidor especificado no existe.!",
                  });
               } else if (error.detail === "It's not authorized") {
                  Swal.fire({
                     type: "warning",
                     title: "Autorización",
                     text: "No esta autorizado!",
                  });
               } else {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "Ohhhs, ocurrio un error.",
                  });
               }
            })
            .finally(() => setTriggerOnFetchData(false));
      }
   };
   const handleToExcel = () => {
      if (pagination.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 pagination.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               donwloadToExcel();
            }
         });
      } else {
         donwloadToExcel();
      }
   };
   const donwloadToExcel = () => {
      setIsLoading(true);
      if (typeFilter === 1) {
         apiClient.meters
            .getMeterByGatewayIdListingSinceUntil({
               gatewayId: filterValue.gatewayId,
               from: formatDate(dateFrom),
               to: formatDate(dateTo),
               draw: 0,
               page: 1,
               length: pagination.recordsTotal,
               sorted: pagination.sorted,
               filtered: pagination.filtered,
            })
            .then((rest) => {
               saveAsExcel({
                  columns: columnsExport,
                  data: convertDataExport(rest.data),
                  endMergeTitle: "J1",
                  endMergeSubTitle: "J2",
                  columnWidthConfigs,
                  title: "REPORTE DE MEDIDORES PLC",
                  name: `Gw. ${
                     filterValue.masterName
                  } Medidores PLC (${new Date().toISOString().slice(0, 10)})`,
                  subTitle: `Gateway:${filterValue.masterName}  DESDE:  ${dateFrom}  HASTA:  ${dateTo}`,
               });
               setIsLoading(false);
            })
            .catch((error) => {
               setIsLoading(false);
               if (error.detail === "The specified meter does not exists") {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "El medidor especificado no existe.!",
                  });
               } else if (error.detail === "It's not authorized") {
                  Swal.fire({
                     type: "warning",
                     title: "Autorización",
                     text: "No esta autorizado!",
                  });
               } else {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "Ohhhs, ocurrio un error.",
                  });
               }
            });
      } else {
         apiClient.meters
            .getMeterBySerialListingSinceUntil({
               meter: filterValue.meter,
               from: formatDate(dateFrom),
               to: formatDate(dateTo),
               draw: 0,
               page: 1,
               length: pagination.recordsTotal,
               sorted: pagination.sorted,
               filtered: pagination.filtered,
            })
            .then((rest) => {
               saveAsExcel({
                  columns: columnsExport,
                  data: convertDataExport(rest.data),
                  endMergeTitle: "J1",
                  endMergeSubTitle: "J2",
                  columnWidthConfigs,
                  title: "REPORTE DE MEDIDORES PLC",
                  name: `Mt. ${filterValue.meter} Medidores PLC (${new Date()
                     .toISOString()
                     .slice(0, 10)})`,
                  subTitle: `DESDE:  ${dateFrom}  HASTA:  ${dateTo}`,
               });
               setIsLoading(false);
            })
            .catch((error) => {
               setIsLoading(false);
               if (error.detail === "The specified meter does not exists") {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "El medidor especificado no existe.!",
                  });
               } else if (error.detail === "It's not authorized") {
                  Swal.fire({
                     type: "warning",
                     title: "Autorización",
                     text: "No esta autorizado!",
                  });
               } else {
                  Swal.fire({
                     type: "error",
                     title: "Error",
                     text: "Ohhhs, ocurrio un error.",
                  });
               }
            });
      }
   };
   const convertDataExport = (data) => {
      return data?.map((dataExcel) => ({
         serial: Number.parseInt(dataExcel.serial),
         recordId: dataExcel.recordId,
         cardSerial: dataExcel.cardSerial,
         cardSerial: dataExcel.cardSerial,
         fatherCardSerial: dataExcel.fatherCardSerial,
         tries: dataExcel.tries,
         txgain: dataExcel.txgain,
         busyPlc: dataExcel.busyPlc,
         gatewaySnr: dataExcel.gatewaySnr,
         meterSnr: dataExcel.meterSnr,
         lastCommunication: dataExcel.lastCommunication,
      }));
   };
   return (
      <>
         <LoadingComponent loading={isLoading} />
         <Card>
            <CardTitle
               style={{ fontSize: "18px" }}
               className="bg-light border-bottom p-3 mb-0"
            >
               <i className="mdi mdi-hamburger mr-2"> </i>Consulta de medidores
               PLC
            </CardTitle>
            <CardBody className="border-top">
               <Row className={"justify-content-center"}>
                  <Col md={2}>
                     <label> Tipo </label>
                     <Select
                        name="selectType"
                        id="selectType"
                        defaultValue={[TypeEntry[1]]}
                        options={TypeEntry}
                        placeholder="Type"
                        closeMenuOnSelect={true}
                        onChange={(e) => setTypeFilter(e.value)}
                     />
                  </Col>
                  <Col md={2}>
                     <label> Tipo de origen </label>
                     {typeFilter === 1 ? (
                        <Select
                           name="master"
                           id="master"
                           options={masterData}
                           placeholder="Gateway"
                           closeMenuOnSelect={true}
                           onChange={getMasterId}
                        />
                     ) : (
                        <Input
                           type="text"
                           name="meter"
                           id="meter"
                           placeholder="Medidor"
                           onChange={getMeter}
                        />
                     )}
                  </Col>
                  <Col md={2}>
                     <label> Desde </label>
                     <CustomDate
                        id="DateFrom"
                        value={new Date(dateFrom)}
                        onChange={(moment) =>
                           handleOnDateChange(moment, "DateFrom")
                        }
                     />
                  </Col>
                  <Col md={2}>
                     <label> Hasta </label>
                     <CustomDate
                        id="DateTo"
                        value={new Date(dateTo)}
                        onChange={(moment) =>
                           handleOnDateChange(moment, "DateTo")
                        }
                     />
                  </Col>
               </Row>
               <Row className={"justify-content-center"}>
                  <Col md={2}>
                     <label>
                        {" "}
                        <br />{" "}
                     </label>
                     <ButtonCustom
                        name={"generate"}
                        id={"generate"}
                        title={"Generar"}
                        Click={handleConsult}
                     />
                  </Col>{" "}
                  <Col md={2}>
                     <label>
                        {" "}
                        <br />{" "}
                     </label>
                     <ButtonCustom
                        loading={isLoading}
                        Click={handleToExcel}
                        disabled={!(data.length > 0)}
                        style={{ paddingLeft: 20 }}
                        block
                        name={"submit"}
                        id={"submit"}
                        title={"Descargar"}
                        color={"success"}
                     />
                  </Col>
               </Row>
               <br />
               <GenericTable
                  data={data}
                  headers={headers}
                  loading={isLoading}
                  pages={pages}
                  onPageSizeChange={(value) => {
                     setPagination((old) => ({ ...old, pageSize: value }));
                  }}
                  pageSize={
                     data.length > 0 && data.length < pagination.pageSize
                        ? data.length
                        : pagination.pageSize
                  }
                  onFetchData={(state) => {
                     if (
                        filterValue.gatewayId !== 0 ||
                        filterValue.meter !== ""
                     ) {
                        setTableState(state);
                        setTriggerOnFetchData(true);
                     }
                  }}
               />
            </CardBody>
         </Card>
      </>
   );
};

export default MeterPLC;
