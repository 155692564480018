export function splitDateRangeInDays(startDate, endDate, interval) {
    interval = interval || 1;
    let dates = [],
        currentDate = startDate,
        addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        const dateFormat = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), 0);
        dates.push(dateFormat );
        currentDate = addDays.call(currentDate, interval);
    }
    return dates;
}


export function splitDateRangeInHours(startDate, endDate, interval) {
    interval = interval || 1;
    let dates = [],
        currentDate = startDate,
        addHours = function(hours) {
            let date = new Date(this.valueOf());
            date.setHours(date.getHours() + hours);
            return date;
        };
    while (currentDate <= endDate) {
        const dateFormat = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), 0);
        dates.push(dateFormat);
        currentDate = addHours.call(currentDate, interval);
    }
    dates.splice(-1,1);
    return dates;
}
