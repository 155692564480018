import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App'
import './assets/scss/style.css';

//constants hist = createBrowserHistory();

ReactDOM.render(
    <App />
    ,document.getElementById('root'));
