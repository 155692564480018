import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import useGenericTable from "../hooks/useGenericTable";
import TableLayout from "./TableLayout";
import { CardBody, Col, Row } from "reactstrap";
import ButtonCustom from "./ButtonCustom";

function GenericTable({
   data = [],
   headers,
   actions,
   customColumns,
   loading,
   getTrProps,
   getTdProps,
   onFetchData,
   triggerOnFetchData,
   pages,
   onAddClick = null,
   onDownloadClick = null,
   onPageSizeChange,
   pageSize,
}) {
   const { columns, reactTableRef } = useGenericTable({
      headers,
      data,
      actions,
      customColumns,
      triggerOnFetchData,
   });

   return (
      <CardBody>
         {onAddClick && (
            <Row className={"justify-content-start mb-3 "}>
               <Col md={2}>
                  <ButtonCustom
                     name={"add"}
                     id={"add"}
                     title={"Agregar"}
                     icon={<i className="mdi mdi-plus"> </i>}
                     color={"info"}
                     Click={onAddClick}
                  />
               </Col>
            </Row>
         )}

         <TableLayout
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            ref={reactTableRef}
            data={data}
            pages={pages}
            manual
            trProps={getTrProps}
            getTdProps={getTdProps}
            filterable={true}
            loading={loading}
            onFetchData={debounce(onFetchData, 500)}
            columns={columns}
            showPaginationBottom={false}
         />

         {onDownloadClick && (
            <Row className={"justify-content-end mt-3 "}>
               <Col md={2}>
                  <ButtonCustom
                     loading={loading}
                     Click={onDownloadClick}
                     disabled={!(data.length > 0)}
                     style={{ paddingLeft: 20 }}
                     block
                     name={"submit"}
                     id={"submit"}
                     title={"Descargar"}
                     color={"success"}
                  />
               </Col>
            </Row>
         )}
      </CardBody>
   );
}

export default GenericTable;
