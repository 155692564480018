import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock';

const defaultOptions = {
};

const StockChart = ({options}) => {
    const chartOptions = Object.assign({}, defaultOptions, options);
    return <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
    />
};

export default StockChart


