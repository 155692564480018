import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";

class Sidebar extends React.Component {
   constructor(props) {
      super(props);
      this.expandLogo = this.expandLogo.bind(this);
      this.activeRoute.bind(this);
      this.state = {
         authentication:
            this.activeRoute("/authentication") !== "" ? true : false,
         uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
         samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
         dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
         iconsPages: this.activeRoute("/icons") !== "" ? true : false,
         formlayoutPages:
            this.activeRoute("/form-layouts") !== "" ? true : false,
         formpickerPages:
            this.activeRoute("/form-pickers") !== "" ? true : false,
      };
   }

   expandLogo() {
      document.getElementById("logobg").classList.toggle("expand-logo");
   }

   activeRoute(routeName) {
      return this.props.location.pathname.indexOf(routeName) > -1
         ? "selected"
         : "";
   }

   render() {
      return (
         <aside
            className="left-sidebar"
            id="sidebarbg"
            data-sidebarbg={this.props.data.settings[0].sidebarbg}
            onMouseEnter={this.expandLogo}
            onMouseLeave={this.expandLogo}
         >
            <div className="scroll-sidebar">
               <div className="sidebar-nav">
                  <Nav
                     className={"justify-content-center"}
                     style={{ background: "#2962ff" }}
                     id="sidebarnav"
                  >
                     {this.props.routes.map((prop, key) => {
                        if (prop.isVisible) {
                           return null;
                        }
                        if (prop.redirect) {
                           return null;
                        } else if (prop.navlabel) {
                           return (
                              <li className="nav-small-cap" key={key}>
                                 <i className={prop.icon}></i>
                                 <span className="hide-menu">{prop.name}</span>
                              </li>
                           );
                        } else if (prop.collapse) {
                           let firstdd = {};
                           firstdd[prop["state"]] = !this.state[prop.state];
                           return (
                              <li
                                 className={
                                    this.activeRoute(prop.path) +
                                    "  sidebar-item   " +
                                    prop.extra
                                 }
                                 key={key}
                              >
                                 <span
                                    onClick={() => this.setState(firstdd)}
                                    data-toggle="collapse"
                                    className="sidebar-link has-arrow"
                                    aria-expanded={this.state[prop.state]}
                                 >
                                    <i
                                       style={{ color: "#fff" }}
                                       className={prop.icon}
                                    />
                                    <span
                                       style={{ color: "#fff" }}
                                       className="hide-menu"
                                    >
                                       {prop.name}
                                    </span>
                                 </span>
                                 <Collapse
                                    style={{ width: "auto" }}
                                    className="first-level"
                                    isOpen={this.state[prop.state]}
                                 >
                                    <ul>
                                       {prop.child.map((prop, key) => {
                                          if (prop.redirect) return null;
                                          if (prop.collapse) {
                                             let seconddd = {};
                                             seconddd[prop["state"]] =
                                                !this.state[prop.state];
                                             return (
                                                <li
                                                   className={
                                                      this.activeRoute(
                                                         prop.path
                                                      ) + "  sidebar-item"
                                                   }
                                                   key={key}
                                                >
                                                   <span
                                                      data-toggle="collapse"
                                                      className="sidebar-link has-arrow "
                                                      aria-expanded={
                                                         this.state[prop.state]
                                                      }
                                                      onClick={() =>
                                                         this.setState(seconddd)
                                                      }
                                                   >
                                                      <i
                                                         className={prop.icon}
                                                      />
                                                      <span className="hide-menu">
                                                         {prop.name}
                                                      </span>
                                                   </span>
                                                   <Collapse
                                                      className="second-level"
                                                      isOpen={
                                                         this.state[prop.state]
                                                      }
                                                   >
                                                      <ul>
                                                         {prop.subchild.map(
                                                            (prop, key) => {
                                                               if (
                                                                  prop.redirect
                                                               )
                                                                  return null;
                                                               return (
                                                                  <li
                                                                     className={
                                                                        this.activeRoute(
                                                                           prop.path
                                                                        ) +
                                                                        " sidebar-item"
                                                                     }
                                                                     key={key}
                                                                  >
                                                                     <NavLink
                                                                        to={
                                                                           prop.path
                                                                        }
                                                                        activeClassName="active"
                                                                        className="sidebar-link"
                                                                     >
                                                                        <i
                                                                           className={
                                                                              prop.icon
                                                                           }
                                                                        />
                                                                        <span className="hide-menu">
                                                                           {
                                                                              prop.name
                                                                           }
                                                                        </span>
                                                                     </NavLink>
                                                                  </li>
                                                               );
                                                            }
                                                         )}
                                                      </ul>
                                                   </Collapse>
                                                </li>
                                             );
                                          }
                                          return (
                                             <li
                                                className={
                                                   this.activeRoute(prop.path) +
                                                   (prop.pro
                                                      ? "active active-pro"
                                                      : "") +
                                                   "sidebar-item"
                                                }
                                                key={key}
                                             >
                                                <NavLink
                                                   to={prop.path}
                                                   className="sidebar-link"
                                                   activeClassName="active"
                                                >
                                                   <i
                                                      style={
                                                         this.activeRoute(
                                                            prop.path
                                                         )
                                                            ? { color: "#fff" }
                                                            : { color: "" }
                                                      }
                                                      className={prop.icon}
                                                   />
                                                   <span className="hide-menu">
                                                      {prop.name}
                                                   </span>
                                                </NavLink>
                                             </li>
                                          );
                                       })}
                                    </ul>
                                 </Collapse>
                              </li>
                           );
                        } else {
                           return (
                              <li
                                 className={
                                    this.activeRoute(prop.path) +
                                    (prop.pro ? " active active-pro" : "") +
                                    " sidebar-item"
                                 }
                                 key={key}
                              >
                                 <NavLink
                                    to={prop.path}
                                    className="sidebar-link"
                                    activeClassName="active"
                                 >
                                    <i
                                       style={{ color: "#fff" }}
                                       className={prop.icon}
                                    />
                                    <span
                                       style={{ color: "#fff" }}
                                       className="hide-menu"
                                    >
                                       {prop.name}
                                    </span>
                                 </NavLink>
                              </li>
                           );
                        }
                     })}
                  </Nav>
               </div>
            </div>
         </aside>
      );
   }
}
export default Sidebar;
