import React, { useEffect, useState } from "react";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import {
    Card,
    CardTitle,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import TableLayout from "../../components/custom/TableLayout";
import { ModalCustom } from "../../components/custom/ModalCustom";
import ViewGatewayPlc from "./components/ViewGatewayPlc";

export default function GatewayPlc(...props) {
    const {} = props;

    const [gatewayPlcList, setGatewayPlcList] = useState([]);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [selectedGateway, setSelectedGateway] = useState();
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiClient.gateways
            .getAllGatewayPlcConfiguration()
            .then((response) => {
                if (response) {
                    const data = response.data;
                    setGatewayPlcList(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                setErrorMsg(error);
                toggleErrorModal();
            });
        setLoading(false);
    }, []);

    useEffect(() => {
        if (selectedGateway != null) {
            const index = gatewayPlcList.findIndex(
                (x) => x.id === selectedGateway.id
            );
            if (index != null) {
                let record = gatewayPlcList;
                record[index] = selectedGateway;
                setGatewayPlcList(record);
            }
        }
    }, [selectedGateway]);

    const toggleModal = () => {
        setOpenFormModal(!openFormModal);
    };

    const toggleErrorModal = () => {
        setOpenErrorModal(!openErrorModal);
    };

    const onButtonClick = ({ value }) => {
        var index = gatewayPlcList.findIndex((x) => x.id === value);
        setSelectedGateway(gatewayPlcList[index]);
        toggleModal();
    };
    return (
        <>
            <ModalCustom
                error={errorMsg}
                toggleModal={openErrorModal}
                toggleModalFunc={toggleErrorModal}
            />
            <LoadingComponent loading={loading} />
            <Modal isOpen={openFormModal}>
                <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <ViewGatewayPlc
                        value={selectedGateway}
                        toggleModal={toggleModal}
                        title={setModalTitle}
                    />
                </ModalBody>
            </Modal>
            <Card>
                <CardTitle
                    style={{ fontSize: "18px" }}
                    className="bg-light border-bottom p-3 mb-0"
                >
                    <i className="mdi mdi-hamburger mr-2"> </i>
                    Configuración PLC de los gateway
                </CardTitle>
                <CardBody>
                    <TableLayout
                        data={gatewayPlcList}
                        // pages={this.state.pages}
                        defaultPageSize={10}
                        manual={false}
                        filterable={true}
                        pageSizeOptions={[10, 20, 30]}
                        columns={[
                            {
                                Header: "Alias",
                                accessor: "gatewayAlias",
                            },
                            {
                                Header: "Identificador",
                                accessor: "gatewayIdentifier",
                            },
                            {
                                Header: "Frecuencia alta añadida",
                                accessor: "highFrequencyAdd",
                            },
                            {
                                Header: "Frecuencia alta",
                                accessor: "highFrequency",
                            },
                            {
                                Header: "Frecuencia baja",
                                accessor: "lowFrequency",
                            },
                            {
                                Header: "Maestro",
                                accessor: "masterId",
                            },
                            {
                                Header: "txGainN",
                                accessor: "txGainN",
                            },
                            {
                                Header: "txGainR",
                                accessor: "txGainR",
                            },
                            {
                                Header: "toModem",
                                accessor: "toModem",
                            },
                            {
                                Header: "toPlc",
                                accessor: "toPlc",
                            },
                            {
                                Header: "ssKey",
                                accessor: "ssKey",
                            },
                            {
                                Header: "Última actualización",
                                accessor: "updatedAt",
                            },
                            {
                                Header: "Acción",
                                accessor: "id",
                                sortable: false,
                                filterable: false,
                                Cell: (props) => (
                                    <div className="text-center">
                                        <ButtonCustom
                                            name={"view"}
                                            id={"view"}
                                            title={"Ver"}
                                            color={"success"}
                                            className={""}
                                            size={"sm"}
                                            Click={() =>
                                                onButtonClick({
                                                    value: props.value,
                                                })
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </CardBody>
            </Card>
        </>
    );
}
