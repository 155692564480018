export const columns = [
  "Fecha",
  "Conexión fallida",
  "Pdp Deact",
  "Cpin no listo",
  "Apagado normal",
  "QoS",
];

export const columnWidthConfigs = [
  { column: "A", width: 20 },
  { column: "B", width: 16 },
  { column: "C", width: 12 },
  { column: "D", width: 15 },
  { column: "E", width: 17 },
  { column: "F", width: 10 },
];
