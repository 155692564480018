export const columns = [
   "Nombre de origen",
   "Identificador",
   "Alias",
   "Severidad",
   "Cantidad",
   "Tipo",
   "Fecha de Ocurrencia",
   "Procesado",
   "Detalles",
];

export const columnWidthConfigs = [
   { column: "A", width: 20 },
   { column: "B", width: 20 },
   { column: "C", width: 35 },
   { column: "D", width: 12 },
   { column: "E", width: 12 },
   { column: "F", width: 35 },
   { column: "G", width: 25 },
   { column: "H", width: 12 },
   { column: "I", width: 25 },
];
