export const columns = [
   "Fecha",
   "Exitosos",
   "RequestType",
   "Error",
   "Parametros",
   "Respuesta",
];

export const columnWidthConfigs = [
   { column: "A", width: 25 },
   { column: "B", width: 12 },
   { column: "C", width: 15 },
   { column: "D", width: 12 },
   { column: "E", width: 25 },
   { column: "F", width: 25 },
];
