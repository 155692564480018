export const columns = [
  "Identificador",
  "Alias",
  "Estado actual",
  "Estado",
  "Tipo comunicación",
  "Tipo de módulo de comunicación",
  "Dirección de comunicación",
  "Última comunicación",
  "Instalado en",
  "Institución",
];

export const columnWidthConfigs = [
  { column: "A", width: 17 },
  { column: "B", width: 20 },
  { column: "C", width: 13 },
  { column: "D", width: 13 },
  { column: "E", width: 20 },
  { column: "F", width: 30 },
  { column: "G", width: 25 },
  { column: "H", width: 25 },
  { column: "I", width: 25 },
  { column: "J", width: 15 },
];
