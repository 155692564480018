export const columns = [
  "Serial",
  "Card Serial",
  "Father Card Serial",
  "Tipo medidor",
  "Forma",
  "Tipo módulo",
  "Fase comunicación",
  "Estado",
  "Instalado en",
  "Latitud",
  "Longitud",
];
export const columnWidthConfigs = [
  { column: "A", width: 10 },
  { column: "B", width: 12 },
  { column: "C", width: 17 },
  { column: "D", width: 13 },
  { column: "E", width: 10 },
  { column: "F", width: 12 },
  { column: "G", width: 20 },
  { column: "H", width: 10 },
  { column: "I", width: 20 },
  { column: "J", width: 12 },
  { column: "K", width: 12 },
];
