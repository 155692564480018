export const columns = [
  "Medidor",
  "Fecha",
  "PowerOff",
  "Medidor limpio",
  "Demanda limpia",
  "Cover Open",
  "Reiniciar módulo",
  "Reiniciar",
];

export const columnWidthConfigs = [
  { column: "A", width: 12 },
  { column: "B", width: 20 },
  { column: "C", width: 12 },
  { column: "D", width: 17 },
  { column: "E", width: 17 },
  { column: "F", width: 12 },
  { column: "G", width: 17 },
  { column: "H", width: 15 },
];
