import classnames from "classnames";
import * as moment from "moment";
import React from "react";
import Select from "react-select";
import {
   Button,
   Card,
   CardBody,
   CardTitle,
   Col,
   Nav,
   NavItem,
   NavLink,
   Row,
   TabContent,
   TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import StockChart from "../../components/chart/StockChart";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import TableLayout from "../../components/custom/TableLayout";
import {
   customDateFrom,
   customDateTo,
   inputDateTime,
   Time,
} from "../../constants/DateTime";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/punctualReadings";
import { saveAsExcel } from "../../utilities/exportToExcel";
import { filterByFieldAndValue } from "../../utilities/genericFilters";

class LecturasPuntuales extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         loading: false,
         masterId: [],
         readings: [],
         readingsAux: [],
         isOpen: false,
         activeTab: "2",
         masterName: "",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         fillingData: [],
         page: 0,
         pageSize: 10,
         recordsTotal: 0,
         filtered: [],
         sorted: [],
         fillingTypes: [
            { value: 0, label: "Normal" },
            { value: 1, label: "Refill" },
            { value: 3, label: "RefillFromLp" },
            { value: 4, label: "RefillFromLpExtended" },
         ],
         showAlert: false,
         textAlert: "",
         titleAlert: "",
         colorAlert: "",
         showSeries: true,
         chartOptions: {
            chart: { type: "spline", height: "600px" },
            navigator: { adaptToUpdatedData: true, series: [] },
            scrollbar: {
               barBackgroundColor: "#e0e0e0",
               barBorderRadius: 7,
               barBorderWidth: 0,
               buttonBackgroundColor: "#e0e0e0",
               buttonBorderWidth: 0,
               buttonBorderRadius: 7,
               trackBackgroundColor: "none",
               trackBorderWidth: 1,
               trackBorderRadius: 8,
               trackBorderColor: "#CCC",
            },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: false,
            },
            tooltip: {
               shared: true,
               split: false,
               useHTML: true,
               outside: true,
               formatter: function () {
                  let table = [[], []];
                  this.points.map((data, key) => {
                     if (key % 2 === 0) {
                        table[0].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     } else {
                        table[1].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     }
                  });
                  return (
                     '<small style="font-size: small">Fecha: ' +
                     moment(this.points[0].x).utc().format("YYYY-MM-DD HH:mm") +
                     "</small>" +
                     '<div style="display: table; clear: both; width:25vw">' +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[0].join(" ") +
                     "</div>" +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[1].join(" ") +
                     "</div>" +
                     "</div>"
                  );
               },
               valueDecimals: 2,
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            xAxis: {
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%y-%m-%d %H:%M}" },
               minRange: 3600 * 1000,
               plotLines: [{ color: "#FF0000", width: 2, value: 0 }],
            },
            yAxis: {
               title: { text: "Desempeño %" },
               allowDecimals: false,
               min: 0,
               max: 100,
            },
            credits: false,
            series: [],
         },
         toggleModalStatusCode: false,
         error: "",
      };
   }
   toggle = (tab) => {
      this.setState({
         isOpen: !this.state.isOpen,
      });

      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab,
         });
      }
   };
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };

   mouseOver = (e) => {
      if (e.clientY > 115 && e.clientY < 150) {
         this.setState({ dateOpen: undefined });
      }
   };
   componentWillUnmount() {
      window.removeEventListener("mouseover", this.mouseOver);
   }

   componentDidMount = () => {
      window.addEventListener("mouseover", this.mouseOver);
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
      apiClient.readings
         .getRefillTypes()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.name };
            });
            items.splice(0, 0, { value: 5, label: "Todos" });
            this.setState({ fillingData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   getMasterId = (e) => {
      this.setState({ masterId: e });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };
   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };
   getFillingType = (e) => {
      let selectedOption = [];
      if (e !== null) {
         if (e.find((x) => x.value === 5) !== undefined) {
            this.state.fillingData
               .filter((x) => x.value !== 5)
               .map((x) => {
                  selectedOption.push(x);
                  return true;
               });
         } else {
            selectedOption = e;
         }
      }
      this.setState({ fillingTypes: selectedOption });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      saveAsExcel({
         columns: columnsExport,
         data: this.convertDataExport(),
         endMergeTitle: "C1",
         endMergeSubTitle: "C2",
         columnWidthConfigs,
         title: "REPORTE DE LECTURAS PUNTUALES",
         name: `Gw. ${this.state.masterName} Lecturas puntuales   (${new Date()
            .toISOString()
            .slice(0, 10)})`,
         subTitle: `DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
      });
      this.setState({
         ...this.state,
         loading: false,
      });
   };
   convertDataExport = () => {
      const result = this.state.readingsAux.map((item) => ({
         serial: Number.parseInt(item.serial),
         dateTimeRead: item.dateTimeRead,
         quantity: item.quantity,
      }));
      return result;
   };

   getReportSchedulePerformance = () => {
      let ad = this.state;
      let array = ad.fillingTypes.map((x) => {
         return x.value;
      });
      if (
         ad.fillingTypes.length > 0 &&
         ad.masterId !== "" &&
         ad.dateFrom !== "" &&
         ad.dateTo !== ""
      ) {
         this.setState({ loading: true });
         apiClient.readings
            .getReadingsOClock({
               gatewayId: ad.masterId.value,
               from: ad.dateFrom,
               to: ad.dateTo,
               refillTypes: array,
               headers: { "content-type": "application/x-www-form-urlencoded" },
            })
            .then((res) => {
               if (res.readings.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  let arrayReadings = [];
                  res.readings.map((x) => {
                     for (let i = 0; i < x.length; i++) {
                        arrayReadings.push(x[i]);
                     }
                     return true;
                  });
                  this.setState({
                     readings: arrayReadings,
                     readingsAux: arrayReadings,
                     page: 0,
                     recordsTotal: arrayReadings.length,
                  });
               }
               let series = [];
               const shadowSeries = res.shadow.map((shadow) => {
                  let datetime = new Date(shadow.dateTime);
                  let dateFormat = Date.UTC(
                     datetime.getFullYear(),
                     datetime.getMonth(),
                     datetime.getDate(),
                     datetime.getHours(),
                     datetime.getMinutes(),
                     0
                  );
                  return [dateFormat, shadow.performance];
               });
               series.push({
                  name: "Desempeño",
                  data: shadowSeries,
                  type: "areaspline",
                  color: "#bbdefb",
                  gapSize: 5,
               });
               res.readings.forEach((performances) => {
                  let meterSerial = performances[0].serial;
                  let meterPerformance = performances.map((performance) => {
                     let dateTimeD = new Date(performance.dateTimeRead);
                     let dateFormatD = Date.UTC(
                        dateTimeD.getFullYear(),
                        dateTimeD.getMonth(),
                        dateTimeD.getDate(),
                        dateTimeD.getHours(),
                        dateTimeD.getMinutes(),
                        0
                     );
                     return [dateFormatD, performance.quantity * 100];
                  });
                  series.push({
                     name: meterSerial,
                     data: meterPerformance,
                     type: "spline",
                  });
               });
               this.updateSeries(series);
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };
   updateSeries = (series) => {
      this.setState({
         chartOptions: {
            navigator: { series: series },
            series: series,
         },
         loading: false,
      });
   };

   showLegendValues = () => {
      this.setState({
         chartOptions: {
            series: this.state.chartOptions.series.map((x) => {
               x.visible = !this.state.showSeries;
               return x;
            }),
            navigator: {
               series: this.state.chartOptions.series.map((x) => {
                  return {
                     name: x.name,
                     data: x.data,
                     visible: true,
                     type: "spline",
                  };
               }),
            },
         },
         showSeries: !this.state.showSeries,
      });
   };

   fileCSV = () => {
      let ad = this.state;
      let array = ad.fillingTypes.map((x) => {
         return x.value;
      });
      if (ad.masterId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.readings
            .getReadingsOClockFile({
               gatewayId: ad.masterId.value,
               from: ad.dateFrom,
               to: ad.dateTo,
               RefillTypes: array,
               // responseType: 'blob'
            })
            .then((response) => {
               const url = window.URL.createObjectURL(new Blob([response]));
               const link = document.createElement("a");
               link.href = url;
               link.setAttribute(
                  "download",
                  ad.dateTo.concat(
                     " a ",
                     ad.dateFrom,
                     "-desempeño_lectura_puntuales",
                     ".csv"
                  )
               );
               document.body.appendChild(link);
               link.click();
               this.setState({ loading: false });
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   render() {
      const tableTabulated = [
         {
            Header: "Fecha",
            accessor: "dateTimeRead",
            Cell: (props) => Time(props.value),
         },
         { Header: "Medidor", accessor: "serial" },
         { Header: "Cantidad", accessor: "quantity" },
      ];
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Nav tabs>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "1",
                     })}
                     onClick={() => {
                        this.toggle("1");
                     }}
                  >
                     <span>Tabulado</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "2",
                     })}
                     onClick={() => {
                        this.toggle("2");
                     }}
                  >
                     <span>Gráfico</span>
                  </NavLink>
               </NavItem>
            </Nav>
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Reporte de lecturas
                  puntuales
               </CardTitle>
               <CardBody className="border-top">
                  <TabContent activeTab={this.state.activeTab}>
                     <br />
                     <Row className={"justify-content-center"}>
                        <Col md={2}>
                           <label> Gateway </label>
                           <Select
                              name="selectMaster"
                              id="selectMaster"
                              options={this.state.masterData}
                              placeholder="Gateway"
                              closeMenuOnSelect={true}
                              value={this.state.masterId}
                              onChange={this.getMasterId}
                           />
                        </Col>
                        <Col md={2}>
                           <label> Desde </label>
                           <CustomDate
                              id="DateFrom"
                              timeFormat={"HH:00"}
                              value={new Date(this.state.dateFrom)}
                              onChange={(moment) =>
                                 this.onDateChange(moment, "dateFrom")
                              }
                           />
                        </Col>
                        <Col md={2}>
                           <label> Hasta </label>
                           <CustomDate
                              id="DateTo"
                              timeFormat={"HH:00"}
                              value={new Date(this.state.dateTo)}
                              onChange={(moment) =>
                                 this.onDateChange(moment, "dateTo")
                              }
                           />
                        </Col>
                     </Row>
                     <Row className={"justify-content-center"}>
                        <Col md={4}>
                           <label> Tipo de relleno </label>
                           <Select
                              options={this.state.fillingData}
                              id={"itemDetail"}
                              name={"itemDetail"}
                              closeMenuOnSelect={false}
                              isClearable
                              isMulti
                              placeholder={"Tipo de relleno"}
                              value={this.state.fillingTypes}
                              onChange={this.getFillingType}
                           />
                        </Col>
                        <Col md={2}>
                           <label>
                              {" "}
                              <br />{" "}
                           </label>
                           <ButtonCustom
                              name={"generate"}
                              id={"Generar"}
                              title={"Generar"}
                              Click={this.getReportSchedulePerformance}
                           />
                        </Col>{" "}
                        <Col md={2}>
                           <label>
                              <br />
                           </label>
                           <ButtonCustom
                              loading={this.state.loading}
                              Click={this.handleToExcel}
                              disabled={!(this.state.readings.length > 0)}
                              style={{ paddingLeft: 20 }}
                              block
                              name={"submit"}
                              id={"submit"}
                              title={"Descargar"}
                              color={"success"}
                           />
                        </Col>
                     </Row>
                     <br />
                     <TabPane tabId="1">
                        <TableLayout
                           data={this.state.readings}
                           columns={tableTabulated}
                           defaultPageSize={this.state.pageSize}
                           showPaginationBottom={true}
                           showPaginationTop={true}
                           noDataText={false}
                           onFetchData={(state) => {
                              if (state.filtered.length > 0) {
                                 let aux = this.state.readings;
                                 state.filtered.forEach((item) => {
                                    aux = filterByFieldAndValue({
                                       array: aux,
                                       field: item.id,
                                       value: item.value,
                                    });
                                    this.setState({
                                       ...this.state,
                                       readingsAux: aux,
                                       recordsTotal: aux.length,
                                    });
                                 });
                              } else {
                                 this.setState({
                                    ...this.state,
                                    readingsAux: this.state.readings,
                                    recordsTotal: this.state.readings.length,
                                 });
                              }
                           }}
                        />
                     </TabPane>
                     <TabPane tabId="2">
                        <StockChart options={this.state.chartOptions} />
                        <Button
                           style={
                              this.state.chartOptions.series.length !== 0
                                 ? { display: "" }
                                 : { display: "none" }
                           }
                           color={"cyan"}
                           onClick={this.showLegendValues}
                        >
                           {this.state.showSeries ? "Ocultar" : "Mostrar"}
                        </Button>
                     </TabPane>
                  </TabContent>
               </CardBody>
            </Card>
         </div>
      );
   }
}
export default LecturasPuntuales;
