export const columns = [
   "Medidor",
   "Fecha lectura",
   "Fecha de Ingreso",
   "E. Activa",
   "E. Reactiva",
   "E. Reversa",
   "E. Activa T1",
   "E. Activa T3",
   "E. Reactiva T1",
   "E. Reactiva T3",
   "E. Reversa T1",
   "E. Reversa T3",
   "P. T1",
   "P. T3",
   "Fecha P. T1",
   "Fecha P. T3",
   "Bandera",
   "Intentos",
];
export const columnWidthConfigs = [
   { column: "A", width: 12 },
   { column: "B", width: 22 },
   { column: "C", width: 22 },
   { column: "D", width: 12 },
   { column: "E", width: 15 },
   { column: "F", width: 12 },
   { column: "G", width: 14 },
   { column: "H", width: 15 },
   { column: "I", width: 18 },
   { column: "J", width: 18 },
   { column: "K", width: 14 },
   { column: "L", width: 16 },
   { column: "O", width: 22 },
   { column: "P", width: 22 },
   { column: "Q", width: 12 },
   { column: "R", width: 12 },
];
