import * as React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";

import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import { CustomInput } from "../../components/custom/CustomInput";
import CustomDate from "../../components/custom/Date";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import TableLayout from "../../components/custom/TableLayout";

import {
   compareDateAfter,
   compareDateBefore,
   customDateTo,
   formatDate,
   inputDateTime,
   Time,
} from "../../constants/DateTime";

const ReadingHistory = () => {
   const [dateFrom, setDateFrom] = React.useState("");
   const [dateTo, setDateTo] = React.useState(customDateTo());
   const [meter, setMeter] = React.useState("");
   const [meterConsulted, setMeterConsulted] = React.useState(null);
   const [readings, setReadings] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);
   const [pages, setPages] = React.useState(0);
   const [pageSize, setPageSize] = React.useState(10);
   const [recordsTotal, setRecordsTotal] = React.useState(0);
   const handleOnChangeInput = (e) => {
      setMeter(e.target.value);
   };
   const handleOnDateChange = (moment, value) => {
      if (value === "DateFrom") {
         setDateFrom(inputDateTime(moment));
      } else {
         setDateTo(inputDateTime(moment));
      }
   };
   const handleValidatedConsult = () => {
      if (meter === "" || meter.length === 0) {
         Swal.fire("Error", "Introduzca un medidor .", "error");
         return;
      }
      if (!dateFrom) {
         Swal.fire("Error", "Seleccione un rango de fecha .", "error");
         return;
      }

      if (compareDateBefore({ until: dateTo, since: dateFrom })) {
         Swal.fire("Error", "Hasta no puede ser menor a desde.", "error");
         return;
      }
      setMeterConsulted(meter);
      handleConsult({ length: pageSize, pageSize });
   };

   const handleConsult = ({
      draw = 0,
      pageSize,
      start = 1,
      length,
      sorted,
      filtered,
   }) => {
      setIsLoading(true);
      apiClient.readings
         .getMeterReadingsSinceUntil({
            meter,
            from: formatDate(dateFrom),
            to: formatDate(dateTo),
            draw,
            start,
            length,
            sorted,
            filtered,
         })
         .then((result) => {
            if (result.data.length === 0) {
               Swal.fire({
                  type: "info",
                  title: "No hay información",
                  text: "Debes seleccionar otro rango de fechas",
               }).then(() => {
                  setReadings([]);
                  setIsLoading(false);
               });
            } else {
               setReadings(result.data);
               setIsLoading(false);
               setRecordsTotal(result.recordsTotal);
               setPages(Math.ceil(result.recordsTotal / pageSize));
            }
         })
         .catch((error) => {
            setIsLoading(false);
            if (error.detail === "The specified meter does not exists") {
               Swal.fire({
                  type: "error",
                  title: "Error",
                  text: "El medidor especificado no existe.!",
               });
            } else if (error.detail === "It's not authorized") {
               Swal.fire({
                  type: "warning",
                  title: "Autorización",
                  text: "No esta autorizado!",
               });
            } else {
               Swal.fire({
                  type: "error",
                  title: "Error",
                  text: "Ohhhs, ocurrio un error.",
               });
            }
         });
   };

   const handleRemoveAll = () => {
      Swal.fire({
         html: `<strong > Confirmaci&oacute;n.</strong><br/>
              ¿Esta seguro de querer eliminar estos registros.? <br/>
             <strong style='color:#d33'> Se borrar&aacuten ${new Intl.NumberFormat(
                "en-IN",
                { maximumSignificantDigits: 3 }
             ).format(
                recordsTotal
             )}  lecturas del medidor ${meter}  </strong>  <br/> <strong style='color:#d33'>entre fechas ${formatDate(
            dateFrom
         )} y  ${formatDate(dateTo)} </strong>  `,
         type: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Eliminar",
      }).then((result) => {
         if (result.value !== undefined) {
            if (meterConsulted === "" || meterConsulted.length === 0) {
               return;
            }
            if (meterConsulted !== meter) {
               Swal.fire(
                  "Error",
                  "Las lecturas que muestra la tabla es de un medidor diferente.",
                  "error"
               );
               return;
            } else {
               apiClient.readings
                  .deleteMeterReadingsSinceUntil({
                     serial: meter,
                     from: formatDate(dateFrom),
                     to: formatDate(dateTo),
                  })
                  .then((result) => {
                     setIsLoading(false);
                     if (result) {
                        Swal.fire({
                           type: "info",
                           title: "Información",
                           text: "Lecturas borradas.!",
                        }).then(() => {
                           setReadings([]);
                           setMeter("");
                        });
                     }
                  })
                  .catch((error) => {
                     setIsLoading(false);
                     if (
                        error.detail === "The specified meter does not exists"
                     ) {
                        Swal.fire({
                           type: "error",
                           title: "Error",
                           text: "El medidor especificado no existe.!",
                        });
                     } else if (error.detail === "It's not authorized") {
                        Swal.fire({
                           type: "warning",
                           title: "Autorización",
                           text: "No esta autorizado!",
                        });
                     } else {
                        Swal.fire({
                           type: "error",
                           title: "Error",
                           text: "Ohhhs, ocurrio un error.",
                        });
                     }
                  });
            }
         }
      });
   };

   return (
      <>
         <LoadingComponent loading={isLoading} />
         <Card>
            <CardTitle
               style={{ fontSize: "18px" }}
               className="bg-light border-bottom p-3 mb-0"
            >
               <i className="mdi mdi-hamburger mr-2"> </i>Consulta de lecturas
            </CardTitle>
            <CardBody className="border-top">
               <Row className={"justify-content-center"}>
                  <Col md={2}>
                     <CustomInput
                        onChange={handleOnChangeInput}
                        value={meter}
                        label={"Medidor"}
                        id={"meter"}
                     />
                  </Col>
                  <Col md={2}>
                     <label> Desde </label>
                     <CustomDate
                        id="DateFrom"
                        initialValue={new Date()}
                        onChange={(moment) =>
                           handleOnDateChange(moment, "DateFrom")
                        }
                     />
                  </Col>
                  <Col md={2}>
                     <label> Hasta </label>
                     <CustomDate
                        id="DateTo"
                        value={new Date(dateTo)}
                        onChange={(moment) =>
                           handleOnDateChange(moment, "DateTo")
                        }
                     />
                  </Col>
               </Row>
               <Row className={"justify-content-center"}>
                  <Col md={2}>
                     <ButtonCustom
                        name={"consult"}
                        id={"consult"}
                        title={"Consultar"}
                        Click={handleValidatedConsult}
                     />
                  </Col>
                  <Col md={2}>
                     <ButtonCustom
                        name={"clear"}
                        id={"clear"}
                        title={"Borrar todo"}
                        color={"danger"}
                        disabled={!(readings.length > 0)}
                        Click={handleRemoveAll}
                     />
                  </Col>
               </Row>
               <br />
               <TableLayout
                  data={readings}
                  pages={pages}
                  defaultPageSize={pageSize}
                  manual={true}
                  loading={isLoading}
                  onFetchData={(state) => {
                     if (meter) {
                        handleConsult({
                           pageSize: state.pageSize,
                           start: state.page * state.pageSize,
                           length: state.pageSize,
                           sorted: state.sorted,
                           filtered: state.filtered,
                        });
                     }
                  }}
                  columns={[
                     {
                        Header: "Fecha lectura",
                        accessor: "readingDate",
                        minWidth: 125,
                        Cell: (props) => Time(props.value),
                     },
                     {
                        Header: "Fecha de ingreso",
                        accessor: "insertDate",
                        minWidth: 155,
                        Cell: (props) =>
                           props.value !== null ? Time(props.value) : "N/D",
                     },
                     { Header: "E. activa", accessor: "activeEnergy" },
                     { Header: "E. reactiva", accessor: "reactiveEnergy" },
                     { Header: "E. reversa", accessor: "reverseEnergy" },

                     { Header: "E. activa T1", accessor: "activeEnergyT1" },
                     { Header: "E. activa T3", accessor: "activeEnergyT3" },

                     { Header: "E. reactiva T1", accessor: "reactiveEnergyT1" },
                     { Header: "E. reactiva T3", accessor: "reactiveEnergyT3" },

                     { Header: "E. reversa T1", accessor: "reverseEnergyT1" },
                     { Header: "E. reversa T3", accessor: "reverseEnergyT3" },

                     { Header: "P. T1", accessor: "powerT1" },
                     { Header: "P. T3", accessor: "powerT3" },

                     {
                        Header: "Fecha P. T1",
                        accessor: "powerReadingDateT1",
                        minWidth: 155,
                        Cell: (props) =>
                           props.value !== null ? Time(props.value) : "N/D",
                     },
                     {
                        Header: "Fecha P. T3",
                        accessor: "powerReadingDateT3",
                        minWidth: 155,
                        Cell: (props) =>
                           props.value !== null ? Time(props.value) : "N/D",
                     },
                     { Header: "Bandera", accessor: "flag" },
                     { Header: "Intentos", accessor: "tries" },
                  ]}
               />
            </CardBody>
         </Card>
      </>
   );
};

export default ReadingHistory;
