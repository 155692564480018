import * as signalR from '@microsoft/signalr';
import authService from "./AuthService";

const hubUrl = process.env.REACT_APP_API_URL + "/notifications";

const connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, { transport: signalR.HttpTransportType.ServerSentEvents | signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => authService.getAccessToken() })
    .configureLogging(signalR.LogLevel.Warning)
    .build();

export default {
    start() {
        return connection.start();
    },
    stop() {
        return connection.stop();
    },
    onNotification(handler) {
        connection.on("ReceiveNotification", notification => {
            handler(notification);
        });
    }
}
