import {Time} from "./DateTime";
/**
 * @return {{duration: (string|string), createdAt: string, requestType: (string|*), serial: (string|*), id: *, status: *}}
 */
export function Response(data) {
    if (Array.isArray(data)) {
        return data.map(data => {
            let createdAt = Time(data.createdAt), upd = new Date(data.updatedAt), cre = new Date(data.createdAt);
            let subs = upd - cre;
            let time = new Date(null);
            time.setMilliseconds(subs);
            let duration = data.status.name === "Pending" ? "N/A" : time.toISOString().substr(11, 11);
            let serial = data.parameters === null ? "N/A" : data.parameters.serial;
            let requestType = data.requestType.id === 3 || data.requestType.id === 27 ? data.requestType.name : data.requestType.name;
            return {
                gatewayId:data.gatewayId,
                createdAt: createdAt,
                id: data.id,
                duration: duration,
                serial: serial,
                requestType: requestType,
                status: data.status.name,
                cmd1: data.parameters !== null ? data.parameters.cmd1 === undefined ? 0 : data.parameters.cmd1 : 0,
                requestTypeId: data.requestType.id
            }
        });
    } else {
        let createdAt = Time(data.createdAt), upd = new Date(data.updatedAt), cre = new Date(data.createdAt);
        let subs = upd - cre;
        let time = new Date(null);
        time.setMilliseconds(subs);
        let duration = data.status.name === "Pending" ? "N/A" : time.toISOString().substr(11, 11);
        let serial = data.parameters === null ? "N/A" : data.parameters.serial;
        let requestType = data.requestType.id === 3 || data.requestType.id === 27 ? data.requestType.name : data.requestType.name;
        return {
            gatewayId:data.gatewayId,
            createdAt: createdAt,
            id: data.id,
            duration: duration,
            serial: serial,
            requestType: requestType,
            status: data.status.name,
            cmd1: data.parameters !== null ? data.parameters.cmd1 === undefined ? 0 : data.parameters.cmd1 : 0,
            requestTypeId: data.requestType.id
        }
    }
}
