export class SimModel {
sim="";
phoneNumber="";
}

export class GroupModel {
name="";
institution={id:"", name:""};
}

export class RoleModel {
    id="";
    name="";
}

export class InstitutionModel {
    id="";
    name="";
    description="";
}