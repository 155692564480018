import React from "react";
import ReactJson from "react-json-view";
import Select from "react-select";
import {
   Card,
   CardBody,
   CardTitle,
   Col,
   Modal,
   ModalBody,
   ModalFooter,
   ModalHeader,
   Row,
} from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import GenericTable from "../../components/custom/GenericTable";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
   customDateFrom,
   customDateTo,
   inputDateTime,
} from "../../constants/DateTime";
import {
   booleanOptions,
   FILTER_TYPE,
   maxRecordToShowConfirmation,
   notDefined,
} from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/commandHistory";
import { saveAsExcel } from "../../utilities/exportToExcel";
class HistoricoComandos extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         readingHeaders: [],
         triggerOnFetchData: false,
         CommandData: [],
         OptCommand: [],
         readingsData: [],
         isOpen: false,
         masterId: "",
         masterName: "",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         parametersData: [],
         responseData: [],
         modalParameters: false,
         modalResponse: false,
         page: 0,
         pages: 0,
         pageSize: 10,
         loading: false,
         pageIndex: 0,
         toggleModalStatusCode: false,
         error: "",
         Json: {},
         dataExcel: [],
         recordsTotal: 0,
         filtered: [],
         sorted: [],
         responseErrorList: [],
         requestTypeSelected: { value: "", label: "Todos" },
         responseErrorSelected: { value: "", label: "Todos" },
         successfulSelected: { value: "", label: "Todos" },
         selectionChanged: false,
      };
      this.tmpRow = new Set();
   }

   toggleP = () => {
      this.setState({
         modalParameters: !this.state.modalParameters,
      });
   };
   toggleR = () => {
      this.setState({
         Json: {},
         modalResponse: !this.state.modalResponse,
      });
   };
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount = () => {
      window.addEventListener("mouseover", this.mouseOver);

      apiClient.requests
         .getResponseErrors()
         .then((response) => {
            this.setState({ responseErrorList: response.data });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return {
                  value: result.id,
                  label: result.alias,
                  protocolType: result.communicationType.id,
               };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
      apiClient.requests
         .getRequestTypes()
         .then((res) => {
            let items = res.data.map((result) => {
               result.value = result.id;
               result.label = result.name;
               return result;
            });
            items.splice(0, 0, { value: 0, label: "Todo" });
            this.setState({ CommandData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   mouseOver = (e) => {
      if (e.clientY > 90 && e.clientY < 125) {
         this.setState({ dateOpen: undefined });
      }
   };
   componentWillUnmount() {
      window.removeEventListener("mouseover", this.mouseOver);
   }
   getMasterId = (e) => {
      this.setState({ selectedGateway: e });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };
   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };

   getOptCommand = (e) => {
      let selectedOption = [];
      if (e !== null) {
         if (e.find((x) => x.value === 0) !== undefined) {
            this.state.CommandData.filter((x) => x.value !== 0).map((x) => {
               selectedOption.push(x);
               return true;
            });
         } else {
            selectedOption = e;
         }
      }
      this.setState({ OptCommand: selectedOption });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({
         loading: true,
      });
      const st = this.state;
      apiClient.requests
         .getRequestsLog({
            gatewayId: st.selectedGateway.value,
            from: st.dateFrom,
            to: st.dateTo,
            sorted: st.sorted,
            filtered: st.filtered,
            types: st.OptCommand.map((x) => {
               return x.value;
            }),
            draw: 0,
            page: 1,
            length: st.recordsTotal,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: this.convertDataExport(res.data),
               endMergeTitle: "F1",
               endMergeSubTitle: "F2",
               columnWidthConfigs,
               title: "HISTORICO DE COMANDOS",
               name: `Gw. ${
                  this.state.masterName
               } Historio de comandos (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `Gateway: ${this.state.selectedGateway.label} DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
            });
            this.setState({
               loading: false,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   convertDataExport = (data) => {
      return data?.map((dataExcel) => ({
         dateTime: dataExcel.dateTime,
         successful: dataExcel.successful ? "True" : "False",
         requestTypeName: dataExcel.requestTypeName,
         errorCodeName: dataExcel.errorCodeName,
         parameters: dataExcel.parameters,
         response: dataExcel.response,
      }));
   };
   getDataExcel = (length) => {
      let ad = this.state;
      if (
         ad.selectedGateway &&
         ad.dateFrom !== "" &&
         ad.dateTo !== "" &&
         ad.OptCommand !== ""
      ) {
         this.setState({ loading: true });
         apiClient.requests
            .getRequestsLog({
               gatewayId: ad.selectedGateway.value,
               from: ad.dateFrom,
               to: ad.dateTo,
               types: ad.OptCommand.map((x) => {
                  return x.value;
               }),
               page: 0,
               length: length,
            })
            .then((res) => {
               this.setState({ loading: false });
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  let data = res.data.map((data) => {
                     return { ...data, request: data.requestType.name };
                  });
                  this.setState({
                     dataExcel: data,
                     loading: false,
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   getReportSchedulePerformance = () => {
      let ad = this.state;
      if (
         ad.selectedGateway &&
         ad.dateFrom !== "" &&
         ad.dateTo !== "" &&
         ad.OptCommand !== ""
      ) {
         this.setState({ triggerOnFetchData: true });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   tcpFrameSubmit = (tctFrame) => {
      apiClient.tcp
         .parseFrame({ frame: tctFrame })
         .then((response) => {
            this.setState({ Json: response });
         })
         .catch((error) => {
            console.log(error);
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   getSelectNative = ({
      value,
      valueName,
      options = [],
      onChange,
      filterType = FILTER_TYPE.name,
   }) => {
      return (
         <select
            defaultValue={value}
            onChange={(event) => {
               switch (filterType) {
                  case FILTER_TYPE.name:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(event.target.value);
                     break;

                  case FILTER_TYPE.id:
                     onChange(
                        options.find((x) => x.name == event.target.value)?.id ||
                           ""
                     );
                     break;

                  case FILTER_TYPE.boolean:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(
                             options.find((x) => x.name == event.target.value)
                                ?.id
                                ? 1
                                : 0
                          );
                     break;

                  default:
                     break;
               }

               this.setState({ [valueName]: event.target.value });
            }}
         >
            <option value={0}>TODOS</option>
            {options.length > 0 &&
               options?.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
               ))}
         </select>
      );
   };

   onRowClick = (action, row) => {
      switch (action) {
         case "click":
            if (this.tmpRow.has(row.id)) this.tmpRow.delete(row.id);
            else this.tmpRow.add(row.id);
            this.setState({
               selectionChanged: this.state.selectionChanged ? false : true,
            });
            break;
         default:
            this.tmpRow.add(row.id);
            this.setState({
               selectionChanged: this.state.selectionChanged ? false : true,
            });
            break;
      }
   };

   isRowSelected = (id) => {
      return this.tmpRow.has(id);
   };

   getTrProps = (state, rowInfo, instance) => {
      if (rowInfo) {
         return {
            style: {
               background: this.isRowSelected(rowInfo.original.id)
                  ? "#64dfdf"
                  : "",
               color: "black",
            },
         };
      }
      return {};
   };

   getTdProps = (state, rowInfo, column) => {
      if (rowInfo !== undefined) {
         return {
            onClick: (event, handleOriginal) => {
               event.stopPropagation();
               this.onRowClick("click", rowInfo.row._original);
            },
         };
      }
      return {};
   };

   render() {
      let modalParameters = "";
      let modalResponse = "";
      const type =
         this.state.selectedGateway === undefined
            ? ""
            : this.state.selectedGateway.protocolType;
      if (type === 1) {
         //string
         let json = { parametro: this.state.parametersData.parameters };
         if (this.state.parametersData.parameters !== undefined) {
            modalParameters = json;
         }
         if (this.state.responseData.response !== undefined) {
            modalResponse = this.state.responseData;
         }
      } else {
         //json
         if (this.state.parametersData.parameters !== undefined) {
            modalParameters = JSON.parse(this.state.parametersData.parameters);
         }

         if (this.state.responseData.response !== undefined) {
            modalResponse = JSON.parse(this.state.responseData.response);
         }
      }

      let component = "";
      let json = this.state.Json;
      if (json !== undefined) {
         component = (
            <ReactJson
               style={{ fontSize: 18 }}
               src={json}
               name={false}
               displayDataTypes={false}
               displayObjectSize={true}
               sortKeys={false}
            />
         );
      }
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Modal
               size={"lg"}
               isOpen={this.state.modalParameters}
               toggle={this.toggleP}
            >
               <ModalHeader toggle={this.toggleP}>Detalles</ModalHeader>
               <ModalBody>
                  <ReactJson
                     src={modalParameters}
                     name={false}
                     displayDataTypes={false}
                     displayObjectSize={true}
                     sortKeys={true}
                  />
               </ModalBody>
               <ModalFooter>
                  <ButtonCustom
                     color={"success"}
                     className={""}
                     name={"Cerrar"}
                     id={"Cerrar"}
                     Click={this.toggleP}
                     title={"Cerrar"}
                  />
               </ModalFooter>
            </Modal>
            <Modal
               size={"lg"}
               style={{ whiteSpace: "normal" }}
               className="text-break"
               isOpen={this.state.modalResponse}
               toggle={this.toggleR}
            >
               <ModalHeader toggle={this.toggleR}>Respuesta</ModalHeader>
               <ModalBody style={{ wordBreak: "break-all" }}>
                  <div
                     style={{
                        borderWidth: 0.5,
                        border: "solid",
                        margin: 5,
                        padding: 10,
                     }}
                  >
                     <ReactJson
                        style={{ fontSize: 15 }}
                        src={modalResponse}
                        name={false}
                        displayDataTypes={false}
                        displayObjectSize={true}
                        sortKeys={true}
                     />
                  </div>

                  <br />

                  <Row className="justify-content-center">
                     <Col md={10}>{component}</Col>
                     <Col className="text-right" md={2}>
                        <ButtonCustom
                           Click={() =>
                              this.tcpFrameSubmit(
                                 modalResponse && modalResponse.response
                              )
                           }
                           style={{ paddingLeft: 20 }}
                           name={"submit"}
                           id={"submit"}
                           title={"Enviar frame"}
                           color={"success"}
                        />
                     </Col>{" "}
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <ButtonCustom
                     color={"danger"}
                     className={""}
                     name={"Cerrar"}
                     id={"Cerrar"}
                     Click={this.toggleR}
                     title={"Cerrar"}
                  />
               </ModalFooter>
            </Modal>
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Historico de
                  comandos
               </CardTitle>
               <CardBody className="border-top">
                  <Row>
                     <Col md={12}>
                        <label> Tipos de comandos </label>
                        <Select
                           name="selectCommand"
                           id="selectCommand"
                           bom={false}
                           onChange={this.getOptCommand}
                           value={this.state.OptCommand}
                           separator={false}
                           wrapColumnChar={false}
                           placeholder="Comandos"
                           options={this.state.CommandData}
                           closeMenuOnSelect={false}
                           isClearable
                           isMulti
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label> Gateway </label>
                        <Select
                           name="selectMaster"
                           id="selectMaster"
                           options={this.state.masterData}
                           placeholder="Gateway"
                           closeMenuOnSelect={true}
                           onChange={this.getMasterId}
                        />
                     </Col>
                     <Col md={2}>
                        <label> Desde </label>
                        <CustomDate
                           id="DateFrom"
                           value={new Date(this.state.dateFrom)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateFrom")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label> Hasta </label>
                        <CustomDate
                           id="DateTo"
                           value={new Date(this.state.dateTo)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateTo")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                        <ButtonCustom
                           name={"generate"}
                           id={"generate"}
                           title={"Generar"}
                           Click={this.getReportSchedulePerformance}
                        />
                     </Col>
                     <Col md={2}>
                        <label>
                           <br />
                        </label>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.readingsData.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <br />
                  <GenericTable
                     data={this.state.readingsData}
                     headers={this.state.readingHeaders}
                     loading={this.state.loading}
                     pages={this.state.pages}
                     getTrProps={this.getTrProps}
                     getTdProps={this.getTdProps}
                     triggerOnFetchData={this.state.triggerOnFetchData}
                     onPageSizeChange={(value) => {
                        this.setState({ pageSize: value });
                     }}
                     pageSize={
                        this.state.readingsData.length > 0 &&
                        this.state.readingsData.length < this.state.pageSize
                           ? this.state.readingsData.length
                           : this.state.pageSize
                     }
                     onFetchData={(state) => {
                        const st = this.state;
                        if (st.selectedGateway?.value) {
                           this.setState({ loading: true });
                           apiClient.requests
                              .getRequestsLog({
                                 gatewayId: st.selectedGateway.value,
                                 from: st.dateFrom,
                                 to: st.dateTo,
                                 sorted: state.sorted,
                                 filtered: state.filtered,
                                 types: st.OptCommand.map((x) => {
                                    return x.value;
                                 }),
                                 draw: 0,
                                 page: state.page + 1,
                                 length: this.state.pageSize,
                              })
                              .then((res) => {
                                 if (res.data.length === 0) {
                                    Swal.fire({
                                       type: "info",
                                       title: "No hay información",
                                       text: "Debes seleccionar otros parametros",
                                    });
                                 }
                                 this.setState({
                                    readingsData: res.data,
                                    readingHeaders: res.headers,
                                    loading: false,
                                    pages: Math.ceil(
                                       res.recordsTotal / this.state.pageSize
                                    ),
                                    recordsTotal: res.recordsTotal,
                                    sorted: state.sorted ?? [],
                                    filtered: state.filtered ?? [],
                                 });
                              })
                              .catch((error) => {
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                    loading: false,
                                 });
                              });
                           this.setState({ triggerOnFetchData: false });
                        }
                     }}
                     customColumns={[
                        {
                           accessor: "requestTypeName",
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.requestTypeSelected,
                                 valueName: "requestTypeSelected",
                                 options: this.state.OptCommand,
                                 onChange,
                              }),
                        },
                        {
                           accessor: "errorCodeName",
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.responseErrorSelected,
                                 valueName: "responseErrorSelected",
                                 options: this.state.responseErrorList,
                                 onChange,
                              }),
                        },
                        {
                           accessor: "successful",
                           Cell: (props) => (
                              <div className="text-center">
                                 {props.value ? (
                                    <i
                                       style={{
                                          color: "green",
                                          fontSize: "25px",
                                       }}
                                       className="fas fa-check"
                                    />
                                 ) : (
                                    <i
                                       style={{
                                          color: "red",
                                          fontSize: "25px",
                                       }}
                                       className="fas fa-times"
                                    />
                                 )}
                              </div>
                           ),
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.successfulSelected,
                                 valueName: "successfulSelected",
                                 options: booleanOptions,
                                 onChange,
                                 filterType: FILTER_TYPE.boolean,
                              }),
                        },
                        {
                           Header: "Respuesta",
                           accessor: "id",
                           show: true,
                           Cell: (props) => {
                              if (props)
                                 return (
                                    <ButtonCustom
                                       name={"viewResponse"}
                                       id={"viewResponse"}
                                       title={"Ver"}
                                       size={"sm"}
                                       className={""}
                                       disabled={
                                          props.original.response === null
                                       }
                                       Click={() => {
                                          this.setState({
                                             modalResponse:
                                                !this.state.modalResponse,
                                             responseData: {
                                                response:
                                                   props.original?.response,
                                             },
                                          });
                                       }}
                                    />
                                 );
                           },
                        },
                        {
                           accessor: "parameters",
                           show: true,
                           Cell: (props) => (
                              <div className="text-center">
                                 <ButtonCustom
                                    name={"viewParameters"}
                                    id={"viewParameters"}
                                    title={"Ver"}
                                    disabled={props.value === null}
                                    size={"sm"}
                                    className={""}
                                    Click={() => {
                                       this.setState({
                                          modalParameters:
                                             !this.state.modalParameters,
                                          parametersData: {
                                             parameters:
                                                props.original.parameters,
                                          },
                                       });
                                    }}
                                 />
                              </div>
                           ),
                        },
                     ]}
                  />
               </CardBody>
            </Card>
         </div>
      );
   }
}

export default HistoricoComandos;
