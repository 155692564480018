import React, {Component} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import indexRoutes from './routes/index.jsx';
import Loading from "./components/custom/Loading";
import Blanklayout from './layouts/blanklayout.jsx';
import authService from "./services/AuthService";
import './assets/scss/style.css';

export default class App extends Component {
    state = {
        userLoggedIn: false,
        userLoaded: false
    };

    checkLoginStatus() {
        const that=this;

        authService.getUser().then(user =>
        {
            that.setRedirect(user);
        });
    }

    componentDidMount() {
        this.checkLoginStatus();
    }

    setRedirect = (user) => {
        this.setState({
            userLoggedIn: !!user,
            userLoaded: true
        });
    };

    renderRedirect = () => {

        if(!this.state.userLoaded)
            return <Loading/>;

        if (this.state.userLoggedIn) {
            const lasLocation = localStorage.getItem('lastLocation');
            const pathName = lasLocation ? JSON.parse(lasLocation).pathname : "/dashboard";
            return <Redirect to={pathName} />
        }
        else {
            localStorage.setItem('lastLocation', JSON.stringify({pathname: window.location.pathname}));
            authService.login();
            return <Route path='/authentication/login' key='3' component={Blanklayout}/>;
        }
    };

    render() {

        return (
            <div>
                <BrowserRouter>
                    <Switch>
                        {indexRoutes.map((prop, key) => {
                            return <Route path={prop.path} key={key} component={prop.component}/>;
                        })}
                        {this.renderRedirect()}
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}