export class EditFilling {
    id=0;
    serial='';
    status={};
    from='';
    to='';
    retries='';

}

