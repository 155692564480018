export const columns = [
   "Serial",
   "Card Serial",
   "Father Card Serial",
   "Tipo medidor",
   "Forma",
   "Tipo módulo",
   "Fase comunicación",
   "Estado",
   "Institución",
   "Instalado en",
   "Latitud",
   "Longitud",
   "Gateway",
   "Tariff Desde",
   "Tariff Hasta",
   "Tariff Habilitado",
];
export const columnWidthConfigs = [
   { column: "A", width: 10 },
   { column: "B", width: 12 },
   { column: "C", width: 17 },
   { column: "D", width: 14 },
   { column: "E", width: 10 },
   { column: "F", width: 13 },
   { column: "G", width: 18 },
   { column: "H", width: 18 },
   { column: "I", width: 13 },
   { column: "J", width: 20 },
   { column: "K", width: 12 },
   { column: "L", width: 12 },
   { column: "M", width: 20 },
   { column: "N", width: 12 },
   { column: "O", width: 12 },
   { column: "P", width: 18 },
];
