export const columns = [
  "Fecha",
  "Comando",
  "Exitosos",
  "Fallidos",
  "Porcentaje(%)",
];

export const columnWidthConfigs = [
  { column: "A", width: 20 },
  { column: "B", width: 17 },
  { column: "C", width: 13 },
  { column: "D", width: 13 },
  { column: "E", width: 15 },
];
