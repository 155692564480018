import React from "react";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

export const CustomSelect = (props) => {
    return (
        <FormGroup>
            <Select
                isDisabled={props.isDisabled}
                options={props.options}
                value={props.value}
                id={props.id}
                name={props.id}
                noOptionsMessage={() => "No hay opciones"}
                onChange={props.onChange}
                isClearable={props.isClearable}
                placeholder={"Seleccionar..."}
                defaultValue={props.defaultValue}
            />
        </FormGroup>
    );
};

CustomSelect.propTypes = {
    options: PropTypes.array.isRequired,
    id: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    isClearable: PropTypes.bool,
    defaultValue: PropTypes.object,
};

CustomSelect.defaultProps = {
    options: [],
    isClearable: false,
};
