import * as moment from "moment";
/**
 * @return {string}
 */
export function Time(time) {
   return moment(time).format("YYYY-MM-DD HH:mm:ss A");
}
/**
 * @return {string}
 */
export function TimeDay(time) {
   return moment(time).format("YYYY/MM/DD");
}
/**
 * @return {string}
 */
export function TimeHour(time) {
   return moment(time).format("YYYY-MM-DD HH:mm");
}

/**
 * @return {string}
 */
export function formatDate(value) {
   return moment(value).format("YYYY/MM/DD HH:mm");
}
/**
 * @return {boolean}
 */
export const compareDateBefore = ({ since, until }) => {
   return unixTime(since) > unixTime(until);
};

/**
 * @return {boolean}
 */
export const compareDateAfter = ({ since, until }) => {
   return unixTime(until) > unixTime(since);
};

/**
 * @return  {string} unix Date
 */
export const unixTime = (value) => {
   return Math.floor(new Date(value).getTime() / 1000);
};
export function inputDateTime(time) {
   return moment(time).format("MM/DD/YYYY HH:mm");
}
export function day(time) {
   return moment(time).format("DD");
}
export function inputDateProblemTypes(time) {
   return moment(time).format("YYYY/MM/DD HH:mm");
}

export function customDateFrom30Days() {
   return moment().subtract(30, "days").format("MM/DD/YYYY");
}
export function customDateFrom() {
   return moment().subtract(7, "days").format("MM/DD/YYYY");
}
export function customDateTo() {
   return moment().subtract(0, "days").format("MM/DD/YYYY HH:mm");
}
export function alarm24HDato() {
   return moment().subtract(0, "days").format("MM/DD/YYYY HH:mm");
}
export function alarm24HDateFrom() {
   return moment().subtract(1, "days").format("MM/DD/YYYY HH:mm");
}

export function currentDate() {
   return moment().subtract(0, "days").format("MM/DD/YYYY HH:mm");
}
