import React from 'react';
import Select from "react-select";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Input,
    Form,
    InputGroup,
    InputGroupAddon,
    Collapse, Button, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import "react-table/react-table.css";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {ModalCustom} from "../../components/custom/ModalCustom";
import TableLayout from "../../components/custom/TableLayout";
import {Time} from "../../constants/DateTime";
import {Response} from "../../constants/PetitionResponse";
import Trans from "../../translate/Translate";
import notificationsManager from "../../services/NotificationsManager";
// import {NotificationContainer} from "react-notifications";
import {Notification} from "../../components/custom/NotificationComponent"
import Swal  from 'sweetalert2'

const t =(text)=><Trans text={text}/>;


class Command extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requestTypesData:[],
            data:[],
            requestTypes:'',
            toggleModalStatusCode:false,
            error:"",
            modal: false,
            masterData:[],
            masterId:'',
            getUserRequests: [],
            response: {columns:[], data:[]},
            buttonShow:false,
        };
    }
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };

    collapseReady=()=> {
        this.setState({ collapse: !this.state.collapse });
    };

    toggle =()=> {
        this.setState({
            modal: !this.state.modal
        });
    };

    componentDidMount =()=>{
        this.getUserRequestsData();
            this.intervalID = setInterval(
                () => this.getUserRequestsData(),
                40000
            );
        apiClient.requests.getRequestTypes()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.name}
                });
                this.setState({requestTypesData: items});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false}) });

        apiClient.gateways.getActiveGateways()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.alias}
                });
                this.setState({masterData: items});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        notificationsManager.onNotification(notification => {
            if (notification.action !== undefined && notification.action === 'RequestStatusChanged') {
                let data = Response(notification.data);
                let updateSerial = [...this.state.getUserRequests];
                updateSerial.splice(this.state.getUserRequests.findIndex(x => x.id === data.id), 1, data);
                this.setState({getUserRequests: updateSerial});
            }
        });
        // notificationsManager.start();

    };

    componentWillUnmount() {
        clearInterval(this.intervalID);
        // notificationsManager.stop();
    }


    getUserRequestsData() {
        this.setState({loading: true});
         apiClient.requests.getUserRequests().then( response=> {
            let data=[];
            response.data.map(x=>{
                data.push(x);
                return true
            });
            this.setState({
                getUserRequests: Response(data),
                loading: false,
            });
        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    }

    getExecuteParameters = (e) => {
        apiClient.requests.getExecuteParameters({
            requestType: e.value
        }).then(res => {
            const data = res.parameters.map(x => {
                return {name: x, value: ""};
            });
            this.setState({data, requestTypes: e, buttonShow: true});
        }).catch(error => {
            this.setState({toggleModalStatusCode: true, error: error, loading: false})
        });
    };

    sendParameters=(e)=>{
        e.preventDefault();
        const {data, requestTypes, masterId} = this.state;
        const parameters = {};
        let parametersLength=0;
        data.map(x=>{
            parameters[x.name] = x.value;
            parametersLength = parameters[x.name].length + parametersLength;
            return true;
        });
        apiClient.requests.execute({
            requestType:requestTypes.value,
            parameters: parametersLength === 0 ? {} : parameters,
            gatewayId:masterId.value
        }).then(response => {
            console.log(response );
            apiClient.requests.getUserRequests().then( res=> {
                // console.log(res);
                let data=[];
                res.data.map(x=>{
                    data.push(x);
                    return true
                });
                // console.log(data);
                this.setState({
                    getUserRequests: Response(data),
                });

            }).catch(error=>{
                this.setState({toggleModalStatusCode:true, error:error, loading: false});
            });


        }).catch(error => {
            this.setState({toggleModalStatusCode:true, error:error, loading: false});
            return <Notification error={error} type={'info'}/>}
        )
    };

    getMasterId = (e) => {
        this.setState({masterId: e});
    };

    onChangeInput = (e) =>{
        const data = this.state.data.map(x => {
            if (x.name===e.target.name)
                x.value = e.target.value;
            return x;
        });
        this.setState({data});
    };

    requestResponseHttp = (data) => {
        this.setState({collapse: false});
        switch (data.requestType.id) {
            case 2: //events
            case 16://meters // status
            case 3://readings
            case 9://utility
                let events = [];
                let RecordsEvents = JSON.parse(data.requestResponse).Records;
                let HeaderEvents = RecordsEvents.map(x => {return Object.keys(x)});
                HeaderEvents[0].map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                        events.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'LastCommunication' && x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: events, data: RecordsEvents};
            case 5: //instrumentation
                let columnsInstrumentation = [], dataInstrumentation = [];
                dataInstrumentation.push(JSON.parse(data.requestResponse));
                let HeaderInstrumentation = dataInstrumentation.map(x => {
                    return Object.keys(x)
                });
                HeaderInstrumentation[0].map(x => {
                    if (x === "DateTime" ||x === "VoltageA" || x === "VoltageB" || x === "VoltageC" || x === "CurrentA"|| x === "CurrentB"|| x === "CurrentC" || x === "PowerFactorA"|| x === "PowerFactorB"|| x === "PowerFactorC"|| x === "PowerFactor") {
                        columnsInstrumentation.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsInstrumentation, data: dataInstrumentation};
            case 28: //voltages
                let columnsMeterRecord = [], dataMeterRecord = [];
                dataMeterRecord.push(JSON.parse(data.requestResponse).MeterRecord);
                let HeaderMeterRecord = dataMeterRecord.map(x => {
                    return Object.keys(x)
                });
                HeaderMeterRecord[0].map(x => {
                    if (x === "Voltage" || x === "Current" || x === "PowerFactor" || x === "LastCommunication") {
                        columnsMeterRecord.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'LastCommunication'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsMeterRecord, data: dataMeterRecord};
            case 4://Demand

                let columnsDemand = [], dataDemand = [];
                dataDemand.push(JSON.parse(data.requestResponse));
                let HeaderDemand = dataDemand.map(x => {
                    return Object.keys(x)
                });
                HeaderDemand[0].map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                        columnsDemand.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'ReadingDate' && x !== 'LastCommunication' && x !== 'LastOperation'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsDemand, data: dataDemand};
            case 27://Registers

                let columnsRegisters = [], dataRegisters = [];
                dataRegisters.push(JSON.parse(data.requestResponse).ReadingRecord);
                let HeaderRegisters = dataRegisters.map(x => {
                    return Object.keys(x)
                });
                HeaderRegisters[0].map(x => {
                    if (x === "ActiveEnergy" || x === "ReverseEnergy" || x === "ReactiveEnergy" || x === "ReadingDate") {
                        columnsRegisters.push({
                            Header: t(x), accessor: x, minWidth: 150, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsRegisters, data: dataRegisters};
            default:
                return 0
        }
    };

    requestResponseTcp = (data) => {
        this.setState({collapse: false});
        let parse =JSON.parse(data.requestResponse);
        let type='';
        if( typeof (parse.Records)==='undefined' ){
             type = 'true';
        }else if ( parse.Records===null){
             type = 'true';
        }
        switch (data.requestType.id) {
            case 2: //events
            case 3://readings
            case 9://readings
            case 16://meters
                // this.setState({modal: !this.state.modal})
                let meters = [], dataReading=[], HeaderEvents;
                if (type !== 'true') {
                    dataReading = parse.Records;
                    HeaderEvents = dataReading.map(x => {return Object.keys(x)});
                } else {
                    dataReading = parse;
                    HeaderEvents = Object.keys(dataReading);
                }
                    (type !=='true' ? HeaderEvents[0] : HeaderEvents).map(x => {
                        if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway" && x !== "Key" && x !== "FrameLength" && x !== "Error" && x !== "Dyn" && x !== "InvalidData" && x !== "MeterNotFound" && x !== "Active" && x !== "Temperature" && x !== "MeterSerial" && x !== "RequestType") {
                            meters.push({
                                Header: t(x), accessor: x, Cell: props =>
                                    props.value !== null
                                        ?
                                        x !== "DateTime" && x !== 'LastCommunication' && x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                            ?
                                            typeof (props.value) === 'object'
                                                ? <div>
                                                    {Object.keys(props.value).map((x, i) => {
                                                        if (props.value[x] !== null) {
                                                            if (props.value[x] === false) {
                                                                props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                    className="fas fa-times"> </i>;
                                                            } else if (props.value[x] === true) {
                                                                props.value[x] =
                                                                    <i style={{color: 'green', fontSize: '15px'}}
                                                                       className="fas fa-check"> </i>;
                                                            }
                                                            return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                                key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                        } else {
                                                            return 'N/D'
                                                        }
                                                    })}
                                                    {this.state.collapse === true ? <br/> : ''}
                                                    <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                            onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                            <i style={{color: 'green', fontSize: '25px'}}
                                                               className="fas fa-check"> </i> :
                                                            <i style={{color: 'red', fontSize: '25px'}}
                                                               className="fas fa-times"> </i> : props.value : 'N/D'
                                                    }
                                                </div>
                                            :
                                            Time(props.value)
                                        :
                                        'N/D'
                            })
                        }
                        return true
                    });
                return {columns: meters, data: type ==='true' ? [dataReading] : dataReading };
            case 5: //instrumentation
                // this.setState({modal: !this.state.modal})
                let columnsInstrumentation = [], dataInstrumentation = [];
                dataInstrumentation.push(JSON.parse(data.requestResponse));
                let HeaderInstrumentation = dataInstrumentation.map(x => {
                    return Object.keys(x)
                });
                HeaderInstrumentation[0].map(x => {
                    if (x === "DateTime" ||x === "VoltageA" || x === "VoltageB" || x === "VoltageC" || x === "CurrentA"|| x === "CurrentB"|| x === "CurrentC" || x === "PowerFactorA"|| x === "PowerFactorB"|| x === "PowerFactorC"|| x === "PowerFactor") {
                        columnsInstrumentation.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsInstrumentation, data: dataInstrumentation};
            case 28: //voltages
                // this.setState({modal: !this.state.modal})
                let columnsMeterRecord = [], dataMeterRecord = [];
                dataMeterRecord.push(JSON.parse(data.requestResponse));
                let HeaderMeterRecord = dataMeterRecord.map(x => {
                    return Object.keys(x)
                });
                HeaderMeterRecord[0].map(x => {
                    if (x === "VoltageA" ||x === "VoltageB" ||x === "VoltageC" || x === "CurrentA" || x === "CurrentB" || x === "CurrentC" || x === "PowerFactorA" ||x === "PowerFactorB" ||x === "PowerFactorC" || x === "DateTime") {
                        columnsMeterRecord.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsMeterRecord, data: dataMeterRecord};
            case 4://Demand
                // this.setState({modal: !this.state.modal})
                let columnsDemand = [], dataDemand = [];
                dataDemand.push(JSON.parse(data.requestResponse));
                let HeaderDemand = dataDemand.map(x => {
                    return Object.keys(x)
                });
                HeaderDemand[0].map(x => {
                    if (x !== 'Hash' && x !== "Command" && x !== "Unix" && x !== "UnixReading" && x !== "Error" && x !== "Gateway") {
                        columnsDemand.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== "DateTime" && x !== 'ReadingDate' && x !== 'LastCommunication' && x !== 'LastOperation'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsDemand, data: dataDemand};
            case 27://Registers
                // this.setState({modal: !this.state.modal})
                let columnsRegisters = [], dataRegisters = [];
                dataRegisters.push(JSON.parse(data.requestResponse));
                let HeaderRegisters = dataRegisters.map(x => {
                    return Object.keys(x)
                });
                HeaderRegisters[0].map(x => {
                    if (x === "ActiveEnergy" || x === "ReverseEnergy" || x === "ReactiveEnergy" || x === "ReadingDate") {
                        columnsRegisters.push({
                            Header: t(x), accessor: x, minWidth: 150, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'PowerReadingDate' && x !== 'PowerReadingDateT1' && x !== 'PowerReadingDateT2' && x !== 'PowerReadingDateT3' && x !== 'PowerReadingDateT4' && x !== 'ReadingDate'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] === false) {
                                                        props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                            className="fas fa-times"> </i>;
                                                    } else if (props.value[x] === true) {
                                                        props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                            className="fas fa-check"> </i>;
                                                    }
                                                    return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                        key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsRegisters, data: dataRegisters};
            case 45: //ReadPlcConfiguration
                // this.setState({modal: !this.state.modal})
                let columnsReadPlcConfiguration = [], dataReadPlcConfiguration= [];
                dataReadPlcConfiguration.push(JSON.parse(data.requestResponse));
                let HeaderReadPlcConfiguration = dataReadPlcConfiguration.map(x => {
                    return Object.keys(x)
                });
                HeaderReadPlcConfiguration[0].map(x => {
                    if (x === "HighFrequencyAdd" ||x === "HighFrequency" ||x === "LowFrequency" || x === "FrameLength"  || x === "TxGainN"  || x === "TxGainR"  || x === "MasterId"  || x === "ToModem"  || x === "ToPlc"  || x === "SsKey"  || x === "Gateway") {
                        columnsReadPlcConfiguration.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsReadPlcConfiguration, data: dataReadPlcConfiguration};
            case 49: //"GetMetersAssociationsTestResults"
                // this.setState({modal: !this.state.modal})
                let columnsParseResults = [], dataParseResults= [];
                let paser = JSON.parse(data.requestResponse);
                dataParseResults =paser.Results;
                let HeaderParseResults = paser.Results.map(x => {
                    return Object.keys(x)
                });
                HeaderParseResults.map(x => {
                    if (x === "HighFrequencyAdd" ||x === "HighFrequency" ||x === "LowFrequency" || x === "FrameLength"  || x === "TxGainN"  || x === "TxGainR"  || x === "MasterId"  || x === "ToModem"  || x === "ToPlc"  || x === "SsKey"  || x === "Gateway") {
                        columnsParseResults.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsParseResults, data: dataParseResults};
            case 52:
                let columnsParseResults52 = [], dataParseResults52= [];
                 dataParseResults52.push(JSON.parse(data.requestResponse));
                let HeaderParseResults52 = dataParseResults52.map(x => {
                    return Object.keys(x)
                });
                HeaderParseResults52[0].map(x => {
                    if (x==="Frame" || x === "HighFrequencyAdd" ||x === "HighFrequency" ||x === "LowFrequency" || x === "FrameLength"  || x === "TxGainN"  || x === "TxGainR"  || x === "MasterId"  || x === "ToModem"  || x === "ToPlc"  || x === "SsKey"  || x === "Gateway") {
                        columnsParseResults52.push({
                            Header: t(x), accessor: x, Cell: props =>
                                props.value !== null
                                    ?
                                    x !== 'DateTime'
                                        ?
                                        typeof (props.value) === 'object'
                                            ? <div>
                                                {Object.keys(props.value).map((x, i) => {
                                                    if (props.value[x] !== null) {
                                                        if (props.value[x] === false) {
                                                            props.value[x] = <i style={{color: 'red', fontSize: '15px'}}
                                                                                className="fas fa-times"> </i>;
                                                        } else if (props.value[x] === true) {
                                                            props.value[x] = <i style={{color: 'green', fontSize: '15px'}}
                                                                                className="fas fa-check"> </i>;
                                                        }
                                                        return <Collapse key={i} isOpen={this.state.collapse}> <b
                                                            key={i}>{x}: </b>{props.value[x]}</Collapse>
                                                    } else {
                                                        return 'N/D'
                                                    }
                                                })}
                                                {this.state.collapse === true ? <br/> : ''}
                                                <Button color={'cyan'} className={'text-center'} size={"sm"}
                                                        onClick={this.collapseReady}> {this.state.collapse === false ? 'Mostrar' : 'Ocultar'} </Button>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    props.value !== null ? typeof (props.value) === 'boolean' ? props.value === true ?
                                                        <i style={{color: 'green', fontSize: '25px'}}
                                                           className="fas fa-check"> </i> :
                                                        <i style={{color: 'red', fontSize: '25px'}}
                                                           className="fas fa-times"> </i> : props.value : 'N/D'
                                                }
                                            </div>
                                        :
                                        Time(props.value)
                                    :
                                    'N/D'
                        })
                    }
                    return true
                });
                return {columns: columnsParseResults52, data: dataParseResults52};
            default:
                return {columns: [], data: []}
        }
    };

    cancelRequest = (requestId) => {
        apiClient.requests.cancelRequest({requestId: requestId})
            .then(()=> {
                let changeCancel = [...this.state.getUserRequests];
                let Requests = this.state.getUserRequests.find(x => x.id === requestId);
                Requests['status'] = 'Cancelled';
                changeCancel.splice(this.state.getUserRequests.findIndex(x => x.id === requestId), 1, Requests);
                this.setState({getUserRequests: changeCancel});
            }).catch((error) => {
            this.setState({toggleModalStatusCode: true, error: error, loading: false})
        })
    };


    render() {
        const self =this;
        // console.log(this.state.response);
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Detalles</ModalHeader>
                    <ModalBody className='justify-content-center text-center'>
                        {this.state.response.data.length!==0
                        ?
                            <TableLayout
                                data={this.state.response.data}
                                columns={this.state.response.columns}
                                defaultPageSize={5}
                                filterable={true}
                                className={''}
                                showPaginationBottom={true}
                                showPaginationTop={true}
                                noDataText={false}
                            />
                            :
                            <h2 style={{margin:25}}> No hay información</h2>
                        }
                        <br/>
                        <Row>
                            <Col md={2}>
                                <ButtonCustom color={'success'} className={''} name={'Cerrar'} id={'Cerrar'} Click={this.toggle} title={"Cerrar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>Comando</CardTitle>
                    <CardBody className="border-top">
                        <Row className={"justify-content-center"}>
                            <Col md={2}>
                                <label > Gateway </label>
                                <Select
                                    name="selectMaster"
                                    id="selectMaster"
                                    options={this.state.masterData}
                                    placeholder="Gateway"
                                    value={this.state.masterId}
                                    closeMenuOnSelect={true}
                                    onChange={this.getMasterId}
                                />
                            </Col>
                            <Col md={2} >
                                <label>Comando</label>
                                <Select
                                    name="requestTypes"
                                    id="requestTypes"
                                    onChange={this.getExecuteParameters}
                                    value={this.state.requestTypes}
                                    placeholder="Seleccionar tipo de solicitud"
                                    options={this.state.requestTypesData}
                                />
                            </Col>
                            <Col md={2} >
                                <label><br/></label>
                                <ButtonCustom
                                    Click={this.sendParameters}
                                    disabled={!(this.state.masterId && Object.keys(this.state.requestTypes).length===2)}
                                    name={'submit'} id={'submit'}
                                    title={'Enviar'} type={'submit'} color={'success'}/>
                            </Col>
                        </Row>
                        <br/>
                        <hr/>
                        <br/>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    {this.state.data.map((parameter, i)=>{
                                        return <InputGroup style={{paddingBottom: '50px', paddingLeft: '20px', borderColor: '#000', height: '40px'}} key={i}>
                                            <InputGroupAddon addonType="prepend">{parameter.name}</InputGroupAddon>
                                            <Input
                                                style={{height: '40px'}}
                                                value={parameter.value}
                                                name={parameter.name}
                                                onChange={self.onChangeInput}/>
                                        </InputGroup>
                                    })}
                                </Col>
                            </Row>
                            <hr/>
                                <Row>
                                <Col md={12}>
                                    <TableLayout
                                        data={this.state.getUserRequests}
                                        showPaginationBottom={true}
                                        showPaginationTop={true}
                                        className={'affix disabled text-center'}
                                        defaultPageSize={10}
                                        columns={[
                                            {Header: "Gateway", accessor:'gatewayId', Cell:(props)=>{ return this.state.masterData.find(x=>x.value ===props.value) ? this.state.masterData.find(x=>x.value ===props.value).label : 'N/A '}},
                                            {Header: "Medidor", accessor:'serial'},
                                            {Header: "Petición", accessor: "requestType", Cell:(props)=>props.value ==='Voltages'? 'Voltajes' : props.value},
                                            {Header: "Fecha",  accessor: "createdAt"},
                                            {Header: "Duración",  accessor: "duration"},
                                            {Header: "Estado",  accessor: "status", Cell:(props)=>t(props.value)},
                                            {Header: "Detalles",  accessor:"id", filterable:false,  Cell: (props) =>
                                                    <div className="text-center">
                                                        <ButtonCustom
                                                            name={props.value.toString()}
                                                            title={'Ver'}
                                                            size={'sm'}
                                                            disabled={ props.original.status==='Failed' || props.original.status==='Pending'|| props.original.status==='Cancelled'|| props.original.status==='Processing' || props.original.status==='Timeout' || props.original.status==='WaitingResponse'}
                                                            className={''}
                                                            Click={() => {
                                                                apiClient.requests.getRequestResponse({requestId: props.value})
                                                                    .then(response => {
                                                                        // console.log(response );
                                                                        if (response.gateway.communicationType.name ==='Http'){
                                                                            // console.log('Http', response.requestType.name );
                                                                            this.setState({
                                                                                modal: !this.state.modal,
                                                                                response: this.requestResponseHttp(response),
                                                                            });
                                                                        }else if(response.gateway.communicationType.name ==='Tcp') {
                                                                            // console.log('TCP', response.requestType.name);
                                                                            this.setState({
                                                                                modal: !this.state.modal,
                                                                                response: this.requestResponseTcp(response),
                                                                            });
                                                                        }
                                                                    }).catch((error) => {this.setState({toggleModalStatusCode:true, error:error, loading: false})  })
                                                            }}
                                                        />
                                                        {' '}
                                                        <ButtonCustom
                                                            name={'cancel'+props.value.toString()}
                                                            title={'Cancelar'}
                                                            color={'danger'}
                                                            size={'sm'}
                                                            disabled={!(props.original.status==='Pending'|| props.original.status==='Processing')}
                                                            className={''}
                                                            Click={()=>{this.cancelRequest(props.value)}}
                                                        />
                                                    </div>
                                            },
                                        ]}
                                        onFetchData={(state) => {
                                            this.setState({
                                                pageSize: state.pageSize
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Command;
