import {ModalBody, Modal} from "reactstrap";
import React from "react";
import Loading from "./Loading";

export const LoadingComponent = ({loading}) => {
    return (
        <Modal style={{position: 'absolute', margin: 'auto', top: '0', right: '0', bottom: '200px', left: '0', width: '400px', height: '100px'}} isOpen={loading} >
            <ModalBody className={'text-center'}>
                <Loading/>
                <p className={'text-center'}>Procesando...</p>
            </ModalBody>
        </Modal>
    )
};
