import React from 'react';
import {Card, Row, Col, CardTitle, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import apiClient from '../../api/apiClient';
import Swal from 'sweetalert2'
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {CustomInput} from "../../components/custom/CustomInput";
import {ModalCustom} from "../../components/custom/ModalCustom";
import {InstitutionModel} from "../../models/OthersModel"

class Institution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            type:'',
            institution:new InstitutionModel(),
            institutionsAll: [],
            pages:0,
            pageSize:10,
            loading: false,
            toggleModalStatusCode:false,
            error:""
        };
    }
    toggle = (tab) => {
        this.setState({
            isOpen: !this.state.isOpen
        });

        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };
    toggleModal = () => {
        if (this.state.modal) {
            this.setState({institution: new InstitutionModel() });
        }
        this.setState({
            modal: !this.state.modal,
            type: 'add'
        });
    };
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };
    onChangeInputSimple = (e) => {
        let {institution} = this.state;
        institution[e.target.name] = e.target.value;
        this.setState({institution});
    };
    onButtonClick = (id, type) => {
            this.toggleModal();
            const institutionToEdit = Object.assign({}, this.state.institutionsAll.find(x => x.id === id));
            this.setState({institution: institutionToEdit, type});
    };

    submit=()=>{
        const {id='', name="", description=""} =this.state.institution;
        this.toggleModal();
        if (this.state.type === 'edit') {
            apiClient.institutions.edit({
                id:id,
                name:name,
                description: description,
            }).then(response => {
                if (response) {
                    Swal.fire({
                        type: 'success',
                        title: 'Cambios realizados correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let institution = [...this.state.institutionsAll];
                    institution.splice(this.state.institutionsAll.findIndex(x => x.id === response.id), 1, response);
                    this.setState({institutionsAll: institution, institution: {}});
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        }else {
            apiClient.institutions.create({
                name:name,
                description: description,
            }).then(response => {
                    Swal.fire({
                        type: 'success',
                        title:'Institucion agregada correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let newInstitution= this.state.institutionsAll.slice();
                    newInstitution.push({name: response.name, description: response.description,});
                    this.setState({institutionsAll: newInstitution , institution: {}});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        }
    };

    render() {
        const {name="", description=""} =this.state.institution;
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>{this.state.type==="add" ? "Agregar ":"Editar"} </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <CustomInput valid={name.length>0} onChange={this.onChangeInputSimple} value={name} label={"Nombre"} id={"name"}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <CustomInput valid={description.length>0} onChange={this.onChangeInputSimple} value={description} label={"Descripcción"} id={"description"}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <ButtonCustom color={'success'} className={''} name={'submit'} id={'submit'} disabled={!(description.length>0)} Click={this.submit} title={name.length > 0 ?"Actualizar" : "Enviar"  } />
                                {' '}
                                <ButtonCustom type={'cancel'} color={'danger'} className={''} name={'cancel'} id={'cancel'} Click={this.toggleModal} title={"Cancelar"}/>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>Instituciones</CardTitle>
                    <CardBody>
                        <br/>
                        <Row>
                            <Col sm={{size: 'auto', offset: 0}}>
                                <h4 className={"buttonAdd ml-4 "} onClick={this.toggleModal}> Agregar <i className="mdi mdi-plus"></i></h4>
                            </Col>
                        </Row>
                        <br/>
                        <TableLayout
                            data={this.state.institutionsAll}
                            pageSize={this.state.pageSize}
                            manual={true}
                            filterable={false}
                            loading={this.state.loading}
                            onFetchData={(state) => {
                                this.setState({loading: true});
                                apiClient.institutions.all({
                                    draw: 0,
                                    start: (state.page * state.pageSize),
                                    length: state.pageSize,
                                    pageSize:state.pageSize,
                                    sorted:state.sorted,
                                    filtered:state.filtered
                                }).then(res => {
                                    this.setState({
                                        institutionsAll: res.data,
                                        pageSize: state.pageSize,
                                        pages: Math.ceil(res.recordsTotal / state.pageSize),
                                        loading: false
                                    });
                                }).catch(error => {this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                            }}
                            columns={[
                                {
                                    Header: "Acción",
                                    accessor: "id",
                                    Cell: (props) =>
                                        <div className="text-center">
                                            <ButtonCustom
                                                name={'edit'}
                                                id={'edit'}
                                                title={'Editar'}
                                                color={'success'}
                                                className={''}
                                                size={'sm'}
                                                Click={() => this.onButtonClick(props.value, "edit")}
                                            />
                                        </div>
                                },
                                {Header: "Nombre", accessor: "name"},
                                {Header: "Descripcción", accessor: "description"},
                            ]}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Institution;
