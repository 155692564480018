export const columns = [
   "Serial",
   "Tipo",
   "Resuelto",
   "Registrado en",
   "Actualizado en",
];

export const columnWidthConfigs = [
   { column: "A", width: 12 },
   { column: "B", width: 13 },
   { column: "C", width: 10 },
   { column: "D", width: 22 },
   { column: "E", width: 22 },
];
