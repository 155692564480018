import React from 'react';
import {Card, Row, Col, CardTitle, CardBody, Modal, ModalHeader, ModalBody,Button, ButtonGroup} from 'reactstrap';
import apiClient from '../../api/apiClient';
import Swal from 'sweetalert2'
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {GroupModel} from "../../models/OthersModel"
import {CustomInput} from "../../components/custom/CustomInput";
import {ModalCustom} from "../../components/custom/ModalCustom";
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import Select from "react-select";
import authService from "../../services/AuthService";
import {SelectSimple} from "../../components/custom/Select";

class Group extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalEd: false,
            type:'',
            group:new GroupModel(),
            loading: false,
            obj: {},
            gatewayMeter: [],
            dataAssociated: [],
            dataNotAssociated:[],
            id: '',
            name:'',
            selectedAssociated: null,
            selectedNotAssociated: null,
            gatewayToMoveAssociated:[],
            identifierToMoveAssociated:[],
            gatewayToMoveNotAssociated:[],
            identifierToMoveNotAssociated:[],
            institutionIdToMoveNotAssociated:[],
            institutionIdToMoveAssociated:[],
            getGroupValue:'',
            pageGNA:0,
            pageGA:0,
            pageSizeGNA:10,
            pageSizeGA:10,
            toggleModalStatusCode:false,
            error:"",
            institution:[],
            permission:false,
        };
    }

    toggleModal = (type) => {
        if (this.state.modal) {
            this.setState({group:new GroupModel() });
        }
        this.setState({
            modal: !this.state.modal,
            type: type
        });
    };

    toggleModalEdit = (type) => {
        if (this.state.modalEd) {
            this.setState({group: new GroupModel() });
        }
        this.setState({
            modalEd: !this.state.modalEd,
            type: type
        });
    };

    onButtonEdit= () => {
        this.toggleModalEdit();
           let id =  this.state.getGroupValue.value;
            const groupEdit = Object.assign({}, this.state.gatewayMeter.find(x => x.value === id));
        groupEdit["institution"] = this.state.institution.filter(institution => institution.id===groupEdit.institutionId)[0];
        groupEdit["name"] = groupEdit.label;
            this.setState({group: groupEdit});
    };

    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };

    componentDidMount =()=> {
        authService.userHasPermissions("ManageAllInstitutions").then(response => {
            if (response === true) {
                apiClient.institutions.all()
                    .then(res => {
                        this.setState({institution: res.data, permission: response});
                    }).catch(error => {
                    this.setState({toggleModalStatusCode: true, error: error, loading: false})
                });
            }
        });

        apiClient.gateways.getGatewayGroups()
            .then(res => {
                let items = res.data.map(result => {
                    return {
                        value: result.id,
                        label: result.name,
                        institutionId: result.institutionId
                    }
                });
                this.setState({gatewayMeter: items});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

    };

    onChangeInputSimple = (e) => {
        let {group} = this.state;
        group[e.target.name] = e.target.value;
        this.setState({group});
    };

    onChangeSelect = (e, key) => {
        let {group} = this.state;
        group[key] = Object.assign({}, this.state[key].find(x => x.id === e));
        this.setState({group});
    };

    onButtonClick = () => {
            const GroupToDelete =this.state.getGroupValue;
            Swal.fire({
                title: '¿Estás seguro?',
                text: "que deseas eliminar el grupo: "+ GroupToDelete.label,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0277bd',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    apiClient.gateways.deleteGroup({id: GroupToDelete.value})
                        .then(()=>{
                            let group = [...this.state.gatewayMeter];
                            group.splice(this.state.gatewayMeter.findIndex(x => x.value === GroupToDelete.value),1);
                            this.setState({gatewayMeter: group, getGroupValue: '', dataAssociated:[], dataNotAssociated:[]});
                            Swal.fire('¡Eliminado!', 'Grupo eliminado', 'success')
                        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

                }
            })
    };

    submit=(type)=>{
        let id = this.state.getGroupValue.value;
        let {name ='', institution={} } = this.state.group;
        if( type  ==="ed"){
            this.toggleModalEdit();
            apiClient.gateways.editGatewayGroup({
                name: name,
                id: id,
                institutionId: this.state.permission ===true ? institution.id : null,
            }).then(response => {
                if (response) {
                    Swal.fire({
                        type: 'success',
                        title: 'Cambios realizados correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let items = {value: response.id, label: response.name, institutionId:response.institutionId};
                    let group = this.state.gatewayMeter;
                    group.splice(this.state.gatewayMeter.findIndex(x => x.value === items.value), 1, items);
                    this.setState({gatewayMeter: group, getGroupValue: items});
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
        } else if (this.state.type==="ad"){
            this.toggleModal();
            apiClient.gateways.createGatewayGroup({
                name:name,
                institutionId: this.state.permission ===true ? institution.id : null,
            }).then(response => {
                if(response) {
                    Swal.fire({
                        type: 'success',
                        title:' Grupo fue agregado correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let items = {value: response.id, label: response.name, institutionId:response.institutionId };
                    let newGroup= this.state.gatewayMeter.slice();
                    newGroup.push(items);
                    this.setState({gatewayMeter: newGroup});
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
        }
    };

    getTableInfo = (e) => {
        this.setState({id: e.value, name: e.label, loading:true, getGroupValue:e });
        apiClient.gateways.getGatewaysInGroup({
            id: e.value
        }).then(res => {
            const x = res.data.map( function (data) {
                return {gateway: data.alias, identifier: data.identifier, institutionId:data.institutionId}
            });
            this.setState({ dataAssociated:x, pageGA:0 });
        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.gateways.getActiveGateways()
            .then(res => {
                const x = res.data.map( function (data) {
                    return {gateway: data.alias, identifier: data.identifier, institutionId:data.institutionId}
                });
                this.setState({dataNotAssociated :x, pageGNA:0});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        this.setState({loading:false});
    };

    getDataAssociated = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selectedAssociated : rowInfo.index,
                        gatewayToMoveNotAssociated: rowInfo.row.gateway,
                        identifierToMoveNotAssociated: rowInfo.row.identifier,
                        institutionIdToMoveNotAssociated: rowInfo.row.institutionId,
                    });
                },
                style: {
                    background: rowInfo.index === this.state.selectedAssociated ? '#00c853' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedAssociated ? 'white' : 'black'
                }
            }
        }
        return {};
    };

    getDataNotAssociated = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selectedNotAssociated: rowInfo.index,
                        gatewayToMoveAssociated: rowInfo.row.gateway,
                        identifierToMoveAssociated: rowInfo.row.identifier,
                        institutionIdToMoveAssociated: rowInfo.row.institutionId,

                    });
                },
                style: {
                    background: rowInfo.index === this.state.selectedNotAssociated ? '#00c853' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedNotAssociated ? 'white' : 'black'
                }
            }
        }
        return {};
    };

    moveTableNotAssociated = () => {
        let arrayNotAssociated = this.state.dataNotAssociated;
        arrayNotAssociated.push( {gateway: this.state.gatewayToMoveNotAssociated, identifier: this.state.identifierToMoveNotAssociated, institutionId:this.state.institutionIdToMoveNotAssociated });
        let arrayAssociated = this.state.dataAssociated.filter(x => x.gateway !== this.state.gatewayToMoveNotAssociated);
        this.setState({
            dataAssociated: arrayAssociated,
            dataNotAssociated: arrayNotAssociated,
            gatewayToMoveNotAssociated: null,
            selectedAssociated: null,
        });
    };

    moveTableAssociated =()=>{
        let arrayNotAssociated = this.state.dataAssociated;
        arrayNotAssociated.push( {gateway: this.state.gatewayToMoveAssociated,  identifier: this.state.identifierToMoveAssociated,  institutionId:this.state.institutionIdToMoveAssociated});
        let arrayAssociated = this.state.dataNotAssociated.filter(x => x.gateway !== this.state.gatewayToMoveAssociated);
        this.setState({
            dataNotAssociated: arrayAssociated,
            dataAssociated: arrayNotAssociated,
            gatewayToMoveAssociated: null,
            selectedNotAssociated: null,
        });
    };

    saveChanges = () => {
        let id = this.state.id;
        let array = this.state.dataAssociated.map(x => {return  x.identifier });
        apiClient.gateways.associateGatewaysToGroup({
            group: id,
            gateways: array.length===0 ? [] : array,
        }).then(res => {
            Swal.fire({
                type: 'success',
                title:'Cambios realizados correctamente',
                showConfirmButton: false,
                timer: 1500
            });
        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

    };

    render() {
        const {name="", institution={}} =this.state.group;

        const tableDataAssociated = this.state.dataAssociated.map(  (data)=> {
            return {
                gateway: data.gateway,
                identifier: data.identifier,
                institutionId:data.institutionId,
            }
        });
        const tableDataNotAssociated = this.state.dataNotAssociated.map(  (data)=> {
            return {
                gateway: data.gateway,
                identifier: data.identifier,
                institutionId:data.institutionId,
            }
        });
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>{"Agregar"} </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <CustomInput valid={name.length>0} onChange={this.onChangeInputSimple} value={name} placeholder={"Nombre del grupo"} label={"Grupo"} id={"name"}/>
                            </Col>
                        </Row>
                        <Row style={ this.state.permission ===true ? {display:''} : {display: "none"}}>
                            <Col md={12}>
                                <SelectSimple valueCallback={this.onChangeSelect} item={institution} id={"institution"} label={"Institución"} array={this.state.institution}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <ButtonCustom color={'success'} className={''} name={'submit'} id={'submit'} disabled={!(name.length>0)} Click={()=>this.submit('ad')} title={"Enviar" } />
                                {' '}
                                <ButtonCustom type={'cancel'} color={'danger'} className={''} name={'cancel'} id={'cancel'} Click={this.toggleModal} title={"Cancelar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalEd} toggle={this.toggleModalEdit}>
                    <ModalHeader toggle={this.toggleModalEdit}>{"Editar"} </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col   md={12}>
                                <CustomInput valid={name.length>0} onChange={this.onChangeInputSimple} value={name} label={'Grupo'} placeholder={"nombre del grupo"} id={"name"}/>
                            </Col>
                        </Row>
                        <Row style={ this.state.permission ===true ? {display:''} : {display: "none"}}>
                            <Col md={12}>
                                <SelectSimple valueCallback={this.onChangeSelect} item={institution} id={"institution"} label={"Institución"} array={this.state.institution}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <ButtonCustom color={'success'} className={''} name={'submit'} id={'submit'} disabled={!(name.length>0)} Click={()=>this.submit("ed")} title={"Editar"  } />
                                {' '}
                                <ButtonCustom type={'cancel'} color={'danger'} className={''} name={'cancel'} id={'cancel'} Click={this.toggleModalEdit} title={"Cancelar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                        <Card>
                            <LoadingComponent loading={this.state.loading}/>
                            <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                                className="mdi mdi-hamburger mr-2"> </i> Asociar gateway a grupo</CardTitle>
                            <CardBody className="border-top">
                                <Row >
                                    <Col md={2}>
                                        <label > Grupos </label>
                                        <Select
                                            name="selectGr"
                                            id="selectGr"
                                            options={this.state.gatewayMeter}
                                            placeholder="Selecionar grupo"
                                            value={this.state.getGroupValue}
                                            onChange={this.getTableInfo}
                                        />
                                    </Col>

                                    <Col md={3}>
                                        <label > Acción </label>
                                        <br/>
                                        <ButtonGroup>
                                            <Button onClick={()=>this.toggleModal('ad')} color={'warning'}>Agregar</Button>
                                            <Button disabled={!this.state.getGroupValue} onClick={()=>this.onButtonEdit()} color={'success'}>Editar</Button>
                                            <Button disabled={!this.state.getGroupValue} onClick={this.onButtonClick} color={'danger'}>Eliminar</Button>
                                        </ButtonGroup>
                                    </Col>

                                </Row>
                                <br/>
                                <Row>
                                    <Col sm={{ size: 5, offset: 0 }}>
                                        <TableLayout
                                            trProps={this.getDataAssociated}
                                            data={tableDataAssociated}
                                            defaultPageSize={this.state.pageSizeGA}
                                            columns={[{   Header: "Gateways asociados", columns: [{Header: "Alias", accessor: "gateway"},
                                                    {Header: "Identificador", accessor: "identifier"},
                                                    {Header: "Institución", accessor: "institutionId", show: this.state.permission, Cell: (props) => props.value !==null ? this.state.institution.find(x=>x.id ===props.value).name : ''},
                                                ]
                                            }]}
                                        />
                                    </Col>
                                    <Col sm={{ size: 2, offset: 0 }}>
                                        <br/><br/>
                                        <br/><br/>
                                        <br/><br/>
                                        <br/>
                                        <Row className={'mt-5 pt-5'}>
                                            <Col sm="12" md={{ size: 8, offset: 2 }}>
                                                <ButtonCustom
                                                    className={"btn btn-block" + (this.state.selectedNotAssociated==null? " disable":"")}
                                                    name={'moveTableAssociated'}
                                                    color={'success'}
                                                    id={'moveTableAssociated'}
                                                    title={<i className="fas fa-angle-double-left"> </i>}
                                                    disabled={this.state.selectedNotAssociated==null}
                                                    Click={this.moveTableAssociated}
                                                />
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col sm="12" md={{ size: 8, offset: 2 }}>
                                                <ButtonCustom
                                                    className={"btn btn-block" + (this.state.selectedAssociated==null? " disable":"")}
                                                    name={'moveTableNotAssociated'}
                                                    color={'success'}
                                                    id={'moveTableNotAssociated'}
                                                    title={<i className="fas fa-angle-double-right"> </i>}
                                                    disabled={this.state.selectedAssociated==null}
                                                    Click={this.moveTableNotAssociated}
                                                />
                                            </Col>
                                        </Row>

                                        <br/><br/><br/><br/>
                                        <Row>
                                            <Col sm="12" md={{ size: 8, offset: 2 }}>
                                                <ButtonCustom
                                                    name={'saveChanges'}
                                                    id={'saveChanges'}
                                                    title={'Guardar'}
                                                    Click={this.saveChanges}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={{ size: 5, offset: 0 }}>
                                        <TableLayout
                                            trProps={this.getDataNotAssociated}
                                            data={tableDataNotAssociated}
                                            defaultPageSize={this.state.pageSizeGNA}
                                            columns={[{Header: "Gateways no asociados", columns: [{Header: "Alias", accessor: "gateway"},
                                                    {Header: "Identificador", accessor: "identifier"},
                                                    {Header: "Institución", accessor: "institutionId", show: this.state.permission, Cell: (props) => props.value !==null ? this.state.institution.find(x=>x.id ===props.value).name : ''},
                                                ]
                                            }]}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                            </CardBody>
                        </Card>
            </div>
        );
    }
}

export default Group;
