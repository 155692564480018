import React from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
   customDateFrom,
   customDateTo,
   inputDateTime,
} from "../../constants/DateTime";

import GenericTable from "../../components/custom/GenericTable";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/diagnostic";
import { saveAsExcel } from "../../utilities/exportToExcel";
class Diagnostic extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         readingHeaders: [],
         triggerOnFetchData: false,
         readings: [],
         isOpen: false,
         masterId: "",
         masterName: "",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         page: 0,
         pages: 0,
         pageSize: 10,
         loading: false,
         toggleModalStatusCode: false,
         error: "",
         recordsTotal: 0,
         filtered: [],
         sorted: [],
      };
   }
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount = () => {
      window.addEventListener("mouseover", this.mouseOver);
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   mouseOver = (e) => {
      if (e.clientY > 90 && e.clientY < 125) {
         this.setState({ dateOpen: undefined });
      }
   };
   componentWillUnmount() {
      window.removeEventListener("mouseover", this.mouseOver);
   }
   getMasterId = (e) => {
      this.setState({ masterId: e.value });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({
         ...this.state,
         loading: true,
      });
      const st = this.state;
      apiClient.diagnostic
         .all({
            gatewayId: st.masterId,
            from: st.dateFrom,
            to: st.dateTo,
            draw: 0,
            page: 1,
            length: st.recordsTotal,
            sorted: st.sorted,
            filtered: st.filtered,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: this.convertDataExport(res.data),
               endMergeTitle: "F1",
               endMergeSubTitle: "F2",
               columnWidthConfigs,
               title: "REPORTE DE DIAGNOSTICO",
               name: `Gw. ${this.state.masterName} Diagnostico (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
            });
            this.setState({
               ...this.state,
               loading: false,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   convertDataExport = (data) => {
      return data?.map((reading) => ({
         insertDate: reading.insertDate,
         connectFail: reading.connectFail,
         pdpDeact: reading.pdpDeact,
         cpinNotReady: reading.cpinNotReady,
         normalPowerDown: reading.normalPowerDown,
         qoS: reading.qoS,
      }));
   };

   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };

   getReportSchedulePerformance = () => {
      let ad = this.state;
      if (ad.masterId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ triggerOnFetchData: true });
         // this.setState({ loading: true });
         // apiClient.diagnostic
         //    .all({
         //       gatewayId: ad.masterId,
         //       from: ad.dateFrom,
         //       to: ad.dateTo,
         //       start: 0,
         //       length: ad.pageLength,
         //    })
         //    .then((res) => {
         //       if (res.data.length === 0) {
         //          Swal.fire({
         //             type: "info",
         //             title: "No information",
         //             text: "You must select another date range",
         //          }).then(() => {
         //             this.setState({ loading: false });
         //          });
         //       } else {
         //          this.setState({
         //             readings: res.data,
         //             readingHeaders: res.headers,
         //             page: 0,
         //             pages: Math.ceil(res.recordsTotal / ad.pageSize),
         //             loading: false,
         //             recordsTotal: res.recordsTotal,
         //          });
         //       }
         //    })
         //    .catch((error) => {
         //       this.setState({
         //          toggleModalStatusCode: true,
         //          error: error,
         //          loading: false,
         //       });
         //    });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };
   render() {
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Reporte de
                  diagnostico
               </CardTitle>
               <CardBody className="border-top">
                  <br />
                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label> Gateway </label>
                        <Select
                           name="selectMaster"
                           id="selectMaster"
                           options={this.state.masterData}
                           placeholder="Gateway"
                           closeMenuOnSelect={true}
                           onChange={this.getMasterId}
                        />
                     </Col>
                     <Col md={2}>
                        <label> Desde </label>
                        <CustomDate
                           id="DateFrom"
                           value={new Date(this.state.dateFrom)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateFrom")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label> Hasta </label>
                        <CustomDate
                           id="DateTo"
                           value={new Date(this.state.dateTo)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateTo")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                        <ButtonCustom
                           name={"generate"}
                           id={"generate"}
                           title={"Generar"}
                           Click={this.getReportSchedulePerformance}
                        />
                     </Col>{" "}
                     <Col md={2}>
                        <label>
                           <br />
                        </label>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.readings.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <br />
                  <GenericTable
                     data={this.state.readings}
                     headers={this.state.readingHeaders}
                     loading={this.state.loading}
                     pages={this.state.pages}
                     triggerOnFetchData={this.state.triggerOnFetchData}
                     onPageSizeChange={(value) => {
                        this.setState({ pageSize: value });
                     }}
                     pageSize={
                        this.state.readings.length > 0 &&
                        this.state.readings.length < this.state.pageSize
                           ? this.state.readings.length
                           : this.state.pageSize
                     }
                     onFetchData={(state) => {
                        let st = this.state;
                        if (st.masterId) {
                           apiClient.diagnostic
                              .all({
                                 gatewayId: st.masterId,
                                 from: st.dateFrom,
                                 to: st.dateTo,
                                 draw: 0,
                                 page: state.page + 1,
                                 length: this.state.pageSize,
                                 sorted: state.sorted,
                                 filtered: state.filtered,
                              })
                              .then((res) => {
                                 this.setState({
                                    readings: res.data,
                                    readingHeaders: res.headers,
                                    triggerOnFetchData: false,
                                    pages: Math.ceil(
                                       res.recordsTotal / this.state.pageSize
                                    ),
                                    recordsTotal: res.recordsTotal,
                                    sorted: state.sorted ?? [],
                                    filtered: state.filtered ?? [],
                                 });
                              })
                              .catch((error) => {
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                    loading: false,
                                 });
                              });
                        }
                     }}
                  />
               </CardBody>
            </Card>
         </div>
      );
   }
}

export default Diagnostic;
