import * as moment from "moment";
import React from "react";
import Select from "react-select";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import StockChart from "../../components/chart/StockChart";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
    customDateFrom,
    customDateTo,
    inputDateTime
} from "../../constants/DateTime";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
    columns as columnsExport,
    columnWidthConfigs
} from "../../constants/exportExcel/meterPerformance";
import { saveAsExcel } from "../../utilities/exportToExcel";
class MeterPerformance extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         gateway: [],
         masterId: "",
         masterName: "",
         TypeReports: "1",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         showSeries: true,
         chartOptions: {
            chart: { type: "spline", height: "600px" },
            navigator: { adaptToUpdatedData: true, series: [] },
            scrollbar: {
               barBackgroundColor: "#e0e0e0",
               barBorderRadius: 7,
               barBorderWidth: 0,
               buttonBackgroundColor: "#e0e0e0",
               buttonBorderWidth: 0,
               buttonBorderRadius: 7,
               trackBackgroundColor: "none",
               trackBorderWidth: 1,
               trackBorderRadius: 8,
               trackBorderColor: "#CCC",
            },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: false,
            },
            tooltip: {
               shared: true,
               split: false,
               useHTML: true,
               outside: true,
               formatter: function () {
                  let table = [[], []];
                  this.points.map((data, key) => {
                     if (key % 2 === 0) {
                        table[0].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     } else {
                        table[1].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     }
                  });
                  return (
                     '<small style="font-size: small">Fecha: ' +
                     moment(this.points[0].x).utc().format("YYYY-MM-DD HH:mm") +
                     "</small>" +
                     '<div style="display: table; clear: both; width:25vw">' +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[0].join(" ") +
                     "</div>" +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[1].join(" ") +
                     "</div>" +
                     "</div>"
                  );
               },
               valueDecimals: 2,
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            xAxis: {
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%y-%m-%d %H:%M}" },
               minRange: 3600 * 1000,
               plotLines: [{ color: "#FF0000", width: 2, value: 0 }],
            },
            yAxis: {
               title: { text: "Desempeño %" },
               allowDecimals: false,
               min: 0,
               max: 100,
            },
            credits: false,
            series: [],
         },
         loading: false,
         toggleModalStatusCode: false,
         error: "",
         recordsTotal: 0,
         filtered: [],
         sorted: [],
      };
   }
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount = () => {
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   getMasterId = (e) => {
      this.setState({ masterId: e.value });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };
   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({
         ...this.state,
         loading: true,
      });
      saveAsExcel({
         columns: columnsExport,
         data: this.convertDataExport(),
         endMergeTitle: "E1",
         endMergeSubTitle: "E2",
         columnWidthConfigs,
         title: "REPORTE DE DESEMPEÑO DE MEDIDOR",
         name: `Gw. ${this.state.masterName} Desempeno de medidor  (${new Date()
            .toISOString()
            .slice(0, 10)})`,
         subTitle: `DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
      });
      this.setState({
         ...this.state,
         loading: false,
      });
   };
   convertDataExport = () => {
      const result = this.state.gateway.map((gateway) => ({
         serial: Number.parseInt(gateway.serial),
         type: gateway.type.name === "State" ? "Estado" : "Lectura",
         gatewayDateTime: gateway.gatewayDateTime,
         meterDateTime: gateway.meterDateTime,
         performance: gateway.performance,
      }));
      return result;
   };
   getTypeReports = (e) => {
      this.setState({ TypeReports: e.value });
   };
   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };

   getReportSchedulePerformance = () => {
      let ad = this.state;
      if (
         ad.masterId !== "" &&
         ad.dateFrom !== "" &&
         ad.dateTo !== "" &&
         ad.TypeReports !== ""
      ) {
         this.setState({ loading: true });
         apiClient.performance
            .getTriesPerformanceReportByGateway({
               gatewayId: ad.masterId,
               from: ad.dateFrom,
               to: ad.dateTo,
               type: ad.TypeReports,
            })
            .then((res) => {
               if (res.performances.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  res.performances.map((result) => {
                     this.setState({ gateway: result });
                     return result;
                  });
               }

               const series = [];
               const shadowSeries = res.shadow.map((shadow) => {
                  const datetime = new Date(shadow.dateTime);
                  const dateFormat = Date.UTC(
                     datetime.getFullYear(),
                     datetime.getMonth(),
                     datetime.getDate(),
                     datetime.getHours(),
                     datetime.getMinutes(),
                     0
                  );
                  return [dateFormat, shadow.performance];
               });
               series.push({
                  name: "Desempeño",
                  data: shadowSeries,
                  type: "areaspline",
                  color: "#bbdefb",
                  gapSize: 5,
               });
               res.performances.forEach((performances) => {
                  let meterSerial = performances[0].serial;
                  let meterPerformance = performances.map((performance) => {
                     const dateTimeD = new Date(performance.gatewayDateTime);
                     const dateFormatD = Date.UTC(
                        dateTimeD.getFullYear(),
                        dateTimeD.getMonth(),
                        dateTimeD.getDate(),
                        dateTimeD.getHours(),
                        dateTimeD.getMinutes(),
                        0
                     );
                     return [dateFormatD, performance.performance];
                  });
                  series.push({
                     name: meterSerial,
                     data: meterPerformance,
                     type: "spline",
                  });
               });
               this.updateSeries(series);
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   updateSeries = (series) => {
      this.setState({
         chartOptions: {
            navigator: { series: series },
            series: series,
         },
         loading: false,
      });
   };

   showLegendValues = () => {
      this.setState({
         chartOptions: {
            series: this.state.chartOptions.series.map((x) => {
               x.visible = !this.state.showSeries;
               return x;
            }),
            navigator: {
               series: this.state.chartOptions.series.map((x) => {
                  return {
                     name: x.name,
                     data: x.data,
                     visible: true,
                     type: "spline",
                  };
               }),
            },
         },
         showSeries: !this.state.showSeries,
      });
   };

   render() {
      const typeReports = [
         { value: "1", label: "Estado" },
         { value: "2", label: "Lectura" },
      ];

      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i> Reporte de
                  desempeño de medidor{" "}
               </CardTitle>
               <CardBody className="border-top">
                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label> Tipo de reporte </label>
                        <Select
                           name="typeReports"
                           id="typeReports"
                           options={typeReports}
                           placeholder="Tipo de reporte"
                           closeMenuOnSelect={true}
                           onChange={this.getTypeReports}
                        />
                     </Col>
                     <Col md={2}>
                        <label> Gateway </label>
                        <Select
                           name="selectMaster"
                           id="selectMaster"
                           options={this.state.masterData}
                           placeholder="Gateway"
                           closeMenuOnSelect={true}
                           onChange={this.getMasterId}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label> Desde </label>
                        <CustomDate
                           id="DateFrom"
                           value={new Date(this.state.dateFrom)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateFrom")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label> Hasta </label>
                        <CustomDate
                           id="DateTo"
                           value={new Date(this.state.dateTo)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateTo")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                        <ButtonCustom
                           name={"generate"}
                           id={"generate"}
                           title={"Generar"}
                           Click={this.getReportSchedulePerformance}
                        />
                     </Col>
                     <Col md={2}>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.gateway.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <StockChart options={this.state.chartOptions} />
                  <Button
                     style={
                        this.state.chartOptions.series.length !== 0
                           ? { display: "" }
                           : { display: "none" }
                     }
                     color={"cyan"}
                     onClick={this.showLegendValues}
                  >
                     {this.state.showSeries ? "Ocultar" : "Mostrar"}
                  </Button>
               </CardBody>
            </Card>
         </div>
      );
   }
}
export default MeterPerformance;
