import React from 'react';
import {ModalCustom} from "../../components/custom/ModalCustom";
import {Card, CardBody, Row, Col, Button, CardTitle, Modal, ModalHeader, ModalBody, Form} from 'reactstrap';
import apiClient from '../../api/apiClient';
import TableLayout from "../../components/custom/TableLayout";
import Swal from "sweetalert2";
import  {UserModel}  from "../../models/UserModel";
import  {CustomInput} from "../../components/custom/CustomInput"
import  {SelectSimple} from "../../components/custom/Select"
import ButtonCustom from "../../components/custom/ButtonCustom";
import ReactTooltip from "react-tooltip";
import {LoadingComponent} from "../../components/custom/LoadingComponent";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            allUsers:[],
            roles:[],
            status:[],
            institution:[],
            modalToggle: false,
            passwordToggle: false,
            user: new UserModel(),
            type: '',
            loading: false,
            pageSize:10,
            toggleModalStatusCode:false,
            error:""
        };
    }

    toggleModal = () => {
        if (this.state.modalToggle) {
            this.setState({user: new UserModel() });
        }
        this.setState({
            modalToggle: !this.state.modalToggle,
            type: 'add'
        });
    };

    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };


    togglePassword = () => {
        if (this.state.modalToggle) {
            this.setState({user:  new UserModel()});}
        this.setState({passwordToggle: !this.state.passwordToggle,});
    };
    componentDidMount = () => {
        this.setState({loading: true});
        apiClient.users.all()
            .then(res => {
                let array = res.data.filter(user => user.status.name !== 'Deleted');
                this.setState({allUsers: array, loading: false})
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.users.getRoles()
            .then(res => {
                this.setState({roles: res.data})
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.users.getStatuses()
            .then(res => {
                this.setState({status: res.data})
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.institutions.all()
            .then(res => {
                this.setState({institution: res.data});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});


    };

    onChangeInputSimple = (e) => {
        ReactTooltip.hide();
        let {user} = this.state;
        user[e.target.name] = e.target.value;
        this.setState({user});
    };

    onChangeSelect = (e, key) => {
        let {user} = this.state;
        user[key] = Object.assign({}, this.state[key].find(x => x.id === e));
        this.setState({user});
    };

    onButtonClick = (id, type) => {
        if (type === "view") {
            this.toggleModal();
            const userToEdit = Object.assign({}, this.state.allUsers.find(x => x.id === id));
            this.setState({user: userToEdit, type});
        }
        if (type === "delete") {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0277bd',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    const userToDelete =this.state.allUsers.find(x=>x.id===id);
                    apiClient.users.delete({userName: userToDelete.userName})
                        .then(()=>{
                        let newClients = [...this.state.allUsers];
                        newClients.splice(this.state.allUsers.findIndex(x=>x.id===id),1);
                        this.setState({allUsers: newClients });
                        Swal.fire('¡Eliminado!', 'Usuario eliminado', 'success')
                        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

                }
            })
        }
        if ( type === "change" ){
            this.togglePassword();
            const userToEdit = Object.assign({}, this.state.allUsers.find(x => x.id === id));
            this.setState({user: userToEdit, type});
        }
    };

    submit = () => {
        this.toggleModal();
        const { name="", userName="",password="",email="",phoneNumber="",institution={},status={},roles=[{}]} =this.state.user;
        if ('id' in this.state.user && this.state.user.id > 0) {
            apiClient.users.edit({
                userName: userName,
                name: name,
                email:email,
                phoneNumber:phoneNumber,
                status:status.id,
                roleId:roles.id,
                institutionId:institution.id,
            }).then(res => {
                let newClients = [...this.state.allUsers];
                newClients.splice(this.state.allUsers.findIndex(x=>x.id===res.id),1,res);
                this.setState({allUsers: newClients , user:  new UserModel()});
                Swal.fire({type: 'success', title: 'Cambios realizados correctamente', showConfirmButton: false, timer: 1500});
            }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});


        }else {
            apiClient.users.create({
                name: name,
                userName: userName,
                password: password,
                email: email,
                phoneNumber: phoneNumber,
                status: status.id,
                institutionId: institution.id,
                roleId: roles.id,
            }).then(res => {
                let newClients = this.state.allUsers.slice();
                newClients.push(res);
                this.setState({allUsers: newClients , user: {}});
                Swal.fire({type: 'success', title: 'Usuario fue creado correctamente', showConfirmButton: false, timer: 1500
                });
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        }
    };
    changerPassword = () => {
        this.togglePassword();
        apiClient.users.editPassword({
            userName:this.state.user.userName,
            newPassword:this.state.user.newPassword,
            passwordConfirmation:this.state.user.confirmPassword,
        }).then(()=>Swal.fire({type: 'success', title:'Cambios realizados',  timer: 1500}))
            .catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    render() {
        const {onButtonClick, submit,changerPassword}=this;
        const {id, name="", userName="",password="",email="",phoneNumber="",status={}, institution={},roles=[{}],newPassword="",confirmPassword="" } =this.state.user;
        let REGEX = new RegExp("(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})");
        let pattern = new RegExp("([a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5})");

        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <LoadingComponent loading={this.state.loading}/>
                <Modal fade={true} size={"md"}  isOpen={this.state.modalToggle} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        { this.state.type==="view" ? "Editar Usuario" :  "Agregar Usuario"}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                        <Row>
                        <Col md={12}>
                            <SelectSimple valueCallback={this.onChangeSelect} item={status} id={"status"} label={"Estado"} array={this.state.status} />
                        </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <SelectSimple valueCallback={this.onChangeSelect} item={institution} id={"institution"} label={"Institución"} array={this.state.institution} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <SelectSimple valueCallback={this.onChangeSelect} item={roles} id={"roles"} label={"Roles"} array={this.state.roles} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{size:  '12' , offset: 0}}>
                                <CustomInput type={"email"}  invalid={!pattern.test(email) } valid={pattern.test(email)} onChange={this.onChangeInputSimple} value={email} label={"Correo"} id={"email"}/>
                            </Col>
                        </Row>
                            <Row  style={ this.state.type==="view" ? {display:'none'}:{}}>
                                <Col sm={{size: '6' , offset: 0}}>

                                    <CustomInput autocomplete={"password"} invalid={!REGEX.test(password)}  valid={REGEX.test(password)} onChange={this.onChangeInputSimple} type={"password"} value={password} label={"Contraseña"} id={"password"} tooltip={"Debe incluir un carácter en mayúscula, un carácter en minúscula, un número, un carácter especial y un mínimo de 8 caracteres"} />

                                    </Col>
                                <Col  sm={{size: '6' , offset: 0}}>
                                    <CustomInput type={"text"} invalid={!userName.length>0} valid={userName.length > 0} onChange={this.onChangeInputSimple} value={userName} label={"Nombre de usuario"} id={"userName"}/>
                                </Col>
                            </Row>
                        <Row>
                            <Col md={6}>
                                <CustomInput invalid={!name.length>0} valid={name.length>0} onChange={this.onChangeInputSimple} value={name} label={"Nombre"} id={"name"}/>
                            </Col>
                            <Col md={6}>
                                <CustomInput invalid={phoneNumber.length!==10 } valid={phoneNumber.length===10} onChange={this.onChangeInputSimple} value={phoneNumber} label={"Teléfono"} id={"phoneNumber"}/>
                            </Col>
                        </Row>
                    </Form>
                        <ButtonCustom
                            className={''}
                            name={'submit'}
                            id={'submit'}
                            disabled={id >0
                                ? !(name.length > 0 && pattern.test(email) && phoneNumber.length === 10)
                                : !(name.length > 0 && REGEX.test(password) && userName.length > 0 && pattern.test(email) && phoneNumber.length === 10 && status.name.length > 0 && institution.name.length > 0 && roles.length === undefined)}
                            Click={submit} title={id >0? "Actualizar" : "Enviar"}/>
                        {' '}
                        <ButtonCustom className={''} color={"danger"} name={'Cancel'} id={'Cancel'} Click={this.toggleModal} title={"Cancelar"} />
                        <ReactTooltip delayHide={100} delayShow={100} type={'warning'} place={'top'}/>
                    </ModalBody>
                </Modal>

                <Modal fade={true} size={"md"}  isOpen={this.state.passwordToggle} toggle={this.togglePassword}>
                    <ModalHeader toggle={this.togglePassword}>
                        Cambiar contraseña
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                        <Row>
                            <Col sm={{size: '12', offset: 0}}>
                                <CustomInput autocomplete={"password username email"}  tooltip={"Debe incluir un carácter en mayúscula, un carácter en minúscula, un número, un carácter especial y un mínimo de 8 caracteres"} invalid={ !REGEX.test(newPassword)} valid={newPassword.length > 0} onChange={this.onChangeInputSimple} type={"password"} value={newPassword} label={"Nueva Contraseña"} id={"newPassword"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{size: '12', offset: 0}}>
                                <CustomInput autocomplete={"new-password username email"} invalid={ (!REGEX.test(confirmPassword)) || (confirmPassword !== newPassword) } valid={confirmPassword === newPassword} onChange={this.onChangeInputSimple} type={"password"} value={confirmPassword} label={"Confirmar Contraseña"} id={"confirmPassword"}/>
                            </Col>
                        </Row>
                        </Form>
                        <ButtonCustom  className={''} name={'changerPassword'} id={'changerPassword'} disabled={!(REGEX.test(newPassword) && (confirmPassword === newPassword))} Click={changerPassword} title={"Actualizar"}/>
                        {' '}
                        <ButtonCustom className={''} color={"danger"} name={'Cancel'} id={'Cancel'} Click={this.togglePassword} title={"Cancelar"} />

                        <ReactTooltip delayHide={800} delayShow={0} type={'warning'} place={'top'}/>
                    </ModalBody>
                </Modal>


                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-account-multiple mr-2"> </i> Usuarios </CardTitle>
                    <CardBody>
                        <br/>
                        <Row>
                            <Col sm={{size: 'auto', offset: 0}}>
                                <h4 className={"buttonAdd ml-4 "} onClick={this.toggleModal}> Agregar <i className="mdi mdi-plus"></i></h4>
                            </Col>
                        </Row>
                        <br/>
                            <TableLayout
                                data={this.state.allUsers}
                                columns={[
                                    {Header: "Estado", accessor: "status.name", minWidth: 60},
                                    {Header: "Nombre", accessor: "name"},
                                    {Header: "Nombre de usuario", accessor: "userName"},
                                    {Header: "Correo", accessor: "email"},
                                    {Header: "Teléfono", accessor: "phoneNumber"},
                                    {Header: "Institución", accessor:"institution", minWidth: 175, Cell: (props)=>props.value.name +" : "+props.value.description },
                                    {Header: "Roles", accessor: "roles", Cell: (props)=>props.value.map(x=>{ return x.name })},
                                    {Header: "Contraseña", accessor: "password",minWidth: 60, Cell: props => <Button size={'sm'} color={"cyan"} onClick={()=>onButtonClick(props.original.id,"change")}>Cambiar</Button> },
                                    {Header: 'Acción', accessor: 'id', minWidth: 100,sortable: false, filterable: false,
                                        Cell: props => <div>
                                            <Button size={'sm'} color={"success"} onClick={()=>onButtonClick(props.value,"view")}>Ver</Button>
                                            {' '}
                                            <Button size={'sm'} color={"danger"} onClick={()=>onButtonClick(props.value,"delete")}>Eliminar</Button>
                                        </div>},
                                ]}
                                showPaginationBottom={true}
                                showPaginationTop={true}
                                defaultPageSize={this.state.pageSize}
                            />
                </CardBody>
            </Card>
            </div>
        );
    }
}

export default Users;
