export function filterByValue(array, string) {
   return array.filter((o) =>
      Object.keys(o).some((k) =>
         o[k].toLowerCase().includes(string.toLowerCase())
      )
   );
}
export function filterByFieldAndValue({ array, field, value }) {
   return array.filter((item) =>
      item[field].toLowerCase().includes(value.toLowerCase())
   );
}
