import React from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const Notification = ({ type }) => {
     switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'success':
            NotificationManager.success('Success message', 'Title here');
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
            break;
        case 'error':
            NotificationManager.error('Error message', 'Click me!', 5000, () => {
                alert('callback');
            });
            break;
        default: return 0
    }
    return <NotificationContainer />
};
