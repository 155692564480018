import React from "react";
export function tableFilterComponent ({ filter, onChange, column,}){
    let option;
    if (column.isDropdown!==undefined){
        option = column.isDropdown.map(option=>{
            return <option key={option.id} value={option.id}>{option.name}</option>
        });
    }
    return(
        column.isDropdown === undefined
            ?
        <input
            type="text"
            style={{width: '100%',}}
            placeholder={column.Placeholder}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}/>
            :
            <form>
                <select onChange={event => onChange(event.target.value)} style={{width:'100%', height:'50%'}}>
                    {option}
                </select>
            </form>
    );
}