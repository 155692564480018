import TelemamApiClient from '@ees/telemam-api-client';
import authService from "../services/AuthService";

const apiUrl = process.env.REACT_APP_API_URL;

const auth = function () {
    return authService.getAccessToken()
        .then(token => {
            return token ? `Bearer ${token}` : '';
        });
};
const errorHook = function (error) {
    if(error.status && error.status === 401){
        authService.login();
    }
};
const hooks = {error: errorHook};
const apiClient = new TelemamApiClient({baseUrl: apiUrl, auth, hooks});

export default apiClient;
