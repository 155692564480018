import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonCustom from "../../../components/custom/ButtonCustom";
import { CustomInput } from "../../../components/custom/CustomInput";
import { SelectSimple } from "../../../components/custom/Select";

const modalType = {
    EDIT: "edit",
    ADD: "add",
};

export default function AddGatewayPerformance({ ...props }) {
    const {
        value,
        setValue,
        toggleModal,
        submit,
        title,
        type,
        gatewayPerformanceList,
        moduleCommunicationTypeList,
        communicationTypeList,
    } = props;

    const [availableModuleType, setAvailableModuleType] = useState([]);
    const [selectedComunicationType, setSelectedComunicationType] = useState(
        {}
    );
    const [selectedModuleComunicationType, setSelectedModuleComunicationType] =
        useState({});

    useEffect(() => {
        value && type === modalType.EDIT
            ? title(
                  `Configuración: ${value.gatewayTypeDescription} - ${value.moduleCommunicationTypeDescription}`
              )
            : title("Nueva configuracion de desempeño");
    }, []);

    useEffect(() => {
        if (selectedComunicationType.id != null) {
            let result = moduleCommunicationTypeList.filter(
                (moduleItem) =>
                    !gatewayPerformanceList.some(
                        (gatewayItem) =>
                            moduleItem.id ===
                                gatewayItem.moduleCommunicationType &&
                            selectedComunicationType.id ===
                                gatewayItem.gatewayType
                    )
            );
            setAvailableModuleType(result);
        }
    }, [selectedComunicationType]);

    const onChangeSelect = (e, key) => {
        let index;
        let item;

        switch (key) {
            case "communicationTypeList":
                index = communicationTypeList.findIndex((x) => x.id == e);
                item = communicationTypeList[index];

                setValue({
                    ...value,
                    gatewayType: item.id,
                });
                setSelectedComunicationType(item);
                setSelectedModuleComunicationType(null);
                break;
            case "moduleCommunicationTypeList":
                index = moduleCommunicationTypeList.findIndex((x) => x.id == e);
                item = moduleCommunicationTypeList[index];

                setValue({
                    ...value,
                    moduleCommunicationType: item.id,
                });
                setSelectedModuleComunicationType(item);
                break;
            default:
                break;
        }
    };

    return (
        <>
            {type === modalType.ADD && (
                <Row>
                    <Col md={6}>
                        <SelectSimple
                            valueCallback={onChangeSelect}
                            item={selectedComunicationType}
                            id={"communicationTypeList"}
                            label={"Tipo de Comunicación"}
                            array={communicationTypeList}
                        />
                    </Col>
                    <Col md={6}>
                        <SelectSimple
                            valueCallback={onChangeSelect}
                            item={selectedModuleComunicationType}
                            id={"moduleCommunicationTypeList"}
                            label={"Módulo de Comunicación"}
                            array={availableModuleType}
                        />
                    </Col>
                </Row>
            )}

            <Row>
                <Col md={6}>
                    <CustomInput
                        autoComplete={"off"}
                        type="number"
                        valid={value.readingsPerHour.length > 0}
                        onChange={(e) =>
                            setValue({
                                ...value,
                                readingsPerHour: e.target.value,
                            })
                        }
                        value={value.readingsPerHour || 0}
                        label={"Lecturas por hora"}
                        id={"readingsPerHour"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        autoComplete={"off"}
                        type="number"
                        valid={value.instrumentationPerHour.length > 0}
                        onChange={(e) =>
                            setValue({
                                ...value,
                                instrumentationPerHour: e.target.value,
                            })
                        }
                        value={value.instrumentationPerHour || 0}
                        label={"Instrumentación por hora"}
                        id={"instrumentationPerHour"}
                    />
                </Col>
            </Row>
            <div style={{ display: "flex", gap: 10 }}>
                <ButtonCustom
                    color={"success"}
                    className={""}
                    name={"submit"}
                    id={"submit"}
                    Click={submit}
                    title={type === modalType.EDIT ? "Actualizar" : "Guardar"}
                />
                <ButtonCustom
                    type={"cancel"}
                    color={"danger"}
                    className={""}
                    name={"cancel"}
                    id={"cancel"}
                    Click={toggleModal}
                    title={"Cancelar"}
                />
            </div>
        </>
    );
}
