export class UserModel {
    id=null;
    name="";
    userName="";
    email="";
    phoneNumber="";
    password="";
    status={id:'', name:""};
    institution={id:'', name: "", description: ""};
    roles=[{id:'', name: ""}];
}
