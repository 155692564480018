import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import { CustomInput } from "../../components/custom/CustomInput";
import { CustomSelect } from "../../components/custom/CustomSelect";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import TableLayout from "../../components/custom/TableLayout";
import { Time } from "../../constants/DateTime";
import authService from "../../services/AuthService";

import Swal from "sweetalert2";
import {
   booleanOptions,
   FILTER_TYPE,
   maxRecordToShowConfirmation,
   notDefined,
} from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/gateways";
import { saveAsExcel } from "../../utilities/exportToExcel";
import GenericTable from "../../components/custom/GenericTable";
class Gateway extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         gateways: [],
         gatewayHeaders: [],
         status: [],
         isOpen: false,
         page: 0,
         pages: 0,
         pageSize: 10,
         institution: [],
         institutionSelect: { value: "", label: "Todos" },
         communicationTypeSelect: { value: "", label: "Todos" },
         moduleCommunicationTypeSelect: { value: "", label: "Todos" },
         moduleTypeSelect: { value: "", label: "Todos" },
         statusSelect: { value: "", label: "Todos" },
         selectedOption: { value: "", label: "Todos" },
         permission: false,
         identifier: "",
         masterName: "",
         loading: false,
         toggleModalStatusCode: false,
         error: "",
         recordsTotal: 0,
         filtered: [],
         sorted: [],
      };
   }
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount = () => {
      const { communicationType, module } = this.props.location;
      authService
         .userHasPermissions("ManageAllInstitutions")
         .then((response) => {
            if (response === true) {
               apiClient.institutions
                  .all()
                  .then((res) => {
                     this.setState({
                        institution: res.data,
                        permission: response,
                     });
                  })
                  .catch((error) => {
                     this.setState({
                        toggleModalStatusCode: true,
                        error: error,
                        loading: false,
                     });
                  });
            }
         });

      apiClient.gateways
         .getGatewayStatuses()
         .then((res) => {
            this.setState({ status: res.data });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getCommunicationTypes()
         .then((res) => {
            this.setState({
               communicationType: res.data,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getModuleTypes()
         .then((res) => {
            this.setState({ moduleType: res.data });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getModuleCommunicationTypes()
         .then((res) => {
            this.setState({
               moduleCommunicationType: res.data,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      if (communicationType !== undefined) {
         if (communicationType === "Comunicados") {
            this.setState({
               selectedOption: { value: true, label: "Comunicados" },
               moduleCommunicationTypeSelect: module,
            });
         } else {
            this.setState({
               selectedOption: { value: false, label: "Incomunicados" },
               moduleCommunicationTypeSelect: module,
            });
         }
      }
   };

   getMasterId = (selectedOption) => {
      this.setState({ selectedOption });
   };

   onChangeInput = (e) => {
      this.setState({ identifier: e.target.value });
      this.setState({
         masterName: e.target.value,
      });
   };

   onChangeSearch = (e, key) => {
      this.setState({ [key.name]: e });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({
         ...this.state,
         loading: true,
      });
      const { communicationType, module } = this.props.location;
      const {
         identifier,
         institutionSelect,
         communicationTypeSelect,
         moduleCommunicationTypeSelect,
         moduleTypeSelect,
         statusSelect,
         recordsTotal,
         sorted,
         filtered,
      } = this.state;
      apiClient.gateways
         .communicationReport({
            IsCommunicating:
               typeof communicationType === "string"
                  ? communicationType === "Comunicados"
                  : this.state.selectedOption.value,
            draw: 0,
            page: 1,
            length: recordsTotal,
            sorted,
            filtered,
            Identifier: identifier,
            InstitutionId: institutionSelect.value,
            Status: statusSelect.value,
            CommunicationType: communicationTypeSelect.value,
            ModuleType: moduleTypeSelect.value,
            ModuleCommunicationType:
               module === undefined
                  ? moduleCommunicationTypeSelect.value
                  : module.value,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: this.convertDataExport(res.data),
               endMergeTitle: "J1",
               endMergeSubTitle: "J2",
               columnWidthConfigs,
               title: "REPORTE DE COMUNICACION DE GATEWAYS",
               name: this.state.masterName
                  ? `Identificador. ${
                       this.state.masterName
                    } Comunicacion de gateways (${new Date()
                       .toISOString()
                       .slice(0, 10)})`
                  : `Comunicacion de gateways (${new Date()
                       .toISOString()
                       .slice(0, 10)})`,
               subTitle: "",
            });
            this.setState({
               ...this.state,
               loading: false,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   convertDataExport = (data) => {
      return data?.map((gateways) => ({
         identifier: gateways.identifier,
         alias: gateways.alias,
         isCommunicating: gateways.isCommunicating ? "Activo" : "Inactivo",
         status: gateways.statusName,
         communicationType: gateways.communicationTypeName,
         moduleCommunicationType: gateways.moduleCommunicationTypeName,
         communicationAddress: gateways.communicationAddress,
         lastCommunication: gateways.lastCommunication,
         installedAt: gateways.installedAt,
         institutionId: this.getInstitutionName(gateways.institutionId),
      }));
   };

   getInstitutionName = (institutionId) => {
      const institution = this.state.institution.find(
         (x) => x.id === institutionId
      );
      return institution ? institution.name : "N/D";
   };
   getReportSchedulePerformance = (e) => {
      this.setState({ loading: true });
      const {
         selectedOption,
         pageSize,
         identifier,
         institutionSelect,
         communicationTypeSelect,
         moduleCommunicationTypeSelect,
         moduleTypeSelect,
         statusSelect,
      } = this.state;
      apiClient.gateways
         .communicationReport({
            start: 0,
            length: pageSize,
            IsCommunicating: typeof e === "boolean" ? e : selectedOption.value,
            Identifier: identifier,
            InstitutionId: institutionSelect.value,
            Status: statusSelect.value,
            CommunicationType: communicationTypeSelect.value,
            ModuleType: moduleTypeSelect.value,
            ModuleCommunicationType: moduleCommunicationTypeSelect.value,
         })
         .then((res) => {
            this.setState({
               page: 0,
               gateways: res.data,
               loading: false,
               recordsTotal: res.recordsTotal,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   getSelectNative = ({
      value,
      valueName,
      options = [],
      onChange,
      filterType = FILTER_TYPE.name,
   }) => {
      return (
         <select
            defaultValue={value}
            onChange={(event) => {
               switch (filterType) {
                  case FILTER_TYPE.name:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(event.target.value);
                     break;

                  case FILTER_TYPE.id:
                     onChange(
                        options.find((x) => x.name == event.target.value)?.id ||
                           ""
                     );
                     break;

                  case FILTER_TYPE.boolean:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(
                             options.find((x) => x.name == event.target.value)
                                ?.id
                                ? 1
                                : 0
                          );
                     break;

                  default:
                     break;
               }

               this.setState({ [valueName]: event.target.value });
            }}
         >
            <option value={0}>TODOS</option>
            {options.length > 0 &&
               options?.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
               ))}
         </select>
      );
   };

   render() {
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Reporte de
                  comunicación de gateways{" "}
               </CardTitle>
               <CardBody className="border-top">
                  {" "}
                  <Row className={"justify-content-start"}>
                     <Col md={2}>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.gateways.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <GenericTable
                     data={this.state.gateways}
                     headers={this.state.gatewayHeaders}
                     loading={this.state.loading}
                     pages={this.state.pages} 
                     onPageSizeChange={(value) => {
                        this.setState({ pageSize: value });
                     }}
                     pageSize={
                        this.state.gateways.length > 0 &&
                        this.state.gateways.length < this.state.pageSize
                           ? this.state.gateways.length
                           : this.state.pageSize
                     }
                     onFetchData={(state) => {
                        apiClient.gateways
                           .communicationReport({
                              draw: 0,
                              page: state.page + 1,
                              length: this.state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                           })
                           .then((res) => {
                              this.setState({
                                 gateways: res.data,
                                 gatewayHeaders: res.headers,
                                 pages: Math.ceil(
                                    res.recordsTotal / this.state.pageSize
                                 ),
                                 recordsTotal: res.recordsTotal,
                                 sorted: state.sorted ?? [],
                                 filtered: state.filtered ?? [],
                              });
                           })
                           .catch((error) => {
                              this.setState({
                                 toggleModalStatusCode: true,
                                 error: error,
                                 loading: false,
                              });
                           });
                     }}
                     customColumns={[
                        {
                           accessor: "isCommunicating",
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.isComunicatingSelect,
                                 valueName: "isComunicatingSelect",
                                 options: booleanOptions,
                                 onChange,
                                 filterType: FILTER_TYPE.boolean,
                              }),
                           Cell: (props) => (
                              <div className="text-center">
                                 {props.value ? (
                                    <i
                                       className="fas fa-circle mr-1 text-success font-12"
                                       width="50"
                                    />
                                 ) : (
                                    <i
                                       className="fas fa-circle mr-1 text-danger font-12"
                                       width="50"
                                    />
                                 )}
                              </div>
                           ),
                        },
                        {
                           accessor: "institutionId",
                           width: 140,
                           Cell: (props) =>
                              this.state.institution.find(
                                 (x) => x.id == props.value
                              )?.name ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.institutionSelect,
                                 valueName: "institutionSelect",
                                 options: this.state.institution,
                                 onChange,
                                 filterType: FILTER_TYPE.id,
                              }),
                        },
                        {
                           accessor: "statusName",
                           width: 140,
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.statusSelect,
                                 valueName: "statusSelect",
                                 options: this.state.status,
                                 onChange,
                              }),
                        },
                        {
                           accessor: "communicationTypeName",
                           width: 140,
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.communicationTypeSelect,
                                 valueName: "communicationTypeSelect",
                                 options: this.state.communicationType,
                                 onChange,
                              }),
                        },
                        {
                           accessor: "moduleCommunicationTypeName",
                           width: 140,
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state
                                    .moduleCommunicationTypeSelect,
                                 valueName: "moduleCommunicationTypeSelect",
                                 options: this.state.moduleCommunicationType,
                                 onChange,
                              }),
                        },
                        {
                           accessor: "moduleTypeName",
                           width: 140,
                           Cell: (props) => props.value ?? notDefined,
                           Filter: ({ onChange }) =>
                              this.getSelectNative({
                                 value: this.state.moduleTypeSelect,
                                 valueName: "moduleTypeSelect",
                                 options: this.state.moduleType,
                                 onChange,
                              }),
                        },
                     ]}
                  />
               </CardBody>
            </Card>
         </div>
      );
   }
}

export default Gateway;
