import cache from "./cache"
import md5 from "blueimp-md5";

export function makeCacheableRequest(request, requestOptions, cacheMaxAge, shouldICacheTheData) {
    let method = request.endpoint.method.toUpperCase();
    let url = request.prepareUrl(requestOptions);
    cacheMaxAge = cacheMaxAge || 60;
    shouldICacheTheData = shouldICacheTheData || function () {return true;};

    if(method === "GET"){
        const key = md5(url);
        const cachedData = cache.getItem(key);

        if(cachedData){
            return Promise.resolve().then( () => {
                return cachedData;
            });
        }

        return request(requestOptions).then(data => {
            if(shouldICacheTheData(data)){
                cache.setItem(key, data, cacheMaxAge);
            }
            return data;
        });
    }

    return request(requestOptions);
}



