import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import {
   Card,
   CardBody,
   CardTitle,
   Col,
   Label,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
} from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../../api/apiClient";
import ButtonCustom from "../../../components/custom/ButtonCustom";
import { CustomInput } from "../../../components/custom/CustomInput";
import GenericTable from "../../../components/custom/GenericTable";
import { LoadingComponent } from "../../../components/custom/LoadingComponent";
import { SelectSimple } from "../../../components/custom/Select";
import {
   booleanOptions,
   FILTER_TYPE,
   maxRecordToShowConfirmation,
   notDefined,
   paginationModel,
} from "../../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../../constants/exportExcel/meterInstallation";
import { MeterModel } from "../../../models/MeterModel";
import authService from "../../../services/AuthService";
import { saveAsExcel } from "../../../utilities/exportToExcel";

const getCustomFilter = (filter, row, property = "name") => {
   const value = filter.value.toLowerCase();
   const rowProp = row[filter.id] || null;
   const name = rowProp != null ? row[filter.id][property].toLowerCase() : null;
   return name != null ? name.includes(value) : false;
};

const MeterInstallation = ({ handleError }) => {
   const [loading, setLoading] = useState(false);
   const [meterList, setMeterList] = useState([]);
   const [meterSelected, setMeterSelected] = useState(new MeterModel());
   const [meterOperation, setMeterOperation] = useState("");
   const [openMeterModal, setOpenMeterModal] = useState(false);
   const [isAllowed, setIsAllowed] = useState(false);
   const [institutionList, setInstitutionList] = useState([]);
   const [statusList, setStatusList] = useState([]);
   const [meterTypeList, setMeterTypeList] = useState([]);
   const [moduleTypeList, setModuleTypeList] = useState([]);
   const [formList, setFormList] = useState([]);
   const [pagination, setPagination] = useState(paginationModel);
   const [headers, setHeaders] = useState([]);

   //Handle pagination manually
   const [triggerOnFetchData, setTriggerOnFetchData] = useState(false);
   const [tableState, setTableState] = useState();

   //SELECT ON TABLE
   const [institutionSelect, setInstitutionSelect] = useState(null);
   const [meterTypeSelect, setMeterTypeSelect] = useState(null);
   const [formSelect, setFormSelect] = useState(null);
   const [meterModuleTypeSelect, setMeterModuleTypeSelect] = useState(null);
   const [statusSelect, setStatusSelect] = useState(null);
   const [touEnabledSelect, setTouEnabledSelect] = useState(null);

   useEffect(() => {
      if (triggerOnFetchData) {
         onFetchData(tableState);
      }
   }, [triggerOnFetchData]);

   useEffect(() => {
      setLoading(true);
      authService
         .userHasPermissions("ManageAllInstitutions")
         .then((response) => {
            if (response) {
               setIsAllowed(true);

               apiClient.institutions
                  .all()
                  .then((res) => {
                     const formattedData = res.data.map((item) => ({
                        ...item,
                        value: item.id,
                        label: item.name,
                     }));
                     setInstitutionList(formattedData);
                  })
                  .catch(handleError);
            }
         })
         .finally(() => setLoading(false));

      setLoading(true);
      apiClient.meters
         .getMeterStatuses()
         .then((res) => {
            const formattedData = res.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));
            setStatusList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));

      setLoading(true);
      apiClient.meters
         .getMeterTypes()
         .then((res) => {
            const formattedData = res.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));

            setMeterTypeList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));

      setLoading(true);
      apiClient.meters
         .getMeterModuleTypes()
         .then((res) => {
            const formattedData = res.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));

            setModuleTypeList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));

      setLoading(true);
      apiClient.meters
         .getMeterForms()
         .then((res) => {
            const formattedData = res.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));
            setFormList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));
   }, []);

   const getCustomColumns = () => {
      const value = [
         {
            accessor: "statusId",
            width: 140,
            Cell: (props) =>
               statusList.find((x) => x.id == props.value)?.name ?? notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: statusSelect,
                  options: statusList,
                  setSelected: setStatusSelect,
                  onChange,
               }),
         },
         {
            accessor: "meterTypeId",
            width: 180,
            Cell: (props) =>
               meterTypeList.find((x) => x.id == props.value)?.name ??
               notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: meterTypeSelect,
                  options: meterTypeList,
                  setSelected: setMeterTypeSelect,
                  onChange,
               }),
         },
         {
            accessor: "formId",
            width: 140,
            Cell: (props) =>
               formList.find((x) => x.id == props.value)?.name ?? notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: formSelect,
                  options: formList,
                  setSelected: setFormSelect,
                  onChange,
               }),
         },
         {
            accessor: "institutionId",
            width: 140,
            Cell: (props) =>
               institutionList.find((x) => x.id == props.value)?.name ??
               notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: institutionSelect,
                  options: institutionList,
                  setSelected: setInstitutionSelect,
                  onChange,
                  filterType: FILTER_TYPE.id,
               }),
         },
         {
            accessor: "meterModuleTypeId",
            width: 140,
            Cell: (props) =>
               moduleTypeList.find((x) => x.id == props.value)?.name ??
               notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: meterModuleTypeSelect,
                  options: moduleTypeList,
                  setSelected: setMeterModuleTypeSelect,
                  onChange,
               }),
         },
         {
            accessor: "touEnabled",
            Cell: (props) => (
               <div className="text-center">
                  <Switch
                     onChange={() => {}}
                     checked={props.value ?? false}
                     disabled
                     className="react-switch"
                     id="disabled-switch"
                  />
               </div>
            ),
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: touEnabledSelect,
                  options: booleanOptions,
                  setSelected: setTouEnabledSelect,
                  onChange,
                  filterType: FILTER_TYPE.boolean,
               }),
         },
      ];

      return value;
   };
   const getActions = () => {
      const value = [
         {
            Header: "Acción",
            accessor: "id",
            sortable: false,
            filterable: false,
            Cell: (props) => (
               <div className="text-center">
                  <ButtonCustom
                     name={"edit"}
                     id={"edit"}
                     title={"Editar"}
                     color={"warning"}
                     className={""}
                     size={"sm"}
                     Click={() => onEditButtonClick(props.value)}
                  />
               </div>
            ),
         },
      ];
      return value;
   };

   const getSelectNative = ({
      value,
      options = [],
      setSelected,
      onChange,
      filterType = FILTER_TYPE.name,
   }) => {
      return (
         <select
            defaultValue={value}
            onChange={(event) => {
               switch (filterType) {
                  case FILTER_TYPE.name:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(event.target.value);
                     break;

                  case FILTER_TYPE.id:
                     onChange(
                        options.find((x) => x.name == event.target.value)?.id ||
                           ""
                     );
                     break;

                  case FILTER_TYPE.boolean:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(
                             options.find((x) => x.name == event.target.value)
                                ?.id
                                ? 1
                                : 0
                          );
                     break;

                  default:
                     break;
               }

               setSelected(event.target.value);
            }}
         >
            <option value={0}>TODOS</option>
            {options.length > 0 &&
               options?.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
               ))}
         </select>
      );
   };

   const toggleMeterModal = () => {
      setOpenMeterModal((old) => !old);
   };
   const handleToExcel = async () => {
      if (pagination.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 pagination.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               donwloadToExcel();
            }
         });
      } else {
         donwloadToExcel();
      }
   };
   const donwloadToExcel = () => {
      setLoading(true);
      apiClient.meters
         .getListing({
            draw: 0,
            page: 1,
            length: pagination.recordsTotal,
            filtered: pagination.filtered,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: convertDataExport(res.data),
               endMergeTitle: "P1",
               endMergeSubTitle: "P2",
               columnWidthConfigs,
               title: "LISTADO DE MEDIDORES",
               name: `TMAM-Listado de medidores (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `Descargado el: ${new Date().toLocaleString()}`,
            });
            setLoading(false);
         })
         .catch(handleError)
         .finally(() => setLoading(false));
   };

   const convertDataExport = (data) => {
      return data?.map((item) => ({
         serial: item.serial,
         meterCardSerial: item.meterCardSerial,
         meterFatherCardSerial: item.meterFatherCardSerial,
         meterTypeId:
            item.meterTypeId !== null
               ? meterTypeList.find((x) => x.id === item.meterTypeId)?.name
               : "N/D",
         form:
            item.formId !== null
               ? formList.find((x) => x.id === item.formId)?.name
               : "N/D",
         meterModuleType:
            item.meterModuleTypeId !== null
               ? moduleTypeList.find((x) => x.id === item.meterModuleTypeId)
                    ?.name
               : "N/D",
         meterPhaseCommunication: item.meterPhaseCommunication,
         status:
            item.statusId !== null
               ? statusList.find((x) => x.id === item.statusId)?.name
               : "N/D",
         institution:
            item.institutionId !== null
               ? institutionList.find((x) => x.id === item.institutionId)?.name
               : "N/D",
         installedAt: item.installedAt,
         latitude: item.latitude,
         longitude: item.longitude,
         gateway: item.gatewayAlias,
         touFrom: item.touFrom ? "True" : "False",
         touTo: item.touTo ? "True" : "False",
         touEnabled: item.touEnabled ? "True" : "False",
      }));
   };
   const onFetchData = (state) => {
      setLoading(true);
      apiClient.meters
         .getListing({
            draw: 0,
            page: state.page + 1,
            length: pagination.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
         })
         .then((res) => {
            setPagination((old) => ({
               ...old,
               pages: Math.ceil(res.recordsTotal / pagination.pageSize),
               recordsTotal: res.recordsTotal,
               filtered: state.filtered,
               sorted: state.sorted,
            }));
            setMeterList(res.data);
            setHeaders(res.headers);
         })
         .catch(handleError)
         .finally(() => {
            setLoading(false);
            setTriggerOnFetchData(false);
         });
   };

   const onAddButtonClick = () => {
      setMeterSelected(new MeterModel());
      setMeterOperation("add");
      toggleMeterModal();
   };

   const onEditButtonClick = (meterId) => {
      setMeterOperation("edit");
      toggleMeterModal();
      const meterToEdit = meterList.find((x) => x.id === meterId);
      const institution = institutionList.find(
         (institution) => institution.id === meterToEdit.institutionId
      );
      const meterType = meterTypeList.find(
         (item) => item.id == meterToEdit.meterTypeId
      );
      const meterModuleType = moduleTypeList.find(
         (item) => item.id == meterToEdit.meterModuleTypeId
      );
      const status = statusList.find((item) => item.id == meterToEdit.statusId);
      const form = formList.find((item) => item.id == meterToEdit.formId);
      setMeterSelected({
         ...meterToEdit,
         institution,
         meterType,
         meterModuleType,
         status,
         form,
      });
   };

   const onChangeSelect = (value, key, array) => {
      setMeterSelected((old) => ({
         ...old,
         [key]: array.find((x) => x.id === value),
      }));
   };

   const handleOnChangeMeterInput = (e, _, key) => {
      if (e.target == null) {
         setMeterSelected((old) => ({ ...old, [key]: e }));
         return;
      }

      const {
         target: { name, value },
      } = e;
      setMeterSelected((old) => ({ ...old, [name]: value }));
   };

   const submit = () => {
      toggleMeterModal();
      const {
         id = "",
         institution = {},
         serial = "",
         meterCardSerial = "",
         meterFatherCardSerial = "",
         meterType = {},
         meterModuleType = {},
         status = {},
         form = {},
         latitude = "",
         longitude = "",
         meterPhaseCommunication = "",
         touEnabled = false,
         touFrom = 0,
         touTo = 0,
      } = meterSelected;
      if ("id" in meterSelected && meterSelected.id > 0) {
         setLoading(true);
         apiClient.meters
            .editListing({
               id: id,
               Serial: serial,
               MeterCardSerial: serial,
               MeterFatherCardSerial: serial,
               meterType: meterType.id,
               meterModuleType: meterModuleType.id,
               institutionId: isAllowed ? institution.id : null,
               status: status.id,
               form: form.id,
               latitude: latitude,
               longitude: longitude,
               MeterPhaseCommunication: Number(meterPhaseCommunication),
               touEnabled,
               touFrom,
               touTo,
            })
            .then((response) => {
               if (response) {
                  Swal.fire({
                     type: "success",
                     title: "Cambios realizados correctamente",
                     showConfirmButton: false,
                     timer: 1500,
                  });
                  const editedMeter = meterList.findIndex(
                     (x) => x.id === response.id
                  );
                  let newMeterlist = [...meterList];
                  newMeterlist[editedMeter] = response;
                  setMeterSelected(new MeterModel());
                  setMeterList(newMeterlist);
               }
            })
            .catch(handleError)
            .finally(() => setLoading(false));
      } else {
         setLoading(true);
         apiClient.meters
            .createListing({
               Serial: serial,
               MeterCardSerial: serial,
               MeterFatherCardSerial: serial,
               meterType: meterType.id,
               meterModuleType: meterModuleType.id,
               status: status.id,
               institutionId: isAllowed ? institution.id : null,
               form: form.id,
               latitude: latitude,
               longitude: longitude,
               MeterPhaseCommunication: Number(meterPhaseCommunication),
               touEnabled,
               touFrom,
               touTo,
            })
            .then((response) => {
               if (response) {
                  Swal.fire({
                     type: "success",
                     title: " Medidor fue agregado correctamente",
                     showConfirmButton: false,
                     timer: 1500,
                  });

                  setMeterSelected(new MeterModel());
                  setMeterList((old) => [...old, response]);
               }
            })
            .catch(handleError)
            .finally(() => setLoading(false));
      }
   };

   return (
      <>
         <LoadingComponent loading={loading} />
         <Modal isOpen={openMeterModal}>
            <ModalHeader toggle={toggleMeterModal}>
               {meterOperation === "add" ? "Agregar" : "Editar"}
            </ModalHeader>
            <ModalBody>
               <Row>
                  <Col md={6}>
                     <CustomInput
                        valid={meterSelected.serial.length > 0}
                        invalid={meterSelected.serial.length > 12}
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.serial}
                        label={"Serial"}
                        id={"serial"}
                     />
                  </Col>
                  <Col md={6}>
                     <CustomInput
                        type={"number"}
                        valid={meterSelected.meterPhaseCommunication >= 0}
                        invalid={
                           meterSelected.meterPhaseCommunication > 255 ||
                           meterSelected.meterPhaseCommunication < 0 ||
                           !Number.isInteger(
                              Number(meterSelected.meterPhaseCommunication)
                           )
                        }
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.meterPhaseCommunication}
                        label={"Tipo de comunicación"}
                        id={"meterPhaseCommunication"}
                     />
                  </Col>
               </Row>
               <Row style={isAllowed ? { display: "" } : { display: "none" }}>
                  <Col md={12}>
                     <SelectSimple
                        valueCallback={onChangeSelect}
                        item={meterSelected.institution}
                        id={"institution"}
                        label={"Institución"}
                        array={institutionList}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelect}
                        item={meterSelected.meterType}
                        id={"meterType"}
                        label={"Tipo medidor"}
                        array={meterTypeList}
                     />
                  </Col>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelect}
                        item={meterSelected.form}
                        id={"form"}
                        label={"Forma"}
                        array={formList}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelect}
                        item={meterSelected.meterModuleType}
                        id={"meterModuleType"}
                        label={"Tipo módulo"}
                        array={moduleTypeList}
                     />
                  </Col>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelect}
                        item={meterSelected.status}
                        id={"status"}
                        label={"Estado"}
                        array={statusList}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col md={6}>
                     <CustomInput
                        valid={meterSelected.latitude.toString().length > 0}
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.latitude}
                        label={"Latitud"}
                        id={"latitude"}
                     />
                  </Col>

                  <Col md={6}>
                     <CustomInput
                        valid={meterSelected.longitude.toString().length > 0}
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.longitude}
                        label={"Longitud"}
                        id={"longitude"}
                     />
                  </Col>
                  <Col md={12}>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           padding: "10px 0",
                        }}
                     >
                        <Label>Habilitar Tariff</Label>
                        <Switch
                           onChange={handleOnChangeMeterInput}
                           checked={meterSelected.touEnabled ?? false}
                           className="react-switch"
                           id="touEnabled"
                        />
                     </div>
                  </Col>
                  <Col md={6}>
                     <CustomInput
                        type={"number"}
                        valid={meterSelected.touFrom >= 0}
                        readOnly={!meterSelected.touEnabled}
                        invalid={
                           meterSelected.touFrom > 23 ||
                           meterSelected.touFrom < 0 ||
                           !Number.isInteger(Number(meterSelected.touFrom))
                        }
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.touFrom}
                        label={"Tariff Desde"}
                        id={"touFrom"}
                     />
                  </Col>
                  <Col md={6}>
                     <CustomInput
                        type={"number"}
                        valid={meterSelected.touTo >= 0}
                        readOnly={!meterSelected.touEnabled}
                        invalid={
                           meterSelected.touTo > 23 ||
                           meterSelected.touTo < 0 ||
                           !Number.isInteger(Number(meterSelected.touTo))
                        }
                        onChange={handleOnChangeMeterInput}
                        value={meterSelected.touTo}
                        label={"Tariff Hasta"}
                        id={"touTo"}
                     />
                  </Col>
               </Row>
               <br />

               <Row>
                  <Col md={12}>
                     <ButtonCustom
                        color={"success"}
                        className={""}
                        name={"submit"}
                        id={"submit"}
                        disabled={
                           meterSelected.id > 0
                              ? !(
                                   meterSelected.serial?.length > 0 &&
                                   meterSelected.latitude.toString()?.length >
                                      0 &&
                                   meterSelected.longitude.toString()?.length >
                                      0 &&
                                   meterSelected.meterPhaseCommunication <
                                      256 &&
                                   meterSelected.meterPhaseCommunication >= 0 &&
                                   Number.isInteger(
                                      Number(
                                         meterSelected.meterPhaseCommunication
                                      )
                                   ) &&
                                   meterSelected.touTo < 24 &&
                                   meterSelected.touTo >= 0 &&
                                   meterSelected.touFrom < 24 &&
                                   meterSelected.touFrom >= 0
                                )
                              : !(
                                   (isAllowed
                                      ? meterSelected.institution?.name
                                           ?.length > 0
                                      : meterSelected.institution?.name
                                           ?.length === 0) &&
                                   meterSelected.meterPhaseCommunication <
                                      256 &&
                                   meterSelected.meterPhaseCommunication >= 0 &&
                                   Number.isInteger(
                                      Number(
                                         meterSelected.meterPhaseCommunication
                                      )
                                   ) &&
                                   meterSelected.serial?.length > 0 &&
                                   meterSelected.serial?.length < 13 &&
                                   meterSelected.meterType?.name?.length > 0 &&
                                   meterSelected.meterModuleType?.name?.length >
                                      0 &&
                                   meterSelected.status?.name?.length > 0 &&
                                   meterSelected.form?.name?.length > 0 &&
                                   meterSelected.latitude.toString()?.length >
                                      0 &&
                                   meterSelected.longitude.toString()?.length >
                                      0 &&
                                   meterSelected.touTo < 24 &&
                                   meterSelected.touTo >= 0 &&
                                   meterSelected.touFrom < 24 &&
                                   meterSelected.touFrom >= 0
                                )
                        }
                        Click={submit}
                        title={meterSelected.id > 0 ? "Actualizar" : "Enviar"}
                     />{" "}
                     <ButtonCustom
                        type={"cancel"}
                        color={"danger"}
                        className={""}
                        name={"cancel"}
                        id={"cancel"}
                        Click={toggleMeterModal}
                        title={"Cancelar"}
                     />
                  </Col>
               </Row>
            </ModalBody>
         </Modal>
         <Card>
            <CardTitle
               style={{
                  fontSize: "18px",
               }}
               className="bg-light border-bottom p-3 mb-0"
            >
               <i className="mdi mdi-hamburger mr-2"> </i> Instalación de
               medidores
            </CardTitle>
            <CardBody>
               <GenericTable
                  data={meterList}
                  headers={headers}
                  loading={loading}
                  pages={pagination.pages}
                  customColumns={getCustomColumns()}
                  onPageSizeChange={(value) =>
                     setPagination((old) => ({ ...old, pageSize: value }))
                  }
                  pageSize={
                     meterList.length > 0 &&
                     meterList.length < pagination.pageSize
                        ? meterList.length
                        : pagination.pageSize
                  }
                  onFetchData={(state) => {
                     setTableState(state);
                     setTriggerOnFetchData(true);
                  }}
                  actions={getActions()}
                  onAddClick={onAddButtonClick}
                  onDownloadClick={handleToExcel}
               />
            </CardBody>
         </Card>
      </>
   );
};

export default MeterInstallation;
