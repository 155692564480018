import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export async function saveAsExcel({
   title,
   name,
   subTitle,
   data,
   columns,
   endMergeTitle,
   endMergeSubTitle,
   columnWidthConfigs,
}) {
   XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const report = workbook.sheet(0);
      const sheetData = getSheetData(data, columns);

      //   const totalColumns = sheetData[0].length;
      if (title != "") {
         const rangeTitle = workbook.sheet(0).range(`A1:${endMergeTitle}`);
         rangeTitle.merged(true);
         report.cell("A1").value(title).style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            border: false,
         });
      }

      if (subTitle != "") {
         const rangeSubTitle = workbook
            .sheet(0)
            .range(`A2:${endMergeSubTitle}`);
         rangeSubTitle.merged(true);
         report.cell("A2").value(subTitle).style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            border: false,
         });
      }

      report.cell("A3").value(sheetData);

      //   const rangeBody = report.usedRange();
      //   const endColumn = String.fromCharCode(64 + totalColumns);
      //   report.row(1).style({
      //      bold: true,
      //      fontSize: 14,
      //   });

      //   report.range("A3:" + endColumn + "3").style({
      //      bold: true,
      //      fill: "BFBFBF",
      //      border: true,
      //   });
      //   rangeBody.style("border", true);

      if (columnWidthConfigs?.length > 0) {
         for (let i = 0; i < columnWidthConfigs.length; i++) {
            report
               .column(columnWidthConfigs[i].column)
               .width(columnWidthConfigs[i].width);
         }
      }

      return workbook.outputAsync().then((res) => {
         saveAs(res, `${name}.xlsx`);
      });
   });
}

function getSheetData(data, header) {
   var fields = Object.keys(data[0]);
   var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
         if (row[fieldName] === 0) return row[fieldName] ? row[fieldName] : 0;
         else return row[fieldName] ? row[fieldName] : "N/A";
      });
   });
   sheetData.unshift(header);
   return sheetData;
}
