import React from 'react';
import {Nav, Navbar, NavbarBrand, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import logolighticon from '../../assets/images/logo-light-icon.png';
import logolighttext from '../../assets/images/logo-light-text.png';
import profilephoto from '../../assets/images/users/new.png';
import authService from "../../services/AuthService";
import notificationsManager from "../../services/NotificationsManager";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: [],
			isOpen: false,
		};
	}

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	showMobilemenu = () => {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	};

	componentDidMount = () => {
		authService.getUser().then(user => {
			this.setState({user: user ? user.profile : null});
		});

		// notificationsManager.onNotification(notification => {
        // if (notification.action !== undefined && notification.action ==='RequestStatusChanged') {
        //     console.log("Notification header : ", notification.data);
        // this.setState({NotificationHeader:notification.data});
		// 	return (<Link to="/petitions">Go to Results</Link>)
        // }
		// });
		notificationsManager.start()
	};

	logout = () => {
		authService.logout();
	};

	render(){
		return (
			<header  className="topbar navbarbg" data-navbarbg={this.props.data.settings[0].navbarbg}>
				<Navbar   className={"top-navbar " + (this.props.data.settings[0].navbarbg === "skin6" ? 'navbar-dark' : 'navbar-dark')} expand="md">
					<div  style={{paddingRight:'250px',background:"#fff"}} className="navbar-header" id="logobg" data-logobg={this.props.data.settings[0].logobg}>
						<span  className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>
						<NavbarBrand href="/dashboard" style={{paddingRight:'180px', }}>
							<b className="logo-icon">
								<img src={logolighticon} alt="homepage" width={80}  className="light-logo"/>
							</b>
							<span className="logo-text">
								<img src={logolighttext} className="light-logo" alt="homepage"/>
							</span>
						</NavbarBrand>
						<span  className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse  style={{background:"#fff" }} className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.data.settings[0].navbarbg} >
						<Nav className="ml-auto float-right" navbar>
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle  nav caret className="pro-pic">
									<img
										src={profilephoto}
										alt="user"
										className="rounded-circle"
										width="50"
									/>
								</DropdownToggle>
								<DropdownMenu  right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div style={{ background:"#0d367b"}}  className="d-flex no-block align-items-center p-3  text-white mb-2">
										<div className="">
										</div>
										<div  className="ml-2">
											<h4 className="mb-0">{this.state.user ? this.state.user.given_name : 'N/A'}</h4>
										</div>
									</div>
									<DropdownItem  href="/profile">
										<i className="ti-user mr-1 ml-1"/> Mi perfil
									</DropdownItem>
									<DropdownItem onClick={this.logout}>
										<i className="fa fa-power-off mr-1 ml-1"/> Logout
									</DropdownItem>
									<DropdownItem divider/>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
export default Header;
