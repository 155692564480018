import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../components/header/header.jsx";
import Sidebar from "../components/sidebar/sidebar.jsx";
import Customizer from "../components/customizer/customizer";
import ThemeRoutes from "../routes/routing.jsx";
import authService from "../services/AuthService";

class Fulllayout extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         user: [],
         isOpen: false,
         width: window.innerWidth,
         settings: [
            {
               theme: "light",
               layout: "horizontal",
               dir: "ltr",
               sidebartype: "full",
               sidebarpos: "absolute",
               headerpos: "absolute",
               boxed: "full",
               navbarbg: "skin1",
               sidebarbg: "skin6",
               logobg: "skin1",
            },
         ],
         ManageUsers: "",
         ManageGateways: "",
         ManageMeters: "",
         ManageAllInstitutions: "",
         ManageInternalProcesses: "",
         ManageSimInventory: "",
         SendCommandsToGateways: "",
         SendSms: "",
      };

      this.props.history.listen((location, action) => {
         if (
            window.innerWidth < 767 &&
            document
               .getElementById("main-wrapper")
               .className.indexOf("show-sidebar") !== -1
         ) {
            document
               .getElementById("main-wrapper")
               .classList.toggle("show-sidebar");
         }
      });
   }

   componentDidMount() {
      authService.getUser().then((user) => {
         this.setState({ user });
      });
      window.addEventListener("load", this.updateDimensions);
      window.addEventListener("resize", this.updateDimensions);

      Promise.all([
         authService.userHasPermissions("ManageUsers"),
         authService.userHasPermissions("ManageGateways"),
         authService.userHasPermissions("ManageMeters"),
         authService.userHasPermissions("ManageAllInstitutions"),
         authService.userHasPermissions("ManageInternalProcesses"),
         authService.userHasPermissions("ManageSimInventory"),
         authService.userHasPermissions("SendCommandsToGateways"),
         authService.userHasPermissions("SendSms"),
      ]).then((response) => {
         this.setState({
            ManageUsers: response[0],
            ManageGateways: response[1],
            ManageMeters: response[2],
            ManageAllInstitutions: response[3],
            ManageInternalProcesses: response[4],
            ManageSimInventory: response[5],
            SendCommandsToGateways: response[6],
            SendSms: response[7],
         });
      });
   }

   updateDimensions = () => {
      let element = document.getElementById("main-wrapper");
      // this.setState({width: window.innerWidth});
      switch (this.state.settings[0].sidebartype) {
         case "full":
         case "iconbar":
            if (window.innerWidth < 1170) {
               // before
               // if (this.state.width < 1170) {
               element.setAttribute("data-sidebartype", "mini-sidebar");
               element.classList.add("mini-sidebar");
            } else {
               element.setAttribute(
                  "data-sidebartype",
                  this.state.settings[0].sidebartype
               );
               element.classList.remove("mini-sidebar");
            }
            break;

         case "overlay":
            if (window.innerWidth < 767) {
               // before
               // if (this.state.width < 1170) {
               element.setAttribute("data-sidebartype", "mini-sidebar");
            } else {
               element.setAttribute(
                  "data-sidebartype",
                  this.state.settings[0].sidebartype
               );
            }
            break;

         default:
      }
      if (this.state.settings[0].sidebarpos === "fixed") {
         document
            .getElementById("sidebar-position")
            .setAttribute("checked", "");
      }
      if (this.state.settings[0].headerpos === "fixed") {
         document.getElementById("header-position").setAttribute("checked", "");
      }
      if (this.state.settings[0].theme === "dark") {
         document.getElementById("theme-view").setAttribute("checked", "");
      }
      if (this.state.settings[0].boxed === "boxed") {
         document.getElementById("boxed-layout").setAttribute("checked", "");
      }
   };

   componentWillUnmount() {
      window.removeEventListener("load", this.updateDimensions);
      window.removeEventListener("resize", this.updateDimensions);
   }

   darkTheme = (a) => {
      if (a.target.checked) {
         let darktheme = JSON.parse(JSON.stringify(this.state.settings));
         darktheme[0].theme = "dark";
         this.setState({ settings: darktheme });
      } else {
         let lighttheme = JSON.parse(JSON.stringify(this.state.settings));
         lighttheme[0].theme = "light";
         this.setState({ settings: lighttheme });
      }
   };

   boxedTheme = (b) => {
      if (b.target.checked) {
         let boxtheme = JSON.parse(JSON.stringify(this.state.settings));
         boxtheme[0].boxed = "boxed";
         this.setState({ settings: boxtheme });
      } else {
         let fulltheme = JSON.parse(JSON.stringify(this.state.settings));
         fulltheme[0].boxed = "full";
         this.setState({ settings: fulltheme });
      }
   };

   headerPosition = (c) => {
      if (c.target.checked) {
         let fixedpos = JSON.parse(JSON.stringify(this.state.settings));
         fixedpos[0].headerpos = "fixed";
         this.setState({ settings: fixedpos });
      } else {
         let absolutepos = JSON.parse(JSON.stringify(this.state.settings));
         absolutepos[0].headerpos = "absolute";
         this.setState({ settings: absolutepos });
      }
   };

   sidebarPosition = (d) => {
      if (d.target.checked) {
         let sidebarfixedpos = JSON.parse(JSON.stringify(this.state.settings));
         sidebarfixedpos[0].sidebarpos = "fixed";
         this.setState({ settings: sidebarfixedpos });
      } else {
         let sidebarabsolutepos = JSON.parse(
            JSON.stringify(this.state.settings)
         );
         sidebarabsolutepos[0].sidebarpos = "absolute";
         this.setState({ settings: sidebarabsolutepos });
      }
   };

   navbarbgChange = (e) => {
      let navskin = e.currentTarget.dataset.navbarbg;
      let newsettings = JSON.parse(JSON.stringify(this.state.settings));
      newsettings[0].navbarbg = navskin;
      this.setState({
         settings: newsettings,
      });
   };

   sidebarbgChange = (f) => {
      let sidebarskin = f.currentTarget.dataset.sidebarbg;
      let newsettings = JSON.parse(JSON.stringify(this.state.settings));
      newsettings[0].sidebarbg = sidebarskin;
      this.setState({
         settings: newsettings,
      });
   };

   logobgChange = (g) => {
      let logoskin = g.currentTarget.dataset.logobg;
      let newsettings = JSON.parse(JSON.stringify(this.state.settings));
      newsettings[0].logobg = logoskin;
      this.setState({
         settings: newsettings,
      });
   };

   permission(Routes) {
      let NewThemeRoutes = Routes;
      if (this.state.ManageUsers === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Usuarios"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageUsers === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Clear Data"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageUsers === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Roles"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageAllInstitutions === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Instituciones"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.SendCommandsToGateways === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Command"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageInternalProcesses === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Administración") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Rellenos"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }

      if (this.state.ManageMeters === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Centro de instalación") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Medidores"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageGateways === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Centro de instalación") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "Gateways"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      if (this.state.ManageSimInventory === false) {
         NewThemeRoutes = NewThemeRoutes.map((menu) => {
            if (menu.name === "Centro de instalación") {
               let newMenu = [...menu.child];
               newMenu.splice(
                  menu.child.findIndex((x) => x.name === "SIM"),
                  1
               );
               return {
                  child: newMenu,
                  collapse: menu.collapse,
                  extra: menu.extra,
                  icon: menu.icon,
                  name: menu.name,
                  state: menu.state,
               };
            } else {
               return menu;
            }
         });
      }
      return NewThemeRoutes;
   }

   render() {
      return (
         <div
            id="main-wrapper"
            dir={this.state.settings[0].dir}
            data-theme={this.state.settings[0].theme}
            data-layout={this.state.settings[0].layout}
            data-sidebartype={this.state.settings[0].sidebartype}
            data-sidebar-position={this.state.settings[0].sidebarpos}
            data-header-position={this.state.settings[0].headerpos}
            data-boxed-layout={this.state.settings[0].boxed}
         >
            <Header data={this.state} />
            <Sidebar
               data={this.state}
               {...this.props}
               routes={this.permission(ThemeRoutes)}
            />
            <div className="page-wrapper d-block">
               {/*<div style={{marginTop:10}} className="container-fluid">*/}
               <div className=" page-content container-fluid">
                  <Switch>
                     {this.permission(ThemeRoutes).map((prop, key) => {
                        if (prop.navlabel) {
                           return null;
                        } else if (prop.collapse) {
                           return prop.child.map((prop2, key2) => {
                              if (prop2.collapse) {
                                 return prop2.subchild.map((prop3, key3) => {
                                    return (
                                       <Route
                                          path={prop3.path}
                                          component={prop3.component}
                                          key={key3}
                                       />
                                    );
                                 });
                              }
                              return (
                                 <Route
                                    path={prop2.path}
                                    component={prop2.component}
                                    key={key2}
                                 />
                              );
                           });
                        } else if (prop.redirect) {
                           return (
                              <Redirect
                                 from={prop.path}
                                 to={prop.pathTo}
                                 key={key}
                              />
                           );
                        } else {
                           return (
                              <Route
                                 path={prop.path}
                                 component={prop.component}
                                 key={key}
                              />
                           );
                        }
                     })}
                  </Switch>
               </div>
            </div>
            <Customizer
               darkTheme={this.darkTheme}
               boxedTheme={this.boxedTheme}
               rtl={this.rtl}
               headerPosition={this.headerPosition}
               sidebarPosition={this.sidebarPosition}
               navbarbgChange={this.navbarbgChange}
               sidebarbgChange={this.sidebarbgChange}
               logobgChange={this.logobgChange}
            />
         </div>
      );
   }
}
export default Fulllayout;
