import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";

const ModalDashBoard = ({
   data,
   columns,
   isOpen,
   toggle,
   PageSize,
   title,
   isHasReport,
   downloadReport,
   style,
   children,
}) => {
   return (
      <Modal size={"lg"} isOpen={isOpen} style={style}>
         <ModalHeader toggle={toggle}>{title}</ModalHeader>
         <ModalBody>
            {children ? (
               children
            ) : (
               <TableLayout
                  data={data}
                  defaultPageSize={PageSize}
                  showPaginationBottom={true}
                  showPaginationTop={true}
                  columns={columns}
               />
            )}
         </ModalBody>
         <ModalFooter>
            {isHasReport ? (
               <ButtonCustom
                  color={"success"}
                  className={""}
                  name={"download"}
                  id={"download"}
                  Click={() => downloadReport()}
                  title={"Descargar"}
               />
            ) : null}
            <ButtonCustom
               color={"danger"}
               className={""}
               name={"Cerrar"}
               id={"Cerrar"}
               Click={toggle}
               title={"Cerrar"}
            />
         </ModalFooter>
      </Modal>
   );
};

export default ModalDashBoard;
