export const maxRecordToShowConfirmation = 1000;

export const paginationModel = {
   pages: 0,
   pageSize: 10,
   recordsTotal: 0,
   filtered: [],
   sorted: [],
};

export const notDefined = "N/D";

export const FILTER_TYPE = {
   name: "name",
   id: "id",
   boolean: "boolean",
};

export const booleanOptions = [
   { id: true, name: "Si" },
   { id: false, name: "No" },
];
