import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { FormGroup } from "reactstrap";

export default class Date extends React.Component {
   render() {
      return (
         <FormGroup>
            <Datetime
               name={this.props.id}
               id={this.props.id}
               inputProps={{
                  readOnly: true,
                  style: { backgroundColor: "white" },
               }}
               closeOnSelect={true}
               closeOnTab={true}
               open={this.props.open}
               timeFormat={this.props.timeFormat}
               dateFormat={this.props.dateFormat}
               onChange={this.props.onChange}
               value={this.props.value}
            />
         </FormGroup>
      );
   }
}
Date.propTypes = {
   name: PropTypes.string,
   id: PropTypes.string,
   onChange: PropTypes.func,
};

Date.defaultProps = {
   timeFormat: "HH:mm",
};
