import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
   Button,
   Card,
   CardBody,
   CardTitle,
   Col,
   Input,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
} from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../../api/apiClient";
import ButtonCustom from "../../../components/custom/ButtonCustom";
import { CustomInput } from "../../../components/custom/CustomInput";
import CustomDate from "../../../components/custom/Date";
import GenericTable from "../../../components/custom/GenericTable";
import { SelectSimple } from "../../../components/custom/Select";
import {
   currentDate,
   customDateFrom,
   inputDateProblemTypes,
} from "../../../constants/DateTime";
import {
   booleanOptions,
   FILTER_TYPE,
   maxRecordToShowConfirmation,
   notDefined,
} from "../../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../../constants/exportExcel/meterWithProblems";
import { MeterModel } from "../../../models/MeterModel";
import { ProblemType } from "../../../models/ProblemType";
import { saveAsExcel } from "../../../utilities/exportToExcel";
// const resolved = [
//    { value: "", label: "Todos" },
//    { value: true, label: "Si" },
//    { value: false, label: "No" },
// ];

// const TypeOrigin = [
//    { value: "", label: "Todos" },
//    { value: 2, label: "Medidor" },
//    {
//       value: 1,
//       label: "Gateway",
//    },
// ];
const initialState = {
   pageSizeProblemType: 10,
   pageProblemType: 0,
   pagesProblemType: 0,
   selectData: [],
   meter: new MeterModel(),
   problemType: new ProblemType(),
   dateFrom: customDateFrom(),
   dateTo: currentDate(),
   MetersProblems: [],
   problemTypes: [],
   type: [],
   resolved: [
      { id: true, name: "Si", value: true, label: "Si" },
      { id: false, name: "No", value: false, label: "No" },
   ],
   selectedProblemTypes: { value: "", label: "Todos" },
   selectedResolved: { value: "", label: "Todos" },
   selectedTypeOrigin: { value: "", label: "Todo" },
   autocompleteData: [],
   value: "",
   suggestions: [],
   gatewayData: [],
   selectedGatewayId: { value: "", label: "Todos" },
   meterValue: "",
   SuggestionsData: [],
   recordsTotal: 0,
   filtered: [],
   sorted: [],
};

const MeterWithProblem = ({ handleError }) => {
   const [loading, setLoading] = useState(false);
   const [state, setState] = useState(initialState);
   const [problemType, setProblemType] = useState(new ProblemType());
   const [problemTypeList, setProblemTypeList] = useState([]);
   const [gatewayList, setGatewayList] = useState([]);
   const [problemTypeOperation, setProblemTypeOperation] = useState("");
   const [openProblemTypeModal, setOpenProblemTypeModal] = useState(false);
   const [typeOrigin, setTypeOrigin] = useState();
   const [resolveTypeSelected, setResolveTypeSelected] = useState({
      value: "",
      label: "Todos",
   });
   const [problemTypeSelected, setProblemTypeSelected] = useState({
      value: "",
      label: "Todos",
   });
   const [headers, setHeaders] = useState([]);
   const [triggerOnFetchData, setTriggerOnFetchData] = useState(false);

   useEffect(() => {
      setLoading(true);
      apiClient.gateways
         .getActiveGateways()
         .then((response) => {
            const formattedData = response.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));

            setGatewayList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));

      setLoading(true);
      apiClient.meters
         .getMeterProblemsTypes()
         .then((response) => {
            const formattedData = response.data.map((item) => ({
               ...item,
               value: item.id,
               label: item.name,
            }));
            setProblemTypeList(formattedData);
         })
         .catch(handleError)
         .finally(() => setLoading(false));
   }, []);

   useEffect(() => {
      if (state.selectedTypeOrigin.value === 1) {
         setTypeOrigin(
            <Col md={2}>
               <label>Tipo de origen</label>
               <Select
                  name="gateway"
                  id="gateway"
                  placeholder="Gateway"
                  closeMenuOnSelect={true}
                  onChange={onChangeGatewayId}
                  value={state.selectedGatewayId}
                  options={[{ value: "", label: "Todos" }, ...gatewayList]}
               />
            </Col>
         );
      } else if (state.selectedTypeOrigin.value === 2) {
         setTypeOrigin(
            <Col md={2}>
               <label>Tipo de origen</label>
               <Input
                  type="text"
                  name="meter"
                  id="meter"
                  placeholder="Todos"
                  onChange={onChangeMeterValue}
               />
            </Col>
         );
      } else if (state.selectedTypeOrigin.value === "") {
         setTypeOrigin("");
      }
   }, [state.selectedTypeOrigin]);

   const toggleProblemTypeModal = (method) => {
      setProblemType(new ProblemType());
      setOpenProblemTypeModal((old) => !old);
      setProblemTypeOperation(method);
   };

   const onDateChange = (moment, value) => {
      setTriggerOnFetchData(true);
      setState((old) => ({
         ...old,
         [value]: inputDateProblemTypes(moment),
      }));
   };

   const onDateChangeModal = (moment, value) => {
      // let { problemType } = state;
      // problemType[value] = inputDateProblemTypes(moment);
      // setState((old) => ({
      //    ...old,
      //    problemType,
      // }));
      setProblemType((old) => ({
         ...old,
         [value]: inputDateProblemTypes(moment),
      }));
   };

   const onChangeSelectModal = (e, key, array) => {
      setProblemType((old) => ({
         ...old,
         [key]: array.find((x) => x.id === e),
      }));
   };

   // const onChange = (event, { newValue }) => {
   //    setState((old) => ({
   //       ...old,
   //       value: newValue,
   //    }));
   // };

   // const onSelect = (val) => {
   //    let { problemType } = state;
   //    problemType["meter"] = val;
   //    setState((old) => ({
   //       ...old,
   //       problemType,
   //       value: val,
   //    }));
   //    return val;
   // };

   //    const onSuggestionsFetchRequested = ({ value }) => {
   //       apiClient.meters
   //          .getInstalledMetersFiltered({ serial: value })
   //          .then((response) => {
   //             setState((old) => ({
   //                ...old,
   //                SuggestionsData: response.data.slice(0, 10),
   //             }));
   //          });
   //       let array =
   //          value.length === 0
   //             ? []
   //             : state.SuggestionsData.filter(
   //                  (items) => items.serial.slice(0, value.length) === value
   //               );
   //       setState((old) => ({
   //          ...old,
   //          suggestions: array,
   //       }));
   //    };

   //    const onChangeProblemTypes = (selectedProblemTypes) => {
   //       setState((old) => ({
   //          ...old,
   //          selectedProblemTypes,
   //       }));
   //    };

   //    const onChangeResolved = (selectedResolved) => {
   //       setState((old) => ({
   //          ...old,
   //          selectedResolved,
   //       }));
   //    };

   //    const onChangeTypeOrigin = (selectedTypeOrigin) => {
   //       setState((old) => ({
   //          ...old,
   //          selectedTypeOrigin,
   //       }));
   //    };

   const actionButton = (id, type) => {
      if (type === "edit") {
         toggleProblemTypeModal();

         const selectedProblemType = state.MetersProblems.find(
            (x) => x.id === id
         );
         const formattedValue = {
            ...selectedProblemType,
            meter: selectedProblemType.serial,
            type: problemTypeList.find(
               (x) => x.name === selectedProblemType.typeName
            ),
            resolved: selectedProblemType.resolved
               ? state.resolved[0]
               : state.resolved[1],
         };

         setProblemType(formattedValue);
         setProblemTypeOperation(type);

         // const meterToEdit = Object.assign(
         //    {},
         //    state.MetersProblems.find((x) => x.id === id)
         // );
         // let serial = meterToEdit.serial;
         // meterToEdit["resolved"] === true
         //    ? (meterToEdit["resolved"] = {
         //         name: "Si",
         //         id: true,
         //      })
         //    : (meterToEdit["resolved"] = { name: "No", id: false });
         // meterToEdit["meter"] = meterToEdit["serial"];
         setState((old) => ({
            ...old,
            // problemType: meterToEdit,
            value: selectedProblemType.serial,
            // methodProblemType: type,
         }));
      }
      if (type === "delete") {
         Swal.fire({
            title: "¿Estás seguro?",
            text: "¡No podrás revertir esto!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0277bd",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar",
            cancelButtonText: "Cancelar",
         }).then((result) => {
            if (result.value) {
               setLoading(true);
               apiClient.meters
                  .deleteProblem({ id: id })
                  .then(() => {
                     let meterToDelete = [...state.MetersProblems];
                     meterToDelete.splice(
                        state.MetersProblems.findIndex((x) => x.id === id),
                        1
                     );
                     setState((old) => ({
                        ...old,
                        MetersProblems: meterToDelete,
                     }));
                     Swal.fire(
                        "¡Eliminado!",
                        "Tipo de problema eliminado",
                        "success"
                     );
                  })
                  .catch(handleError)
                  .finally(() => setLoading(false));
            }
         });
      }
      if (type === "resolved") {
         Swal.fire({
            title: "¿Cambiar estado?",
            text: "Cambiar a estado resuelto",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#00c853",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, actulizar",
            cancelButtonText: "Cancelar",
         }).then((result) => {
            if (result.value) {
               setLoading(true);
               apiClient.meters
                  .resolveProblem({ id })
                  .then(() => {
                     let MetersProblems = [...state.MetersProblems];
                     const meterUpdate = Object.assign(
                        {},
                        MetersProblems.find((x) => x.id === id)
                     );
                     meterUpdate["resolved"] = true;
                     MetersProblems.splice(
                        state.MetersProblems.findIndex((x) => x.id === id),
                        1,
                        meterUpdate
                     );
                     setState((old) => ({
                        ...old,
                        MetersProblems: MetersProblems,
                        value: "",
                     }));
                  })
                  .catch(handleError)
                  .finally(() => setLoading(false));
            }
         });
      }
   };

   //    const getProblemTypesData = () => {
   //       // const {
   //       //    dateFrom,
   //       //    dateTo,
   //       //    selectedProblemTypes,
   //       //    selectedResolved,
   //       //    selectedTypeOrigin,
   //       //    selectedGatewayId,
   //       //    meterValue,
   //       // } = state;
   //       // if (
   //       //    selectedGatewayId ||
   //       //    meterValue.length >= 0 ||
   //       //    selectedTypeOrigin.value === ""
   //       // ) {
   //       //    setLoading(true);
   //       //    apiClient.meters
   //       //       .getMetersProblems({
   //       //          From: dateFrom,
   //       //          To: dateTo,
   //       //          Resolved: selectedResolved.value,
   //       //          Type: selectedProblemTypes.value,
   //       //          GatewayId:
   //       //             selectedTypeOrigin.value === 1 ? selectedGatewayId.value : "",
   //       //          Meter: selectedTypeOrigin.value === 2 ? meterValue : "",
   //       //          OriginType: selectedTypeOrigin.value,
   //       //          draw: 0,
   //       //          start: 0,
   //       //          length: state.pageSizeProblemType,
   //       //       })
   //       //       .then((res) => {
   //       //          if (res.data.length === 0) {
   //       //             Swal.fire({
   //       //                type: "info",
   //       //                title: "No hay información",
   //       //                text: "No hay información para los parámetros seleccionados ",
   //       //             }).then(() => {
   //       //                setState((old) => ({
   //       //                   ...old,
   //       //                   loading: false,
   //       //                }));
   //       //             });
   //       //          } else {
   //       //             setHeaders(res.headers);
   //       //             setState((old) => ({
   //       //                ...old,
   //       //                MetersProblems: res.data,
   //       //                pagesProblemType: Math.ceil(
   //       //                   res.recordsTotal / state.pageSizeProblemType
   //       //                ),
   //       //             }));
   //       //          }
   //       //       })
   //       //       .catch(handleError)
   //       //       .finally(() => setLoading(false));
   //       // } else {
   //       //    Swal.fire({
   //       //       type: "warning",
   //       //       title: "Campos vacios",
   //       //       text: "No pueden ver campos vacios",
   //       //    });
   //       // }
   //    };

   const handleToExcel = () => {
      if (state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(state.recordsTotal)} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               donwloadToExcel();
            }
         });
      } else {
         donwloadToExcel();
      }
   };
   const donwloadToExcel = () => {
      const {
         dateFrom,
         dateTo,
         selectedProblemTypes,
         selectedResolved,
         selectedTypeOrigin,
         selectedGatewayId,
         meterValue,
         MetersProblems,
      } = state;
      setState({ ...state, loading: true });
      setLoading(true);
      apiClient.meters
         .getMetersProblems({
            From: dateFrom,
            To: dateTo,
            Resolved: selectedResolved.value,
            Type: selectedProblemTypes.value,
            GatewayId:
               selectedTypeOrigin.value === 1 ? selectedGatewayId.value : "",
            Meter: selectedTypeOrigin.value === 2 ? meterValue : "",
            OriginType: selectedTypeOrigin.value,
            draw: 0,
            page: 1,
            length: state.recordsTotal,
            sorted: state.sorted,
            filtered: state.filtered,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: convertDataExport(res.data),
               endMergeTitle: "E1",
               endMergeSubTitle: "E2",
               columnWidthConfigs,
               title: "LISTADO DE MEDIDORES CON PROBLEMAS",
               name: `TMAM-Listado de medidores con problemas (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `Descargado el: ${new Date().toLocaleString()}`,
            });
         })
         .catch(handleError)
         .finally(() => setLoading(false));
   };
   const convertDataExport = (data) => {
      return data?.map((item) => ({
         serial: item.serial,
         typeName: item.typeName,
         resolved: item.resolved ? "Si" : "No",
         registeredAt: item.registeredAt,
         updatedAt: item.updatedAt,
      }));
   };

   const onFetchData = (tableState) => {
      const {
         dateFrom,
         dateTo,
         selectedProblemTypes,
         selectedResolved,
         selectedTypeOrigin,
         selectedGatewayId,
         meterValue,
         MetersProblems,
      } = state;
      setLoading(true);
      apiClient.meters
         .getMetersProblems({
            From: dateFrom,
            To: dateTo,
            Resolved: selectedResolved.value,
            Type: selectedProblemTypes.value,
            GatewayId:
               selectedTypeOrigin.value === 1 ? selectedGatewayId.value : "",
            Meter: selectedTypeOrigin.value === 2 ? meterValue : "",
            OriginType: selectedTypeOrigin.value,
            draw: 0,
            page: tableState.page + 1,
            length: tableState.pageSize,
            sorted: tableState.sorted,
            filtered: tableState.filtered,
         })
         .then((res) => {
            setHeaders(res.headers);
            if (res.data.length > 0)
               setState((old) => ({
                  ...old,
                  MetersProblems: res.data,
                  pageSizeProblemType: tableState.pageSize,
                  pageProblemType: tableState.page,
                  pagesProblemType: Math.ceil(
                     res.recordsTotal / tableState.pageSize
                  ),
                  loadingProblemTypes: false,
                  sorted: tableState.sorted,
                  filtered: tableState.filtered,
                  recordsTotal: res.recordsTotal,
               }));
            else
               setState((old) => ({
                  ...old,
                  MetersProblems: res.data,
                  pageSizeProblemType: tableState.pageSize,
                  pageProblemType: tableState.page,
                  pagesProblemType: Math.ceil(
                     res.recordsTotal / tableState.pageSize
                  ),
                  loadingProblemTypes: false,
                  sorted: [],
                  filtered: [],
                  recordsTotal: res.recordsTotal,
               }));
            setTriggerOnFetchData(false);
         })
         .catch(handleError)
         .finally(() => setLoading(false));
   };

   const problemTypesRequest = () => {
      toggleProblemTypeModal();
      // const { methodProblemType } = state;
      const { id, meter, type, from, to, resolved } = problemType;
      if (problemTypeOperation === "add") {
         setLoading(true);
         apiClient.meters
            .registerProblem({
               serial: meter,
               type: type.id,
               from: from,
               to: to,
               resolved: resolved.id,
            })
            .then((response) => {
               state.MetersProblems.splice(0, 0, response);
               let newMetersProblems = state.MetersProblems.slice();
               setState((old) => ({
                  ...old,
                  MetersProblems: newMetersProblems,
                  value: "",
               }));
            })
            .catch(handleError)
            .finally(() => setLoading(false));
      } else {
         setLoading(true);
         apiClient.meters
            .editProblem({
               id: id,
               serial: meter,
               type: type.id,
               from: from,
               to: to,
               resolved: resolved.id,
            })
            .then((response) => {
               let newMetersProblems = [...state.MetersProblems];
               newMetersProblems.splice(
                  state.MetersProblems.findIndex((x) => x.id === response.id),
                  1,
                  response
               );
               setState((old) => ({
                  ...old,
                  MetersProblems: newMetersProblems,
                  value: "",
               }));
            })
            .catch(handleError)
            .finally(() => setLoading(false));
      }
   };

   const onChangeGatewayId = (selectedGatewayId) => {
      setState((old) => ({
         ...old,
         selectedGatewayId,
      }));
   };

   const onChangeMeterValue = (e) => {
      console.log(e.target.value);
      setState((old) => ({
         ...old,
         meterValue: e.target?.value,
      }));
   };

   const getSelectNative = ({
      value,
      options = [],
      setSelected,
      onChange,
      filterType = FILTER_TYPE.name,
   }) => {
      return (
         <select
            defaultValue={value}
            onChange={(event) => {
               switch (filterType) {
                  case FILTER_TYPE.name:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(event.target.value);
                     break;

                  case FILTER_TYPE.id:
                     onChange(
                        options.find((x) => x.name == event.target.value)?.id ||
                           ""
                     );
                     break;

                  case FILTER_TYPE.boolean:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(
                             options.find((x) => x.name == event.target.value)
                                ?.id
                                ? 1
                                : 0
                          );
                     break;

                  default:
                     break;
               }

               setSelected(event.target.value);
            }}
         >
            <option value={0}>TODOS</option>
            {options.length > 0 &&
               options?.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
               ))}
         </select>
      );
   };

   const getCustomColumns = () => {
      const value = [
         {
            accessor: "resolved",
            sortable: false,
            filterable: true,
            Cell: (props) =>
               props.value ? (
                  <i
                     style={{
                        color: "green",
                        fontSize: "20px",
                     }}
                     className="fas fa-check"
                  />
               ) : (
                  <i
                     style={{
                        color: "red",
                        fontSize: "20px",
                     }}
                     className="fas fa-times"
                  />
               ),
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: resolveTypeSelected,
                  setSelected: setResolveTypeSelected,
                  options: booleanOptions,
                  onChange,
                  filterType: FILTER_TYPE.boolean,
               }),
         },
         {
            accessor: "typeName",
            sortable: false,
            filterable: true,
            Cell: (props) => props.value ?? notDefined,
            Filter: ({ onChange }) =>
               getSelectNative({
                  value: problemTypeSelected,
                  setSelected: setProblemTypeSelected,
                  options: problemTypeList,
                  onChange,
                  filterType: FILTER_TYPE.name,
               }),
         },
      ];
      return value;
   };

   const getActions = () => {
      const value = [
         {
            Header: "Acción",
            accessor: "id",
            minWidth: 100,
            sortable: false,
            filterable: false,
            Cell: (props) => (
               <div>
                  <Button
                     size={"sm"}
                     color={"success"}
                     onClick={() => actionButton(props.value, "edit")}
                  >
                     Editar
                  </Button>{" "}
                  <Button
                     disabled={props.original.resolved === true}
                     size={"sm"}
                     color={"cyan"}
                     onClick={() => actionButton(props.value, "resolved")}
                  >
                     Resolver
                  </Button>{" "}
                  <Button
                     size={"sm"}
                     color={"danger"}
                     onClick={() => actionButton(props.value, "delete")}
                  >
                     Eliminar
                  </Button>
               </div>
            ),
         },
      ];
      return value;
   };

   return (
      <>
         <Modal size={"lg"} isOpen={openProblemTypeModal}>
            <ModalHeader toggle={toggleProblemTypeModal}>
               {problemTypeOperation === "add" ? "Agregar" : "Editar"}
            </ModalHeader>
            <ModalBody>
               <Row>
                  <Col md={4}>
                     <CustomInput
                        valid={problemType.meter.length > 0}
                        onChange={({ target: { name, value } }) => {
                           setProblemType((old) => ({ ...old, [name]: value }));
                        }}
                        value={problemType.meter}
                        label={"Medidor"}
                        id={"meter"}
                     />
                     {/* <label> Medidor </label>
                     <FormGroup>
                        <Autosuggest
                           suggestions={state.suggestions}
                           onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested
                           }
                           onSuggestionsClearRequested={() =>
                              setState((old) => ({ ...old, suggestions: [] }))
                           }
                           getSuggestionValue={(suggestion) =>
                              onSelect(suggestion.serial)
                           }
                           renderSuggestion={(suggestion) => (
                              <div>{suggestion.serial}</div>
                           )}
                           inputProps={{
                              placeholder: "Ingrese medidor",
                              value: state.value,
                              onChange: onChange,
                           }}
                        />
                     </FormGroup> */}
                  </Col>
                  <Col md={4}>
                     <label> Desde </label>
                     <CustomDate
                        id="DateFrom"
                        timeFormat={"HH:mm"}
                        value={new Date(problemType.from)}
                        onChange={(moment) => onDateChangeModal(moment, "from")}
                     />
                  </Col>
                  <Col md={4}>
                     <label> Hasta </label>
                     <CustomDate
                        id="DateTo"
                        timeFormat={"HH:mm"}
                        value={new Date(problemType.to)}
                        onChange={(moment) => onDateChangeModal(moment, "to")}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelectModal}
                        item={problemType.type}
                        id={"type"}
                        label={"Tipos de problemas"}
                        array={problemTypeList}
                     />
                  </Col>
                  <Col md={6}>
                     <SelectSimple
                        valueCallback={onChangeSelectModal}
                        item={problemType.resolved}
                        id={"resolved"}
                        label={"Resuelto"}
                        array={state.resolved}
                     />
                  </Col>
               </Row>
               <br />
               <Row>
                  <Col md={12}>
                     <ButtonCustom
                        color={"success"}
                        className={""}
                        name={"submit"}
                        id={"submit"}
                        disabled={
                           !(
                              problemType.meter.length > 0 &&
                              problemType.type &&
                              problemType.resolved
                           )
                        }
                        Click={problemTypesRequest}
                        title={
                           problemTypeOperation === "add"
                              ? "Enviar"
                              : "Actualizar"
                        }
                     />{" "}
                     <ButtonCustom
                        type={"cancel"}
                        color={"danger"}
                        className={""}
                        name={"cancel"}
                        id={"cancel"}
                        Click={toggleProblemTypeModal}
                        title={"Cancelar"}
                     />
                  </Col>
               </Row>
            </ModalBody>
         </Modal>
         <Card>
            <CardTitle
               style={{
                  fontSize: "18px",
               }}
               className="bg-light border-bottom p-3 mb-0"
            >
               <i className="mdi mdi-hamburger mr-2"> </i> Medidores con
               problemas
            </CardTitle>
            <CardBody>
               <Row className={"justify-content-center"}>
                  {/* <Col md={2}>
                     <label>Tipo</label>
                     <Select
                        name="typeOrigin"
                        id="typeOrigin"
                        onChange={onChangeTypeOrigin}
                        value={state.selectedTypeOrigin}
                        options={TypeOrigin}
                     />
                  </Col>
                  {typeOrigin}
                  <Col md={2}>
                     <label>Tipos de problemas</label>
                     <Select
                        name="problemTypes"
                        id="problemTypes"
                        onChange={onChangeProblemTypes}
                        value={state.selectedProblemTypes}
                        options={[
                           { value: "", label: "Todos" },
                           ...problemTypeList,
                        ]}
                     />
                  </Col>
                  <Col md={2}>
                     <label>Resuelto</label>
                     <Select
                        name="resolved"
                        id="resolved"
                        onChange={onChangeResolved}
                        value={state.selectedResolved}
                        options={[
                           { value: "", label: "Todos" },
                           ...state.resolved,
                        ]}
                     />
                  </Col> */}
                  <Col md={2}>
                     <label> Desde </label>
                     <CustomDate
                        id="DateFrom"
                        timeFormat={"HH:mm"}
                        value={new Date(state.dateFrom)}
                        onChange={(moment) => onDateChange(moment, "dateFrom")}
                     />
                  </Col>
                  <Col md={2}>
                     <label> Hasta </label>
                     <CustomDate
                        id="DateTo"
                        timeFormat={"HH:mm"}
                        value={new Date(state.dateTo)}
                        onChange={(moment) => onDateChange(moment, "dateTo")}
                     />
                  </Col>
               </Row>
               <Row className={"justify-content-start"}>
                  {/* <Col md={2}>
                     <ButtonCustom
                        name={"reportButton"}
                        id={"reportButton"}
                        title={"Generar reporte"}
                        Click={getProblemTypesData}
                     />
                  </Col> */}
                  <Col md={2}>
                     <ButtonCustom
                        name={"reportReading"}
                        id={"reportReading"}
                        color={"orange"}
                        title={"Registrar problema"}
                        Click={() => toggleProblemTypeModal("add")}
                     />
                  </Col>
               </Row>
               <GenericTable
                  data={state.MetersProblems}
                  headers={headers}
                  loading={loading}
                  pages={state.pagesProblemType}
                  onDownloadClick={handleToExcel}
                  customColumns={getCustomColumns()}
                  onFetchData={onFetchData}
                  triggerOnFetchData={triggerOnFetchData}
                  actions={getActions()}
               />
               {/* <TableLayout
                  data={state.MetersProblems}
                  pages={state.pagesProblemType}
                  defaultPageSize={state.pageSizeProblemType}
                  filterable={true}
                  pageSizeOptions={[10, 50, 100, 250, 500]}
                  manual={true}
                  loading={loading}
                  onFetchData={onFetchData}
                  columns={[
                     {
                        Header: "Serial",
                        accessor: "serial",
                     },
                     {
                        Header: "Tipo",
                        accessor: "typeName",
                        Cell: (props) => props.value,
                     },
                     {
                        Header: "Resuelto",
                        accessor: "resolved",
                        Cell: (props) =>
                           props.value === true ? (
                              <i
                                 style={{
                                    color: "green",
                                    fontSize: "20px",
                                 }}
                                 className="fas fa-check"
                              >
                                 {" "}
                              </i>
                           ) : (
                              <i
                                 style={{
                                    color: "red",
                                    fontSize: "20px",
                                 }}
                                 className="fas fa-times"
                              >
                                 {" "}
                              </i>
                           ),
                     },
                     {
                        Header: "Registrado en",
                        accessor: "registeredAt",
                        Cell: (props) => Time(props.value),
                     },
                     {
                        Header: "Actulizado en",
                        accessor: "updatedAt",
                        Cell: (props) => Time(props.value),
                     },
                     {
                        Header: "Acción",
                        accessor: "id",
                        minWidth: 100,
                        sortable: false,
                        filterable: false,
                        Cell: (props) => (
                           <div>
                              <Button
                                 size={"sm"}
                                 color={"success"}
                                 onClick={() =>
                                    actionButton(props.value, "edit")
                                 }
                              >
                                 Editar
                              </Button>{" "}
                              <Button
                                 disabled={props.original.resolved === true}
                                 size={"sm"}
                                 color={"cyan"}
                                 onClick={() =>
                                    actionButton(props.value, "resolved")
                                 }
                              >
                                 Resolver
                              </Button>{" "}
                              <Button
                                 size={"sm"}
                                 color={"danger"}
                                 onClick={() =>
                                    actionButton(props.value, "delete")
                                 }
                              >
                                 Eliminar
                              </Button>
                           </div>
                        ),
                     },
                  ]}
               /> */}
            </CardBody>
         </Card>
      </>
   );
};

export default MeterWithProblem;
