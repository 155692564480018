import React from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import GenericTable from "../../components/custom/GenericTable";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/gatewayMeter";
import { saveAsExcel } from "../../utilities/exportToExcel";

class GatewayMeter extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterName: "",
         metersAssociated: [],
         metersAssociatedHeaders: [],
         triggerOnFetchData: false,
         pages: 0,
         gatewayId: "",
         pageSize: 10,
         loading: false,
         toggleModalStatusCode: false,
         error: "",
         recordsTotal: 0,
         filtered: [],
         sorted: [],
      };
   }
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount() {
      window.addEventListener("mouseover", this.mouseOver);
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   }

   getTableInfo = (e) => {
      this.setState({
         gatewayId: e.value,
         masterName: e.label,
         triggerOnFetchData: true,
      });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };

   donwloadToExcel = () => {
      this.setState({
         loading: true,
      });
      const st = this.state;
      apiClient.meters
         .getMetersInGateway({
            gatewayId: st.gatewayId,
            sorted: st.sorted,
            filtered: st.filtered,
            recordsTotal: st.recordsTotal,
         })
         .then((res) => {
            saveAsExcel({
               columns: columnsExport,
               data: this.convertDataExport(res.data),
               endMergeTitle: "K1",
               endMergeSubTitle: "K2",
               columnWidthConfigs,
               title: "REPORTE DE MEDIDORES ASOCIADOS A GATEWAY",
               name: `Gt. ${
                  this.state.masterName
               } Medidores asociados a gateway (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `GATEWAY:  ${this.state.masterName}  `,
            });
            this.setState({
               loading: false,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   convertDataExport = (data) => {
      return data?.map((item) => ({
         serial: item.serial,
         cardSerial: item.cardSerial,
         fatherCardSerial: item.fatherCardSerial,
         meterType: item.meterType,
         form: item.form,
         meterModuleType: item.meterModuleType,
         meterPhaseCommunication: item.meterPhaseCommunication,
         status: item.status,
         installedAt: item.installedAt,
         latitude: item.latitude,
         longitude: item.longitude,
      }));
   };

   render() {
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Reporte de
                  medidores asociados a gateway
               </CardTitle>
               <CardBody className="border-top">
                  <br />
                  <Row className={"justify-content-start ml-2"}>
                     <Col md={3}>
                        <label> Gateway </label>
                        <Select
                           name="selectGr"
                           id="selectGr"
                           options={this.state.masterData}
                           placeholder="Seleccione un gateway"
                           onChange={this.getTableInfo}
                        />
                     </Col>{" "}
                     <Col md={2}>
                        <label>
                           <br />
                        </label>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.metersAssociated.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <br />
                  <GenericTable
                     data={this.state.metersAssociated}
                     headers={this.state.metersAssociatedHeaders}
                     loading={this.state.loading}
                     pages={this.state.pages}
                     triggerOnFetchData={this.state.triggerOnFetchData}
                     onPageSizeChange={(value) => {
                        this.setState({ pageSize: value });
                     }}
                     pageSize={
                        this.state.metersAssociated.length > 0 &&
                        this.state.metersAssociated.length < this.state.pageSize
                           ? this.state.metersAssociated.length
                           : this.state.pageSize
                     }
                     onFetchData={(state) => {
                        let st = this.state;
                        if (st.gatewayId) {
                           apiClient.meters
                              .getMetersInGateway({
                                 gatewayId: st.gatewayId,
                                 sorted: state.sorted,
                                 filtered: state.filtered,
                                 page: state.page + 1,
                                 length: this.state.pageSize,
                              })
                              .then((res) => {
                                 this.setState({
                                    metersAssociated: this.convertDataExport(
                                       res.data
                                    ),
                                    metersAssociatedHeaders: res.headers,
                                    recordsTotal: res.recordsTotal,
                                    pages: Math.ceil(
                                       res.recordsTotal / this.state.pageSize
                                    ),
                                    recordsTotal: res.recordsTotal,
                                    sorted: state.sorted ?? [],
                                    filtered: state.filtered ?? [],
                                 });
                              })
                              .catch((error) => {
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                    loading: false,
                                 });
                              });
                           this.setState({
                              loading: false,
                              triggerOnFetchData: false,
                           });
                        }
                     }}
                  />
               </CardBody>
            </Card>
         </div>
      );
   }
}
export default GatewayMeter;
