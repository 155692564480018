import React from 'react';
import {Map, GoogleApiWrapper, Marker,} from 'google-maps-react';
import {Row, Col, FormGroup, Button, Card, CardBody} from 'reactstrap';
import Select from "react-select";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import apiClient from "../../api/apiClient";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import {GatewayIcon, GatewayText, MeterIcon, GetColorByPerformance} from "../../components/custom/CustomMarkers";
import {MarkerModal} from "../../components/custom/CustomModal";
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import {Slider, Rail, Handles, Tracks } from "react-compound-slider";
import {SliderRail, Handle, Track} from "../../components/custom/SliderComponents"; // example render components - source below
import {startOfToday, format,differenceInSeconds,subDays } from "date-fns";
import {ModalCustom} from "../../components/custom/ModalCustom";
import moment from "moment";
import Fullscreen from "react-full-screen";
const FullHour = 1000 * 60 * 60;
const sliderStyle = {position: "relative", width: "100%",zIndex:0};

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.window=window.innerHeight;
        this.state = {
            isFull: false,
            loading: false,
            markerToggleModal: false,
            selectedGroup: 0,
            performanceTypes: [{label: 'Lectura', value: 'readingPerformance'},
                {label: 'Instrumentación', value: 'instrumentationPerformance'},
                {label: 'Lectura Puntual', value: 'readingOclockPerformance'}],
            performanceSelectedType: 'readingPerformance',
            dateFrom: subDays(new Date(), 7),
            dateTo: new Date(),
            selectedMarker: null,
            chartGateways: [{label: 'Todos los Gateways', value: "_all"}],
            selectedChartGateway: "_all",
            mapBounds: null,
            fullMapData: [],
            actualMapData: [],
            gateways: [],
            filteredMetersData: [],
            metersData: [],
            selectedMeter: '',
            suggestions: [],
            value:"",
            selectedDate: new Date(),
            selected: startOfToday(),
            updated: startOfToday(),
            min: subDays(startOfToday(), 1),
            max: startOfToday(),
            initialGeolocation:{lat: 19.098938, lng: -70.928611},
            loadTime:0,
            showSeries:true,
            toggleModalStatusCode:false,
            error:"",
            options : {
                navigator: {adaptToUpdatedData: true, series: []},
                scrollbar: {barBackgroundColor: '#e0e0e0', barBorderRadius: 7, barBorderWidth: 0, buttonBackgroundColor: '#e0e0e0', buttonBorderWidth: 0, buttonBorderRadius: 7, trackBackgroundColor: 'none', trackBorderWidth: 1, trackBorderRadius: 8, trackBorderColor: '#CCC'},
                chart: {type: 'spline',  height: (2.5/16 * 100) + '%',alignTicks: false},
                title: false,
                credits:false,
                subtitle: false,
                series: [],
                tooltip: {  useHTML: true, shared: false, crosshairs: true},
                yAxis: {title: {text: "Desempeño (%)"}, min:0, max:100},
                xAxis: {
                    type: 'datetime',
                    labels: {format: '{value:%d-%m-%y %H:%M}'},
                    // tickInterval: 24 * 3600 * 1000,
                    plotLines: [{color: '#FF0000', width: 3, value: 0}],
                },
            },
        };
    }


    componentDidMount = () => {
        this.setState({options:{chart:{height: (15) + '%' }} });
        apiClient.gateways.getGatewayGroups().then(res => {
            let gateways = res.data.map(x => {x.value= x.id;x.label= x.name;return x;});


            this.setState({gateways,});
        }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    onDateChange =(moment,value) =>{
        this.setState({[value]: moment.format("MM/DD/YYYY HH:00")});
    };

    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };

    onGroupChange = (e) => {
        this.setState({selectedGroup: e.value,});
    };

    onChartGatewaysChange=(e)=>{
        this.setState({selectedChartGateway:e.value});
        // Segun el mapa original, no es necesario renderizar.
        // this.generateActualMarkers({selectedGateway:e.value});
        this.getGraphBySelectedPerformanceAndGateway({selectedGateway:e.value});
    };

    onPerformanceType=( e )=>{
        this.setState({performanceSelectedType:e.value});
        this.generateActualMarkers({selectedPerformanceType:e.value});
        this.getGraphBySelectedPerformanceAndGateway({selectedPerformanceType:e.value});

    };


    onSubmit = () =>{
        this.setState({selectedChartGateway: "_all", selectedMeter:'',performanceSelectedType: 'readingPerformance',});
        const startTime = new Date();
        this.setState({loading:true, loadTime:0});
        const getMarkers = apiClient.map.getMarkersByGroupTest({
            from: format(new Date(this.state.dateFrom),'yyyy-MM-dd HH:00'),
            to: format(new Date(this.state.dateTo),'yyyy-MM-dd HH:00'),
            gatewayGroupId: this.state.selectedGroup
        });

        getMarkers.then(response=>{
            if (response === undefined ){
                const finishedTime = differenceInSeconds(new Date(), startTime);
                this.setState({options: {series: [], navigator: {series:  [] }}, isFull:false, loading: false,
                    loadTime: finishedTime,
                    metersData:[],
                    actualMapData:[]
                });
                Swal.fire({type: 'info', title: 'No hay información', text: 'Error al recuperar datos'});
                return;
            }
            if (response.gateways.length === 0) {
                const finishedTime = differenceInSeconds(new Date(), startTime);
                this.setState({options: {series: [], navigator: {series:  [] }}, isFull:false, loading: false, loadTime: finishedTime,
                    metersData:[],
                    actualMapData:[]
                });
                Swal.fire({type: 'info', title: 'No hay información', text: 'No hay información para esta fecha.'});
                return;
            }
            const data = this.generateGatewayColorsAndPerformance(response);

            const dates =Object.keys(response.gatewaysPerformance);
            const lastDate = (new Date(dates[dates.length - 1]));
            const gateways = response.gateways.map(x => {return {value: x.gatewayId, label: x.alias}});
            const dateFormatD = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate(), lastDate.getHours(), lastDate.getMinutes(), 0);
            this.setState({
                fullMapData: data,
                selectedDate: this.formatDateString(lastDate),
                chartGateways: [{label: 'Todos los Gateways', value: "_all"}, ...gateways],
                options: {
                    // title: {text: format(lastDate, "dd/MM/yyyy HH:mm")},
                    xAxis: {plotLines: [{color: '#FF0000', width: 2, value: dateFormatD}]}
                },
                min: (new Date(dates[0])),
                max: new Date(dates[dates.length - 1]),
            });

            const markers = this.generateActualMarkers({data:response,selectedDate:this.formatDateString(lastDate)});

            this.getGraphBySelectedPerformanceAndGateway({selectedGateway:'_all', selectedPerformanceType:'readingPerformance'});

            //first time to render bounds
            let mapBounds = new this.props.google.maps.LatLngBounds();
            let metersData = [];
            markers.forEach(x=>{
                if (x.props.position.lat!==null && x.props.position.lat!==0 && x.props.position.lng!==null && x.props.position.lng!==0){
                    mapBounds.extend(x.props.position);
                }
                metersData.push({lat:x.props.position.lat,lng:x.props.position.lng,label:x.props.title,value:x.props.title});
            });
            const finishedTime = differenceInSeconds(new Date(), startTime);
            this.setState({mapBounds, metersData, isFull:true, loading: false, loadTime: finishedTime});

        }).catch((error)=>{Swal.fire({type: 'error', title: 'Error', text: error,})})
    };

    formatDateString = (date) => moment(date).format();

    generateGatewayColorsAndPerformance = (data) => {
        data.gatewaysPerformance = [];
        let gateways = data.gateways.map(p => {
            // console.log(p);
            let fathers = p.meters;
            let children = [];
            fathers.map(x => x.meters.map(y => children.push(y)));
            return {gatewayId: p.gatewayId, alias: p.alias, meters: [...fathers, ...children]};
        });

        //Loop Para asignar los performances a los gateways.
        let performances = [];
        data.performances.forEach(datePerformance => {
            let gatewaysPerformance = [];
            gateways.forEach(gw => {
                datePerformance.meters.forEach(x => {
                    const met = gw.meters.find(y => y.meterId === x.meterId);
                    x.serial = met!==undefined ? met.serial:x.meterId;
                    //x.serial = x.meterId;
                });

                performances[this.formatDateString(datePerformance.dateTimeRead)] = datePerformance.meters;
                let dataMeters = datePerformance.meters.filter(x => x.gatewayId === gw.gatewayId);

                gatewaysPerformance.push({
                    gatewayId: gw.gatewayId,
                    alias: gw.alias,
                    readingPerformance: (gw.meters.length === 0 ? 0 : Number((dataMeters.reduce((a, b) => a + b.readingPerformance,0) / gw.meters.length).toFixed(2))  || 0),
                    readingOclockPerformance: (gw.meters.length === 0 ? 0 : Number((dataMeters.reduce((a, b) => a + b.readingOclockPerformance,0) / gw.meters.length).toFixed(2))  || 0),
                    instrumentationPerformance: (gw.meters.length === 0 ? 0 : Number((dataMeters.reduce((a, b) => a + b.instrumentationPerformance,0) / gw.meters.length).toFixed(2))  || 0)
                });
            });
            data.gatewaysPerformance[this.formatDateString(datePerformance.dateTimeRead)] = gatewaysPerformance;
        });
        data.performances = performances;
        return data;
    };

    generateActualMarkers = ({selectedDate = this.state.selectedDate, data = this.state.fullMapData, selectedPerformanceType = this.state.performanceSelectedType}) => {
        let markers = [];
        const dataDateForGateways = data.gatewaysPerformance[selectedDate];
        dataDateForGateways.forEach(g=>{
            const gw = data.gateways.find(x=>x.gatewayId===g.gatewayId);
            markers.push(
                <Marker position={{lat: gw.latitude, lng: gw.longitude}} title={gw.alias} key={gw.alias}
                        label={GatewayText({text: gw.label})}
                        onClick={() => this.openMarkerModal(gw.alias, "gateway",g.gatewayId)}
                        icon={GatewayIcon({
                            iconColor: GetColorByPerformance(g[selectedPerformanceType]),
                            google: this.props.google
                        })}/>
            );
        });

        const datePer = data.performances[selectedDate];
        data.gateways.forEach(gw=>{
           gw.meters.forEach(fatherMeter=>{
               let datePerMeter = datePer.find(x=> x.meterId === fatherMeter.meterId);
               if (datePerMeter=== undefined){
                   datePerMeter = {meterId:fatherMeter.meterId,serial:fatherMeter.serial,readingPerformance:0,instrumentationPerformance:0,readingOclockPerformance:0,};
               }
               markers.push(
                   <Marker position={{lat: fatherMeter.latitude, lng: fatherMeter.longitude}}
                           title={fatherMeter.serial} key={fatherMeter.serial ? fatherMeter.serial : fatherMeter.meterId}
                           label={GatewayText({text: fatherMeter.label})}
                           icon={MeterIcon({
                               meterColor: fatherMeter.color,
                               meterHeadColor: GetColorByPerformance(datePerMeter[selectedPerformanceType]),
                               google: this.props.google
                           })} onClick={() => this.openMarkerModal(fatherMeter.serial, "meter",fatherMeter.meterId)}/>
               );

               fatherMeter.meters.forEach(childrenMeter=>{
                   let datePerChildMeter = datePer.find(x=> x.meterId === childrenMeter.meterId);
                   if (datePerChildMeter=== undefined){
                       datePerChildMeter = {meterId:childrenMeter.meterId,serial:childrenMeter.serial,readingPerformance:0,instrumentationPerformance:0,readingOclockPerformance:0,};
                   }
                   markers.push(
                       <Marker position={{lat: childrenMeter.latitude, lng: childrenMeter.longitude}}
                               title={childrenMeter.serial} key={childrenMeter.serial ? childrenMeter.serial : childrenMeter.meterId}
                               label={GatewayText({text: childrenMeter.label})}
                               icon={MeterIcon({
                                   meterColor: childrenMeter.color,
                                   meterHeadColor: GetColorByPerformance(datePerChildMeter[selectedPerformanceType]),
                                   google: this.props.google
                               })} onClick={() => this.openMarkerModal(childrenMeter.serial, "meter",childrenMeter.meterId)}/>
                   );
               });
           });
        });


        this.setState({actualMapData: markers});
        return markers;
    };

    toggleModalMarker = ()=>{
        this.setState({markerToggleModal:!this.state.markerToggleModal});
    };

    openMarkerModal = (serial,type,elementId)=>{
        let selectedMarker={};
        if (type==="meter"){
            const data = this.state.fullMapData.performances[this.state.selectedDate].find(x=>x.meterId===elementId);
            const meterBody = <div>{this.state.performanceTypes.map(p => {return <Row><Col md={12}> {p.label} : {data[p.value]} % </Col></Row>;})}</div>;
            selectedMarker={title: "Medidor: " +serial, body: meterBody,}
        }
        if (type==="gateway"){
            const data = this.state.fullMapData.gatewaysPerformance[this.state.selectedDate].find(x=>x.gatewayId===elementId);
            const gatewayBody = <div>{this.state.performanceTypes.map(p => {return <Row><Col md={12}> {p.label} : {data[p.value]} % </Col></Row>;})}</div>;
            selectedMarker = {title: "Gateway: " + serial, body: gatewayBody}
        }
        this.setState({selectedMarker});
        this.toggleModalMarker();
    };

    onChange = ([ms]) => {
        let dateTimeD = new Date(ms);
        let dateFormatD = Date.UTC(dateTimeD.getFullYear(), dateTimeD.getMonth(), dateTimeD.getDate(), dateTimeD.getHours(), dateTimeD.getMinutes(), 0);
        this.generateActualMarkers({selectedDate: this.formatDateString(dateTimeD) });
        this.setState({
            selectedDate: this.formatDateString(dateTimeD),
            options: {
                // title:{text:format(dateTimeD,"dd/MM/yyyy HH:mm")},
                xAxis: {plotLines: [{color: '#FF0000', width: 2, value: dateFormatD}]}
            },
        });
    };

    onUpdate = ([ms]) => {
        let dateTimeD = new Date(ms);
        let dateFormatD = Date.UTC(dateTimeD.getFullYear(), dateTimeD.getMonth(), dateTimeD.getDate(), dateTimeD.getHours(), dateTimeD.getMinutes(), 0);
        // this.generateActualMarkers({selectedDate: dateTimeD.toISOString() });
        this.setState({
            selectedDate: this.formatDateString(dateTimeD),
            options: {
                // title:{text:format(dateTimeD,"dd/MM/yyyy HH:mm")},
                xAxis: {plotLines: [{color: '#FF0000', width: 2, value: dateFormatD}]}
            },
        });
    };

    showLegendValues = ()=>{
        this.setState({
            options: {
                series: this.state.options.series.map(x=>{x.visible=!this.state.showSeries; return x}),
                navigator: {series:  this.state.options.series.map(x=>{return {name: x.name, data: x.data, visible: true, type: "spline"}})},
            },
            showSeries:!this.state.showSeries
        });
    };


    getGraphBySelectedPerformanceAndGateway = ({selectedPerformanceType = this.state.performanceSelectedType, selectedGateway = this.state.selectedChartGateway}) => {
        let arrayGraph = [];
        if (selectedGateway === "_all") {
            const keys = Object.keys(this.state.fullMapData.gatewaysPerformance);
            keys.forEach(date=>{
                let dateTimeD = new Date(date);
                let dateFormatD = Date.UTC(dateTimeD.getFullYear(), dateTimeD.getMonth(), dateTimeD.getDate(), dateTimeD.getHours(), dateTimeD.getMinutes(), 0);
                this.state.fullMapData.gatewaysPerformance[date].forEach(gw=>{

                    let gatewayArr = arrayGraph.find(x => x.gatewayId === gw.gatewayId);
                    if (gatewayArr === undefined) {
                        arrayGraph.push({
                            gatewayId: gw.gatewayId,
                            name: gw.alias,
                            data: [[dateFormatD, gw[selectedPerformanceType]]]
                        });
                    } else {
                        gatewayArr.data.push([dateFormatD, gw[selectedPerformanceType]]) ;
                    }
                });
            });

        }else {
            const keys = Object.keys(this.state.fullMapData.performances);
            keys.forEach(date=>{
                let dateTimeD = new Date(date);
                let dateFormatD = Date.UTC(dateTimeD.getFullYear(), dateTimeD.getMonth(), dateTimeD.getDate(), dateTimeD.getHours(), dateTimeD.getMinutes(), 0);
                const data = this.state.fullMapData.performances[date];
                // console.log( this.state.fullMapData.gateways);
                // console.log( this.state.fullMapData.gateways.find(x=>x.gatewayId===selectedGateway));
                this.state.fullMapData.gateways.find(x=>x.gatewayId===selectedGateway).meters.forEach(fatherM=>{
                    let meterArr = arrayGraph.find(x => x.meterId === fatherM.meterId);
                    let fatherPerDate = data.find(f=> f.meterId=== fatherM.meterId);
                    if (fatherPerDate===undefined){
                        fatherPerDate= {meterId:fatherM.meterId,serial:fatherM.serial,readingPerformance:0,instrumentationPerformance:0,readingOclockPerformance:0};
                    }
                    if (meterArr === undefined) {
                        arrayGraph.push({
                            meterId: fatherM.meterId,
                            name: fatherM.serial,
                            data: [[dateFormatD, fatherPerDate[selectedPerformanceType]]]
                        });
                    } else {
                        meterArr.data.push([dateFormatD, fatherPerDate[selectedPerformanceType]]) ;
                    }

                    fatherM.meters.forEach(childrenM=>{
                        let meterChildrenArr = arrayGraph.find(x => x.meterId === childrenM.meterId);
                        let childrenPerDate = data.find(f=> f.meterId=== childrenM.meterId);
                        //Si no hay data para este mete para esta fecha
                        if (childrenPerDate===undefined){
                            childrenPerDate= {meterId:childrenM.meterId,serial:childrenM.serial,readingPerformance:0,instrumentationPerformance:0,readingOclockPerformance:0};
                        }
                        if (meterChildrenArr === undefined) {
                            arrayGraph.push({
                                meterId: childrenM.meterId,
                                name: childrenM.serial,
                                data: [[dateFormatD, childrenPerDate[selectedPerformanceType]]]
                            });
                        } else {
                            meterChildrenArr.data.push([dateFormatD, childrenPerDate[selectedPerformanceType]]) ;
                        }
                    });


                });
            });
        }

        this.setState({
            options: {
                navigator: {series: arrayGraph},
                series: arrayGraph,
                // title: {text: format(new Date(), "dd/MM/yyyy HH:mm")},
            }
        });
    };

    onAutoCompleteMeterSelect = (val) => {
        let mapBounds = new this.props.google.maps.LatLngBounds();
        const meter = this.state.metersData.find(x=>x.label===val.value);
        mapBounds.extend({lat:meter.lat,lng:meter.lng});
        this.setState({selectedMeter: val, value: val.value,mapBounds});
        return val.value
    };

    goFull = () => {
        this.setState({ isFull: true });
    };

    render() {
        const { min, max, value, filteredMetersData, suggestions} = this.state;
        let markerModal=null;
        if (this.state.selectedMarker!=null){
            markerModal=<MarkerModal body={this.state.selectedMarker.body} title={this.state.selectedMarker.title} toggleModal={this.state.markerToggleModal} toggleModalFunc={this.toggleModalMarker}/>
        }
        return (
            <div style={{marginTop:10}}>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Button style={this.state.actualMapData.length > 0? {display:''} :{display:'none'}} color={'white'} size={'sm'} onClick={this.goFull}>
                    <i  className="mdi mdi-fullscreen display-7"> </i>
                </Button>
                <LoadingComponent loading={this.state.loading}/>
                {markerModal}
                <Row className={"justify-content-center pt-0"} style={{marginBottom:"-2rem",marginTop: "0rem"}}>
                    <Col md={2}>
                        <FormGroup><Select name="groupId" id="groupId" options={this.state.gateways} placeholder="Seleccionar Grupo" onChange={this.onGroupChange}/></FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup><Datetime name="dateFrom" id="dateFrom" inputProps={{readOnly: true, style:{backgroundColor: "white"}}} closeOnSelect timeFormat="HH:00" onChange={(moment) => this.onDateChange(moment, 'dateFrom')} value={new Date(this.state.dateFrom)}/></FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup><Datetime name="dateTo" id="dateTo" inputProps={{readOnly: true, style:{backgroundColor: "white"}}} closeOnSelect timeFormat="HH:00" onChange={(moment) => this.onDateChange(moment, 'dateTo')} value={new Date(this.state.dateTo)}/></FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup><Button className="btn btn-block" color="primary" name="checkRound" id="checkRound" onClick={this.onSubmit}>Generar</Button></FormGroup>
                    </Col>
                </Row>
                <Fullscreen
                    style={{background: '#fff'}}
                    enabled={this.state.isFull}
                    onChange={isFull => this.setState({isFull})}
                >​
                    <div className="full-screenable-node">
                        <Row style={{position: 'relative'}}>
                            <Col md={12} style={{height: "62vh"}}>
                                <Map google={this.props.google} zoom={8} containerStyle={{position: 'initial'}}
                                     initialCenter={this.state.initialGeolocation}
                                     bounds={this.state.mapBounds}
                                     fullscreenControl={false}
                                     zoomControl={false}
                                     mapTypeControl={true}
                                     streetViewControl={false}
                                >
                                    {this.state.actualMapData}
                                </Map>
                            </Col>
                        </Row>
                        <Row style={this.state.actualMapData.length > 0? {display:'', marginBottom: "2rem",} :{display:'none'}} className={"pt-1 pb-0 "}>
                            <Col  md={12} className={"p-0"}>
                                <Card className={"mb-0 pb-0"}>
                                    <CardBody style={{padding: "5px",}}>
                                        <Row>
                                            <Col  md={2}>
                                                <Select name="groupId"
                                                        value={this.state.chartGateways.find(x => x.value === this.state.selectedChartGateway)}
                                                        id="groupId"
                                                        options={this.state.chartGateways}
                                                        placeholder="Buscar medidor"
                                                        onChange={this.onChartGatewaysChange}/>
                                            </Col>
                                            <Col  md={2}>
                                                <Select name="performanceSelectedType" id="performanceSelectedType"
                                                        menuPlacement="auto"
                                                        options={this.state.performanceTypes}
                                                        value={this.state.performanceTypes.find(x => x.value === this.state.performanceSelectedType)}
                                                        placeholder="Gateway"
                                                        onChange={this.onPerformanceType}
                                                        style={{minWidth: "70%"}}/>
                                            </Col>
                                            <Col  md={2}>
                                                <Button className={'btn btn-block'} color={'cyan'} onClick={this.showLegendValues}>{this.state.showSeries ? 'Ocultar series' : 'Mostrar series'}</Button>
                                            </Col>
                                            <Col  md={2}>
                                                {'   '}
                                            </Col>
                                            <Col  md={2}>
                                                <Select
                                                    name="searchMeter"
                                                    value={this.state.selectedMeter}
                                                    id="searchMeter"
                                                    options={this.state.metersData}
                                                    placeholder="Buscar medidor"
                                                    className='text-dark'
                                                    onChange={this.onAutoCompleteMeterSelect}
                                                />
                                            </Col>

                                            <Col md={2} >
                                                <label style={{fontSize: "large"}}> {this.state.actualMapData.length > 0 ? format(new Date(this.state.selectedDate), "dd/MM/yyyy HH:mm") : ''} </label>
                                            </Col>
                                        </Row>
                                        ​{this.state.actualMapData.length > 0 ?
                                        <Row  style={{marginLeft: "3.5rem", paddingTop:15, marginRight: "1rem", marginTop: "-2rem"}}>
                                            <Col md={12}>
                                                <div className={"pr-3"} style={{margin: "1%", padding: 1, width: "100%"}}>
                                                    <Slider
                                                        mode={1}
                                                        step={FullHour}
                                                        domain={[+min, +max]}
                                                        rootStyle={sliderStyle}
                                                        onChange={this.onChange}
                                                        onUpdate={this.onUpdate}
                                                        values={[+(new Date(this.state.selectedDate))]}
                                                    >
                                                        <Rail>
                                                            {({getRailProps}) => <SliderRail
                                                                getRailProps={getRailProps}/>}
                                                        </Rail>
                                                        <Handles>
                                                            {({handles, getHandleProps}) => (
                                                                <div>
                                                                    {handles.map(handle => (
                                                                        <Handle
                                                                            key={handle.id}
                                                                            handle={handle}
                                                                            domain={[+min, +max]}
                                                                            getHandleProps={getHandleProps}
                                                                            style={{zIndex: 0}}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </Handles>

                                                        <Tracks right={false}>
                                                            {({tracks, getTrackProps}) => (
                                                                <div>
                                                                    {tracks.map(({id, source, target}) => (
                                                                        <Track
                                                                            key={id}
                                                                            source={source}
                                                                            target={target}
                                                                            getTrackProps={getTrackProps}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </Tracks>

                                                    </Slider>
                                                </div>
                                            </Col>
                                        </Row>
                                        : ''}
                                        <Row style={this.state.actualMapData.length > 0? {display:''} :{display:'none'}} >
                                            <Col md={12}>
                                                <HighchartsReact highcharts={Highcharts} options={this.state.options} oneToOne={true}/>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                ​</Col>
                        </Row>
                    </div>
                </Fullscreen>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBp2F2Ji2ivxAXxaSCHp4VgyWwaMX4LLXo"),
    language: "props.language",
})(Maps)
