import React from 'react';
import {Time} from "../../constants/DateTime";
import {Card, Row, Col, CardTitle, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import Select from "react-select";
import apiClient from "../../api/apiClient";
import Swal from "sweetalert2";
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import * as moment from 'moment';
import ButtonCustom from "../../components/custom/ButtonCustom";
import {ModalCustom} from "../../components/custom/ModalCustom";
import CustomDate from "../../components/custom/Date";
import {customDateFrom, customDateTo, inputDateTime} from "../../constants/DateTime";
import TableLayout from "../../components/custom/TableLayout";
import classnames from "classnames";

class Performance extends React.Component {
    constructor(props) {
        super(props);
        this.interval=[{label:'Día',value:2}, {label:'Hora',value:1}];
        this.state = {
            isOpen: false,
            activeTab: '1',
            valueId:'',
            valueIdData:[],
            dateFrom: customDateFrom(),
            dateTo: customDateTo(),
            global:[],
            types:{ value: 0, label: 'Grupo' },
            recordsGlobal:0,
            globalRequests:[],
            recordsGlobalRequests:0,
            dateValue:{value:2, label:"Ultima semana"},
            gatewaysId:'',
            gatewaysData:[],
            timeInterval:{label:'Día',value:2},
            dateSelect:[
                {value:0, label:"Ultima hora"},
                {value:1, label:"Último dia"},
                {value:2, label:"Ultima semana"},
                {value:3, label:"Último mes"},
                {value:4, label:"Personalizada"},
            ],
            PerformanceDifferences:[],
            loading: false,
            pageSize:50,
            page:0,
            toggleModalStatusCode:false,
            error:""
        };
    }
    toggle = (tab) => {
        this.setState({
            isOpen: !this.state.isOpen
        });

        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };

    toggleLoading =()=> {
        this.setState({
            loading: !this.state.loading
        });
    };

    mouseOver=(e)=>{
        if (e.clientY > 90 && e.clientY < 125) {
            this.setState({dateOpen: undefined})
        }
    };
    componentWillUnmount(){
        window.removeEventListener('mouseover', this.mouseOver)
    }

    componentDidMount =()=> {
        window.addEventListener('mouseover', this.mouseOver);
        apiClient.gateways.getGatewayGroups()
            .then(res => {
                let items = res.data.map(result => {
                    return {
                        value: result.id,
                        label: result.name,
                    }
                });
                this.setState({valueIdData: items});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.gateways.getActiveGateways()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.alias}
                });
                this.setState({gatewaysData: items});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    onDateChange =(moment,value) =>{
        this.setState({[value]: inputDateTime(moment) });
    };

    getvalueId=(e)=>{
        this.setState({valueId:e})
    };

    getGatewaysId=(e)=>{
        this.setState({valueId:e})
    };

    getObjectTypes = (e) => {
        this.setState({types:e, valueId:''});
    };

    getDateSelect = (e) => {
        let dateFrom = '';
        let dateTo = moment().subtract(0, 'days').format("MM/DD/YYYY HH:00" );
        if (e.value === 3) {
            dateFrom= moment().subtract(1, 'months').format("MM/DD/YYYY HH:00" );
        } else if (e.value  === 2) {
            dateFrom= moment().subtract(7, 'days').format("MM/DD/YYYY HH:00" );
        } else if (e.value  === 1) {
            dateFrom= moment().subtract(1, 'days').format("MM/DD/YYYY HH:00" );
        } else if (e.value  === 0) {
            dateFrom= moment().subtract(1, 'hour').format("MM/DD/YYYY HH:00" );
        } else if(e.value  === 4 ) {
            dateFrom = moment().subtract(7,'days').format("MM/DD/YYYY HH:00");
        }
        this.setState({
            dateFrom:dateFrom,
            dateTo:dateTo,
            dateValue:e
        })
    };

    timeInterval=(e)=>{
        this.setState({timeInterval:e})
    };

    fileCSV=()=>{
        let ad = this.state;
        if( ad.valueId !=='' && ad.dateFrom !==''&& ad.dateTo !=='' ) {
            this.setState({loading:true});
            if (this.state.types.value === 0) {
                apiClient.performance.getGlobalPerformanceDifferenceFile({
                    groupId: ad.valueId.value,
                    from: ad.dateFrom,
                    to: ad.dateTo,
                    timeInterval: this.state.timeInterval.value,
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download',  ad.dateTo.concat(' a ', ad.dateFrom, '-grupo', '.csv'));
                    document.body.appendChild(link);
                    link.click();
                    this.setState({loading:false});
                });

            } else {
                apiClient.performance.getGlobalPerformanceDifferenceFileByGateway({
                    gatewayId: ad.valueId.value,
                    timeInterval: this.state.timeInterval.value,
                    from: ad.dateFrom,
                    to: ad.dateTo,
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', ad.dateTo.concat(' a ', ad.dateFrom, '-gateway', '.csv'));
                    document.body.appendChild(link);
                    link.click();
                    this.setState({loading:false});
                });
            }
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Campos vacios',
                text: 'No pueden ver campos vacios',
            });
        }

    };

    getGroupPerformance =()=>{
        this.setState({global:[],globalRequests:[] });
        let ad = this.state;
        if( ad.valueId !=='' && ad.dateFrom !==''&& ad.dateTo !=='' ){
            this.setState({loading: true, page:0});
            ///Global
            apiClient.performance.getGlobalPerformance({
                groupId: ad.valueId.value,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(global =>{
                console.log(global )
                if (global.data.length === 0) {
                    Swal.fire({
                        type: 'info',
                        title: 'Información no disponible en medición',
                        text: 'Debes seleccionar otro rango de fechas',
                    }).then(() => {
                        this.setState({loading: false})
                    });
                    let arrayGlobal = {readingModbus:"N/D", readingRs485:"N/D", readingPlc:"N/D",readingPlc3:"N/D", instrumentationModbus:"N/D", instrumentationRs485:"N/D", instrumentationPlc:"N/D",instrumentationPlc3:"N/D", puntualModbus:"N/D", puntualRs485:"N/D", puntualPlc:"N/D",puntualPlc3:"N/D", };
                    global.data.map(data => {
                        arrayGlobal["reading" + data.moduleCommunicationType.name]=(data.readingPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["instrumentation" + data.moduleCommunicationType.name]=(data.instrumentationPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["puntual" + data.moduleCommunicationType.name]=(data.readingOClockPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        global: arrayGlobal,
                    });

                } else {
                    let arrayGlobal = {readingModbus:"N/D", readingRs485:"N/D", readingPlc:"N/D",readingPlc3:"N/D", instrumentationModbus:"N/D", instrumentationRs485:"N/D", instrumentationPlc:"N/D", instrumentationPlc3:"N/D", puntualModbus:"N/D", puntualRs485:"N/D", puntualPlc:"N/D", puntualPlc3:"N/D", };
                    global.data.map(data => {
                        arrayGlobal["reading" + data.moduleCommunicationType.name]=(data.readingPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["instrumentation" + data.moduleCommunicationType.name]=(data.instrumentationPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["puntual" + data.moduleCommunicationType.name]=(data.readingOClockPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        global: arrayGlobal,
                    });
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
            ///Global Request
            apiClient.performance.getGlobalRequestPerformance({
                groupId: ad.valueId.value,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(globalRequests => {
                this.setState({loading: false});
                if (globalRequests.data.length === 0){
                    Swal.fire({
                        type: 'info',
                        title: 'Información no disponible',
                        text: 'Debes seleccionar otro rango de fechas',
                    }).then(() => {
                        this.setState({loading: false})
                    });
                    let arrayRequest = {requestModbus:"N/D", requestRs485:"N/D", requestPlc:"N/D",requestPlc3:"N/D"};
                    globalRequests.data.map(data => {
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        globalRequests: arrayRequest,
                    });

                }else {
                    let arrayRequest = {requestModbus:"N/D", requestRs485:"N/D", requestPlc:"N/D", requestPlc3:"N/D"};
                    globalRequests.data.map(data => {
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        globalRequests: arrayRequest,
                    });
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
            apiClient.performance.getGlobalPerformanceDifferences({
                groupId: ad.valueId.value,
                timeInterval:this.state.timeInterval.value,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(res => {
                let PerformanceDifferences=[];
                res.data.map(x=>{
                    PerformanceDifferences.push({serial:x.serial, dateTime: Time(x.dateTime), readingPerformance:x.readingPerformance,instrumentationPerformance:x.instrumentationPerformance, readingOClockPerformance: x.readingOClockPerformance  });
                    return true
                });
                this.setState({PerformanceDifferences:PerformanceDifferences});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
        }else {
            Swal.fire({
                type: 'warning',
                title: 'Campos vacios',
                text: 'No pueden ver campos vacios',
            });
        }
    };

    getGatewayPerformance =()=>{
        this.setState({global:[],globalRequests:[] });
        let ad = this.state;
        if( ad.valueId !=='' && ad.dateFrom !==''&& ad.dateTo !=='' ){
            this.setState({loading: true, page:0});
            ///Global
            apiClient.performance.getGlobalPerformanceByGateway({
                gatewayId: ad.valueId.value
                ,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(global =>{
                if (global.data.length === 0) {
                    Swal.fire({
                        type: 'info',
                        title: 'Información no disponible en medición',
                        text: 'Debes seleccionar otro rango de fechas',
                    }).then(() => {
                        this.setState({loading: false})
                    });
                    let arrayGlobal = {readingModbus:"N/D", readingRs485:"N/D", readingPlc:"N/D", readingPlc3:"N/D", instrumentationModbus:"N/D", instrumentationRs485:"N/D", instrumentationPlc:"N/D", instrumentationPlc3:"N/D", puntualModbus:"N/D", puntualRs485:"N/D", puntualPlc:"N/D", puntualPlc3:"N/D", };
                    global.data.map(data => {
                        arrayGlobal["reading" + data.moduleCommunicationType.name]=(data.readingPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["instrumentation" + data.moduleCommunicationType.name]=(data.instrumentationPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["puntual" + data.moduleCommunicationType.name]=(data.readingOClockPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        global: arrayGlobal,
                    });

                } else {
                    let arrayGlobal = {readingModbus:"N/D", readingRs485:"N/D", readingPlc:"N/D", readingPlc3:"N/D", instrumentationModbus:"N/D", instrumentationRs485:"N/D", instrumentationPlc:"N/D", instrumentationPlc3:"N/D", puntualModbus:"N/D", puntualRs485:"N/D", puntualPlc:"N/D", puntualPlc3:"N/D", };
                    global.data.map(data => {
                        arrayGlobal["reading" + data.moduleCommunicationType.name]=(data.readingPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["instrumentation" + data.moduleCommunicationType.name]=(data.instrumentationPerformance *100).toString().substring(0,5)+"%";
                        arrayGlobal["puntual" + data.moduleCommunicationType.name]=(data.readingOClockPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        global: arrayGlobal,
                    });
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
            ///Global Request
            apiClient.performance.getGlobalRequestPerformanceByGateway({
                gatewayId: ad.valueId.value,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(globalRequests => {
                this.setState({loading: false});
                if (globalRequests.data.length === 0){
                    Swal.fire({
                        type: 'info',
                        title: 'Información no disponible',
                        text: 'Debes seleccionar otro rango de fechas',
                    }).then(() => {
                        this.setState({loading: false})
                    });
                    let arrayRequest = {requestModbus:"N/D", requestRs485:"N/D", requestPlc:"N/D", requestPlc3:"N/D"};
                    globalRequests.data.map(data => {
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        globalRequests: arrayRequest,
                    });

                }else {
                    let arrayRequest = {requestModbus:"N/D", requestRs485:"N/D", requestPlc:"N/D", requestPlc3:"N/D"};
                    globalRequests.data.map(data => {
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        arrayRequest["request" + data.moduleCommunicationType.name]=(data.requestPerformance *100).toString().substring(0,5)+"%";
                        return true;
                    });
                    this.setState({
                        globalRequests: arrayRequest,
                    });
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
            apiClient.performance.getGlobalPerformanceDifferencesByGateway({
                gatewayId: ad.valueId.value,
                timeInterval:this.state.timeInterval.value,
                from: ad.dateFrom,
                to: ad.dateTo,
            }).then(res => {
                let PerformanceDifferences=[];
                res.data.map(x=>{
                    PerformanceDifferences.push({serial:x.serial, dateTime: Time(x.dateTime), readingPerformance:x.readingPerformance,instrumentationPerformance:x.instrumentationPerformance, readingOClockPerformance: x.readingOClockPerformance  });
                    return true
                });
                this.setState({PerformanceDifferences:PerformanceDifferences});
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
        }else {
            Swal.fire({
                type: 'warning',
                title: 'Campos vacios',
                text: 'No pueden ver campos vacios',
            });
        }
    };

    render() {
        let type = this.state.types.value;
        let typeButton;
        if (type === 0 || type===undefined) {
            typeButton =
                <Col md={2}>
                    <label> Tipo de origen </label>
                    <Select
                        name="selectGr"
                        id="selectGr"
                        options={this.state.valueIdData}
                        value={this.state.valueId}
                        placeholder="Grupo"
                        onChange={this.getvalueId}
                    />
                </Col>
        }
        else if (type === 1) {
            typeButton =
                <Col md={2}>
                    <label> Tipo de origen </label>
                    <Select
                        name="selectGt"
                        id="selectGt"
                        options={this.state.gatewaysData}
                        value={this.state.valueId}
                        placeholder="Gateway"
                        onChange={this.getGatewaysId}
                    />
                </Col>
        }
        let dateValue = this.state.dateValue.value;
        let customDateFrom, customDateTo, timeInterval;
        if (dateValue === 4) {
            customDateFrom =
                <Col md={2}>
                    <label > Desde </label>
                    <CustomDate
                        id="DateFrom"
                        value={new Date(this.state.dateFrom)}
                        onChange={(moment) => this.onDateChange(moment, 'dateFrom')}
                    />
                </Col>;
            customDateTo =
                <Col md={2}>
                    <label > Hasta </label>
                    <CustomDate
                        id="DateTo"
                        value={new Date(this.state.dateTo)}
                        onChange={(moment)=> this.onDateChange(moment,'dateTo')}
                    />
                </Col>
        }
        if (this.state.activeTab==="2"){
            timeInterval=
                <Col md={2}>
                    <label> Intervalo por: </label>
                    <Select
                        name="interval"
                        id="interval"
                        options={this.interval}
                        value={this.state.timeInterval}
                        onChange={this.timeInterval}
                    />
                </Col>
        }

        return (
            <div style={{marginTop:10}}>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <LoadingComponent loading={this.state.loading}/>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            <span>Global</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={() => {
                                this.toggle('2');
                            }}
                        >
                            <span>Diferencial</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>{this.state.activeTab==="1"? 'Desempeño global ' :'Desempeño diferencial '} </CardTitle>
                    <CardBody className="border-top">
                        <TabContent activeTab={this.state.activeTab}>
                            <Row className={"justify-content-center"}>
                                <Col md={2}>
                                    <label> Tipo</label>
                                    <Select
                                        name="objectTypes"
                                        id="objectTypes"
                                        onChange={this.getObjectTypes}
                                        value={this.state.selectedTypes}
                                        defaultValue={[{value: 0, label: 'Grupo'}]}
                                        placeholder="Seleccionar tipo"
                                        options={[{value: 0, label: 'Grupo'}, {value: 1, label: 'Gateways'},]}
                                    />
                                </Col>
                                {typeButton}
                                <Col md={2}>
                                    <label> Tiempo </label>
                                    <Select
                                        name="selectGr"
                                        id="selectGr"
                                        options={this.state.dateSelect}
                                        placeholder="Selecionar fecha"
                                        value={this.state.dateValue}
                                        onChange={this.getDateSelect}
                                    />
                                </Col>
                                {customDateFrom}
                                {customDateTo}
                                {timeInterval}
                            </Row>
                            <br/>
                            <Row className={"justify-content-center"}>
                                <Col md={2}>
                                    <ButtonCustom
                                        name={'generate'}
                                        id={'generate'}
                                        title={'Generar'}
                                        Click={this.state.types.value === 0 ? this.getGroupPerformance : this.getGatewayPerformance}
                                    />
                                </Col>
                                {  this.state.activeTab==="2"
                                    ?
                                    <Col md={2}>
                                        <ButtonCustom
                                            name={'generate'}
                                            id={'generate'}
                                            style={{backgroundColor:'#00c853', borderColor:'#a5d6a7'}}
                                            title={'Descargar CSV'}
                                            Click={this.fileCSV}
                                        />
                                    </Col>
                                    :
                                    ''
                                }
                            </Row>
                            <br/>
                            <TabPane tabId="1">
                                <Table striped bordered hover>
                                    <thead style={{width:'30%'}}>
                                    <tr>
                                        <th style={{width:'30%'}}>Desempeño de medición</th>
                                        <th style={{width:'17.5%'}}>ModBus</th>
                                        <th style={{width:'17.5%'}}>RS485</th>
                                        <th style={{width:'17.5%'}}>PLC</th>
                                        <th style={{width:'17.5%'}}>PLC3</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{width:'30%'}}>Lectura</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.readingModbus}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.readingRs485}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.readingPlc}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.readingPlc3}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'30%'}}>Instrumentación</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.instrumentationModbus}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.instrumentationRs485}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.instrumentationPlc}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.instrumentationPlc3}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'30%'}}>Lectura puntuales</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.puntualModbus}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.puntualRs485}</td>
                                        <td style={{width:'17.5%'}}>{this.state.global.puntualPlc} </td>
                                        <td style={{width:'17.5%'}}>{this.state.global.puntualPlc3} </td>

                                    </tr>
                                    </tbody>
                                </Table>
                                <br/>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th style={{width:'30%'}}>Desempeño de comunicación</th>
                                        <th style={{width:'17.5%'}}>ModBus</th>
                                        <th style={{width:'17.5%'}}>RS485</th>
                                        <th style={{width:'17.5%'}}>PLC</th>
                                        <th style={{width:'17.5%'}}>PLC3</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{width:'30%'}}>Solicitud Vs Respuesta</td>
                                        <td style={{width:'17.5%'}} >{this.state.globalRequests.requestModbus}</td>
                                        <td style={{width:'17.5%'}} >{this.state.globalRequests.requestRs485}</td>
                                        <td style={{width:'17.5%'}} >{this.state.globalRequests.requestPlc}</td>
                                        <td style={{width:'17.5%'}} >{this.state.globalRequests.requestPlc3}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </TabPane>
                            <TabPane tabId="2">
                                       <TableLayout
                                           data={this.state.PerformanceDifferences}
                                           showPaginationBottom={true}
                                           showPaginationTop={true}
                                           noDataText={false}
                                           defaultPageSize={this.state.pageSize}
                                           columns={[
                                               {Header: "Medidor", accessor: 'serial'},
                                               {Header: "Fecha", accessor: "dateTime"},
                                               {Header: "Lectura", accessor: "readingPerformance",Cell:(props)=> String(props.value).substr(0,5)+"%" },
                                               {Header: "Instrumentación", accessor: "instrumentationPerformance",Cell:(props)=> String(props.value).substr(0,5)+"%" },
                                               {Header: "Lecturas puntuales", accessor: "readingOClockPerformance",Cell:(props)=> String(props.value).substr(0,5)+"%" },
                                           ]}
                                       />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Performance;
