function getStorage() {
    return sessionStorage;
}

function getTime() {
    return Math.floor(new Date().getTime() / 1000);
}

const storage = getStorage();

const cache = {
    getItem(key)
    {
        if (!key) {
            return false;
        }

        const res = storage.getItem(key);

        if(!res)
            return false;

        const ret = JSON.parse(res);

        if ((typeof ret.expires == 'undefined') || (getTime() > ret.expires)) { //check if the time is still valid

            return false;
        }

        return ret.data;
    },
    setItem(key, data, time)
    {
        if(!key)
            return;

        data = data || '';
        const expires = getTime() + (time || 30);

        storage.setItem(key, JSON.stringify({expires, data}));
    },
    removeItem(key)
    {
        if(!key)
            return;

        storage.removeItem(key);
    },
    exists(key)
    {
        if (!key) {
            return false;
        }

        const res = storage.getItem(key);

        return !!res;
    }
};

export default cache