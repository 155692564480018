import React, { useRef } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import PropTypes from "prop-types";
import { tableFilterComponent } from "../custom/tableFilterComponent";
const TableLayout = React.forwardRef((props, ref) => {
   return (
      <ReactTable
         ref={ref}
         data={props.data}
         resolveData={(reportTableData) => reportTableData.map((row) => row)}
         columns={props.columns}
         getTrProps={props.trProps}
         getTdProps={props.getTdProps}
         onFetchData={props.onFetchData}
         filterable={props.filterable}
         defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
               ? String(row[id])
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
               : true;
         }}
         showPaginationBottom={props.showPaginationBottom}
         showPaginationTop={props.showPaginationTop}
         style={props.style}
         className={props.className}
         pageSize={props.pageSize}
         pageSizeOptions={props.pageSizeOptions}
         pages={props.pages}
         page={props.page}
         manual={props.manual}
         previousText={"Anterior"}
         nextText={"Próximo"}
         loadingText={"Cargando..."}
         loading={props.loading}
         noDataText={props.noDataText}
         pageText={"Página"}
         ofText={"De"}
         rowsText={"Filas"}
         isExpanded
         defaultPageSize={props.defaultPageSize}
         defaultSorted={props.defaultSorted}
         original
         collapseOnSortingChange={true}
         collapseOnPageChange={true}
         collapseOnDataChange={true}
         freezeWhenExpanded={false}
         sortable={true}
         FilterComponent={tableFilterComponent}
         onPageSizeChange={props.onPageSizeChange}
      />
   );
});

export default TableLayout;

TableLayout.propTypes = {
   data: PropTypes.array.isRequired,
   columns: PropTypes.array.isRequired,
   pageSize: PropTypes.number,
   pages: PropTypes.number,
   manual: PropTypes.bool,
   loading: PropTypes.bool,
   trProps: PropTypes.func,
   getTdProps: PropTypes.func,
   onFetchData: PropTypes.func,
   showPaginationBottom: PropTypes.bool,
   showPaginationTop: PropTypes.bool,
};

TableLayout.defaultProps = {
   data: [],
   columns: [],
   className: "text-center",
   filterable: true,
   noDataText: false,
   manual: false,
   loading: false,
   showPaginationBottom: true,
   showPaginationTop: true,
   defaultPageSize: 10,
   pageSizeOptions: [10, 25, 50, 100, 250],
};
