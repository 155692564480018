export const columns = [
  "Medidor",
  "Fecha",
  "Voltaje A",
  "Voltaje B",
  "Voltaje C",
  "Corriente A",
  "Corriente B",
  "Corriente C",
  "Factor de potencia",
  "Factor de potencia A",
  "Factor de potencia B",
  "Factor de potencia C",
];

export const columnWidthConfigs = [
  { column: "A", width: 12 },
  { column: "B", width: 20 },
  { column: "C", width: 12 },
  { column: "D", width: 12 },
  { column: "E", width: 15 },
  { column: "F", width: 12 },
  { column: "G", width: 14 },
  { column: "H", width: 15 },
  { column: "I", width: 20 },
  { column: "J", width: 20 },
  { column: "K", width: 20 },
  { column: "L", width: 20 },
];
