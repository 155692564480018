export const columns = [
   "Estado actual",
   "Estado",
   "Protocolo",
   "Módulo de comunicación",
   "Tipo de módulo",
   "Identificador",
   "Alias",
   "Dirección IP",
   "Última comunicación",
   "Instalado en",
   "Institución",
];
export const columnWidthConfigs = [
   { column: "A", width: 13 },
   { column: "B", width: 13 },
   { column: "C", width: 10 },
   { column: "D", width: 23 },
   { column: "E", width: 15 },
   { column: "F", width: 17 },
   { column: "G", width: 15 },
   { column: "H", width: 16 },
   { column: "I", width: 22 },
   { column: "J", width: 22 },
   { column: "K", width: 13 },
];
