import * as React from "react";
import { useRef } from "react";
import { notDefined } from "../../constants/defaultValue";

export default function useGenericTable({
   headers = [],
   data = [],
   actions = [],
   customColumns = [],
   triggerOnFetchData = false,
}) {
   const reactTableRef = useRef();

   const [columns, setColumns] = React.useState([]);

   React.useEffect(() => {
      if (data.length > 0 && headers.length > 0) {
         const _colums = headers
            .map(({ displayName, accessor, isVisible = true }) => {
               if (
                  data[0][accessor] !== null &&
                  typeof data[0][accessor] === "object"
               )
                  return null;

               const columnProp =
                  customColumns.length > 0
                     ? customColumns.find((x) => x.accessor === accessor)
                     : undefined;

               return columnProp != undefined
                  ? {
                       Header: displayName,
                       accessor,
                       show: isVisible,
                       ...columnProp,
                    }
                  : {
                       Header: displayName,
                       accessor,
                       show: isVisible,
                       Cell: (props) =>
                          props.value != null ? props.value : notDefined,
                    };
            })
            .filter((value) => value != null);
         setColumns([...actions, ..._colums]);
      }
   }, [data, headers]);

   React.useEffect(() => {
      const current = reactTableRef.current;
      if (current && triggerOnFetchData) {
         current.fireFetchData();
      }
   }, [triggerOnFetchData]);

   return { columns, reactTableRef };
}
