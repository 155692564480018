import React from 'react';
import {Card, Row, Col, CardTitle, CardBody, Modal, ModalHeader, ModalBody,Button, ButtonGroup} from 'reactstrap';
import apiClient from '../../api/apiClient';
import Swal from 'sweetalert2'
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {RoleModel} from "../../models/OthersModel"
import {CustomInput} from "../../components/custom/CustomInput";
import {CustomSelect} from "../../components/custom/CustomSelect";
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import {ModalCustom} from "../../components/custom/ModalCustom";

class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalEd: false,
            role:new RoleModel(),
            rolesAll:[],
            roleValue:'',
            PermissionsAll:[],
            RolePermissions:[],
            loading: false,
            selectedAssociated: null,
            selectedNotAssociated: null,
            ToMoveAssociated:[],
            ToMoveNotAssociated:[],
            pageGNA:0,pageGA:0,
            pageSizeGNA:5, pageSizeGA:5,
            toggleModalStatusCode:false,
            error:""
        };
    }

    toggleModal = () => {
        if (this.state.modal) {
            this.setState({role: new RoleModel() });
        }
        this.setState({
            modal: !this.state.modal,
        });
    };
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };
    componentDidMount =()=> {
        apiClient.users.getRoles()
            .then(res => {
                let items = res.data.map(result => {return {value: result.id, label: result.name}});
                this.setState({rolesAll: items})
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    onChangeInputSimple = (e) => {
        let {role} = this.state;
        role[e.target.name] = e.target.value;
        this.setState({role});
    };
    submit=()=>{
        let name = this.state.role.name;
            this.toggleModal();
            apiClient.users.createRole({
                name:name,
            }).then(response => {
                if(response) {
                    Swal.fire({
                        type: 'success',
                        title:' Rol fue agregado correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let items = {value: response.id, label: response.name};
                    let newRole= this.state.rolesAll.slice();
                    newRole.push(items);
                    this.setState({rolesAll: newRole});
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    getTableInfo = (e) => {
        this.setState({loading:true, roleValue:e });
        Promise.all([
            apiClient.users.getPermissions(),
            apiClient.users.getRolePermissions({id: e.value})
            ]).then(response=>{
                let PermissionsAll = response[0].data.map(x=>{ return x.name  });
                let RolePermissions = response[1].data.map(x=>{ return x.name });
            function different(array1, array2) {
                let temp = [];
                /* eslint-disable no-unused-vars */
                for (let i in array1) {
                    if(array2.indexOf(array1[i]) === -1) temp.push(array1[i]);
                }
                for(let i in array2) {
                    if(array1.indexOf(array2[i]) === -1) temp.push(array2[i]);
                }
                return  temp.sort((a,b) => a-b);
                /* eslint-enable no-unused-vars */
            }
            this.setState({ RolePermissions:response[1].data, pageGA:0, PermissionsAll:different(PermissionsAll,RolePermissions).map(x=>{return  {name:x}}),loading:false,});
        });
    };
    getDataAssociated = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selectedAssociated : rowInfo.index,
                        ToMoveNotAssociated: rowInfo.row.name,
                    });
                },
                style: {
                    background: rowInfo.index === this.state.selectedAssociated ? '#00c853' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedAssociated ? 'white' : 'black'
                }
            }
        }
        return {};
    };
    getDataNotAssociated = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selectedNotAssociated: rowInfo.index,
                        ToMoveAssociated: rowInfo.row.name,
                    });
                },
                style: {
                    background: rowInfo.index === this.state.selectedNotAssociated ? '#00c853' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedNotAssociated ? 'white' : 'black'
                }
            }
        }
        return {};
    };
    moveTableNotAssociated = () => {
        let arrayNotAssociated = this.state.PermissionsAll;
        arrayNotAssociated.push( {name: this.state.ToMoveNotAssociated });
        let arrayAssociated = this.state.RolePermissions.filter(x => { console.log( x.name  );  return  x.name !== this.state.ToMoveNotAssociated});
        console.log(arrayAssociated);
        this.setState({
            RolePermissions: arrayAssociated,
            PermissionsAll: arrayNotAssociated,
            ToMoveNotAssociated: null,
            selectedAssociated: null,
        });
    };
    moveTableAssociated =()=>{
        let arrayNotAssociated = this.state.RolePermissions;
        arrayNotAssociated.push( {name: this.state.ToMoveAssociated});
        let arrayAssociated = this.state.PermissionsAll.filter(x => x.name !== this.state.ToMoveAssociated);
        this.setState({
            PermissionsAll: arrayAssociated,
            RolePermissions: arrayNotAssociated,
            ToMoveAssociated: null,
            selectedNotAssociated: null,
        });
    };
    saveChanges = () => {
        let id = this.state.roleValue.value;
        let array = this.state.RolePermissions.map(x => {return  x.name });
        apiClient.users.updateRolePermissions({
            roleId: id,
            permissions: array.length===0 ? [] : array,
        }).then(res => {
            Swal.fire({
                type: 'success',
                title:'Cambios realizados correctamente',
                showConfirmButton: false,
                timer: 1500
            });
        }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    render() {
        const {name=""} =this.state.role;

        const tableDataAssociated = this.state.RolePermissions.map(  (data)=> {return {name: data.name}});
        const columnAssociates = [{Header: "Permisos asociados por rol", columns: [{Header: "Nombre", accessor: "name"}]}];

        const tableDataNotAssociated = this.state.PermissionsAll.map(  (data)=> {return {name: data.name}});
        const columnNotAssociated= [{Header: "Permisos no asociados",columns: [{Header: "Nombre", accessor: "name"}]}];


        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>{"Agregar"} </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <CustomInput valid={name.length>0} onChange={this.onChangeInputSimple} value={name} placeholder={"Nombre del rol"} label={"Rol"} id={"name"}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <ButtonCustom color={'success'} className={''} name={'submit'} id={'submit'} disabled={!(name.length>0)} Click={this.submit} title={"Enviar" } />
                                {' '}
                                <ButtonCustom type={'cancel'} color={'danger'} className={''} name={'cancel'} id={'cancel'} Click={this.toggleModal} title={"Cancelar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <LoadingComponent loading={this.state.loading}/>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>Roles</CardTitle>
                    <CardBody className="border-top">
                        <Row >
                            <Col md={2}>
                                <label> Roles </label>
                                <CustomSelect
                                    id={"roles"}
                                    options={this.state.rolesAll}
                                    placeholder="Selecionar grupo"
                                    value={this.state.rolesValue}
                                    onChange={this.getTableInfo}
                                />
                            </Col>
                            <Col md={3}>
                                <label> Acción </label>
                                <br/>
                                <ButtonGroup>
                                    <Button onClick={()=>this.toggleModal('ad')} color={'warning'}> Agregar </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={{ size: 5, offset: 0 }}>
                                <TableLayout
                                    trProps={this.getDataAssociated}
                                    data={tableDataAssociated}
                                    columns={columnAssociates}
                                    filterable={false}
                                    defaultPageSize={this.state.pageSizeGA}
                                />
                            </Col>
                            <Col sm={{ size: 2, offset: 0 }}>
                                {/*<br/><br/>*/}
                                {/*<br/><br/>*/}
                                {/*<br/><br/>*/}
                                <br/>
                                <Row className={'mt-5 pt-5'}>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <ButtonCustom
                                            className={"btn btn-block" + (this.state.selectedNotAssociated==null? " disable":"")}
                                            name={'moveTableAssociated'}
                                            color={'success'}
                                            id={'moveTableAssociated'}
                                            title={<i className="fas fa-angle-double-left"> </i>}
                                            disabled={this.state.selectedNotAssociated==null}
                                            Click={this.moveTableAssociated}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <ButtonCustom
                                            className={"btn btn-block" + (this.state.selectedAssociated==null? " disable":"")}
                                            name={'moveTableNotAssociated'}
                                            color={'success'}
                                            id={'moveTableNotAssociated'}
                                            title={<i className="fas fa-angle-double-right"> </i>}
                                            disabled={this.state.selectedAssociated==null}
                                            Click={this.moveTableNotAssociated}
                                        />
                                    </Col>
                                </Row>

                                <br/><br/><br/><br/>
                                <Row>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <ButtonCustom
                                            name={'saveChanges'}
                                            id={'saveChanges'}
                                            title={'Guardar'}
                                            Click={this.saveChanges}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={{ size: 5, offset: 0 }}>
                                <TableLayout
                                    trProps={this.getDataNotAssociated}
                                    data={tableDataNotAssociated}
                                    columns={columnNotAssociated}
                                    filterable={false}
                                    defaultPageSize={this.state.pageSizeGNA}
                                />
                            </Col>
                        </Row>
                        <br/>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Roles;
