import React from 'react';
import {
    Button
} from 'reactstrap';

import img1 from '../../assets/images/logo-icon.png';

class Maintanance extends React.Component {
  render() {
    return <div className="">
    	<div class="error-box">
            <div class="error-body text-center">
                <img src={img1} alt="" />
                <h4 class="text-dark font-24">Xtreme Admin</h4>
                <div class="mt-4">
                    <h3>Your page in under maintenance</h3>
                    <h5 class="mb-0 text-muted font-medium">Something wrong going on this page.</h5>
                    <h5 class="text-muted font-medium">Please Check back again.</h5>
                </div>
                <div class="mt-4 mb-4"><i class="ti-settings font-24"></i></div>
                <Button color="primary">
                    <i aria-hidden="true" class="fab fa-facebook-f"></i> 
                </Button>{' '}
                <Button color="secondary">
                    <i aria-hidden="true" class="fab fa-linkedin-in"></i>
                </Button>{' '}
                <Button color="success">
                    <i aria-hidden="true" class="fab fa-skype"></i>
                </Button>{' '}
                <Button color="info">
                    <i aria-hidden="true" class="fab fa-twitter"></i>
                </Button>{' '}
            </div>
        </div>
    </div>;
  }
}

export default Maintanance;
