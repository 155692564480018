import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import ButtonCustom from "../../../components/custom/ButtonCustom";
import { CustomInput } from "../../../components/custom/CustomInput";

export default function ViewGatewayPlc({ ...props }) {
    const { value, toggleModal, title } = props;

    useEffect(() => {
        title(
            `Configuración: ${value.gatewayAlias} - ${value.gatewayIdentifier}`
        );
    }, []);

    return (
        <>
            <Row>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.gatewayAlias}
                        label={"Gateway Tcp"}
                        id={"gatewayTcpText"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.highFrequencyAdd || 0}
                        label={"Frecuencia alta añadida"}
                        id={"highFrequencyAdd"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.highFrequency || 0}
                        label={"Frecuencia alta"}
                        id={"highFrequency"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.lowFrequency || 0}
                        label={"Frecuencia baja"}
                        id={"lowFrequency"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.masterId}
                        label={"Master"}
                        id={"master"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.txGainN}
                        label={"txGainN"}
                        id={"txGainN"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.txGainR}
                        label={"txGainR"}
                        id={"txGainR"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.toModem}
                        label={"toModem"}
                        id={"toModem"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.toModem}
                        label={"toPlc"}
                        id={"toPlc"}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        readOnly={true}
                        value={value.ssKey}
                        label={"ssKey"}
                        id={"ssKey"}
                    />
                </Col>
            </Row>
            <div
                style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}
            >
                <ButtonCustom
                    type={"cancel"}
                    color={"danger"}
                    className={""}
                    name={"close"}
                    id={"close"}
                    Click={toggleModal}
                    title={"Cerrar"}
                />
            </div>
        </>
    );
}
