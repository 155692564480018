import React from "react";
import {
   Card,
   Row,
   Col,
   CardTitle,
   CardBody,
   Modal,
   ModalHeader,
   ModalBody,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
} from "reactstrap";
import apiClient from "../../api/apiClient";
import Swal from "sweetalert2";
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import { GatewayModel } from "../../models/GatewayModel";
import { SelectSimple } from "../../components/custom/Select";
import { CustomInput } from "../../components/custom/CustomInput";
import classnames from "classnames";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import Select from "react-select";
import { ModalCustom } from "../../components/custom/ModalCustom";
import Trans from "../../translate/Translate";
import authService from "../../services/AuthService";
import GatewayPerformance from "./GatewayPerformance";
import GatewayPlc from "./GatewayPlc";
import GenericTable from "../../components/custom/GenericTable";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/gatewayInstallation";
import {
   booleanOptions,
   FILTER_TYPE,
   maxRecordToShowConfirmation,
   notDefined,
} from "../../constants/defaultValue";
import { saveAsExcel } from "../../utilities/exportToExcel";

// const t = (text) => <Trans text={text} />;

// const getCustomFilter = (filter, row) => {
//    const value = filter.value.toLowerCase();
//    const name = row[filter.id].name.toLowerCase();
//    return name.includes(value);
// };

const ipRegex = new RegExp(
   /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:\d{1,5})?$/
);
const domainRegEx = new RegExp(
   /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])(:\d{1,5})?$/
);
class GatewaysInstallation extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         modal: false,
         type: "",
         status: [],
         communicationType: [],
         institution: [],
         institutionOption: [],
         institutionSelect: { value: "", label: "Todos" },
         isComunicatingSelect: { value: "", label: "Todos" },
         communicationTypeSelect: { value: "", label: "Todos" },
         communicationTypeOptions: [],
         moduleCommunicationTypeSelect: { value: "", label: "Todos" },
         moduleTypeSelect: { value: "", label: "Todos" },
         statusSelect: { value: "", label: "Todos" },
         moduleType: [],
         moduleCommunicationType: [],
         gatewaysAll: [],
         gatewayHeaders: [],
         pageMNA: 0,
         pageMA: 0,
         pages: 0,
         pageSize: 25,
         pageSizeMA: 10,
         pageSizeMNA: 10,
         selectData: [],
         gateway: new GatewayModel(),
         loading: false,
         loadingGateways: false,
         isOpen: false,
         activeTab: "1",
         obj: {},
         gatewayMeter: [],
         dataAssociated: [],
         dataNotAssociated: [],
         id: "",
         name: "",
         lastDate: "",
         selectedAssociated: null,
         selectedNotAssociated: null,
         itemToMoveAssociated: [],
         itemToMoveNotAssociated: [],
         toggleModalStatusCode: false,
         error: "",
         permission: false,
         identifier: "",
         recordsTotal: 0,
         filtered: [],
         sorted: [],
      };
   }

   toggle = (tab) => {
      this.setState({
         isOpen: !this.state.isOpen,
      });

      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab,
         });
      }
   };

   toggleModal = () => {
      if (this.state.modal) {
         this.setState({ gateway: new GatewayModel() });
      }
      this.setState({
         modal: !this.state.modal,
         type: "add",
      });
   };

   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };

   componentDidMount = () => {
      authService
         .userHasPermissions("ManageAllInstitutions")
         .then((response) => {
            if (response === true) {
               apiClient.institutions
                  .all()
                  .then((res) => {
                     let items = res.data.map((result) => {
                        return { value: result.id, label: result.name };
                     });
                     items.splice(0, 0, { value: "", label: "Todos" });
                     this.setState({
                        institution: res.data,
                        institutionOption: items,
                        permission: response,
                     });
                  })
                  .catch((error) => {
                     this.setState({
                        toggleModalStatusCode: true,
                        error: error,
                        loading: false,
                     });
                  });
            }
         });

      apiClient.gateways
         .getGatewayStatuses()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.name };
            });
            items.splice(0, 0, { value: "", label: "Todos" });
            this.setState({ status: res.data, statusOptions: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getCommunicationTypes()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.name };
            });
            items.splice(0, 0, { value: "", label: "Todos" });
            this.setState({
               communicationType: res.data,
               communicationTypeOptions: items,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getModuleTypes()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.name };
            });
            items.splice(0, 0, { value: "", label: "Todos" });
            this.setState({ moduleType: res.data, moduleTypeOptions: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getModuleCommunicationTypes()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.name };
            });
            items.splice(0, 0, { value: "", label: "Todos" });
            this.setState({
               moduleCommunicationType: res.data,
               moduleCommunicationTypeOptions: items,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return {
                  value: result.id,
                  label: result.alias,
                  date: result.lastCommunication,
               };
            });
            this.setState({ gatewayMeter: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   onChangeSelect = (e, key) => {
      let { gateway } = this.state;

      //Valida si el Protocolo = tcp
      if (key == "communicationType" && e == 1) {
         gateway.communicationAddress = "";
      }
      gateway[key] = Object.assign(
         {},
         this.state[key].find((x) => x.id === e)
      );
      this.setState({ gateway });
   };

   onChangeSearch = (e, key) => {
      this.setState({ [key.name]: e });
   };

   onChangeInputSimple = (e) => {
      let { gateway } = this.state;
      gateway[e.target.name] = e.target.value;
      this.setState({ gateway });
   };

   onChangeInput = (e) => {
      this.setState({ identifier: e.target.value });
   };

   getSelectNative = ({
      value,
      valueName,
      options = [],
      onChange,
      filterType = FILTER_TYPE.name,
   }) => {
      return (
         <select
            defaultValue={value}
            onChange={(event) => {
               switch (filterType) {
                  case FILTER_TYPE.name:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(event.target.value);
                     break;

                  case FILTER_TYPE.id:
                     onChange(
                        options.find((x) => x.name == event.target.value)?.id ||
                           ""
                     );
                     break;

                  case FILTER_TYPE.boolean:
                     event.target.value == 0
                        ? onChange("")
                        : onChange(
                             options.find((x) => x.name == event.target.value)
                                ?.id
                                ? 1
                                : 0
                          );
                     break;

                  default:
                     break;
               }

               this.setState({ [valueName]: event.target.value });
            }}
         >
            <option value={0}>TODOS</option>
            {options.length > 0 &&
               options?.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
               ))}
         </select>
      );
   };

   getCustomColumns = () => {
      const value = [
         {
            accessor: "institutionId",
            width: 140,
            Cell: (props) =>
               this.state.institution.find((x) => x.id == props.value)?.name ??
               notDefined,
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.institutionSelect,
                  valueName: "institutionSelect",
                  options: this.state.institution,
                  onChange,
                  filterType: FILTER_TYPE.id,
               }),
         },
         {
            accessor: "statusName",
            width: 140,
            Cell: (props) => props.value ?? notDefined,
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.statusSelect,
                  valueName: "statusSelect",
                  options: this.state.status,
                  onChange,
               }),
         },
         {
            accessor: "communicationTypeName",
            width: 140,
            Cell: (props) => props.value ?? notDefined,
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.communicationTypeSelect,
                  valueName: "communicationTypeSelect",
                  options: this.state.communicationType,
                  onChange,
               }),
         },
         {
            accessor: "moduleCommunicationTypeName",
            width: 140,
            Cell: (props) => props.value ?? notDefined,
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.moduleCommunicationTypeSelect,
                  valueName: "moduleCommunicationTypeSelect",
                  options: this.state.moduleCommunicationType,
                  onChange,
               }),
         },
         {
            accessor: "moduleTypeName",
            width: 140,
            Cell: (props) => props.value ?? notDefined,
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.moduleTypeSelect,
                  valueName: "moduleTypeSelect",
                  options: this.state.moduleType,
                  onChange,
               }),
         },
         {
            accessor: "isCommunicating",
            Cell: (props) =>
               props.value ? (
                  <i
                     style={{
                        color: "green",
                        fontSize: "20px",
                     }}
                     className="fas fa-check"
                  />
               ) : (
                  <i
                     style={{
                        color: "red",
                        fontSize: "20px",
                     }}
                     className="fas fa-times"
                  />
               ),
            Filter: ({ onChange }) =>
               this.getSelectNative({
                  value: this.state.isComunicatingSelect,
                  valueName: "isComunicatingSelect",
                  options: booleanOptions,
                  onChange,
                  filterType: FILTER_TYPE.boolean,
               }),
         },
      ];

      return value;
   };

   onButtonClick = (id, type) => {
      if (type === "edit") {
         this.toggleModal();

         const gatewayToEdit = Object.assign(
            {},
            this.state.gatewaysAll.find((x) => x.id === id)
         );
         Object.keys(gatewayToEdit).map((x) => {
            if (gatewayToEdit[x] === null) {
               gatewayToEdit[x] = "";
            }
            return true;
         });
         gatewayToEdit["institution"] = this.state.institution.filter(
            (institution) => institution.id === gatewayToEdit.institutionId
         )[0];

         gatewayToEdit.status = this.state.status.find(
            (item) => item.name == gatewayToEdit.statusName
         );

         gatewayToEdit.moduleType = this.state.moduleType.find(
            (item) => item.name == gatewayToEdit.moduleTypeName
         );

         gatewayToEdit.communicationType = this.state.communicationType.find(
            (item) => item.name == gatewayToEdit.communicationTypeName
         );

         gatewayToEdit.communicationType = this.state.communicationType.find(
            (item) => item.name == gatewayToEdit.communicationTypeName
         );

         gatewayToEdit.moduleCommunicationType =
            this.state.moduleCommunicationType.find(
               (item) => item.name == gatewayToEdit.moduleCommunicationTypeName
            );

         this.setState({ gateway: gatewayToEdit, type });
      } else if (type === "delete") {
      }
   };

   submit = () => {
      this.toggleModal();
      const {
         id = "",
         institution = {},
         status = {},
         communicationType = {},
         moduleType = {},
         moduleCommunicationType = {},
         identifier = "",
         description = "",
         alias = "",
         communicationAddress = "",
         firmware = "",
         latitude = "",
         longitude = "",
      } = this.state.gateway;
      if ("id" in this.state.gateway && this.state.gateway.id > 0) {
         apiClient.gateways
            .edit({
               id: id,
               status: status.id,
               description: description,
               communicationType: communicationType.id,
               moduleType: moduleType.id,
               moduleCommunicationType: moduleCommunicationType.id,
               identifier: identifier,
               institutionId:
                  this.state.permission === true ? institution.id : null,
               alias: alias,
               communicationAddress: communicationAddress,
               firmware: firmware,
               latitude: latitude,
               longitude: longitude,
            })
            .then((response) => {
               if (response) {
                  Swal.fire({
                     type: "success",
                     title: "Cambios realizados correctamente",
                     showConfirmButton: false,
                     timer: 1500,
                  });
                  let gateway = [...this.state.gatewaysAll];
                  gateway.splice(
                     this.state.gatewaysAll.findIndex(
                        (x) => x.id === response.id
                     ),
                     1,
                     response
                  );
                  this.setState({
                     gatewaysAll: gateway,
                     gateway: new GatewayModel(),
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         apiClient.gateways
            .create({
               status: status.id,
               description: description,
               communicationType: communicationType.id,
               moduleType: moduleType.id,
               moduleCommunicationType: moduleCommunicationType.id,
               identifier: identifier,
               alias: alias,
               institutionId:
                  this.state.permission === true ? institution.id : null,
               communicationAddress: communicationAddress,
               firmware: firmware,
               latitude: latitude,
               longitude: longitude,
            })
            .then((response) => {
               if (response) {
                  Swal.fire({
                     type: "success",
                     title: " Gateway fue agregado correctamente",
                     showConfirmButton: false,
                     timer: 1500,
                  });
                  let newGateway = this.state.gatewaysAll.slice();
                  newGateway.push(response);
                  this.setState({
                     gatewaysAll: newGateway,
                     gateway: new GatewayModel(),
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      }
   };

   getTableInfo = (e) => {
      this.setState({ loading: true });
      this.setState({ id: e.value, name: e.label, lastDate: e.date });
      apiClient.meters
         .getMetersInGateway({
            gatewayId: e.value,
         })
         .then((res) => {
            // console.log("Medidores Resp", res);
            // const serial = res.data.map(function (data) {
            //    return { serial: data.serial };
            // });
            this.setState({ dataAssociated: res.data });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      apiClient.meters
         .getInstallableMeters()
         .then((res) => {
            // const serial = res.data.map(function (data) {
            //    return {
            //       serial: data.serial,
            //    };
            // });
            this.setState({
               dataNotAssociated: res.data,
               pageMNA: 0,
               pageMA: 0,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });

      this.setState({ loading: false });
   };

   getDataAssociated = (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
         return {
            onClick: () => {
               this.setState({
                  selectedAssociated: rowInfo.index,
                  itemToMoveNotAssociated: rowInfo.row.serial,
               });
            },
            style: {
               background:
                  rowInfo.index === this.state.selectedAssociated
                     ? "#2dce89"
                     : rowInfo.index % 2 === 0
                     ? "#DDDDDD"
                     : "#FFFFFF",
               color:
                  rowInfo.index === this.state.selectedAssociated
                     ? "white"
                     : "black",
            },
         };
      }
      return {};
   };

   getDataNotAssociated = (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
         return {
            onClick: () => {
               this.setState({
                  selectedNotAssociated: rowInfo.index,
                  itemToMoveAssociated: rowInfo.row.serial,
               });
            },
            style: {
               background:
                  rowInfo.index === this.state.selectedNotAssociated
                     ? "#2dce89"
                     : rowInfo.index % 2 === 0
                     ? "#DDDDDD"
                     : "#FFFFFF",
               color:
                  rowInfo.index === this.state.selectedNotAssociated
                     ? "white"
                     : "black",
            },
         };
      }
      return {};
   };

   moveTableNotAssociated = () => {
      let arrayNotAssociated = this.state.dataNotAssociated;
      arrayNotAssociated.push({ serial: this.state.itemToMoveNotAssociated });
      let arrayAssociated = this.state.dataAssociated.filter(
         (x) => x.serial !== this.state.itemToMoveNotAssociated
      );
      this.setState({
         dataAssociated: arrayAssociated,
         dataNotAssociated: arrayNotAssociated,
         itemToMoveNotAssociated: null,
         selectedAssociated: null,
      });
   };

   moveTableAssociated = () => {
      let arrayNotAssociated = this.state.dataAssociated;
      arrayNotAssociated.push({ serial: this.state.itemToMoveAssociated });
      let arrayAssociated = this.state.dataNotAssociated.filter(
         (x) => x.serial !== this.state.itemToMoveAssociated
      );
      this.setState({
         dataNotAssociated: arrayAssociated,
         dataAssociated: arrayNotAssociated,
         itemToMoveAssociated: null,
         selectedNotAssociated: null,
      });
   };

   saveChanges = (event) => {
      event.preventDefault();
      let id = this.state.id;
      let array = this.state.dataAssociated.map((x) => {
         return x.serial;
      });
      apiClient.gateways
         .associateMeters({
            gateway: id,
            meters: array.length === 0 ? [] : array,
         })
         .then((res) => {
            Swal.fire({
               type: "success",
               title: "Cambios realizados correctamente",
               showConfirmButton: false,
               timer: 1500,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   onClickSearchMeter = () => {
      const {
         pageSize,
         identifier,
         institutionSelect,
         communicationTypeSelect,
         moduleCommunicationTypeSelect,
         moduleTypeSelect,
         statusSelect,
      } = this.state;
      apiClient.gateways
         .all({
            draw: 0,
            start: 0,
            length: pageSize,
            // Identifier: identifier,
            // InstitutionId: institutionSelect.value,
            // Status: statusSelect.value,
            // CommunicationType: communicationTypeSelect.value,
            // ModuleType: moduleTypeSelect.value,
            // ModuleCommunicationType: moduleCommunicationTypeSelect.value,
         })
         .then((res) => {
            this.setState({
               gatewaysAll: res.data,
               page: 0,
               loadingGateways: false,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loadingGateways: false,
            });
         });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({ ...this.state, loading: true });
      apiClient.gateways
         .all({
            draw: 0,
            page: 1,
            length: this.state.recordsTotal,
            sorted: this.state.sorted,
            filtered: this.state.filtered,
         })
         .then((res) => {
            console.log(res.data);
            saveAsExcel({
               columns: columnsExport,
               data: this.convertDataExport(res.data),
               endMergeTitle: "K1",
               endMergeSubTitle: "K2",
               columnWidthConfigs,
               title: "LISTADO DE GATEWAYS",
               name: `TMAM-Listado de gateways (${new Date()
                  .toISOString()
                  .slice(0, 10)})`,
               subTitle: `Descargado el: ${new Date().toLocaleString()}`,
            });
            this.setState({ ...this.state, loading: false });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loadingGateways: false,
               loading: false,
            });
         });
   };
   convertDataExport = (data) => {
      return data?.map((item) => ({
         isCommunicating: item.isCommunicating ? "Activo" : "Inactivo",
         status: item.statusName,
         communicationType: item.communicationTypeName,
         moduleCommunicationType: item.moduleCommunicationTypeName,
         moduleType: item.moduleTypeName,
         identifier: item.identifier,
         alias: item.alias,
         communicationAddress: item.communicationAddress,
         lastCommunication: item.lastCommunication,
         installedAt: item.installedAt,
         institutionId:
            this.state.institution.find((x) => x.id == item.institutionId)
               ?.name ?? notDefined,
      }));
   };

   render() {
      const tableDataAssociated = this.state.dataAssociated.map(function (
         data
      ) {
         return {
            serial: data.serial,
         };
      });
      const tableDataNotAssociated = this.state.dataNotAssociated.map(function (
         data
      ) {
         return {
            serial: data.serial,
         };
      });

      const {
         id = "",
         institution = {},
         status = {},
         communicationType = {},
         moduleType = {},
         moduleCommunicationType = {},
         identifier = "",
         description = "",
         alias = "",
         communicationAddress = "",
         firmware = "",
         installedAt = "",
         latitude = "",
         longitude = "",
      } = this.state.gateway;
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <Modal isOpen={this.state.modal}>
               <ModalHeader toggle={this.toggleModal}>
                  {this.state.type === "add" ? "Agregar" : "Editar"}{" "}
               </ModalHeader>
               <ModalBody>
                  <Row>
                     <Col md={6}>
                        <CustomInput
                           valid={alias.length > 0}
                           onChange={this.onChangeInputSimple}
                           value={alias}
                           label={"Alias"}
                           id={"alias"}
                        />
                     </Col>
                     <Col md={6}>
                        <CustomInput
                           valid={identifier.length > 0}
                           onChange={this.onChangeInputSimple}
                           value={identifier}
                           label={"Identificador"}
                           id={"identifier"}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col md={6}>
                        <SelectSimple
                           valueCallback={this.onChangeSelect}
                           item={communicationType}
                           id={"communicationType"}
                           label={"Protocolo"}
                           array={this.state.communicationType}
                        />
                     </Col>
                     <Col md={6}>
                        <SelectSimple
                           valueCallback={this.onChangeSelect}
                           item={moduleCommunicationType}
                           id={"moduleCommunicationType"}
                           label={"Módulo de Comunicación"}
                           array={this.state.moduleCommunicationType}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col md={6}>
                        <SelectSimple
                           valueCallback={this.onChangeSelect}
                           item={moduleType}
                           id={"moduleType"}
                           label={"Tipo módulo"}
                           array={this.state.moduleType}
                        />
                     </Col>
                     <Col md={6}>
                        <CustomInput
                           readOnly={communicationType.id == 1}
                           valid={
                              domainRegEx.test(communicationAddress) ||
                              ipRegex.test(communicationAddress) ||
                              communicationType.id == 1
                           }
                           onChange={this.onChangeInputSimple}
                           value={communicationAddress}
                           label={"Dirección IP"}
                           id={"communicationAddress"}
                        />
                     </Col>
                  </Row>
                  <Row
                     style={
                        this.state.permission === true
                           ? { display: "" }
                           : { display: "none" }
                     }
                  >
                     <Col md={12}>
                        <SelectSimple
                           valueCallback={this.onChangeSelect}
                           item={institution}
                           id={"institution"}
                           label={"Institución"}
                           array={this.state.institution}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col md={12}>
                        <CustomInput
                           valid={description.length > 0}
                           onChange={this.onChangeInputSimple}
                           value={description}
                           label={"Descripción"}
                           id={"description"}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col md={6}>
                        <SelectSimple
                           valueCallback={this.onChangeSelect}
                           item={status}
                           id={"status"}
                           label={"Estado"}
                           array={this.state.status}
                        />
                     </Col>
                     <Col md={6}>
                        <CustomInput
                           valid={firmware.length > 0}
                           onChange={this.onChangeInputSimple}
                           value={firmware}
                           label={"Firmware"}
                           id={"firmware"}
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col md={6}>
                        <CustomInput
                           valid={latitude.toString().length > 0}
                           onChange={this.onChangeInputSimple}
                           value={latitude}
                           label={"Latitud"}
                           id={"latitude"}
                        />
                     </Col>

                     <Col md={6}>
                        <CustomInput
                           valid={longitude.toString().length > 0}
                           onChange={this.onChangeInputSimple}
                           value={longitude}
                           label={"Longitud"}
                           id={"longitude"}
                        />
                     </Col>
                  </Row>
                  <Row
                     style={
                        this.state.type === "edit" ? {} : { display: "none" }
                     }
                  >
                     <Col md={12}>
                        <CustomInput
                           readOnly={true}
                           onChange={this.onChangeInputSimple}
                           value={installedAt}
                           label={"Instalado en"}
                           id={"installedAt"}
                        />
                     </Col>
                  </Row>
                  <br />
                  <Row>
                     <Col md={12}>
                        <ButtonCustom
                           color={"success"}
                           className={""}
                           name={"submit"}
                           id={"submit"}
                           disabled={
                              id > 0
                                 ? !(
                                      identifier.length > 0 &&
                                      description.length > 0 &&
                                      alias.length > 0 &&
                                      (communicationType.id === 2
                                         ? domainRegEx.test(
                                              communicationAddress
                                           ) ||
                                           ipRegex.test(communicationAddress)
                                         : communicationAddress.length >= 0) &&
                                      firmware.length >= 0 &&
                                      latitude.toString().length > 0 &&
                                      longitude.toString().length > 0
                                   )
                                 : !(
                                      (this.state.permission === true
                                         ? institution.name.length > 0
                                         : institution.name.length === 0) &&
                                      status.name.length > 0 &&
                                      communicationType.name.length > 0 &&
                                      moduleType.name.length > 0 &&
                                      moduleCommunicationType.name.length > 0 &&
                                      identifier.length > 0 &&
                                      description.length > 0 &&
                                      alias.length > 0 &&
                                      (communicationType.id === 2
                                         ? domainRegEx.test(
                                              communicationAddress
                                           ) ||
                                           ipRegex.test(communicationAddress)
                                         : communicationAddress.length >= 0) &&
                                      firmware.length >= 0 &&
                                      latitude.toString().length > 0 &&
                                      longitude.toString().length > 0
                                   )
                           }
                           Click={this.submit}
                           title={id > 0 ? "Actualizar" : "Enviar"}
                        />{" "}
                        <ButtonCustom
                           type={"cancel"}
                           color={"danger"}
                           className={""}
                           name={"cancel"}
                           id={"cancel"}
                           Click={this.toggleModal}
                           title={"Cancelar"}
                        />
                     </Col>
                  </Row>
               </ModalBody>
            </Modal>
            <Nav tabs>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "1",
                     })}
                     onClick={() => {
                        this.toggle("1");
                     }}
                  >
                     <span>Gateway</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "2",
                     })}
                     onClick={() => {
                        this.toggle("2");
                     }}
                  >
                     <span>Asociar medidor</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "3",
                     })}
                     onClick={() => {
                        this.toggle("3");
                     }}
                  >
                     <span>Configuración de Desempeño</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "4",
                     })}
                     onClick={() => {
                        this.toggle("4");
                     }}
                  >
                     <span>Configuración Plc</span>
                  </NavLink>
               </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
               <TabPane tabId="1">
                  <Card>
                     <CardTitle
                        style={{ fontSize: "18px" }}
                        className="bg-light border-bottom p-3 mb-0"
                     >
                        <i className="mdi mdi-hamburger mr-2"> </i>Instalación
                        de gateway
                     </CardTitle>
                     <CardBody>
                        <GenericTable
                           data={this.state.gatewaysAll}
                           headers={this.state.gatewayHeaders}
                           loading={this.state.loadingGateways}
                           pages={this.state.pages}
                           onAddClick={this.toggleModal}
                           onDownloadClick={this.handleToExcel}
                           customColumns={this.getCustomColumns()}
                           onPageSizeChange={(value) => {
                              this.setState({ pageSize: value });
                           }}
                           pageSize={
                              this.state.gatewaysAll.length > 0 &&
                              this.state.gatewaysAll.length < this.state.pageSize
                                 ? this.state.gatewaysAll.length
                                 : this.state.pageSize
                           }
                           actions={[
                              {
                                 Header: "Acción",
                                 accessor: "id",
                                 sortable: false,
                                 filterable: false,
                                 Cell: (props) => (
                                    <div className="text-center">
                                       <ButtonCustom
                                          name={"edit"}
                                          id={"edit"}
                                          title={"Editar"}
                                          color={"warning"}
                                          className={""}
                                          size={"sm"}
                                          Click={() =>
                                             this.onButtonClick(
                                                props.value,
                                                "edit"
                                             )
                                          }
                                       />
                                    </div>
                                 ),
                              },
                           ]}
                           onFetchData={(state) => {
                              this.setState({ loadingGateways: true });
                              apiClient.gateways
                                 .all({
                                    draw: 0,
                                    page: state.page + 1,
                                    length: this.state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered,
                                 })
                                 .then((res) => {
                                    if (res.data.length > 0)
                                       this.setState({
                                          gatewaysAll: res.data,
                                          gatewayHeaders: res.headers,
                                          page: state.page,
                                          pages: Math.ceil(
                                             res.recordsTotal / this.state.pageSize
                                          ),
                                          loadingGateways: false,
                                          recordsTotal: res.recordsTotal,
                                          sorted: state.sorted,
                                          filtered: state.filtered,
                                       });
                                    else
                                       this.setState({
                                          gatewaysAll: res.data,
                                          gatewayHeaders: res.headers,
                                          page: state.page,
                                          pageSize: state.pageSize,
                                          pages: Math.ceil(
                                             res.recordsTotal / state.pageSize
                                          ),
                                          loadingGateways: false,
                                          recordsTotal: res.recordsTotal,
                                          sorted: [],
                                          filtered: [],
                                       });
                                 })
                                 .catch((error) => {
                                    this.setState({
                                       toggleModalStatusCode: true,
                                       error: error,
                                       loadingGateways: false,
                                    });
                                 });
                           }}
                        />
                     </CardBody>
                  </Card>
               </TabPane>
               <TabPane tabId="2">
                  <Card>
                     <LoadingComponent loading={this.state.loading} />
                     <CardTitle
                        style={{ fontSize: "18px" }}
                        className="bg-light border-bottom p-3 mb-0"
                     >
                        <i className="mdi mdi-hamburger mr-2"> </i> Asociar
                        medidor a gateways
                     </CardTitle>
                     <CardBody className="border-top">
                        <Row>
                           <Col md={3}>
                              <label> Gateway </label>
                              <Select
                                 name="selectGr"
                                 id="selectGr"
                                 options={this.state.gatewayMeter}
                                 placeholder="Gateway"
                                 onChange={this.getTableInfo}
                              />
                           </Col>
                        </Row>
                        <br />
                        <Row>
                           <Col sm={{ size: 5, offset: 0 }}>
                              <TableLayout
                                 trProps={this.getDataAssociated}
                                 data={tableDataAssociated}
                                 className="-striped -highlight"
                                 defaultPageSize={this.state.pageSizeMNA}
                                 columns={[
                                    {
                                       Header: "Medidores asociados",
                                       columns: [
                                          {
                                             Header: "Medidor",
                                             accessor: "serial",
                                          },
                                       ],
                                    },
                                 ]}
                              />
                           </Col>
                           <Col sm={{ size: 2, offset: 0 }}>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <Row className={"mt-5 pt-5"}>
                                 <Col sm="12" md={{ size: 8, offset: 2 }}>
                                    <ButtonCustom
                                       className={
                                          "btn btn-block" +
                                          (this.state.selectedNotAssociated ==
                                          null
                                             ? " disable"
                                             : "")
                                       }
                                       name={"moveTableAssociated"}
                                       color={"success"}
                                       id={"moveTableAssociated"}
                                       title={
                                          <i className="fas fa-angle-double-left">
                                             {" "}
                                          </i>
                                       }
                                       disabled={
                                          this.state.selectedNotAssociated ==
                                          null
                                       }
                                       Click={this.moveTableAssociated}
                                    />
                                 </Col>
                              </Row>
                              <br />
                              <Row>
                                 <Col sm="12" md={{ size: 8, offset: 2 }}>
                                    <ButtonCustom
                                       className={
                                          "btn btn-block" +
                                          (this.state.selectedAssociated == null
                                             ? " disable"
                                             : "")
                                       }
                                       name={"moveTableNotAssociated"}
                                       color={"success"}
                                       id={"moveTableNotAssociated"}
                                       title={
                                          <i className="fas fa-angle-double-right">
                                             {" "}
                                          </i>
                                       }
                                       disabled={
                                          this.state.selectedAssociated == null
                                       }
                                       Click={this.moveTableNotAssociated}
                                    />
                                 </Col>
                              </Row>
                              <br />
                              <br />
                              <br />
                              <br />
                              <Row>
                                 <Col sm="12" md={{ size: 8, offset: 2 }}>
                                    <ButtonCustom
                                       name={"saveChanges"}
                                       id={"saveChanges"}
                                       title={"Guardar"}
                                       Click={this.saveChanges}
                                    />
                                 </Col>
                              </Row>
                           </Col>
                           <Col sm={{ size: 5, offset: 0 }}>
                              <TableLayout
                                 trProps={this.getDataNotAssociated}
                                 data={tableDataNotAssociated}
                                 className="-striped -highlight"
                                 defaultPageSize={this.state.pageSizeMA}
                                 columns={[
                                    {
                                       Header: "Medidores no asociados",
                                       columns: [
                                          {
                                             Header: "Medidor",
                                             accessor: "serial",
                                          },
                                       ],
                                    },
                                 ]}
                              />
                           </Col>
                        </Row>
                        <br />
                     </CardBody>
                  </Card>
               </TabPane>
               <TabPane tabId={"3"}>
                  <GatewayPerformance />
               </TabPane>
               <TabPane tabId={"4"}>
                  <GatewayPlc />
               </TabPane>
            </TabContent>
         </div>
      );
   }
}

export default GatewaysInstallation;
