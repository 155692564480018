export default {
    "translation": {

        "Error creating user":"Error creando usuario",

        //module Type
        "Plc": "PLC",
        "Rs485": "RS485",
        "Modbus": "ModBus",

        //performances
        "readingPerformance":"Desempeño lectura",
        "instrumentationPerformance":"Desempeño instrumentación ",
        "readingOClockPerformance":"Desempeño lecturas puntuales",

        //status Error
        "The specified gateway does not exists":"Gateway especificado no existe",

        // Petitions type
        "Timeout": "Tiempo agotado",
        "Completed": "Completado",
        "Cancelled": "Cancelado",
        "Pending": "Pendiente",
        "Processing": "Procesando",
        "WaitingResponse": "Esperando respuesta",
        "Failed": "Fallido",
        //Petitions Requests types
        "ActivateTasks": "Activar tareas",
        "AddChildToFather": "Agregar niño al padre",
        "AddNode": "Agregar nodo",
        "AddSerial": "Agregar serie",
        "AdjustDateTime": "Ajustar fecha y hora",
        "ArmingNet": "Red de armado",

        "AssignFather": "Asignar padre",
        "BindNode": "Nodo de enlace",
        "Boog": "Boog",
        "ChangeFrequencies": "Cambiar frecuencias",
        "ChildrenDrop": "ChildrenDrop",
        "ChildrenDropSa": "ChildrenDropSa",
        "CreateNet": "Crear red",
        "DeactivateTasks": "Desactivar tareas",
        "DeleteNode": "Eliminar nodo",
        "Demand": "Potencia",
        "DestroyPreviousReadings": "Destruid lecturas anteriores",
        "DestroyTable": "Destruir tabla",
        "Diagnostic": "Diagnóstico",
        "DropNet": "DropNet",
        "DropNetSa": "DropNetSa",
        "Events": "Eventos",
        "Instrumentation": "Instrumentación",
        "IsBusy": "Está ocupado",
        "Login": "Iniciar sesión",
        "MassiveChildrenDrop": "Caída masiva de niños",
        "Meters": "Medidores",
        "PreviousReadings": "Lecturas anteriores",
        "Readings": "Lecturas",
        "ReadPlcConfiguration": "Leer configuración de Plc",
        "Registers": "Registros",
        "Relay": "Relay",
        "RemoveSerial": "Eliminar serie",
        "RepeatCommand": "Repetir el comando",
        "RequestAllSerials": "Solicitar todas las series",
        "ResetDemand": "Restablecer demanda",
        "ResetMaster": "Restablecer maestro",
        "ResetMeter": "Restablecer medidor",
        "ResetModem": "Restablecer módem",
        "SerialList": "Lista de serie",
        "SetPlcConfiguration": "Establecer configuración de Plc",
        "SimId": "SimId",
        "Tariff": "Arancel",
        "UnbindNode": "Desvincular nodo",
        "Unix": "Unix",
        "Utility": "Utilidad",
        "Voltages": "Voltajes(V)",
        "Voltage": "Voltaje(V)",

        //Petitions response
        "Id": "Id",
        "CardSerial": "Tarjeta de serial",
        "Hash": "Hash",
        "ActiveEnergy": "Energía activa",
        "ActiveEnergyT1": "Energía activa T1",
        "ActiveEnergyT2": "Energía activa T2",
        "ActiveEnergyT3": "Energía activa T3",
        "ActiveEnergyT4": "Energía activa T4",
        "ReverseEnergy": "Energía inversa",
        "ReverseEnergyT1": "Energía inversa T1",
        "ReverseEnergyT2": "Energía inversa T2",
        "ReverseEnergyT3": "Energía inversa T3",
        "ReverseEnergyT4": "Energía inversa T4",
        "Power": "Power",
        "PowerReadingDate": "Fecha de lectura de energía",
        "PowerT1": "PowerT1",
        "PowerT2": "PowerT2",
        "PowerT3": "PowerT3",
        "PowerT4": "PowerT4",
        "PowerReadingDateT1": "Fecha de lectura de energía T1",
        "PowerReadingDateT2": "Fecha de lectura de energía T2",
        "PowerReadingDateT3": "Fecha de lectura de energía T3",
        "PowerReadingDateT4": "Fecha de lectura de energía T4",
        "ReactiveEnergy": "Energía reactiva",
        "ReactiveEnergyT1": "Energía reactiva T1",
        "ReactiveEnergyT2": "Energía reactivaT2",
        "ReactiveEnergyT3": "Energía reactiva T3",
        "ReactiveEnergyT4": "Energía reactiva T4",
        "Tries": "Intentos",
        "ReadingDate": "Fecha de lectura",
        "VoltageA":"Voltaje A (V)",
        "VoltageB":"Voltaje B (V)",
        "VoltageC":"Voltaje C (V)",
        "CurrentA":"Corriente A (A)",
        "CurrentB":"Corriente B (A)",
        "CurrentC":"Corriente C (A)",
        "PowerFactorA":"Factor de potencia A",
        "PowerFactorB":"Factor de potencia B",
        "PowerFactorC":"Factor de potencia C",
        "ModuleAlarm":"Alarma del modulo",


        "Serial": "Serial",
        "FatherCardSerial": "Tarjeta de padre serial",
        "Mode": "Modo",
        "ModemAlarm": "Alarma de módem",
        "MeterAlarm": "Alarma del medidor",
        "PowerFactors": "Factores de potencia",
        "PowerFactor": "Factor de potencia",
        "Currents": "Corrientes(A)",
        "Current": "Corriente(A)",
        "GatewaySnr": "Gateway Snr",
        "MeterSnr": "Medidor Snr",
        "IsGatewaySnrChannel": "Canal Gateway Snr",
        "IsMeterSnrChannel": "Meter Snr Channel",
        "LastCommunication": "Última comunicación",
        "MeterType": "Tipo de medidor",
        "TxGain": "Ganancia de Tx",
        "BusyPlc": "PLC Ocupado",

        "Command": "Comando",
        "UnixReading": "Lectura de Unix",
        "DateTime": "Fecha y hora",
        "Error": "Error",
        "StartedAt": "Iniciado en",
        "LastOperation": "Última operación",
        "Temperature": "Temperatura",
        "Flag": "Bandera",
        "GetReadings": "Obtener lecturas",
        "Firmware": "Firmware",
        "Active": "Activo",

        "PowerOff": "Apagado",
        "MeterClear": "Reseteo de medidor",
        "DemandClear": " Reseteo de demanda",
        "CoverOpen": "Cubierta abierta",
        "ResetModule": "Restablecer módulo",
        "Reset": "Reiniciar",

        "HighFrequencyAdd":"Alta frecuencia agregada",
        "HighFrequency":"Alta frecuencia",
        "LowFrequency":"Baja frecuencia",
        "FrameLength":"Frame Length",
        "TxGainN":"Tx Gain N",
        "TxGainR":"Tx Gain R",
        "MasterId":"Master Id",
        "ToModem":"To Modem",
        "ToPlc":"To Plc",
        "SsKey":"Ss Key",

        //alarm
        "CoverRemovalCountIncrement":"Incremento del recuento de eliminación de la cubierta",
        "DemandClearCountIncrement":"Demand Clea rCount Increment",
        "EventLogCleared":"Registro de eventos borrado",
        "GatewayIdentifierMismatch":"No coincide el identificador de puerta de enlace",
        "GatewayReportsMeterRegistration":"Registro de medidor de informes de puerta de enlace",
        "InvalidData":"Datos inválidos",
        "InvalidEventData":"Datos de evento no válidos",
        "InvalidInstrumentationData":"Datos de instrumentación no válidos",
        "InvalidJson":"Json inválido",
        "InvalidReadingData":"Datos de lectura no válidos",
        "KwChanged":"Kw cambiado",
        "KwDateChanged":"Kw Date Changed",
        "MalformedSerialCard":"Mal formado tarjeta de serie",
        "MeterCardChanged":"Tarjeta de medidor modificada",
        "MeterFatherChanged":"Metro padre cambiado",
        "MeterGatewayChanged":"Puerta de enlace del medidor modificada",
        "MeterModemAutoReset":"Restablecimiento automático del módem del medidor",
        "MeterModemDateTimeUpdated":"Medidor Módem Fecha Hora Actualizada",
        "MeterModemReset":"Restablecimiento del módem del medidor",
        "MeterModemSevereTemperatureIncrease":"Aumento de temperatura severa del módem del medidor",
        "MeterModemTemperatureIncrease":"Aumento de temperatura del módem del medidor",
        "MeterPlcModemIsNotCommunicating":"El módem Meter Plc no se está comunicando",
        "MeterSerialCommunicationError":"Error de comunicación serial del medidor",
        "MeterUnixError":"Error del medidor de Unix",
        "OutageCountIncrement":"Incremento del conteo de cortes",
        "ReadingDateIsFuture":"Fecha de lectura es el futuro",
        "ReadingIsLessThanPrevious":"Leer es menos que el anterior",
        "ReadingIsOlderThanAllowedLimit":"La lectura es mayor que el límite permitido",
        "ReadingIsTooHighThanPrevious":"La lectura es demasiado alta que la anterior",
        "ResetModuleCountIncrement":"Restablecer incremento de recuento de módulos",
        "ReverseEnergyFlow":"Flujo de energía inversa",
        "TierSumDoesNotMatchActualValue":"La suma de niveles no coincide con el valor real",
        "TimeChangedBySystem":"Tiempo cambiado por el sistema",
        "UnknownMeterDiscovered":"Medidor desconocido descubierto",
        "UnknownSimId":"ID de Sim desconocido",



    }
}
