import React from 'react';
import Select from "react-select";
import {Card, Row, Col, CardTitle, CardBody, Input, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import "react-table/react-table.css";
import apiClient from "../../api/apiClient";
import Swal from "sweetalert2";
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {LoadingComponent} from "../../components/custom/LoadingComponent";
import {ModalCustom} from "../../components/custom/ModalCustom";
import CustomDate from "../../components/custom/Date";
import {inputDateTime, alarm24HDateFrom, alarm24HDato, Time} from "../../constants/DateTime"
import {lengthPage} from "../../constants/lengthPage";

class Alarm extends React.Component {
    constructor(props) {
        super(props);
        this.severity=[
            {label:'Todos', value:''},
            {label:'Info', value:0},
            {label:'Low', value:1},
            {label:'Warning', value:2},
            {label:'Critical', value:3},
            {label:'Fatal', value:4},
        ];
        this.processed=[
            {label:'Todos', value:''},
            {label:'Si', value:true},
            {label:'No', value:false},
        ];
        this.state = {
            isOpen: false,
            activeTab: '1',
            objectTypes:[],
            gatewaysId:[],
            alarmsTable:[],
            alarmsTypes:[],
            category:[],
            objectGateway:'',
            objectMeter:'',
            severityValue:{label:'Todos', value:''},
            processedValue:{label:'Todos', value:''},
            dateFrom: alarm24HDateFrom(),
            dateTo: alarm24HDato(),
            objectTypeValue:{label: "Todos",value:''},
            selectedTypes:[],
            categoryTypes:[],
            parametersData:[],
            modalParameters: false,
            loading: false,
            page:0,
            pages:0,
            pageSize:20,
            pageSizeOptions:[10, 20, 50, 100],
            checked: false,
            checkReactTable:[],
            typesAlarms:[],
            toggleModalStatusCode:false,
            error:"",
            open:false
        };
    }
    toggle =(tab) => {
        this.setState({
            isOpen: !this.state.isOpen
        });

        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };
    toggleP =()=> {
        this.setState({
            modalParameters: !this.state.modalParameters,
        });
    };
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };


    componentDidMount =()=>{
        apiClient.alarms.getObjectTypes()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.name};
                });
                this.setState({objectTypes: items});
                let item = {value: '', label:'Todos' };
                this.state.objectTypes.splice(0,0,item);
                let itemAll = this.state.objectTypes.slice();
                this.setState({objectTypes: itemAll});

            }).catch(error=>{  this.setState({toggleModalStatusCode:true, error:error, loading: false})  });

        apiClient.gateways.getActiveGateways()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.alias}
                });
                this.setState({gatewaysId: items});
            }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.alarms.getTypes()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label:result.name};
                });
                this.setState({alarmsTypes: items});
                let item = {value: 0, label:'Todos' };
                this.state.alarmsTypes.splice(0,0,item);
                let itemAll = this.state.alarmsTypes.slice();
                this.setState({alarmsTypes: itemAll, typesAlarms:res.data});
            }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});

        apiClient.alarms.getCategories()
            .then(res => {
                let items = res.data.map(result => {
                    return {value: result.id, label: result.name};
                });
                this.setState({category: items});
                let item = {value: 0, label:'Todos' };
                this.state.category.splice(0,0,item);
                let itemAll = this.state.category.slice();
                this.setState({category: itemAll});
            }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});


    };

    getObjectTypes = (e) =>{
        this.setState({objectTypeValue:e,objectGateway:'', objectMeter:''  });
    };

    objectNameEnterSelect = (e) =>{
        this.setState({objectGateway:e.value });
    };

    getSeverity = (severity) =>{
        this.setState({severityValue:severity });
    };

    getProcessed = (processed) =>{
        this.setState({processedValue:processed });
    };

    objectNameEnterInput = (e) =>{
        this.setState({objectMeter: e.target.value});
    };

    onDateChange =(moment,value) =>{
        this.setState({[value]: inputDateTime(moment) });
    };

    getAlarmsTypes = (e) => {
        let selectedOption = [];
        if (e!==null){
        if(e.find(x => x.value === 0) !== undefined) {
            this.state.alarmsTypes.filter(x => x.value !== 0).map(x => {
                selectedOption.push(x);
                return true;
            });
        } else {
            selectedOption = e;
        }
        }
        this.setState({selectedTypes:selectedOption});
    };

    getCategory = (e) => {
        let selectedOption = [];
        if (e!==null){
        if(e.find(x => x.value === 0) !== undefined) {
            this.state.category.filter(x => x.value !== 0).map(x => {
                selectedOption.push(x);
                return true;
            });
        } else {
            selectedOption = e;
        }
        }
        this.setState({categoryTypes:selectedOption});
    };

    getReportReading =()=>{
        let ad = this.state, categories, severities, types = ad.selectedTypes.map(x=>{return x.value}), processedVal;
            categories = ad.categoryTypes.map(x=>{return x.value});
            severities = ad.severityValue.value;
            processedVal = ad.processedValue.value;

        this.setState({loading: true});
        apiClient.alarms.all({
            types: !types || (types.length === 1 && types[0] === '') ? [] : types,
            categories: !categories || (categories.length === 1 && categories[0] === '') ? [] : categories,
            objectName:ad.objectGateway===''? ad.objectMeter : ad.objectGateway ,
            objectType:ad.objectTypeValue.value,
            from: ad.dateFrom,
            to: ad.dateTo,
            severity: severities,
            acknowledged:processedVal,
            start:0,
            length: ad.pageSize,
        }).then(res => {
            if (res.data.length === 0){
                Swal.fire({type: 'info', title: 'No hay información', text: 'Debes seleccionar otros parametros'})
                    .then(() => {this.setState({loading: false})})
            }else {
               let checkReactTable = res.data.map(x=>{
                  return {id:x.id, statement:x.acknowledged}
              });
                    this.setState({
                        checkReactTable:checkReactTable,
                        alarmsTable:  res.data,
                        page:0,
                        pages: Math.ceil(res.recordsTotal/ad.pageSize),
                        pageSize: res.recordsTotal < 50 ? res.recordsTotal : 50 ,
                        pageSizeOptions: lengthPage(this.state.pageSizeOptions,res.recordsTotal),
                        loading: false
                    });
            }
        }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    processChange=(data)=> {
        const {checkReactTable} = this.state;
        let item = {id: data.id, statement: data.acknowledged};
        item.statement = data.acknowledged === true ? data.acknowledged : true;
        let newItem = [...checkReactTable];
        newItem.splice(checkReactTable.findIndex(x => x.id === item.id), 1, item);
        this.setState({checkReactTable: newItem});
        apiClient.alarms.acknowledge({
            alarms:[item.id]
        }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});
    };

    render() {
        const valueButton = this.state.objectTypeValue.value;
        let ButtonType;
        if (valueButton ===1) {
            ButtonType =
                <Col md={2}>
                    <label > Tipo origen </label>
                    <Select
                        name="gatewaysId"
                        id="gatewaysId"
                        onChange={this.objectNameEnterSelect}
                        placeholder="Gateway"
                        options={this.state.gatewaysId}
                    />
                </Col>
        } else if ( valueButton === 2 ){

            ButtonType =
                <Col md={2}>
                    <label > Tipo origen </label>
                    <Input type="text" name="meter" id="meter" placeholder="Medidor" onChange={this.objectNameEnterInput} />
                </Col>

        }else if (valueButton === 3){
            ButtonType =
                <Col md={2}>
                    <label > Tipo origen </label>
                    <Input disabled  name="system" id="system" placeholder="System"  onChange={this.objectNameEnterInput} />
                </Col>
        }
        let modalParameters = "";
        if (this.state.parametersData.params!==undefined){
            let data =  JSON.parse(this.state.parametersData.params);
            let td=[];
            let index =0;
            /* eslint-disable no-unused-vars */
            for (let key in data) {
                 index = index +1;
                if (data.hasOwnProperty(key)) {
                   td.push( <tr key={index}><td key={index*2}>{key}</td><td key={index*3}>{data[key]}</td></tr>);
                }
            }
            /* eslint-enable no-unused-vars */
            modalParameters = <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Valor</th>
                </tr>
                </thead>
                <tbody>
                {td}
                </tbody>

            </Table>;
        }
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <LoadingComponent loading={this.state.loading}/>
                <Modal isOpen={this.state.modalParameters} toggle={this.toggleP}>
                    <ModalHeader toggle={this.toggleP}>Detalles</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                {modalParameters}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={2}>
                                <ButtonCustom color={'success'} className={''} name={'Cerrar'} id={'Cerrar'} Click={this.toggleP} title={"Cerrar" } />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                        className="mdi mdi-hamburger mr-2"> </i>Alarmas</CardTitle>
                    <CardBody className="border-top">
                        <Row className={"justify-content-center"}>
                            <Col md={2}>
                                <label > Tipo </label>
                                <Select
                                    name="objectTypes"
                                    id="objectTypes"
                                    onChange={this.getObjectTypes}
                                    value={this.state.objectTypeValue}
                                    defaultValue={this.state.objectTypes}
                                    placeholder="Seleccionar Tipo"
                                    options={this.state.objectTypes}
                                />
                            </Col>
                            <Col md={2}>
                                <label > Severidad </label>
                                <Select
                                    name="severity"
                                    id="severity"
                                    onChange={this.getSeverity}
                                    value={this.state.severityValue}
                                    defaultValue={this.state.severityValue}
                                    options={this.severity}
                                />
                            </Col>
                                {ButtonType}
                            <Col md={2}>
                                <label > Desde </label>
                                    <CustomDate
                                        id="DateFrom"
                                        value={new Date(this.state.dateFrom)}
                                        onChange={(moment) => this.onDateChange(moment, 'dateFrom')}
                                    />
                            </Col>
                            <Col md={2}>
                                <label > Hasta </label>
                                <CustomDate
                                    id="DateTo"

                                    value={new Date(this.state.dateTo)}
                                    onChange={(moment)=> this.onDateChange(moment,'dateTo')}
                                />
                            </Col>
                            <Col md={2}>
                                <label > Procesado </label>
                                <Select
                                    name="processed"
                                    id="processed"
                                    onChange={this.getProcessed}
                                    value={this.state.processedValue}
                                    defaultValue={this.state.processedValue}
                                    options={this.processed}
                                />
                                {/*<Switch onChange={this.getCheckbox} checked={this.state.isCheck} />*/}
                            </Col>
                        </Row>
                            <Row>
                                <Col md={12}>
                                    <label > Tipo de alarma </label>
                                    <Select
                                        options={this.state.alarmsTypes }
                                        id={"itemDetail"}
                                        name={"itemDetail"}
                                        closeMenuOnSelect={true}
                                        isClearable
                                        isMulti
                                        placeholder={"Seleccionar tipo de alarma"}
                                        value={this.state.selectedTypes}
                                        onChange={this.getAlarmsTypes}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={12}>
                                    <label > Tipo de categoria</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        onChange={this.getCategory}
                                        value={this.state.categoryTypes}
                                        placeholder="Seleccionar categoria"
                                        options={this.state.category}
                                        closeMenuOnSelect={true}
                                        isClearable
                                        isMulti
                                    />
                                </Col>
                            </Row>
                            <br/>
                        <Row className={"justify-content-center"}>
                            <Col md={2}>
                                {/*<label> </label>*/}
                                <ButtonCustom
                                    name={'reportReading'}
                                    id={'reportReading'}
                                    title={'Generar'}
                                    Click={this.getReportReading}
                                />
                            </Col>
                        </Row>

                        <br/>
                        <TableLayout
                            data={this.state.alarmsTable}
                            pages={this.state.pages}
                            pageSize={this.state.pageSize}
                            pageSizeOptions={this.state.pageSizeOptions.sort((a,b)=>{ return a-b})}
                            manual={true}
                            filterable={false}
                            onFetchData={(state) => {
                                let st = this.state;
                                let types = st.selectedTypes.map(x=>{return x.value});
                                let categories = st.categoryTypes.map(x=>{return x.value});
                                    apiClient.alarms.all({
                                        types: !types || (types.length === 1 && types[0] === '') ? [] : types,
                                        categories:  categories,
                                        objectName:st.objectName,
                                        objectType:st.objectTypeValue,
                                        severity:  st.severityValue.value,
                                        from: st.dateFrom,
                                        to: st.dateTo,
                                        acknowledged:st.processedValue.value,
                                        start: (state.page * state.pageSize),
                                        draw: 0,
                                        length: state.pageSize,
                                        sorted:state.sorted,
                                        filtered:state.filtered,
                                        }).then(res => {
                                        let checkReactTable = res.data.map(x=>{return {id:x.id, statement:x.acknowledged}});
                                        this.setState({
                                                    checkReactTable:checkReactTable,
                                                    loading: false,
                                                    alarmsTable: res.data,
                                                    pageSize: state.pageSize,
                                                    pages:  Math.ceil(res.recordsTotal / state.pageSize),
                                                });
                                    }).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loading: false})});
                            }}
                            columns={[
                                {Header: "Tipo", accessor: "type", Cell: (props) => props.value.name},
                                {Header: "Categoria", accessor: "type.category.name",Cell: (props) =>  props.value},
                                {Header: "Actualizado en", accessor: "updatedAt", minWidth:175, Cell: (props)=> Time(props.value)},
                                {Header: "Fecha de ocurrencia", accessor: "createdAt", Cell: (props)=> Time(props.value)},
                                {Header: "Tipo de origen", accessor: "objectType",Cell: (props) => props.value.name},
                                {Header: "Nombre de origen", accessor: "objectName"},
                                {Header: "Procesado", accessor: "acknowledged", Cell: (props)=>
                                        <div className="text-center">
                                        <ButtonCustom
                                            name={'check'}
                                            id={'check'}
                                            color={ this.state.checkReactTable.find(x=>x.id === props.original.id)? this.state.checkReactTable.find(x=>x.id === props.original.id).statement ===false ? 'danger':'success':null }
                                            title={ this.state.checkReactTable.find(x=>x.id === props.original.id)? this.state.checkReactTable.find(x=>x.id === props.original.id).statement ===false ? <i style={{color: 'white', fontSize: '15px'}} className="fas fa-times"> </i>:<i style={{color: 'white', fontSize: '15px'}} className="fas fa-check"> </i>: null}
                                            size={'sm'}
                                            className={ '' }
                                            Click={() => {this.processChange(props.original)}}
                                        />
                                        </div>
                                },

                                {Header: "Cantidad", accessor: "count"},
                                {Header: "Severidad", accessor: "type.severity.name",Cell: (props) =>  props.value},
                                {Header: "Detalles", accessor: "id", Cell: (props) =>
                                        <div className="text-center">
                                                    <ButtonCustom
                                                        name={'viewParameters'}
                                                        id={'viewParameters'}
                                                        title={'Ver'}
                                                        size={'sm'}
                                                        disabled={props.original.params === null}
                                                        className={''}
                                                        Click={() => {
                                                            let parameters = this.state.alarmsTable.find(o => o.id === props.value);
                                                            this.setState({
                                                                modalParameters: !this.state.modalParameters,
                                                                parametersData: parameters
                                                            });
                                                        }}
                                                    />
                                        </div>
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Alarm;
