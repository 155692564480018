import React from "react";
import {
   Card,
   CardBody,
   Row,
   Col,
   Modal,
   ModalHeader,
   ModalBody,
   Button,
   ModalFooter,
   Table,
} from "reactstrap";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import apiClient from "../../api/apiClient";
import { makeCacheableRequest } from "../../api/apiUtils";
import { CustomSelect } from "../../components/custom/CustomSelect";
import "moment/locale/es-do";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
   customDateFrom30Days,
   customDateTo,
   alarm24HDateFrom,
   alarm24HDato,
   TimeDay,
   TimeHour,
   Time,
} from "../../constants/DateTime";
import { cacheTime } from "../../constants/cacheTime";
import {
   splitDateRangeInDays,
   splitDateRangeInHours,
} from "../../constants/splitDateRange";
import { includes, sortBy, flatMap } from "lodash/collection";
import * as moment from "moment";
import ButtonCustom from "../../components/custom/ButtonCustom";
import ModalDashBoard from "./ModalDashBoard";
import authService from "../../services/AuthService";
import GenericTable from "../../components/custom/GenericTable";
import { saveAsExcel } from "../../utilities/exportToExcel";
import {
   columns,
   columnWidthConfigs,
} from "../../constants/exportExcel/alarmDetail";

require("highcharts/modules/no-data-to-display.js")(Highcharts);

class Dashboard extends React.Component {
   constructor(props) {
      super(props);
      this.chartHours = React.createRef();
      this.chartDays = React.createRef();
      this.chartAlarms = React.createRef();
      this.CharModuleType = React.createRef();
      this.CharReadingOClockDay = React.createRef();
      this.CharReadingDay = React.createRef();
      this.CharInstrumentationDay = React.createRef();
      this.CharReadingOClockHour = React.createRef();
      this.CharReadingHour = React.createRef();
      this.CharInstrumentationHour = React.createRef();
      this.chartPie = React.createRef();
      this.colorProblemType = {
         Unknown: "#00b0ff",
         Other: "#311b92",
         PowerOff: "#00BFA5",
         CutOff: "#b23c17",
         Broken: "#d50000",
         Changed: "#880e4f",
         Tampered: "#f44336",
         Rain: "#aeea00",
         Noise: "#64dd17",
         NightNoise: "#00c853",
         DaylightNoise: "#e65100",
         Distance: "#ffd600",
         Communication: "#795548",
         ElectricalServiceFailure: "#3e2723",
         GatewayFailure: "#607d8b",
      };
      this.state = {
         chartHours: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: {
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#000",
                  opacity: 1,
               },
            },
            chart: { type: "spline", height: 350 },
            title: { text: "Desempeño global últimas 24 horas" },
            navigator: { adaptToUpdatedData: true, series: [] },
            rangeSelector: false,
            xAxis: {
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%H}" },
               minRange: 3600 * 1000,
            },
            // xAxis: { crosshair: true, categories: [],type: 'datetime', labels: {format: '{value:%H}',}, tickInterval: 3600 * 1000, },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Hora: {point.key}</p> <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: { column: { pointPadding: 3, borderWidth: 3 } },
            credits: false,
            series: [],
         },
         chartDays: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "line", height: 350 },
            title: { text: "Desempeño global últimos 30 días" },
            rangeSelector: false,
            navigator: { adaptToUpdatedData: true, series: [] },
            xAxis: {
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%d}" },
               minRange: 3600 * 1000 * 24,
            },
            // xAxis: {crosshair: true, categories: [],type: 'datetime', crosshairs:true, labels: {format: '{value:%d}',}, tickInterval: 24 * 3600 * 1000, },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Día: {point.key}</p>  <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
            credits: false,
            series: [],
         },
         chartAlarms: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "column", height: 350 },
            title: { text: "Resumen de alarma últimas 24 horas" },
            xAxis: { categories: [] },
            yAxis: {
               min: 0,
               title: { text: "Total de alarmas" },
               stackLabels: {
                  enabled: true,
                  style: {
                     fontWeight: "bold",
                     color:
                        (Highcharts.defaultOptions.title.style &&
                           Highcharts.defaultOptions.title.style.color) ||
                        "gray",
                  },
               },
            },
            tooltip: {
               headerFormat: "<b>{point.x}</b><br/>",
               pointFormat:
                  "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
            },
            plotOptions: {
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        let severity = [
                           { label: "Todos", value: "" },
                           { label: "Info", value: 0 },
                           { label: "Low", value: 1 },
                           { label: "Warning", value: 2 },
                           { label: "Critical", value: 3 },
                           { label: "Fatal", value: 4 },
                        ];

                        let categories = this.state.categories;
                        let category = categories.find((x) => {
                           if (e.point.series.name === "Comunications") {
                              return "Comunications";
                           } else if (
                              e.point.series.name === "DeviceConfiguracion"
                           ) {
                              return "DeviceConfiguracion";
                           } else {
                              return x.name === e.point.series.name;
                           }
                        });
                        let severityValue = severity.find(
                           (x) => x.label === e.point.category
                        );
                        if (e.point.y > 0) {
                           this.getDataAlarm(severityValue.value, category.id);
                        }
                     },
                  },
               },
               column: { stacking: "column", dataLabels: { enabled: false } },
            },
            series: [],
            credits: false,
         },
         CharModuleType: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "bar", height: 350 },
            title: { text: "Resumen de comunicación" },
            xAxis: [
               { categories: [], reversed: false, labels: { step: 1 } },
               {
                  opposite: true,
                  reversed: false,
                  linkedTo: 0,
                  labels: { step: 1 },
               },
            ],
            yAxis: {
               min: -100,
               max: 100,
               title: { text: null },
               labels: {
                  formatter: function() {
                     return Math.abs(this.value) + "%";
                  },
               },
            },
            plotOptions: {
               series: {
                  stacking: "normal",
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        let data = this.state.moduleType.find(
                           (item) =>
                              item.label.toUpperCase() ===
                              e.point.category.toUpperCase()
                        );

                        let communication = e.point.series.name;
                        let boolean = communication !== "Incomunicados";
                        this.getDataCommunicationModal(boolean, data.value);
                     },
                  },
               },
            },
            tooltip: {
               formatter: function() {
                  let category = "";
                  if (this.point.category === "PLC") {
                     category = "Plc";
                  }
                  if (this.point.category === "RS485") {
                     category = "Rs485";
                  }
                  if (this.point.category === "ModBus") {
                     category = "Modbus";
                  }
                  if (this.point.category === "PLC3") {
                     category = "Plc3";
                  }
                  //   console.log({
                  //      Connet: this.point.series.userOptions.total[category],
                  //   });
                  return (
                     "<b>" +
                     this.series.name +
                     ",Módulo " +
                     this.point.category +
                     "</b><br/>" +
                     "Cantidad:" +
                     Highcharts.numberFormat(
                        Math.abs(
                           (this.point.y / 100) *
                              this.point.series.userOptions.total[category]
                        ),
                        0
                     )
                  );
               },
            },
            series: [],
            credits: false,
         },
         CharReadingOClockDay: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "line", height: 250 },
            title: { text: "Diferencial lecturas puntuales últimos 30 días" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%d}" },
               tickInterval: 24 * 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Día: {point.key}</p>  <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b> <br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 0.2, borderWidth: 0 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(1, "d")
                                       .format("YYYY/MM/DD"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeDay(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         CharReadingDay: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "line", height: 250 },
            title: { text: "Diferencial lectura últimos 30 días" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%d}" },
               tickInterval: 24 * 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Día: {point.key}</p>  <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 0.2, borderWidth: 0 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(1, "d")
                                       .format("YYYY/MM/DD"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeDay(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         CharInstrumentationDay: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "line", height: 250 },
            title: { text: "Diferencial instrumentación últimos 30 días" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%d}" },
               tickInterval: 24 * 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Día: {point.key}</p>  <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 0.2, borderWidth: 0 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(1, "d")
                                       .format("YYYY/MM/DD"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeDay(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         CharReadingOClockHour: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "spline", height: 250 },
            title: { text: "Diferencial lecturas puntuales últimas 24 horas" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%H}" },
               tickInterval: 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Hora: {point.key}</p> <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 3, borderWidth: 3 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(4, "h")
                                       .format("YYYY/MM/DD HH:00"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeHour(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         CharReadingHour: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "spline", height: 250 },
            title: { text: "Diferencial lectura últimas 24 horas" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%H}" },
               tickInterval: 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Hora: {point.key}</p> <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 3, borderWidth: 3 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(4, "h")
                                       .format("YYYY/MM/DD HH:00"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeHour(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         CharInstrumentationHour: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: { type: "spline", height: 250 },
            title: { text: "Diferencial instrumentación últimas 24 horas" },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: true,
            },
            xAxis: {
               crosshair: true,
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%H}" },
               tickInterval: 3600 * 1000,
            },
            yAxis: {
               opposite: true,
               labels: { align: "left", x: 0, y: -2 },
               min: 0,
               max: 100,
               title: { text: false },
            },
            tooltip: {
               split: false,
               shared: true,
               crosshairs: true,
               valueSuffix: "%",
               headerFormat: "<p>Hora: {point.key}</p> <br>",
               pointFormat:
                  '<p style="color:{series.color}">{series.name}</p>: <b>{point.y}</b><br/>',
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            plotOptions: {
               column: { pointPadding: 3, borderWidth: 3 },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        if (e.point.y > 0) {
                           apiClient.performance
                              .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                                 {
                                    from: moment(e.point.category)
                                       .add(4, "h")
                                       .format("YYYY/MM/DD HH:00"),
                                    performanceType:
                                       e.point.series.userOptions
                                          .performanceType,
                                    problemType:
                                       e.point.series.userOptions.problemType,
                                    groupId: this.state.selectedGroup.value,
                                    moduleType: this.state.selectedModuleType
                                       .value,
                                    timeInterval:
                                       e.point.series.userOptions.timeInterval,
                                 }
                              )
                              .then((response) => {
                                 let data = response.data.map((x) => {
                                    return {
                                       dateTime: TimeHour(x.dateTime),
                                       percentage: x.percentage,
                                       performanceType: x.performanceType.name,
                                       problemType: x.problemType.name,
                                       serial: x.serial,
                                    };
                                 });
                                 this.setState({
                                    modalData: data,
                                    modal: true,
                                 });
                              })
                              .catch((error) =>
                                 this.setState({
                                    toggleModalStatusCode: true,
                                    error: error,
                                 })
                              );
                        }
                     },
                  },
               },
            },
            credits: false,
            series: [],
         },
         chartPie: {
            loading: {
               hideDuration: 100,
               showDuration: 100,
               labelStyle: {
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
               },
               style: { opacity: 1, backgroundColor: "white" },
            },
            lang: {
               noData: "No hay información para mostrar",
               loading: "Cargando...",
            },
            noData: {
               style: { fontWeight: "bold", fontSize: "14px", color: "#000" },
            },
            chart: {
               plotBackgroundColor: null,
               plotBorderWidth: null,
               plotShadow: false,
               type: "pie",
               height: 350,
            },
            title: { text: "Problemas de desempeño últimas 24 horas" },
            tooltip: {
               pointFormat: "{series.name}: <b>{point.percentage:.3f}%</b>",
            },
            plotOptions: {
               pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                     enabled: true,
                     format: "<b>{point.name}</b>: {point.percentage:.3f} %",
                     connectorColor: "silver",
                  },
               },
               series: {
                  cursor: "pointer",
                  events: {
                     click: (e) => {
                        apiClient.performance
                           .getMetersWithPerformancesDifferencesByProblemAndModuleType(
                              {
                                 from: moment(e.point.category).format(
                                    "YYYY/MM/DD HH:00"
                                 ),
                                 performanceType:
                                    e.point.options.performanceType,
                                 problemType: e.point.options.problemType,
                                 groupId: this.state.selectedGroup.value,
                                 moduleType: this.state.selectedModuleType
                                    .value,
                                 timeInterval: e.point.options.timeInterval,
                              }
                           )
                           .then((response) => {
                              let data = response.data.map((x) => {
                                 return {
                                    dateTime: TimeHour(x.dateTime),
                                    percentage: x.percentage,
                                    performanceType: x.performanceType.name,
                                    problemType: x.problemType.name,
                                    serial: x.serial,
                                 };
                              });
                              this.setState({ modalData: data, modal: true });
                           })
                           .catch((error) =>
                              this.setState({
                                 toggleModalStatusCode: true,
                                 error: error,
                              })
                           );
                     },
                  },
               },
            },
            credits: false,
            caption: {
               margin: 15,
               style: { color: "#000" },
               useHTML: true,
               text:
                  "<b>Estos porcientos son en base a la diferencia de desempeño<b>",
            },
            series: [],
         },
         allDays: [],
         group: [],
         selectedGroup: { value: 0, label: "Todos" },
         moduleType: [],
         selectedModuleType: { value: 0, label: "Todos" },
         loadingDays: false,
         loadingHours: false,
         loadingAlarms: false,
         loadingModuleType: false,
         loadingDifferencesDay: false,
         loadingDifferencesHour: false,
         loadingPie: false,
         valueD: 0,
         valueH: 0,
         valueDD: 0,
         valueDH: 0,
         valueDP: 0,
         categories: [],
         modalData: [],
         modal: false,
         modalCommunication: false,
         problemTypes: [],
         showTwoChart: false,
         toggleModalStatusCode: false,
         error: "",
         dataCommunicationModal: [],
         dataCommunicationHeaders: [],
         loadingDataComunication: false,
         dataCommunicationPages: 0,
         moduleCommunicationType: "",
         dataIsCommunicating: "",
         institution: [],
         institutionOption: [],
         permission: [],
         dataAlarmModal: [],
         modalAlarm: false,
         modalParameters: false,
         checkReactTable: [],
         parametersData: [],
      };
   }
   toggle = () => {
      this.setState({ modal: !this.state.modal });
   };
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };

   componentDidMount() {
      this.chartHours.current.chart.showLoading();
      this.chartDays.current.chart.showLoading();
      this.chartAlarms.current.chart.showLoading();
      this.CharModuleType.current.chart.showLoading();
      this.CharReadingOClockDay.current.chart.showLoading();
      this.CharReadingDay.current.chart.showLoading();
      this.CharInstrumentationDay.current.chart.showLoading();
      this.CharReadingOClockHour.current.chart.showLoading();
      this.CharReadingHour.current.chart.showLoading();
      this.CharInstrumentationHour.current.chart.showLoading();
      this.chartPie.current.chart.showLoading();
      this.institution();
      this.getCategories();
      this.getGrout();
      this.getMeterProblemsTypes();
      this.getModuleType();

      this.summaryBySeverityAndCategoryAndGroup();

      this.communicationSummaryByGroup();
      this.charPie();
      this.globalPerformanceByDay();
      this.globalPerformanceByHour();

      this.summaryBySeverityAndCategoryAndGroup();
   }

   institution = () => {
      authService
         .userHasPermissions("ManageAllInstitutions")
         .then((response) => {
            if (response === true) {
               apiClient.institutions
                  .all()
                  .then((res) => {
                     let items = res.data.map((result) => {
                        return { value: result.id, label: result.name };
                     });
                     items.splice(0, 0, { value: "", label: "Todos" });
                     this.setState({
                        institution: res.data,
                        institutionOption: items,
                        permission: response,
                     });
                  })
                  .catch((error) => {
                     this.setState({
                        toggleModalStatusCode: true,
                        error: error,
                        loading: false,
                     });
                  });
            }
         });
   };

   getGrout = () => {
      apiClient.gateways
         .getGatewayGroups()
         .then((response) => {
            let items = response.data.map((result) => {
               return {
                  value: result.id,
                  label: result.name,
               };
            });
            items.splice(0, 0, { value: 0, label: "Todos" });
            this.setState({ group: items });
         })
         .catch((error) =>
            this.setState({ toggleModalStatusCode: true, error: error })
         );
   };

   getCategories = () => {
      let categories = [];
      apiClient.alarms
         .getCategories()
         .then((res) => {
            res.data.map((result) => {
               categories.push({ id: result.id, name: result.name });
               return true;
            });
            this.setState({ categories: categories });
         })
         .catch((error) =>
            this.setState({ toggleModalStatusCode: true, error: error })
         );
   };

   getModuleType = () => {
      apiClient.gateways
         .getModuleCommunicationTypes()
         .then((response) => {
            const capitalize = (s) => {
               if (typeof s !== "string") return "";
               return s.charAt(0).toUpperCase() + s.slice(1);
            };

            let items = response.data.map((result) => {
               return { value: result.id, label: capitalize(result.name) };
            });

            items.splice(
               items.findIndex((x) => x.label === "Unidentified"),
               1
            );
            items.splice(0, 0, { value: 0, label: "Todos" });

            this.setState({ moduleType: items });
         })
         .catch((error) =>
            this.setState({ toggleModalStatusCode: true, error: error })
         );
   };

   getMeterProblemsTypes = () => {
      apiClient.meters
         .getMeterProblemsTypes()
         .then((response) => {
            this.setState({ problemTypes: response.data });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   typeMethod = (e, type) => {
      this.setState({
         loadingDays: true,
         loadingHours: true,
         loadingDifferencesDay: true,
         loadingDifferencesHour: true,
         loadingPie: true,
      });
      let group, ModuleType;
      if (type === "group") {
         this.setState({ selectedGroup: e });
         group = e;
      } else if (type === "moduleType") {
         this.setState({ selectedModuleType: e });
         ModuleType = e;
      }

      this.communicationSummaryByGroup(group);
      this.summaryBySeverityAndCategoryAndGroup(group);
      this.globalPerformanceByDay(group, ModuleType);
      this.globalPerformanceByHour(group, ModuleType);
      this.performanceDifferencesByHour(group, ModuleType);
      this.performanceDifferencesByDay(group, ModuleType);
      this.charPie(group, ModuleType);
   };

   summaryBySeverityAndCategoryAndGroup = (group) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         from: alarm24HDateFrom(),
         to: alarm24HDato(),
      };
      apiClient.alarms
         .summaryBySeverityAndCategoryAndGroup(parameters)
         .then((response) => {
            if (response.data.length > 0) {
               const severities = ["Fatal", "Critical", "Warning", "Low"];
               const chartOptions = { categories: severities };
               const summary = {};
               response.data.forEach((alarm) => {
                  if (typeof summary[alarm.category] == "undefined") {
                     summary[alarm.category] = {
                        name: alarm.category,
                        data: [],
                        other: "",
                     };
                     severities.forEach((severity) => {
                        summary[alarm.category].data.push(0);
                     });
                  }
                  summary[alarm.category].data[
                     severities.indexOf(alarm.severity.name)
                  ] = alarm.quantity;
               });
               chartOptions["series"] = Object.keys(summary).reduce(
                  (map, key) => {
                     map.push(summary[key]);
                     return map;
                  },
                  []
               );
               this.setState({
                  chartAlarms: {
                     series: chartOptions.series,
                     xAxis: { categories: chartOptions.categories },
                  },
               });
               this.chartAlarms.current.chart.hideLoading();
            } else {
               this.setState({ chartAlarms: { series: [] } });
               this.chartAlarms.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.chartAlarms.current.chart.hideLoading();
         });
   };

   communicationSummaryByGroup = (group) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
      };
      apiClient.gateways
         .communicationSummaryByGroup(parameters)
         .then((response) => {
            if (response.data.length > 0) {
               const chartData = {};
               let Totalquantity = { Modbus: 0, Plc: 0, Plc3: 0, Rs485: 0 };
               this.state.moduleType.map((obj) => {
                  let name = obj["label"];
                  if (obj["label"] !== "Todos") {
                     Totalquantity[name] = 0;
                  }
                  return obj;
               });
               response.data.forEach((x) => {
                  if (x.moduleCommunicationType.name) {
                     Totalquantity[x.moduleCommunicationType.name] +=
                        x.quantity;
                  }
               });
               response.data.forEach((x) => {
                  if (
                     typeof chartData[x.moduleCommunicationType.name] ===
                     "undefined"
                  )
                     chartData[x.moduleCommunicationType.name] = {
                        quantityIn: 0,
                        quantityC: 0,
                     };
                  if (x.communicationStatus === "InCommunicating") {
                     chartData[x.moduleCommunicationType.name]["quantityIn"] =
                        ((x.quantity * -1) /
                           Totalquantity[x.moduleCommunicationType.name]) *
                        100;
                  } else {
                     chartData[x.moduleCommunicationType.name]["quantityC"] =
                        (x.quantity /
                           Totalquantity[x.moduleCommunicationType.name]) *
                        100;
                  }
               });
               const categories = [];
               const series = [
                  {
                     name: "Incomunicados",
                     data: [],
                     color: "#FF2015",
                     total: Totalquantity,
                  },
                  {
                     name: "Comunicados",
                     data: [],
                     color: "#77DD77",
                     total: Totalquantity,
                  },
               ];
               Object.keys(chartData).forEach((key) => {
                  categories.push(key);
                  series[0]["data"].push(chartData[key]["quantityIn"]);
                  series[1]["data"].push(chartData[key]["quantityC"]);
               });

               this.setState({
                  loadingModuleType: false,
                  CharModuleType: {
                     xAxis: [
                        {
                           categories: categories.map((x) => {
                              if (x === "Plc") {
                                 return "PLC";
                              }
                              if (x === "Rs485") {
                                 return "RS485";
                              }
                              if (x === "Modbus") {
                                 return "ModBus";
                              }
                              if (x === "Plc3") {
                                 return "PLC3";
                              }
                              return x;
                           }),
                        },
                     ],
                     series: series,
                  },
               });
               this.CharModuleType.current.chart.hideLoading();
            } else {
               this.setState({
                  loadingModuleType: false,
                  CharModuleType: {
                     xAxis: [{ categories: [] }],
                     series: [],
                  },
               });
               this.CharModuleType.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.CharModuleType.current.chart.hideLoading();
         });
   };

   performanceDifferencesByDay = (group, ModuleType) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         moduleType:
            ModuleType === undefined
               ? this.state.selectedModuleType.value
               : ModuleType.value,
         timeInterval: 2,
         from: customDateFrom30Days(),
         to: customDateTo(),
      };
      makeCacheableRequest(
         apiClient.performance
            .getGlobalPerformanceDifferencesByProblemsAndModuleType,
         parameters,
         cacheTime() * 60,
         (response) => {
            return response.data && response.data.length;
         }
      )
         .then((response) => {
            if (response.data.length === 0) {
               this.setState({
                  CharReadingDay: { series: [] },
                  CharReadingOClockDay: { series: [] },
                  CharInstrumentationDay: { series: [] },
               });
               this.CharReadingOClockDay.current.chart.hideLoading();
               this.CharReadingDay.current.chart.hideLoading();
               this.CharInstrumentationDay.current.chart.hideLoading();
            } else {
               let performanceType = [],
                  problemType = [];
               let Reading = [],
                  seriesReading = [],
                  readings = [];
               let ReadingOClock = [],
                  seriesReadingOClock = [],
                  readingOClocks = [];
               let Instrumentation = [],
                  seriesInstrumentation = [],
                  instrumentationV = [];
               let date30Day = splitDateRangeInDays(
                  new Date(customDateFrom30Days()),
                  new Date(customDateTo()),
                  1
               );
               response.data.map((x) => {
                  let performance = (performanceType[x.performanceType.name] =
                     performanceType[x.performanceType.name] || []);
                  performance.push(x.performanceType.id);

                  let problem = (problemType[x.problemType.name] =
                     problemType[x.problemType.name] || []);
                  problem.push(x.problemType.id);
                  /*eslint-disable no-self-compare */
                  if (x.performanceType.name === "Reading") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        Reading.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  if (x.performanceType.name === "ReadingOClock") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        ReadingOClock.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  if (x.performanceType.name === "Instrumentation") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        Instrumentation.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  return true;
                  /*eslint-disable no-self-compare */
               });

               Reading.map((item) => {
                  let x = (readings[item.name] = readings[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               ReadingOClock.map((item) => {
                  let x = (readingOClocks[item.name] =
                     readingOClocks[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               Instrumentation.map((item) => {
                  let x = (instrumentationV[item.name] =
                     instrumentationV[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               /* eslint-disable no-unused-vars */
               for (let [key, value] of Object.entries(readings)) {
                  seriesReading.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["Reading"][0],
                     problemType: problemType[key][0],
                     timeInterval: 2,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               for (let [key, value] of Object.entries(readingOClocks)) {
                  seriesReadingOClock.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["ReadingOClock"][0],
                     problemType: problemType[key][0],
                     timeInterval: 2,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               for (let [key, value] of Object.entries(instrumentationV)) {
                  seriesInstrumentation.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["Instrumentation"][0],
                     problemType: problemType[key][0],
                     timeInterval: 2,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               /* eslint-disable no-unused-vars */

               date30Day.forEach((date) => {
                  seriesReading.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     if (!includes(innerFlat, date)) {
                        obj.data.push([date, 0]);
                     }
                  });
                  seriesReadingOClock.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     if (!includes(innerFlat, date)) {
                        obj.data.push([date, 0]);
                     }
                  });
                  seriesInstrumentation.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     if (!includes(innerFlat, date)) {
                        obj.data.push([date, 0]);
                     }
                  });
               });

               seriesReading.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });
               seriesReadingOClock.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });
               seriesInstrumentation.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });

               this.setState({
                  loadingDifferencesDay: false,
                  CharReadingDay: { series: seriesReading },
                  CharReadingOClockDay: { series: seriesReadingOClock },
                  CharInstrumentationDay: { series: seriesInstrumentation },
               });
               this.CharReadingOClockDay.current.chart.hideLoading();
               this.CharReadingDay.current.chart.hideLoading();
               this.CharInstrumentationDay.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.CharReadingOClockDay.current.chart.hideLoading();
            this.CharReadingDay.current.chart.hideLoading();
            this.CharInstrumentationDay.current.chart.hideLoading();
         });
   };

   performanceDifferencesByHour = (group, ModuleType) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         moduleType:
            ModuleType === undefined
               ? this.state.selectedModuleType.value
               : ModuleType.value,
         timeInterval: 1,
         from: alarm24HDateFrom(),
         to: alarm24HDato(),
      };
      makeCacheableRequest(
         apiClient.performance
            .getGlobalPerformanceDifferencesByProblemsAndModuleType,
         parameters,
         cacheTime() * 60,
         (response) => {
            return response.data && response.data.length;
         }
      )
         .then((response) => {
            if (response.data.length === 0) {
               this.setState({
                  CharReadingHour: { series: [] },
                  CharReadingOClockHour: { series: [] },
                  CharInstrumentationHour: { series: [] },
               });
               this.CharReadingOClockHour.current.chart.hideLoading();
               this.CharReadingHour.current.chart.hideLoading();
               this.CharInstrumentationHour.current.chart.hideLoading();
            } else {
               let performanceType = [],
                  problemType = [];
               let Reading = [],
                  seriesReading = [],
                  readings = [];
               let ReadingOClock = [],
                  seriesReadingOClock = [],
                  readingOClocks = [];
               let Instrumentation = [],
                  seriesInstrumentation = [],
                  instrumentationV = [];
               let date24Hour = splitDateRangeInHours(
                  new Date(alarm24HDateFrom()),
                  new Date(alarm24HDato()),
                  1
               );
               response.data.map((x) => {
                  let performance = (performanceType[x.performanceType.name] =
                     performanceType[x.performanceType.name] || []);
                  performance.push(x.performanceType.id);

                  let problem = (problemType[x.problemType.name] =
                     problemType[x.problemType.name] || []);
                  problem.push(x.problemType.id);

                  /*eslint-disable no-self-compare */
                  if (x.performanceType.name === "Reading") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        Reading.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  if (x.performanceType.name === "ReadingOClock") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        ReadingOClock.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  if (x.performanceType.name === "Instrumentation") {
                     if (x.problemType.name === x.problemType.name) {
                        const datetime = new Date(x.dateTime);
                        const dateFormat = Date.UTC(
                           datetime.getFullYear(),
                           datetime.getMonth(),
                           datetime.getDate(),
                           datetime.getHours(),
                           datetime.getMinutes(),
                           0
                        );
                        Instrumentation.push({
                           name: x.problemType.name,
                           data: [dateFormat, Number(x.percentage.toFixed(4))],
                        });
                     }
                  }
                  return true;
                  /*eslint-disable no-self-compare */
               });

               Reading.map((item) => {
                  let x = (readings[item.name] = readings[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               ReadingOClock.map((item) => {
                  let x = (readingOClocks[item.name] =
                     readingOClocks[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               Instrumentation.map((item) => {
                  let x = (instrumentationV[item.name] =
                     instrumentationV[item.name] || []);
                  x.push(item.data);
                  return true;
               });
               /* eslint-disable no-unused-vars */
               for (let [key, value] of Object.entries(readings)) {
                  seriesReading.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["Reading"][0],
                     problemType: problemType[key][0],
                     timeInterval: 1,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               for (let [key, value] of Object.entries(readingOClocks)) {
                  seriesReadingOClock.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["ReadingOClock"][0],
                     problemType: problemType[key][0],
                     timeInterval: 1,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               for (let [key, value] of Object.entries(instrumentationV)) {
                  seriesInstrumentation.push({
                     name: key,
                     data: value,
                     performanceType: performanceType["Instrumentation"][0],
                     problemType: problemType[key][0],
                     timeInterval: 1,
                     marker: { enabled: true, radius: 4 },
                  });
               }
               /* eslint-disable no-unused-vars */

               date24Hour.forEach((date) => {
                  seriesReading.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     // if(!includes(innerFlat, date)){
                     //     obj.data.push([date, 0]);
                     // }
                  });
                  seriesReadingOClock.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     // if(!includes(innerFlat, date)){
                     //     obj.data.push([date, 0]);
                     // }
                  });
                  seriesInstrumentation.forEach((obj) => {
                     let innerFlat = flatMap(obj.data, (arr) => {
                        return arr[0];
                     });

                     // if(!includes(innerFlat, date)){
                     //     obj.data.push([date, 0]);
                     // }
                  });
               });

               seriesReading.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });
               seriesReadingOClock.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });
               seriesInstrumentation.forEach((obj) => {
                  obj.data = sortBy(obj.data, (arr) => {
                     return arr[0];
                  });
               });

               this.setState({
                  loadingDifferencesHour: false,
                  CharReadingHour: { series: seriesReading },
                  CharReadingOClockHour: { series: seriesReadingOClock },
                  CharInstrumentationHour: { series: seriesInstrumentation },
               });
               this.CharReadingOClockHour.current.chart.hideLoading();
               this.CharReadingHour.current.chart.hideLoading();
               this.CharInstrumentationHour.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.CharReadingOClockHour.current.chart.hideLoading();
            this.CharReadingHour.current.chart.hideLoading();
            this.CharInstrumentationHour.current.chart.hideLoading();
         });
   };

   globalPerformanceByDay = (group, ModuleType) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         moduleType:
            ModuleType === undefined
               ? this.state.selectedModuleType.value
               : ModuleType.value,
         timeInterval: 2,
         from: customDateFrom30Days(),
         to: customDateTo(),
      };
      makeCacheableRequest(
         apiClient.performance.getGlobalPerformanceByModuleType,
         parameters,
         cacheTime() * 60,
         (response) => {
            return response.data && response.data.length;
         }
      )
         .then((response) => {
            if (response.data.length === 0) {
               this.setState({
                  chartDays: {
                     navigator: { series: [] },
                     series: [],
                  },
               });
               this.chartDays.current.chart.hideLoading();
            } else {
               let reading = [],
                  instrumentation = [],
                  readingOClock = [];
               response.data.forEach((data) => {
                  const datetime = new Date(data.dateTime);
                  const dateFormat = Date.UTC(
                     datetime.getFullYear(),
                     datetime.getMonth(),
                     datetime.getDate(),
                     datetime.getHours(),
                     datetime.getMinutes(),
                     0
                  );

                  reading.push([
                     dateFormat,
                     (data.readingPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
                  instrumentation.push([
                     dateFormat,
                     (data.instrumentationPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
                  readingOClock.push([
                     dateFormat,
                     (data.readingOClockPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
               });

               let series = [
                  {
                     name: "Lecturas puntuales",
                     data: readingOClock,
                     color: "#77DD77",
                     marker: { enabled: true, radius: 4 },
                  },
                  {
                     name: "Lectura",
                     data: reading,
                     color: "#2196f3",
                     marker: { enabled: true, radius: 4 },
                  },
                  {
                     name: "Instrumentación",
                     data: instrumentation,
                     color: "#FFB347",
                     marker: { enabled: true, radius: 4 },
                  },
               ];

               this.setState({
                  loadingDays: false,
                  chartDays: {
                     navigator: { series: series },
                     valueD: 0,
                     series: series,
                  },
               });
               this.chartDays.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.chartDays.current.chart.hideLoading();
            this.setState({ toggleModalStatusCode: true, error: error });
         });
   };

   globalPerformanceByHour = (group, ModuleType) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         moduleType:
            ModuleType === undefined
               ? this.state.selectedModuleType.value
               : ModuleType.value,
         timeInterval: 1,
         from: alarm24HDateFrom(),
         to: alarm24HDato(),
      };
      makeCacheableRequest(
         apiClient.performance.getGlobalPerformanceByModuleType,
         parameters,
         cacheTime() * 60,
         (response) => {
            return response.data && response.data.length;
         }
      )
         .then((response) => {
            let reading = [],
               instrumentation = [],
               readingOClock = [];
            if (response.data.length === 0) {
               this.setState({
                  chartHours: {
                     navigator: { series: [] },
                     series: [],
                  },
               });
               this.chartHours.current.chart.hideLoading();
            } else {
               response.data.forEach((data) => {
                  const datetime = new Date(data.dateTime);
                  const dateFormat = Date.UTC(
                     datetime.getFullYear(),
                     datetime.getMonth(),
                     datetime.getDate(),
                     datetime.getHours(),
                     datetime.getMinutes(),
                     0
                  );

                  reading.push([
                     dateFormat,
                     (data.readingPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
                  instrumentation.push([
                     dateFormat,
                     (data.instrumentationPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
                  readingOClock.push([
                     dateFormat,
                     (data.readingOClockPerformance * 100)
                        .toString()
                        .substring(0, 5) * 1,
                  ]);
               });
               let series = [
                  {
                     name: "Lecturas puntuales",
                     data: readingOClock,
                     color: "#77DD77",
                     marker: { enabled: true, radius: 4 },
                  },
                  {
                     name: "Lectura",
                     data: reading,
                     color: "#2196f3",
                     marker: { enabled: true, radius: 4 },
                  },
                  {
                     name: "Instrumentación",
                     data: instrumentation,
                     color: "#FFB347",
                     marker: { enabled: true, radius: 4 },
                  },
               ];

               this.setState({
                  loadingHours: false,
                  valueH: 0,
                  chartHours: {
                     navigator: { series: series },
                     series: series,
                  },
               });
               this.chartHours.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.chartHours.current.chart.hideLoading();
         });
   };

   charPie = (group, ModuleType) => {
      let parameters = {
         groupId:
            group === undefined ? this.state.selectedGroup.value : group.value,
         moduleType:
            ModuleType === undefined
               ? this.state.selectedModuleType.value
               : ModuleType.value,
         timeInterval: 3,
         from: alarm24HDateFrom(),
         to: alarm24HDato(),
      };

      makeCacheableRequest(
         apiClient.performance
            .getGlobalPerformanceDifferencesByProblemsAndModuleType,
         parameters,
         cacheTime() * 60,
         (response) => {
            return response.data && response.data.length;
         }
      )
         .then((response) => {
            if (response.data.length === 0) {
               this.setState({
                  chartPie: {
                     series: [],
                  },
               });
               this.chartPie.current.chart.hideLoading();
            } else {
               let data = [];
               response.data.map((x) => {
                  if (x.performanceType.name === "ReadingOClock") {
                     data.push({
                        name: x.problemType.name,
                        y: x.percentage,
                        timeInterval: 3,
                        performanceType: x.performanceType.id,
                        problemType: x.problemType.id,
                     });
                  }
                  return true;
               });
               this.setState({
                  loadingPie: false,
                  chartPie: {
                     series: [{ name: "Porcentaje", data: data }],
                  },
               });
               this.chartPie.current.chart.hideLoading();
            }
         })
         .catch((error) => {
            this.setState({ toggleModalStatusCode: true, error: error });
            this.chartPie.current.chart.hideLoading();
         });
   };

   showTwoChart = () => {
      this.setState({ showTwoChart: !this.state.showTwoChart });
      this.performanceDifferencesByHour();
      this.performanceDifferencesByDay();
   };

   getDataCommunicationModal = (IsCommunicating, ModuleCommunicationType) => {
      this.setState({
         moduleCommunicationType: ModuleCommunicationType,
         dataIsCommunicating: IsCommunicating,
         modalCommunication: true,
      });

      // apiClient.gateways
      //    .communicationReport({
      //       page: 1,
      //       length: 10,
      //       IsCommunicating: IsCommunicating,
      //       Identifier: "",
      //       InstitutionId: "",
      //       Status: "",
      //       CommunicationType: "",
      //       ModuleType: "",
      //       ModuleCommunicationType: ModuleCommunicationType,
      //    })
      //    .then((res) => {
      //       this.setState({
      //          dataCommunicationModal: res.data,
      //          dataCommunicationHeaders: res.headers,
      //          modalCommunication: true,
      //       });
      //    })
      //    .catch((error) => {
      //       this.setState({
      //          toggleModalStatusCode: true,
      //          error: error,
      //          loading: false,
      //       });
      //    });
   };

   processChange = (data) => {
      const { checkReactTable } = this.state;
      let item = { id: data.id, statement: data.acknowledged };
      item.statement = data.acknowledged === true ? data.acknowledged : true;
      let newItem = [...checkReactTable];
      newItem.splice(
         checkReactTable.findIndex((x) => x.id === item.id),
         1,
         item
      );
      this.setState({ checkReactTable: newItem });
      apiClient.alarms
         .acknowledge({
            alarms: [item.id],
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };

   getDataAlarm = (severity, categoryId) => {
      apiClient.alarms
         .getBySeverityCategoryAndGroupAsync({
            severity: severity,
            categoryId: categoryId,
            groupId: this.state.selectedGroup.value,
            from: alarm24HDateFrom(),
            to: alarm24HDato(),
         })
         .then((res) => {
            let checkReactTable = res.data.map((x) => {
               return { id: x.id, statement: x.acknowledged };
            });
            this.setState({
               dataAlarmModal: res.data,
               modalAlarm: true,
               checkReactTable,
            });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   downloadReport = () => {
      saveAsExcel({
         columns: columns,
         data: this.convertDataExport(this.state.dataAlarmModal),
         endMergeTitle: "I1",
         endMergeSubTitle: "I2",
         columnWidthConfigs,
         title: "RESUMEN DE ALARMAS ULTIMAS 24 HORAS",
         name: `Resumen de alarma  ${alarm24HDateFrom()}__${alarm24HDato()}`,
         subTitle: "",
      });
   };

   convertDataExport = (data) => {
      return data?.map((alarm) => ({
         objectName: alarm.objectName,
         identifier: alarm.gateway.identifier,
         alias: alarm.gateway.alias,
         severity: alarm.type.severity.name,
         count: alarm.count,
         type: alarm.type.name,
         createdAt: alarm.createdAt,
         acknowledged: alarm.acknowledged ? "True" : "False",
         params: alarm.params,
      }));
   };
   render() {
      const {
         chartHours,
         chartDays,
         chartAlarms,
         CharModuleType,
         chartPie,
         CharReadingDay,
         CharReadingOClockDay,
         CharInstrumentationDay,
         CharReadingHour,
         CharReadingOClockHour,
         CharInstrumentationHour,
      } = this.state;
      let modalParameters = "";
      if (this.state.parametersData.params !== undefined) {
         const {
            params,
            type,
            updatedAt,
            objectType,
         } = this.state.parametersData;

         const data = {
            ...JSON.parse(params),
            category: type.category.name,
            updated: updatedAt,
            type: objectType.name,
         };

         const td = Object.entries(data).map(([key, value]) => (
            <tr key={key}>
               <td>{key}</td>
               <td>{value}</td>
            </tr>
         ));

         /* eslint-enable no-unused-vars */
         modalParameters = (
            <Table striped bordered hover>
               <thead>
                  <tr>
                     <th>Nombre</th>
                     <th>Valor</th>
                  </tr>
               </thead>
               <tbody>{td}</tbody>
            </Table>
         );
      }

      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <Modal isOpen={this.state.modalParameters}>
               <ModalHeader
                  toggle={() =>
                     this.setState({
                        modalParameters: !this.state.modalParameters,
                     })
                  }
               >
                  Detalles
               </ModalHeader>
               <ModalBody>{modalParameters}</ModalBody>
               <ModalFooter>
                  <ButtonCustom
                     color={"danger"}
                     className={""}
                     name={"Cerrar"}
                     id={"Cerrar"}
                     Click={() =>
                        this.setState({
                           modalParameters: !this.state.modalParameters,
                        })
                     }
                     title={"Cerrar"}
                  />
               </ModalFooter>
            </Modal>
            <ModalDashBoard
               title={"Detalles de comunicación"}
               toggle={() =>
                  this.setState({
                     modalCommunication: !this.state.modalCommunication,
                  })
               }
               isOpen={this.state.modalCommunication}
            >
               <GenericTable
                  data={this.state.dataCommunicationModal}
                  headers={this.state.dataCommunicationHeaders}
                  pages={this.state.dataCommunicationPages}
                  loading={this.state.loadingDataComunication}
                  customColumns={[
                     {
                        sortable: false,
                        filterable: false,
                        accessor: "institutionId",
                        show: this.state.permission,
                        Cell: (props) =>
                           props.value !== null
                              ? this.state.institution.find(
                                   (x) => x.id === props.value
                                ).name
                              : "",
                     },
                     {
                        accessor: "isCommunicating",
                        sortable: false,
                        filterable: false,
                        Cell: (props) => (
                           <div className="text-center">
                              {props.value === true ? (
                                 <i
                                    className="fas fa-circle mr-1 text-success font-12"
                                    width="50"
                                 >
                                    {" "}
                                 </i>
                              ) : (
                                 <i
                                    className="fas fa-circle mr-1 text-danger font-12"
                                    width="50"
                                 >
                                    {" "}
                                 </i>
                              )}
                           </div>
                        ),
                     },
                  ]}
                  onFetchData={(state) => {
                     if (this.state.modalCommunication) {
                        this.setState({ loadingDataComunication: true });
                        apiClient.gateways
                           .communicationReport({
                              ModuleCommunicationType: this.state
                                 .moduleCommunicationType,
                              IsCommunicating: this.state.dataIsCommunicating,
                              gatewayGroupId:
                                 this.state.selectedGroup.value > 0
                                    ? this.state.selectedGroup.value
                                    : null,
                              page: state.page + 1,
                              length: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                           })
                           .then((res) => {
                              this.setState({
                                 dataCommunicationModal: res.data,
                                 dataCommunicationHeaders: res.headers,
                                 dataCommunicationPages: Math.ceil(
                                    res.recordsTotal / state.pageSize
                                 ),
                                 loadingDataComunication: false,
                              });
                           })
                           .catch((error) => {
                              this.setState({
                                 toggleModalStatusCode: true,
                                 error: error,
                                 loadingDataComunication: false,
                              });
                           });
                     }
                  }}
               />
            </ModalDashBoard>
            <ModalDashBoard
               data={this.state.modalData}
               PageSize={5}
               title={"Detalles de desempeño"}
               columns={[
                  { Header: "Serial", accessor: "serial" },
                  { Header: "Fecha", accessor: "dateTime" },
                  {
                     Header: "Porcentaje",
                     accessor: "percentage",
                     Cell: (props) => Number(props.value.toFixed(4)),
                  },
                  { Header: "Tipp de desempeño", accessor: "performanceType" },
                  { Header: "Tipo de problema", accessor: "problemType" },
               ]}
               toggle={this.toggle}
               isOpen={this.state.modal}
            />

            <ModalDashBoard
               data={this.state.dataAlarmModal}
               title={"Detalles de alarma"}
               PageSize={5}
               style={{ maxWidth: "1080px", width: "100%" }}
               isHasReport
               downloadReport={this.downloadReport}
               columns={[
                  {
                     Header: "Nombre de origen",
                     accessor: "objectName",
                     width: 125,
                  },
                  {
                     Header: "Identificador",
                     accessor: "gateway.identifier",
                     Cell: (props) => props.value,
                  },
                  {
                     Header: "Alias",
                     accessor: "gateway.alias",
                     Cell: (props) => props.value,
                  },
                  {
                     Header: "Severidad",
                     accessor: "type.severity.name",
                     Cell: (props) => props.value,
                  },

                  { Header: "Cantidad", accessor: "count" },

                  {
                     Header: "Tipo",
                     accessor: "type",
                     Cell: (props) => props.value.name,
                  },
                  {
                     Header: "Fecha de ocurrencia",
                     accessor: "createdAt",
                     width: 140,
                     Cell: (props) => Time(props.value),
                  },
                  //   {
                  //      Header: "Categoria",
                  //      accessor: "type.category.name",
                  //      Cell: (props) => props.value,
                  //   },
                  //   {
                  //      Header: "Actualizado en",
                  //      accessor: "updatedAt",
                  //      minWidth: 175,
                  //      Cell: (props) => Time(props.value),
                  //   },

                  //   {
                  //      Header: "Tipo de origen",
                  //      accessor: "objectType",
                  //      Cell: (props) => props.value.name,
                  //   },

                  {
                     Header: "Procesado",
                     accessor: "acknowledged",
                     Cell: (props) => (
                        <div className="text-center">
                           <ButtonCustom
                              name={"check"}
                              id={"check"}
                              color={
                                 this.state.checkReactTable.find(
                                    (x) => x.id === props.original.id
                                 )
                                    ? this.state.checkReactTable.find(
                                         (x) => x.id === props.original.id
                                      ).statement === false
                                       ? "danger"
                                       : "success"
                                    : null
                              }
                              title={
                                 this.state.checkReactTable.find(
                                    (x) => x.id === props.original.id
                                 ) ? (
                                    this.state.checkReactTable.find(
                                       (x) => x.id === props.original.id
                                    ).statement === false ? (
                                       <i
                                          style={{
                                             color: "white",
                                             fontSize: "15px",
                                          }}
                                          className="fas fa-times"
                                       >
                                          {" "}
                                       </i>
                                    ) : (
                                       <i
                                          style={{
                                             color: "white",
                                             fontSize: "15px",
                                          }}
                                          className="fas fa-check"
                                       >
                                          {" "}
                                       </i>
                                    )
                                 ) : null
                              }
                              size={"sm"}
                              className={""}
                              Click={() => {
                                 this.processChange(props.original);
                              }}
                           />
                        </div>
                     ),
                  },

                  {
                     Header: "Detalles",
                     accessor: "id",
                     Cell: (props) => (
                        <div className="text-center">
                           <ButtonCustom
                              name={"viewParameters"}
                              id={"viewParameters"}
                              title={"Ver"}
                              size={"sm"}
                              disabled={props.original.params === null}
                              className={""}
                              Click={() => {
                                 let parameters = this.state.dataAlarmModal.find(
                                    (o) => o.id === props.value
                                 );

                                 this.setState({
                                    modalParameters: !this.state
                                       .modalParameters,
                                    parametersData: parameters,
                                 });
                              }}
                           />
                        </div>
                     ),
                  },
               ]}
               toggle={() =>
                  this.setState({ modalAlarm: !this.state.modalAlarm })
               }
               isOpen={this.state.modalAlarm}
            />

            <Card>
               <CardBody>
                  <Row className={"justify-content-center"}>
                     <Col className={"center mt-2 text-right font-16"} md={1}>
                        <label className={"ml-4"}>Grupo:</label>
                     </Col>
                     <Col md={3}>
                        <CustomSelect
                           options={this.state.group}
                           value={this.state.selectedGroup}
                           onChange={(e) => this.typeMethod(e, "group")}
                           id={"optionsGroup"}
                        />
                     </Col>
                     <Col className={"center mt-2  text-right font-16"} md={3}>
                        <label className={"ml-5"}>
                           Tipo de módulo de comunicación:
                        </label>
                     </Col>
                     <Col md={3}>
                        <CustomSelect
                           options={this.state.moduleType}
                           value={this.state.selectedModuleType}
                           onChange={(e) => this.typeMethod(e, "moduleType")}
                           id={"optionsModuleType"}
                        />
                     </Col>
                  </Row>
               </CardBody>
            </Card>
            <Row>
               <Col md={6}>
                  <Card>
                     <CardBody>
                        <div>
                           <HighchartsReact
                              constructorType={"stockChart"}
                              highcharts={Highcharts}
                              options={chartDays}
                              ref={this.chartDays}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
               <Col md={6}>
                  <Card>
                     <CardBody>
                        <div>
                           <HighchartsReact
                              constructorType={"stockChart"}
                              highcharts={Highcharts}
                              options={chartHours}
                              ref={this.chartHours}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <Row
               style={
                  this.state.showTwoChart === true
                     ? { display: "none" }
                     : { display: "" }
               }
            >
               <Col md={12}>
                  <Card>
                     <CardBody>
                        <Button
                           color={"cyan"}
                           className={"btn btn-block"}
                           onClick={() => {
                              this.showTwoChart();
                           }}
                        >
                           {" "}
                           Mostrar gráfica diferencial{" "}
                        </Button>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <Row
               style={
                  this.state.showTwoChart === false
                     ? { display: "none" }
                     : { display: "" }
               }
            >
               <Col md={6}>
                  <Card>
                     <CardBody>
                        <div>
                           <Button
                              color={"cyan"}
                              onClick={() => {
                                 this.showTwoChart();
                              }}
                           >
                              {" "}
                              Ocultar{" "}
                           </Button>
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharReadingOClockDay}
                              ref={this.CharReadingOClockDay}
                           />
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharReadingDay}
                              ref={this.CharReadingDay}
                           />
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharInstrumentationDay}
                              ref={this.CharInstrumentationDay}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>

               <Col md={6}>
                  <Card>
                     <CardBody>
                        <div>
                           <Button
                              color={"cyan"}
                              onClick={() => {
                                 this.showTwoChart();
                              }}
                           >
                              {" "}
                              Ocultar{" "}
                           </Button>
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharReadingOClockHour}
                              ref={this.CharReadingOClockHour}
                           />
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharReadingHour}
                              ref={this.CharReadingHour}
                           />
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharInstrumentationHour}
                              ref={this.CharInstrumentationHour}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <Row>
               <Col md={4}>
                  <Card>
                     <CardBody>
                        <div>
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={CharModuleType}
                              ref={this.CharModuleType}
                           />
                        </div>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                     </CardBody>
                  </Card>
               </Col>
               <Col md={4}>
                  <Card>
                     <CardBody>
                        <div>
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={chartAlarms}
                              ref={this.chartAlarms}
                           />
                           <label>
                              {" "}
                              <br />{" "}
                           </label>
                        </div>
                     </CardBody>
                  </Card>
               </Col>
               <Col md={4}>
                  <Card>
                     <CardBody>
                        <div>
                           <HighchartsReact
                              highcharts={Highcharts}
                              options={chartPie}
                              ref={this.chartPie}
                           />
                           <label>
                              {" "}
                              <br />
                           </label>
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
      );
   }
}
export default Dashboard;
