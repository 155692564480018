import GatewaysInstallation from "../views/installationCenter/GatewaysInstallation.jsx";
import Meter from "../views/installationCenter/Meter.jsx";
import SimCreation from "../views/installationCenter/SimCreation.jsx";
import Group from "../views/installationCenter/Group.jsx";
import Maps from "../views/map/map.jsx";
//import Relays from '../views/relays/relays.jsx';
import Alarm from "../views/alarm/alarm.jsx";
import SchedulePerformance from "../views/reports/SchedulePerformance";
import InstrumentationSchedulePerformance from "../views/reports/InstrumentationSchedulePerformance";
import PunctualReadings from "../views/reports/PunctualReadings";
import Reading from "../views/reports/Reading";
import Diagnostic from "../views/reports/Diagnostic";
import Gateway from "../views/reports/Gateway";
import GatewayRequest from "../views/reports/GatewayRequest";
import GatewayMeter from "../views/reports/GatewayMeter";
import MeterPerformance from "../views/reports/MeterPerformance";
import CommandHistory from "../views/reports/CommandHistory";
import Events from "../views/reports/Events";
import Instrumentation from "../views/reports/Instrumentation";
import Performance from "../views/performance/Performance";
import Command from "../views/administration/Command";
import Fillings from "../views/administration/Fillings";
import Billing from "../views/administration/Billing";
import Petitions from "../views/petitions/Petitions";
import Dashboard from "../views/dashboard/Dashboard";
import Profile from "../views/administration/Profile";
import Users from "../views/administration/Users";
import Roles from "../views/administration/Roles";
import Institution from "../views/administration/Institution";
import AuthCallback from "../views/authentication/auth-callback";
import Tcp from "../views/administration/Tcp";
import ReadingHistory from "../views/administration/ReadingHistory";
import MeterPLC from "../views/reports/MeterPLC";
import MeterAlarm from "../views/reports/MeterAlarm.jsx";

let ThemeRoutes = [
   {
      path: "/dashboard",
      name: "Dashboard",
      icon: "mdi mdi-view-dashboard",
      component: Dashboard,
   },
   {
      collapse: true,
      name: "Centro de instalación",
      state: "dashboardpages",
      icon: "mdi mdi-wrench",
      extra: "",
      child: [
         {
            path: "/installationcenter/gatewayinstallation",
            name: "Gateways",
            icon: "mdi mdi-crop-square",
            component: GatewaysInstallation,
         },
         {
            path: "/installationcenter/meter",
            name: "Medidores",
            icon: "mdi mdi-speedometer",
            component: Meter,
         },
         {
            path: "/installationcenter/group",
            name: "Grupos",
            icon: "mdi mdi-group",
            component: Group,
         },
         {
            path: "/installationcenter/simcreation",
            name: "SIM",
            icon: "mdi mdi-sim-alert",
            component: SimCreation,
         },
      ],
   },
   {
      path: "/map",
      name: "Mapa",
      icon: "mdi mdi-google-maps",
      component: Maps,
   },
   // {
   // 	path: "/relays",
   // 	name: 'Relays',
   // 	icon: 'mdi mdi-vector-difference-ba',
   // 	component: Relays,
   // },
   {
      path: "/petitions",
      name: "Peticiones",
      icon: "mdi mdi-format-float-center",
      component: Petitions,
   },
   {
      path: "/profile",
      name: "Perfil",
      isVisible: true,
      // icon: "mdi mdi-account",
      component: Profile,
   },
   {
      path: "/auth-callback",
      name: "AuthCallback",
      isVisible: true,
      component: AuthCallback,
   },
   {
      path: "/performance",
      name: "Desempeño",
      icon: "mdi mdi-margin",
      component: Performance,
   },
   {
      navlabel: true,
      name: "Tables",
      isVisible: true,
      icon: "mdi mdi-dots-horizontal",
   },
   {
      collapse: true,
      name: "Reportes",
      state: "openApps",
      icon: "mdi mdi-clipboard-text",
      extra: "",
      child: [
         {
            path: "/reports/desempenoHorarioLectura",
            name: "Desempeño horario lectura",
            icon: "mdi mdi-file-document",
            component: SchedulePerformance,
         },
         {
            path: "/reports/desempenoHorarioInstrumentacion",
            name: "Desempeño horario Instrumentación",
            icon: "mdi mdi-file-document",
            component: InstrumentationSchedulePerformance,
         },
         {
            path: "/reports/punctualreadings",
            name: "Lecturas puntuales",
            icon: "mdi mdi-file-document",
            component: PunctualReadings,
         },
         {
            path: "/reports/reading",
            name: "Lecturas",
            icon: "mdi mdi-file-document",
            component: Reading,
         },
         {
            path: "/reports/instrumentation",
            name: "Instrumentación",
            icon: "mdi mdi-file-document",
            component: Instrumentation,
         },
         {
            path: "/reports/events",
            name: "Eventos",
            icon: "mdi mdi-file-document",
            component: Events,
         },
         {
            path: "/reports/diagnostic",
            name: "Diagnostico",
            icon: "mdi mdi-file-document",
            component: Diagnostic,
         },
         {
            path: "/reports/gateways",
            name: "Comunicación de gateways",
            icon: "mdi mdi-file-document",
            component: Gateway,
         },
         {
            path: "/reports/gatewayrequest",
            name: "Solicitud por gateway",
            icon: "mdi mdi-file-document",
            component: GatewayRequest,
         },
         {
            path: "/reports/gatewaymeter",
            name: "Medidores Asociados a Gateway",
            icon: "mdi mdi-file-document",
            component: GatewayMeter,
         },
         // {
         // 	path: '/reports/gatewayperformance',
         // 	name: 'Desempeño Gateway',
         // 	// icon: "mdi mdi-file-document",
         // 	component: GatewayPerformance
         // },
         {
            path: "/reports/meterperformance",
            name: "Desempeño medidor",
            icon: "mdi mdi-file-document",
            component: MeterPerformance,
         },
         {
            path: "/reports/commandhistory",
            name: "Historico de comandos",
            icon: "mdi mdi-file-document",
            component: CommandHistory,
         },
         {
            path: "/reports/metersplc",
            name: "Medidores PLC",
            icon: "mdi mdi-file-document",
            component: MeterPLC,
         },
         {
            path: "/reports/meteralarm",
            name: "Meter Alarm",
            icon: "mdi mdi-file-document",
            component: MeterAlarm,
         },
      ],
   },
   {
      path: "/alarm",
      name: "Alarmas",
      icon: "mdi mdi-alert-octagram",
      component: Alarm,
   },
   {
      collapse: true,
      name: "Administración",
      state: "dashboardpages",
      icon: "mdi mdi-settings",
      extra: "",
      child: [
         {
            path: "/administration/users",
            name: "Usuarios",
            icon: "mdi mdi-account-multiple",
            component: Users,
         },
         {
            path: "/administration/institution",
            name: "Instituciones",
            icon: "mdi mdi-bank",
            component: Institution,
         },
         {
            path: "/administration/roles",
            name: "Roles",
            icon: "mdi mdi-marker-check",
            component: Roles,
         },
         {
            path: "/administration/tcp",
            name: "Tcp",
            icon: "mdi mdi-access-point",
            component: Tcp,
         },
         {
            path: "/administration/readinghistory",
            name: "Clear Data",
            icon: "mdi mdi-delete-variant",
            component: ReadingHistory,
         },
         {
            path: "/administration/command",
            name: "Command",
            icon: "mdi mdi-code-less-than-or-equal",
            component: Command,
         },
         {
            path: "/administration/fillings",
            name: "Rellenos",
            icon: "mdi mdi-checkbox-multiple-marked-outline",
            component: Fillings,
         },
         {
            path: "/administration/billing",
            name: "Facturación",
            icon: "mdi mdi-format-list-bulleted",
            component: Billing,
         },
      ],
   },
   { path: "", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];
export default ThemeRoutes;
