import React from "react";
import { CustomSelect } from "../../components/custom/CustomSelect";
import { FormGroup } from "reactstrap";
import Trans from "../../translate/Translate";
const t = (text) => <Trans text={text} />;
export const SelectSimple = ({
    array,
    item,
    id,
    label,
    valueCallback,
    isDisabled,
}) => {
    let role;
    const newArray = array.map((x) => {
        return { label: t(x.name), value: x.id };
    });
    if (item == null) {
        return (
            <FormGroup>
                <label>{label}</label>
                <CustomSelect
                    value={null}
                    onChange={(e) => {
                        valueCallback(e.value, id, array);
                    }}
                    options={newArray}
                    id={id}
                />
            </FormGroup>
        );
    } else {
        if (item[0] !== undefined) {
            item.map((x) => {
                return (role = { id: x.id, name: x.name });
            });
        }
        return (
            <FormGroup>
                <label>{label}</label>
                <CustomSelect
                    isDisabled={isDisabled}
                    value={newArray.find(
                        (x) =>
                            x.value ===
                            (item[0] !== undefined ? role.id : item.id)
                    )}
                    onChange={(e) => {
                        valueCallback(e.value, id, array);
                    }}
                    options={newArray}
                    id={id}
                />
            </FormGroup>
        );
    }
};
