export const columns = [
  "Medidor",
  "Fecha",
  "Cantidad",
  "Cantidad sin relleno",
  "Desempeño(%)",
  "Desempeño sin relleno(%)",
];
export const columnWidthConfigs = [
  { column: "A", width: 12 },
  { column: "B", width: 20 },
  { column: "C", width: 14 },
  { column: "D", width: 23 },
  { column: "E", width: 17 },
  { column: "F", width: 27 },
];
