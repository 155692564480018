import React from 'react';
import {Button, Spinner } from 'reactstrap';
import PropTypes from "prop-types";

export default class ButtonCustom extends React.Component {
    render() {
        return <Button
            color={this.props.color}
            name={this.props.name}
            id={this.props.name}
            size={this.props.size}
            className={this.props.className}
            style={this.props.style}
            onClick={this.props.Click}
            disabled={this.props.disabled}
            type={this.props.type}
            >
            {this.props.title} {this.props.icon}
            {this.props.loading && <Spinner color="light"/>}

            </Button>
    }
}

ButtonCustom.propTypes = {
    name:PropTypes.string,
    id:PropTypes.string,
    Click:PropTypes.func,
    type:PropTypes.string
};

ButtonCustom.defaultProps = {
    color: 'cyan',
    className:'btn btn-block',
    size:'',
    type:'Button'
};


