import React from 'react';
import {Card, CardBody, Row, Col, CardTitle} from 'reactstrap';
import Swal from "sweetalert2";
import  {ProfileModel}  from "../../models/ProfileModel";
import  {CustomInput} from "../../components/custom/CustomInput"
import ButtonCustom from "../../components/custom/ButtonCustom";
import ReactTooltip from 'react-tooltip'
import authService from "../../services/AuthService";
import {ModalCustom} from "../../components/custom/ModalCustom";
import apiClient from "../../api/apiClient";
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            profile: new ProfileModel(),
            tooltipOpen:false,
            toggleModalStatusCode:false,
            error:""
        };
    }
    toggle = () => {this.setState({tooltipOpen: !this.state.tooltipOpen})};
    toggleModalStatusCode = ()=>{
        this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
    };
    componentDidMount = () => {
        authService.getUser()
            .then(user => {
                let {profile} = this.state;
                if (user === null || user ===undefined) {
                }else{
                    /* eslint-disable no-unused-vars */
                    for (let i in user.profile) {
                        if (user.profile.hasOwnProperty(i)) {
                            profile[i] = user.profile[i];
                        }
                    }
                    /* eslint-enable no-unused-vars */
                    this.setState({profile})
                }
            }).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});


    };

    onChangeInputSimple = (e) => {
         ReactTooltip.hide();
        let {profile} = this.state;
        profile[e.target.name] = e.target.value;
        this.setState({profile});
    };


    submit = (type) =>{
        if (type==="user"){
        }else{
            let ad =this.state.profile;
            apiClient.account.changePassword({
                currentPassword: ad.currentPassword,
                password: ad.newPassword,
                passwordConfirmation: ad.confirmPassword,
            }).then(response => {
                if (response === undefined) {
                    Swal.fire({type: 'warning', title:'Problema en procesar su petición'});
                } else {
                    Swal.fire({type: 'success', title: 'Contraseña cambiada exitosamente', showConfirmButton: false, timer: 1500});
                }
            }).catch(error => {
                if (error.CurrentPassword[0] === "Incorrect password.") {
                    Swal.fire({type: 'error', title: 'Contraseña incorrecta', showConfirmButton: false, timer: 2000});
                } else {this.setState({toggleModalStatusCode:true, error:error, loading: false})}
            });
        }
    };

    render() {

        const {submit}=this;
        const {given_name="",email="",phone_number="",currentPassword="",newPassword="",confirmPassword="" } =this.state.profile;
        let REGEX = new RegExp("(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})");
        return (
            <div>
                <ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                                className="mdi mdi-account-edit mr-2"> </i>Perfil</CardTitle>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <CustomInput valid={given_name.length>0} onChange={this.onChangeInputSimple} value={given_name} label={"Nombre"} id={"given_name"}/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <CustomInput valid={phone_number.length>0} onChange={this.onChangeInputSimple} value={phone_number} label={"Teléfono"} id={"phone_number"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{size:  '12' , offset: 0}}>
                                        <CustomInput valid={email.length > 0} onChange={this.onChangeInputSimple} value={email} label={"Email"} id={"email"}/>
                                    </Col >
                                </Row>
                                <ButtonCustom className={''} name={'submit'} id={'submit'} disabled={!(given_name.length>0 && email.length>0 && phone_number.length>0)} Click={()=>submit('user')} title={"Actualizar"} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
                                className="mdi mdi-lock mr-2"> </i> Cambiar Contraseña </CardTitle>
                            <CardBody>
                                <form>
                                <Row>
                                    <Col sm={{size: '12', offset: 0}}>
                                        <CustomInput autocomplete={"currentPassword"} invalid={ !REGEX.test(currentPassword) } valid={currentPassword.length > 0} onChange={this.onChangeInputSimple} type={"password"} value={currentPassword} label={"Contraseña actual"} id={"currentPassword"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{size: '12', offset: 0}}>
                                        <CustomInput autocomplete={"newPassword"} tooltip="Debe incluir un carácter en mayúscula, un carácter en minúscula, un número, un carácter especial y un mínimo de 8 caracteres." invalid={ !REGEX.test(newPassword)} valid={newPassword.length > 0} onChange={this.onChangeInputSimple} type={"password"} value={newPassword} label={"Nueva Contraseña"} id={"newPassword"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{size: '12', offset: 0}}>
                                        <CustomInput autocomplete={"confirmPassword"} invalid={ (!REGEX.test(confirmPassword)) || (confirmPassword !== newPassword) } valid={confirmPassword === newPassword} onChange={this.onChangeInputSimple} type={"password"} value={confirmPassword} label={"Confirmar Contraseña"} id={"confirmPassword"}/>
                                    </Col>
                                </Row>
                                </form>
                                <ButtonCustom className={''} name={'submit'} id={'submit'} disabled={!( confirmPassword === newPassword  && REGEX.test(currentPassword) && REGEX.test(confirmPassword) && REGEX.test(newPassword) )} Click={()=>submit("password")} title={"Actualizar"}/>
                                <ReactTooltip delayHide={100} delayShow={100} type={'warning'} place={'top'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Profile;
