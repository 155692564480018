export const columns = [
   "Serial",
   "Record Id",
   "Card Serial",
   "Father Card Serial",
   "Tries",
   "TX Gain",
   "Busy Plc",
   "Gateway SNR",
   "Meter SNR",
   "Ultima Comunicación",
];

export const columnWidthConfigs = [
   { column: "A", width: 12 },
   { column: "B", width: 10 },
   { column: "C", width: 15 },
   { column: "D", width: 17 },
   { column: "E", width: 10 },
   { column: "F", width: 10 },
   { column: "G", width: 10 },
   { column: "H", width: 15 },
   { column: "I", width: 15 },
   { column: "J", width: 25 },
];
