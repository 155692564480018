import React, { useEffect, useState } from "react";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import TableLayout from "../../components/custom/TableLayout";
import AddGatewayPerformance from "./components/AddGatewayPerformance";
import Swal from "sweetalert2";
import { ModalCustom } from "../../components/custom/ModalCustom";
import { GatewayPerformanceConfiguration } from "../../models/GatewayConfiguration";

export default function GatewayPerformance(...props) {
    const {} = props;

    const [gatewayPerformanceList, setGatewayPerformanceList] = useState([]);
    const [gatewayComunicationList, setGatewayComunicationList] = useState([]);
    const [moduleComunicationList, setModuleComunicationList] = useState([]);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalType, setModalType] = useState("add");
    const [selectedGateway, setSelectedGateway] = useState();
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setLoading(true);

        apiClient.gateways
            .getCommunicationTypes()
            .then((response) => {
                if (response) {
                    const data = response.data;
                    setGatewayComunicationList(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                setErrorMsg(error);
                toggleErrorModal();
            });

        apiClient.gateways
            .getModuleCommunicationTypes()
            .then((response) => {
                if (response) {
                    const data = response.data;
                    setModuleComunicationList(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                setErrorMsg(error);
                toggleErrorModal();
            });
        apiClient.gateways
            .getAllGatewayPerformanceConfiguration()
            .then((response) => {
                if (response) {
                    let data = response.data;
                    setGatewayPerformanceList(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                setErrorMsg(error);
                toggleErrorModal();
            });

        setLoading(false);
    }, [refresh]);

    useEffect(() => {
        if (selectedGateway != null) {
            const index = gatewayPerformanceList.findIndex(
                (x) => x.id === selectedGateway.id
            );
            if (index != null) {
                let record = gatewayPerformanceList;
                record[index] = selectedGateway;
                setGatewayPerformanceList(record);
            }
        }
    }, [selectedGateway]);

    const submit = () => {
        if (selectedGateway != null) {
            switch (modalType) {
                case "edit":
                    setLoading(true);

                    apiClient.gateways
                        .editGatewayPerformanceConfiguration(selectedGateway)
                        .then((response) => {
                            toggleModal();
                            if (response) {
                                setLoading(false);
                                Swal.fire({
                                    type: "success",
                                    title: "Cambios realizados correctamente",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                setRefresh(!refresh);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            setErrorMsg(error);
                            toggleErrorModal();
                        });
                    break;
                case "add":
                    setLoading(true);

                    apiClient.gateways
                        .createGatewayPerformanceConfiguration(selectedGateway)
                        .then((response) => {
                            toggleModal();
                            setLoading(false);
                            Swal.fire({
                                type: "success",
                                title: "Cambios realizados correctamente",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            setRefresh(!refresh);
                        })
                        .catch((error) => {
                            setLoading(false);
                            setErrorMsg(error);
                            toggleErrorModal();
                        });
                    break;
                default:
                    break;
            }
        }
    };

    const toggleModal = () => {
        setOpenFormModal(!openFormModal);
    };

    const toggleErrorModal = () => {
        setOpenErrorModal(!openErrorModal);
    };

    const onButtonClick = ({ value, type }) => {
        switch (type) {
            case "edit":
                var index = gatewayPerformanceList.findIndex(
                    (x) => x.id === value
                );
                setSelectedGateway(gatewayPerformanceList[index]);
                setModalType("edit");
                toggleModal();
                break;

            case "add":
                const model = new GatewayPerformanceConfiguration();
                setSelectedGateway(model);
                setModalType("add");
                toggleModal();
                break;
            default:
                break;
        }
    };
    return (
        <>
            <ModalCustom
                error={errorMsg}
                toggleModal={openErrorModal}
                toggleModalFunc={toggleErrorModal}
            />
            <LoadingComponent loading={loading} />
            <Modal isOpen={openFormModal}>
                <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <AddGatewayPerformance
                        value={selectedGateway}
                        setValue={setSelectedGateway}
                        toggleModal={toggleModal}
                        type={modalType}
                        submit={submit}
                        title={setModalTitle}
                        gatewayPerformanceList={gatewayPerformanceList}
                        moduleCommunicationTypeList={moduleComunicationList}
                        communicationTypeList={gatewayComunicationList}
                    />
                </ModalBody>
            </Modal>
            <Card>
                <CardTitle
                    style={{ fontSize: "18px" }}
                    className="bg-light border-bottom p-3 mb-0"
                >
                    <i className="mdi mdi-hamburger mr-2"> </i>
                    Configuración de desempeño de los gateway
                </CardTitle>
                <CardBody>
                    <Row className={"justify-content-end"}>
                        <Col md={2}>
                            <ButtonCustom
                                name={"search"}
                                id={"search"}
                                title={"Agregar"}
                                icon={<i className="mdi mdi-plus"> </i>}
                                color={"orange"}
                                Click={() => {
                                    onButtonClick({ type: "add" });
                                }}
                            />
                        </Col>
                    </Row>
                    <TableLayout
                        data={gatewayPerformanceList}
                        // pages={this.state.pages}
                        defaultPageSize={10}
                        manual={false}
                        filterable={true}
                        pageSizeOptions={[10, 20, 30]}
                        columns={[
                            {
                                Header: "Acción",
                                accessor: "id",
                                sortable: false,
                                filterable: false,
                                Cell: (props) => (
                                    <div className="text-center">
                                        <ButtonCustom
                                            name={"edit"}
                                            id={"edit"}
                                            title={"Editar"}
                                            color={"success"}
                                            className={""}
                                            size={"sm"}
                                            Click={() =>
                                                onButtonClick({
                                                    value: props.value,
                                                    type: "edit",
                                                })
                                            }
                                        />
                                    </div>
                                ),
                            },
                            {
                                Header: "Tipo de gateway",
                                accessor: "gatewayTypeDescription",
                            },
                            {
                                Header: "Tipo de modulo de comunicación",
                                accessor: "moduleCommunicationTypeDescription",
                            },
                            {
                                Header: "Lecturas por hora",
                                accessor: "readingsPerHour",
                            },
                            {
                                Header: "Instrumentación por hora",
                                accessor: "instrumentationPerHour",
                            },
                        ]}
                    />
                </CardBody>
            </Card>
        </>
    );
}
