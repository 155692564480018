import React from 'react';
import { Redirect } from 'react-router-dom'
import authService from "../../services/AuthService";

export default class AuthCallback extends React.Component {

    state = {
        redirect: false
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/dashboard' />
        }
    };

    componentDidMount(){
        const that=this;
        authService.completeAuthentication().then(user =>
        {
            that.setRedirect();
        });
    }

    render() {
        return <div>{this.renderRedirect()}</div>
    }
}