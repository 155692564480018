import React from 'react';
import {Card, Row, Col, CardTitle, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import apiClient from '../../api/apiClient';
import Swal from 'sweetalert2'
import TableLayout from "../../components/custom/TableLayout";
import ButtonCustom from "../../components/custom/ButtonCustom";
import {SimModel} from "../../models/OthersModel";
import {CustomInput} from "../../components/custom/CustomInput";
import {ModalCustom} from "../../components/custom/ModalCustom";
import {Time} from "../../constants/DateTime";


class SimCreation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			type:'',
			sim:new SimModel(),
			simListingAll: [],
			pages:0,
			pageSize:20,
			loading: false,
			toggleModalStatusCode:false,
			error:"",
			simSearch:'',
			phoneNumberSearch:'',

		};
	}
	toggle = (tab) => {
		this.setState({
			isOpen: !this.state.isOpen
		});

		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	toggleModalStatusCode = ()=>{
		this.setState({toggleModalStatusCode:!this.state.toggleModalStatusCode});
	};

	toggleModal = () => {
		if (this.state.modal) {
			this.setState({sim: new SimModel() });
		}
		this.setState({
			modal: !this.state.modal,
			type: 'add'
		});
	};
	onChangeInputSimple = (e) => {
		let {sim} = this.state;
		sim[e.target.name] = e.target.value;
		this.setState({sim});
	};

	onChangeInput=(e)=>{
		this.setState({[e.target.name]:e.target.value});
	};

	onButtonClick = (sim, type) => {
		if (type === "edit"){
			this.toggleModal();
			const simToEdit = Object.assign({}, this.state.simListingAll.find(x => x.sim === sim));
			this.setState({sim: simToEdit, type});
		}
		else if (type === "delete"){
			const SimToDelete =this.state.simListingAll.find(x=>x.sim===sim);
			Swal.fire({
				title: '¿Estás seguro?',
				text: "que deseas eliminar el Sim: "+ SimToDelete.phoneNumber,
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0277bd',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Si, eliminar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.value) {
					apiClient.sim.deleteListing({
						sim: sim})
						.then(()=>{
							let Sim = [...this.state.simListingAll];
							Sim.splice(this.state.simListingAll.findIndex(x=>x.sim===sim),1);
							this.setState({simListingAll: Sim, type });
							Swal.fire('¡Eliminado!', 'Grupo eliminado', 'success')
						});
				}
			}).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});

		}
	};
	submit=()=>{
		const {sim="", phoneNumber=""} =this.state.sim;
		this.toggleModal();
		if (this.state.type === 'edit') {
			apiClient.sim.editListing({
				sim:sim,
				phoneNumber: phoneNumber,
			}).then(response => {
				if (response) {
					Swal.fire({
						type: 'success',
						title: 'Cambios realizados correctamente',
						showConfirmButton: false,
						timer: 1500
					});
					let sim = [...this.state.simListingAll];
					sim.splice(this.state.simListingAll.findIndex(x => x.sim === response.sim), 1, response);
					this.setState({simListingAll: sim, sim: {}});
				}
			}).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});


		}else {
			apiClient.sim.addListing({
				sim: sim,
				phoneNumber: phoneNumber,
			}).then(response => {
				if(response=== undefined) {
					Swal.fire({
						type: 'success',
						title:' Sim fue agregado correctamente',
						showConfirmButton: false,
						timer: 1500
					});
					let newSim= this.state.simListingAll.slice();
					newSim.push({sim: sim, phoneNumber: phoneNumber,});
					this.setState({simListingAll: newSim , sim: {}});
				}else{
					Swal.fire({
						type: 'success',
						title:' Sim fue agregado correctamente',
						showConfirmButton: false,
						timer: 1500
					});
					let newSim= this.state.simListingAll.slice();
					newSim.push(response);
					this.setState({simListingAll: newSim , sim: {}});
				}
			}).catch(error=>{this.setState({toggleModalStatusCode:true, error:error, loading: false})});
		}
	};

	onClickSearchMeter=()=>{
		const {pageSize, simSearch, phoneNumberSearch  } = this.state;
			apiClient.sim.listing({
			draw: 0,
			page: 1,
			length: pageSize,
            sim:simSearch,
			phoneNumber:phoneNumberSearch,
		}).then(res => {
			this.setState({
				simListingAll: res.data,
				page:0,
				loadingGateways: false
			});
		}).catch(error=>{ this.setState({toggleModalStatusCode:true, error:error, loadingGateways: false})});
	};

	render() {
		const {sim="", phoneNumber=""} =this.state.sim;
		return (
			<div>
				<ModalCustom error={this.state.error} toggleModal={this.state.toggleModalStatusCode} toggleModalFunc={this.toggleModalStatusCode}/>
				<Modal isOpen={this.state.modal} toggle={this.toggleModal}>
					<ModalHeader toggle={this.toggleModal}>{this.state.type==="add" ? "Agregar " :"Editar"} </ModalHeader>
					<ModalBody>
							<Row>
								<Col md={12}>
									<CustomInput readOnly={this.state.type!=="add"} onChange={this.onChangeInputSimple} value={sim} label={"SIM"} id={"sim"}/>
								</Col>
							</Row>

							<Row>
								<Col md={12}>
									<CustomInput valid={phoneNumber.length>0} onChange={this.onChangeInputSimple} value={phoneNumber} label={"Teléfono"} id={"phoneNumber"}/>
								</Col>
							</Row>
							<br/>
						<Row>
							<Col md={12}>
								<ButtonCustom color={'success'} className={''} name={'submit'} id={'submit'} disabled={!(phoneNumber.length>0)} Click={this.submit} title={sim.length > 0 ?"Actualizar" : "Enviar"  } />
								{' '}
								<ButtonCustom type={'cancel'} color={'danger'} className={''} name={'cancel'} id={'cancel'} Click={this.toggleModal} title={"Cancelar"}/>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
				<Card>
					<CardTitle style={{fontSize: '18px'}} className="bg-light border-bottom p-3 mb-0"><i
						className="mdi mdi-hamburger mr-2"> </i>Instalación de SIM</CardTitle>
					<CardBody>
						<Row className={"justify-content-center"}>
							<Col md={2}>
								<CustomInput valid={this.state.simSearch.length > 0}
											 onChange={this.onChangeInput}
											 value={this.state.simSearch}
											 label={"Sim"} id={"simSearch"}/>
							</Col>
							<Col md={2}>
								<CustomInput valid={this.state.phoneNumberSearch.length > 0}
											 onChange={this.onChangeInput}
											 value={this.state.phoneNumberSearch}
											 label={"Teléfono"} id={"phoneNumberSearch"}/>
							</Col>

							<Col md={2}>
								<label> <br/></label>
								<ButtonCustom
									name={'search'}
									id={'search'}
									title={'Buscar'}
									Click={this.onClickSearchMeter}
								/>
							</Col>
							<Col md={2}>
								<label> <br/></label>
								<ButtonCustom
									name={'search'}
									id={'search'}
									title={'Agregar'}
									icon={<i className="mdi mdi-plus"> </i>}
									color={'orange'}
									Click={this.toggleModal}
								/>
							</Col>
						</Row>
						<br/>
						<TableLayout
							data={this.state.simListingAll}
							// pageSize={this.state.pageSize}
							manual={true}
							loading={this.state.loading}
							filterable={false}
							onFetchData={(state) => {
								this.setState({loading: true});
								const {simSearch, phoneNumberSearch  } = this.state;
								apiClient.sim.listing({
									draw: 0,
									page: (state.page + 1),
									length: state.pageSize,
									pageSize:state.pageSize,
									sorted:state.sorted,
									filtered:state.filtered,
									sim:simSearch,
									phoneNumber:phoneNumberSearch,
								}).then(res => {
									this.setState({
										simListingAll: res.data,
										pageSize: state.pageSize,
										pages: Math.ceil(res.recordsTotal / state.pageSize),
										loading: false
									});
								}).catch(error => {this.setState({toggleModalStatusCode:true, error:error, loading: false})});

							}}
							columns={[
								{
									Header: "Acción",
									accessor: "sim",
									sortable: false,
									Cell: (props) =>
										<div className="text-center">
											<ButtonCustom
												name={'edit'}
												id={'edit'}
												title={'Editar'}
												color={'success'}
												className={''}
												size={'sm'}
												Click={() => this.onButtonClick(props.value, "edit")}
											/>
											{'  '}
											<ButtonCustom
												name={'delete'}
												id={'delete'}
												title={'Eliminar'}
												color={'danger'}
												className={''}
												size={'sm'}
												Click={() => this.onButtonClick(props.value, "delete")}
											/>
										</div>
								},
								{Header: "SIM", accessor: "sim"},
								{Header: "Teléfono", accessor: "phoneNumber"},
								{Header: "Registrado en", accessor: "registeredAt",  Cell: (props)=> Time(props.value)},
							]}
						/>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default SimCreation;
