export class GatewayModel {
    id=0;
    status={id:"", name:""};
    communicationType={id:"", name:""};
    moduleType={id:"", name:""};
    moduleCommunicationType={id:"", name:""};
    institution={id:"", name:""};
    identifier="";
    description="";
    alias="";
    communicationAddress="";
    firmware="";
    installedAt="";
    latitude="";
    longitude="";
}

