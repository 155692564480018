import classnames from "classnames";
import * as moment from "moment";
import React from "react";
import Select from "react-select";
import {
   Button,
   Card,
   CardBody,
   CardTitle,
   Col,
   Nav,
   NavItem,
   NavLink,
   Row,
   TabContent,
   TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import StockChart from "../../components/chart/StockChart";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import TableLayout from "../../components/custom/TableLayout";
import {
   customDateFrom,
   customDateTo,
   inputDateTime,
   Time,
} from "../../constants/DateTime";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
   columns as columnsExport,
   columnWidthConfigs,
} from "../../constants/exportExcel/gatewayRequest";
import { saveAsExcel } from "../../utilities/exportToExcel";
import { filterByFieldAndValue } from "../../utilities/genericFilters";
class GatewaysRequest extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         summaryRequests: [],
         summaryRequestsAux: [],
         isOpen: false,
         activeTab: "2",
         masterId: "",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         page: 0,
         pages: 0,
         pageSize: 10,
         recordsTotal: 0,
         filtered: [],
         sorted: [],
         showSeries: true,
         chartOptions: {
            chart: { type: "spline", height: "600px" },
            navigator: { adaptToUpdatedData: true, series: [] },
            scrollbar: {
               barBackgroundColor: "#e0e0e0",
               barBorderRadius: 7,
               barBorderWidth: 0,
               buttonBackgroundColor: "#e0e0e0",
               buttonBorderWidth: 0,
               buttonBorderRadius: 7,
               trackBackgroundColor: "none",
               trackBorderWidth: 1,
               trackBorderRadius: 8,
               trackBorderColor: "#CCC",
            },
            rangeSelector: {
               buttons: [
                  { type: "hour", count: 1, text: "1h" },
                  { type: "day", count: 1, text: "1D" },
                  { type: "week", count: 1, text: "1w" },
                  { type: "all", count: 1, text: "All" },
               ],
               selected: 4,
               inputEnabled: false,
            },
            tooltip: {
               shared: true,
               split: false,
               useHTML: true,
               outside: true,
               formatter: function () {
                  let table = [[], []];
                  this.points.map((data, key) => {
                     if (key % 2 === 0) {
                        table[0].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     } else {
                        table[1].push(
                           "<h6>" +
                              '<b style="color:' +
                              data.series.color +
                              '">' +
                              data.series.name +
                              "</b>" +
                              ' : <b style="text-align: right">' +
                              data.y +
                              "%</b> </h6>"
                        );
                     }
                  });
                  return (
                     '<small style="font-size: small">Fecha: ' +
                     moment(this.points[0].x).utc().format("YYYY-MM-DD HH:mm") +
                     "</small>" +
                     '<div style="display: table; clear: both; width:25vw">' +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[0].join(" ") +
                     "</div>" +
                     '<div style="float: left;width: 50%; padding: 10px">' +
                     table[1].join(" ") +
                     "</div>" +
                     "</div>"
                  );
               },
               valueDecimals: 2,
            },
            legend: {
               enabled: true,
               align: "center",
               layout: "horizontal",
               x: 0,
            },
            xAxis: {
               type: "datetime",
               crosshairs: true,
               labels: { format: "{value:%y-%m-%d %H:%M}" },
               minRange: 3600 * 1000,
               plotLines: [{ color: "#FF0000", width: 2, value: 0 }],
            },
            yAxis: {
               title: { text: "Desempeño %" },
               allowDecimals: false,
               min: 0,
               max: 100,
            },
            credits: false,
            series: [],
         },
         loading: false,
         toggleModalStatusCode: false,
         error: "",
      };
   }
   toggle = (tab) => {
      this.setState({
         isOpen: !this.state.isOpen,
      });

      if (this.state.activeTab !== tab) {
         this.setState({
            activeTab: tab,
         });
      }
   };
   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };
   componentDidMount = () => {
      window.addEventListener("mouseover", this.mouseOver);
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   };
   mouseOver = (e) => {
      if (e.clientY > 90 && e.clientY < 125) {
         this.setState({ dateOpen: undefined });
      }
   };
   componentWillUnmount() {
      window.removeEventListener("mouseover", this.mouseOver);
   }
   getMasterId = (e) => {
      this.setState({ masterId: e.value });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };
   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };

   handleToExcel = () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
               ¿Esta seguro de querer descargar estos registros.? <br/>
              <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                 "en-IN",
                 { maximumSignificantDigits: 3 }
              ).format(
                 this.state.recordsTotal
              )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.donwloadToExcel();
            }
         });
      } else {
         this.donwloadToExcel();
      }
   };
   donwloadToExcel = () => {
      this.setState({
         ...this.state,
         loading: true,
      });
      saveAsExcel({
         columns: columnsExport,
         data: this.convertDataExport(),
         endMergeTitle: "E1",
         endMergeSubTitle: "E2",
         columnWidthConfigs,
         title: "DESEMPEÑO DE SOLICITUD PARA MASTER",
         name: `Gw. ${
            this.state.masterName
         } Desempeno de solicitud para master (${new Date()
            .toISOString()
            .slice(0, 10)})`,
         subTitle: `DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
      });
      this.setState({
         ...this.state,
         loading: false,
      });
   };

   convertDataExport = () => {
      const result = this.state.summaryRequestsAux.map((summaryRequest) => ({
         dateTime: summaryRequest.dateTime,
         requestTypeName: summaryRequest.requestTypeName,
         successful: summaryRequest.successful,
         failed: summaryRequest.failed,
         performance: summaryRequest.performance,
      }));
      return result;
   };

   getReportSchedulePerformance = () => {
      let ad = this.state;
      if (ad.masterId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.performance
            .getRequestsPerformanceSummaryTable({
               gatewayId: ad.masterId,
               from: ad.dateFrom,
               to: ad.dateTo,
               draw: 0,
               start: 0,
               length: ad.pageSize,
            })
            .then((res) => {
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  this.setState({
                     summaryRequests: res.data,
                     summaryRequestsAux: res.data,
                     page: 0,
                     pages: Math.ceil(res.recordsTotal / ad.pageSize),
                     loading: false,
                     recordsTotal: res.recordsTotal,
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   getReportSchedulePerformanceChart = () => {
      let ad = this.state;
      if (ad.masterId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.performance
            .getRequestsPerformanceSummaryChart({
               gatewayId: ad.masterId,
               from: ad.dateFrom,
               to: ad.dateTo,
            })
            .then((res) => {
               let dataChart = [];
               let series = [];
               let rqtType = null;
               res.data.map((rqt) => {
                  rqt.map((val) => {
                     rqtType = val.requestTypeName;
                     const dateTimeD = new Date(val.dateTime);
                     const dateFormatD = Date.UTC(
                        dateTimeD.getFullYear(),
                        dateTimeD.getMonth(),
                        dateTimeD.getDate(),
                        dateTimeD.getHours(),
                        dateTimeD.getMinutes(),
                        0
                     );
                     dataChart.push([dateFormatD, val.performance]);
                     return true;
                  });
                  const requestToAdd = {
                     name: rqtType,
                     data: dataChart,
                  };
                  series.push(requestToAdd);
                  return true;
               });
               this.updateSeries(series);
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   updateSeries = (series) => {
      this.setState({
         chartOptions: {
            navigator: { series: series },
            series: series,
         },
         loading: false,
      });
   };

   showLegendValues = () => {
      this.setState({
         chartOptions: {
            series: this.state.chartOptions.series.map((x) => {
               x.visible = !this.state.showSeries;
               return x;
            }),
            navigator: {
               series: this.state.chartOptions.series.map((x) => {
                  return {
                     name: x.name,
                     data: x.data,
                     visible: true,
                     type: "spline",
                  };
               }),
            },
         },
         showSeries: !this.state.showSeries,
      });
   };

   render() {
      let tab = this.state.activeTab;
      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Nav tabs>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "1",
                     })}
                     onClick={() => {
                        this.toggle("1");
                     }}
                  >
                     <span>Tabulado</span>
                  </NavLink>
               </NavItem>
               <NavItem>
                  <NavLink
                     className={classnames({
                        active: this.state.activeTab === "2",
                     })}
                     onClick={() => {
                        this.toggle("2");
                     }}
                  >
                     <span>Gráfico</span>
                  </NavLink>
               </NavItem>
            </Nav>
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Desempeño de
                  solicitud para master
               </CardTitle>
               <CardBody className="border-top">
                  <TabContent activeTab={this.state.activeTab}>
                     <br />
                     <Row className={"justify-content-center"}>
                        <Col md={2}>
                           <label> Gateway </label>
                           <Select
                              name="selectMaster"
                              id="selectMaster"
                              options={this.state.masterData}
                              placeholder="Gateway"
                              closeMenuOnSelect={true}
                              onChange={this.getMasterId}
                           />
                        </Col>
                        <Col md={2}>
                           <label> Desde </label>
                           <CustomDate
                              id="DateFrom"
                              value={new Date(this.state.dateFrom)}
                              onChange={(moment) =>
                                 this.onDateChange(moment, "dateFrom")
                              }
                           />
                        </Col>
                        <Col md={2}>
                           <label> Hasta </label>
                           <CustomDate
                              id="DateTo"
                              value={new Date(this.state.dateTo)}
                              onChange={(moment) =>
                                 this.onDateChange(moment, "dateTo")
                              }
                           />
                        </Col>
                        <Col md={2}>
                           <label>
                              {" "}
                              <br />{" "}
                           </label>
                           <ButtonCustom
                              name={
                                 tab === "1"
                                    ? "generateTable"
                                    : "generateGraphic"
                              }
                              id={
                                 tab === "1"
                                    ? "generateTable"
                                    : "generateGraphic"
                              }
                              title={
                                 tab === "1"
                                    ? "Generar tabla"
                                    : "Generar gráfico"
                              }
                              Click={
                                 tab === "1"
                                    ? this.getReportSchedulePerformance
                                    : this.getReportSchedulePerformanceChart
                              }
                           />
                        </Col>{" "}
                        <Col md={2}>
                           <label>
                              {" "}
                              <br />{" "}
                           </label>
                           <ButtonCustom
                              loading={this.state.loading}
                              Click={this.handleToExcel}
                              disabled={
                                 !(this.state.summaryRequests.length > 0)
                              }
                              style={{ paddingLeft: 20 }}
                              block
                              name={"submit"}
                              id={"submit"}
                              title={"Descargar"}
                              color={"success"}
                           />
                        </Col>
                     </Row>
                     <br />
                     <TabPane tabId="1">
                        <TableLayout
                           data={this.state.summaryRequests}
                           pages={this.state.pages}
                           defaultPageSize={this.state.pageSize}
                           manual={false}
                           onFetchData={(state) => {
                              if (state.filtered.length > 0) {
                                 let aux = this.state.summaryRequests;
                                 state.filtered.forEach((item) => {
                                    aux = filterByFieldAndValue({
                                       array: aux,
                                       field: item.id,
                                       value: item.value,
                                    });
                                    this.setState({
                                       ...this.state,
                                       summaryRequestsAux: aux,
                                       recordsTotal: aux.length,
                                    });
                                 });
                              } else {
                                 this.setState({
                                    ...this.state,
                                    summaryRequestsAux:
                                       this.state.summaryRequests,
                                    recordsTotal:
                                       this.state.summaryRequests.length,
                                 });
                              }
                           }}
                           columns={[
                              {
                                 Header: "Fecha",
                                 accessor: "dateTime",
                                 Cell: (props) => Time(props.value),
                              },
                              {
                                 Header: "Comando",
                                 accessor: "requestTypeName",
                              },
                              { Header: "Exitosos", accessor: "successful" },
                              { Header: "Fallidos", accessor: "failed" },
                              {
                                 Header: "Porcentaje(%)",
                                 accessor: "performance",
                              },
                           ]}
                        />
                     </TabPane>
                     <TabPane tabId="2">
                        <StockChart options={this.state.chartOptions} />
                        <Button
                           style={
                              this.state.chartOptions.series.length !== 0
                                 ? { display: "" }
                                 : { display: "none" }
                           }
                           color={"cyan"}
                           onClick={this.showLegendValues}
                        >
                           {this.state.showSeries ? "Ocultar" : "Mostrar"}
                        </Button>
                     </TabPane>
                  </TabContent>
               </CardBody>
            </Card>
         </div>
      );
   }
}
export default GatewaysRequest;
