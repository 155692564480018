import {UserManager} from 'oidc-client';
import findIndex from "lodash/findIndex"

const settings = {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_URL,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    post_logout_redirect_uri: process.env.REACT_APP_AUTH_LOGOUT_URL,
    silent_redirect_uri: process.env.REACT_APP_AUTH_SILENT_REDIRECT_URL,
    response_type: "code",
    scope: "openid profile phone email telemam telemam.api",
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: false
};

const userManager = new UserManager(settings);

userManager.events.addAccessTokenExpiring(function () {
   userManager.signinSilent().then(user => {});
});

userManager.events.addUserSignedOut(function () {
   userManager.removeUser().then(result => {
       return userManager.signinRedirect();
   });
});

export default {
    getUser() {
        return userManager.getUser();
    },

    login() {
        return userManager.signinRedirect();
    },

    logout() {
        return userManager.signoutRedirect();
    },

    getUserPermissions() {
        return userManager.getUser().then(user=>{
            if (!user || !user.profile.permission) return [];
            const permissions =[];
            if (typeof (user.profile.permission) ==="string"){
                permissions.push(user.profile.permission);
            }
            if (Array.isArray(user.profile.permission)){
                user.profile.permission.forEach(permission=>{
                    permissions.push(permission);
                });
            }
            return permissions;
        });
    },

    userHasPermissions(permission) {
     return this.getUserPermissions()
         .then(permissions=>{
            if (!permissions.length) return false;
             let index = findIndex(permissions, (value)=> {
                 return value === permission;
             });
             return index > -1
         });
    },

    renewAccessToken() {
        return userManager.signinSilent().then(function (user) {
            return user ? user.access_token : null;
        });
    },
    getAccessToken() {
        return userManager.getUser().then((user) => {
            return user ? user.access_token : null;
        });
    },

    completeAuthentication() {
        return userManager.signinRedirectCallback();
    }
};