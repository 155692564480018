import {Input, FormGroup} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

export const CustomInput = (props)=>{
    return (<FormGroup>
            {props.showLabel? <label htmlFor={props.id} >{props.label}</label> : ""}
            {/*<Form>*/}
            <Input
                data-tip={props.tooltip}
                valid={props.valid}
                readOnly={props.readOnly}
                invalid={props.invalid}
                required={props.required}
                type={props.type}
                name={props.id}
                id={props.id}
                autoComplete={props.autocomplete}
                onChange={props.onChange}
                value={props.value==null?'':props.value}
                placeholder={props.placeholder || props.label }
            />
            {/*</Form>*/}
        </FormGroup>
    )
};

CustomInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    required: PropTypes.bool,
    valid: PropTypes.bool,
    invalid: PropTypes.bool || PropTypes.string,
    readOnly: PropTypes.bool,
};

CustomInput.defaultProps = {
    type: "text",
    showLabel: true,
    required: false,
    valid: false,
    invalid: false,
    readOnly: false,
};
