export class MeterModel {
   id = 0;
   serial = "";
   meterCardSerial = "";
   meterFatherCardSerial = "";
   meterType = { id: "", name: "" };
   meterModuleType = { id: "", name: "" };
   institution = { id: "", name: "" };
   status = { id: "", name: "" };
   form = { id: "", name: "" };
   latitude = "";
   longitude = "";
   meterPhaseCommunication = 0;
   touFrom = 0;
   touTo = 0;
}
