import React from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import apiClient from "../../api/apiClient";
import ButtonCustom from "../../components/custom/ButtonCustom";
import CustomDate from "../../components/custom/Date";
import GenericTable from "../../components/custom/GenericTable";
import { LoadingComponent } from "../../components/custom/LoadingComponent";
import { ModalCustom } from "../../components/custom/ModalCustom";
import {
   customDateFrom,
   customDateTo,
   inputDateTime,
} from "../../constants/DateTime";
import { maxRecordToShowConfirmation } from "../../constants/defaultValue";
import {
   columns,
   columnWidthConfigs,
} from "../../constants/exportExcel/reading";
import { saveAsExcel } from "../../utilities/exportToExcel";

class Reading extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         masterData: [],
         readings: [],
         readingHeaders: [],
         readingsCsv: [],
         loadingCsv: [],
         isOpen: false,
         isMaster: false,
         gatewayId: "",
         meterId: "",
         masterName: "",
         dateFrom: customDateFrom(),
         dateTo: customDateTo(),
         type: 1,
         pages: 0,
         pageSize: 10,
         recordsTotal: 0,
         filtered: [],
         sorted: [],
         loading: false,
         toggleModalStatusCode: false,
         error: "",
      };
   }

   toggleModalStatusCode = () => {
      this.setState({
         toggleModalStatusCode: !this.state.toggleModalStatusCode,
      });
   };

   componentDidMount() {
      window.addEventListener("mouseover", this.mouseOver);
      apiClient.gateways
         .getActiveGateways()
         .then((res) => {
            let items = res.data.map((result) => {
               return { value: result.id, label: result.alias };
            });
            this.setState({ masterData: items });
         })
         .catch((error) => {
            this.setState({
               toggleModalStatusCode: true,
               error: error,
               loading: false,
            });
         });
   }

   mouseOver = (e) => {
      if (e.clientY > 90 && e.clientY < 125) {
         this.setState({ dateOpen: undefined });
      }
   };

   componentWillUnmount() {
      window.removeEventListener("mouseover", this.mouseOver);
   }

   onDateChange = (moment, value) => {
      this.setState({ [value]: inputDateTime(moment) });
   };

   getTypeEntry = (e) => {
      this.setState({ type: e.value });
   };

   getMeter = (e) => {
      this.setState({ meterId: e.target.value });
      this.setState({ isMaster: false });
      this.setState({
         masterName: e.target.value,
      });
   };

   getMasterId = (e) => {
      this.setState({ gatewayId: e.value });
      this.setState({ isMaster: true });
      this.setState({
         masterName: this.state.masterData.find(
            (master) => master.value === e.value
         ).label,
      });
   };

   getReportSchedulePerformanceMeter = () => {
      let ad = this.state;
      if (ad.meterId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.readings
            .getByMeter({
               meter: ad.meterId,
               from: ad.dateFrom,
               to: ad.dateTo,
               draw: 0,
               page: 0,
               length: ad.pageSize,
            })
            .then((res) => {
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  apiClient.readings
                     .getByMeter({
                        meter: ad.meterId,
                        from: ad.dateFrom,
                        to: ad.dateTo,
                        page: 0,
                        length: ad.pageSize,
                     })
                     .then((res2) => {
                        this.setState({ loadingCsv: false });
                        if (res2 === 0) {
                           Swal.fire({
                              type: "info",
                              title: "No information",
                              text: "You must select another date range",
                           }).catch((e) => {
                              console.log(e);
                           });
                        } else {
                           this.setState({
                              readingsCsv: res2.data,
                              recordsTotal: res2.recordsTotal,
                           });
                        }
                     })
                     .catch((error) => {
                        this.setState({
                           toggleModalStatusCode: true,
                           error: error,
                           loadingCsv: false,
                        });
                     });

                  this.setState({
                     readings: res.data,
                     readingHeaders: res.headers,
                     loading: false,
                     pages: Math.ceil(res.recordsTotal / ad.pageSize),
                     recordsTotal: res.recordsTotal,
                  });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "info",
            title: "No hay información",
            text: "Debes seleccionar otro rango de fechas",
         });
      }
   };

   getReportSchedulePerformanceMaster = () => {
      let ad = this.state;
      if (ad.gatewayId !== "" && ad.dateFrom !== "" && ad.dateTo !== "") {
         this.setState({ loading: true });
         apiClient.readings
            .getByGateway({
               gatewayId: ad.gatewayId,
               from: ad.dateFrom,
               to: ad.dateTo,
               start: 0,
               length: ad.pageSize,
            })
            .then((res) => {
               if (res.data.length === 0) {
                  Swal.fire({
                     type: "info",
                     title: "No hay información",
                     text: "Debes seleccionar otro rango de fechas",
                  }).then(() => {
                     this.setState({ loading: false });
                  });
               } else {
                  this.setState({
                     readings: res.data,
                     readingHeaders: res.headers,
                     loading: false,
                     pages: Math.ceil(res.recordsTotal / ad.pageSize),
                     recordsTotal: res.recordsTotal,
                  });
                  apiClient.readings
                     .getByGateway({
                        gatewayId: ad.gatewayId,
                        from: ad.dateFrom,
                        to: ad.dateTo,
                        page: 0,
                        length: ad.pageSize,
                     })
                     .then((res2) => {
                        this.setState({ loadingCsv: false });
                        if (res2 === 0) {
                           Swal.fire({
                              type: "info",
                              title: "No information",
                              text: "You must select another date range",
                           }).catch((e) => {
                              console.log(e);
                           });
                        } else {
                           this.setState({
                              readingsCsv: res2.data,
                              recordsTotal: res.recordsTotal,
                           });
                        }
                     })
                     .catch((error) => {
                        this.setState({
                           toggleModalStatusCode: true,
                           error: error,
                           loadingCsv: false,
                        });
                     });
               }
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         Swal.fire({
            type: "warning",
            title: "Campos vacios",
            text: "No pueden ver campos vacios",
         });
      }
   };

   handleToExcel = async () => {
      if (this.state.recordsTotal >= maxRecordToShowConfirmation) {
         Swal.fire({
            html: `<strong > Confirmaci&oacute;n.</strong><br/>
                 ¿Esta seguro de querer descargar estos registros.? <br/>
                <strong style='color:#5cb85c'> Se descargaran ${new Intl.NumberFormat(
                   "en-IN",
                   { maximumSignificantDigits: 3 }
                ).format(
                   this.state.recordsTotal
                )} registros    </strong>  <br/>   `,
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Descargar",
         }).then((result) => {
            if (result.value !== undefined && result.value) {
               this.proccessDownload();
            }
         });
      } else {
         this.proccessDownload();
      }
   };

   proccessDownload = () => {
      this.setState({ ...this.state, loading: true });
      const state = this.state;
      if (this.state.type === 1) {
         apiClient.readings
            .getByGateway({
               gatewayId: state.gatewayId,
               from: state.dateFrom,
               to: state.dateTo,
               filtered: state.filtered,
               sorted: state.sorted,
               page: 1,
               length: state.recordsTotal,
            })
            .then((res) => {
               this.donwload(res.data);
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      } else {
         apiClient.readings
            .getByMeter({
               meter: state.meterId,
               from: state.dateFrom,
               to: state.dateTo,
               filtered: state.filtered,
               sorted: state.sorted,
               page: 1,
               length: state.recordsTotal,
            })
            .then((res) => {
               this.donwload(res.data);
            })
            .catch((error) => {
               this.setState({
                  toggleModalStatusCode: true,
                  error: error,
                  loading: false,
               });
            });
      }
      this.setState({ ...this.state, loading: false });
   };

   donwload = async (data) => {
      saveAsExcel({
         columns: columns,
         data: this.convertDataExport(data),
         endMergeTitle: "R1",
         endMergeSubTitle: "R2",
         columnWidthConfigs,
         title: "REPORTE DE LECTURAS",
         name: !this.state.isMaster
            ? `Mt. ${this.state.masterName} Lecturas (${new Date()
                 .toISOString()
                 .slice(0, 10)})`
            : `Gw. ${this.state.masterName} Lecturas (${new Date()
                 .toISOString()
                 .slice(0, 10)})`,
         subTitle: `DESDE:  ${this.state.dateFrom}  HASTA:  ${this.state.dateTo}`,
      });
   };

   convertDataExport = (data) => {
      return data?.map((item) => ({
         serial: Number.parseInt(item.serial),
         readingDate: item.readingDate,
         insertDate: item.insertDate,
         activeEnergy: item.activeEnergy,
         reactiveEnergy: item.reactiveEnergy,
         reverseEnergy: item.reverseEnergy,
         activeEnergyT1: item.activeEnergyT1,
         activeEnergyT3: item.activeEnergyT3,
         reactiveEnergyT1: item.reactiveEnergyT1,
         reactiveEnergyT3: item.reactiveEnergyT3,
         reverseEnergyT1: item.reverseEnergyT1,
         reverseEnergyT3: item.reverseEnergyT3,
         powerT1: item.powerT1,
         powerT3: item.powerT3,
         powerReadingDateT1: item.powerReadingDateT1,
         powerReadingDateT3: item.powerReadingDateT3,
         flag: item.flag,
         tries: item.tries,
      }));
   };

   render() {
      const valueButton = this.state.type;
      let ButtonType;
      if (valueButton === 1) {
         ButtonType = (
            <Select
               name="master"
               id="master"
               options={this.state.masterData}
               placeholder="Gateway"
               closeMenuOnSelect={true}
               onChange={this.getMasterId}
            />
         );
      } else if (valueButton === 2) {
         ButtonType = (
            <Input
               type="text"
               name="meter"
               id="meter"
               placeholder="Medidor"
               onChange={this.getMeter}
            />
         );
      }

      const TypeEntry = [
         { value: 2, label: "Meter" },
         { value: 1, label: "Master" },
      ];

      return (
         <div>
            <ModalCustom
               error={this.state.error}
               toggleModal={this.state.toggleModalStatusCode}
               toggleModalFunc={this.toggleModalStatusCode}
            />
            <LoadingComponent loading={this.state.loading} />
            <Card>
               <CardTitle
                  style={{ fontSize: "18px" }}
                  className="bg-light border-bottom p-3 mb-0"
               >
                  <i className="mdi mdi-hamburger mr-2"> </i>Reporte de lecturas
               </CardTitle>
               <CardBody className="border-top">
                  <br />
                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label> Tipo </label>
                        <Select
                           name="selectType"
                           id="selectType"
                           defaultValue={[TypeEntry[1]]}
                           options={TypeEntry}
                           placeholder="Type"
                           closeMenuOnSelect={true}
                           onChange={this.getTypeEntry}
                        />
                     </Col>
                     <Col md={2}>
                        <label> Tipo de origen </label>
                        {ButtonType}
                     </Col>
                     <Col md={2}>
                        <label> Desde </label>
                        <CustomDate
                           id="DateFrom"
                           value={new Date(this.state.dateFrom)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateFrom")
                           }
                        />
                     </Col>
                     <Col md={2}>
                        <label> Hasta </label>
                        <CustomDate
                           id="DateTo"
                           value={new Date(this.state.dateTo)}
                           onChange={(moment) =>
                              this.onDateChange(moment, "dateTo")
                           }
                        />
                     </Col>
                  </Row>

                  <Row className={"justify-content-center"}>
                     <Col md={2}>
                        <label>
                           {" "}
                           <br />{" "}
                        </label>
                        <ButtonCustom
                           name={
                              valueButton === 1
                                 ? "generateMaster"
                                 : "generateGateway"
                           }
                           id={
                              valueButton === 1
                                 ? "generateMaster"
                                 : "generateGateway"
                           }
                           title={valueButton === 1 ? "Generar" : "Generar"}
                           Click={
                              valueButton === 1
                                 ? this.getReportSchedulePerformanceMaster
                                 : this.getReportSchedulePerformanceMeter
                           }
                        />
                     </Col>{" "}
                     <Col md={2}>
                        <label>
                           <br />
                        </label>
                        <ButtonCustom
                           loading={this.state.loading}
                           Click={this.handleToExcel}
                           disabled={!(this.state.readings.length > 0)}
                           style={{ paddingLeft: 20 }}
                           block
                           name={"submit"}
                           id={"submit"}
                           title={"Descargar"}
                           color={"success"}
                        />
                     </Col>
                  </Row>
                  <br />
                  <GenericTable
                     data={this.state.readings}
                     headers={this.state.readingHeaders}
                     loading={this.state.loading}
                     pages={this.state.pages}
                     onPageSizeChange={(value) => {
                        this.setState({ pageSize: value });
                     }}
                     pageSize={
                        this.state.readings.length > 0 &&
                        this.state.readings.length < this.state.pageSize
                           ? this.state.readings.length
                           : this.state.pageSize
                     }
                     onFetchData={(state) => {
                        let st = this.state;
                        if (st.gatewayId || st.meterId) {
                           if (this.state.type === 1) {
                              apiClient.readings
                                 .getByGateway({
                                    gatewayId: st.gatewayId,
                                    from: st.dateFrom,
                                    to: st.dateTo,
                                    draw: 0,
                                    pageSize: state.pageSize,
                                    page: state.page + 1,
                                    length: this.state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered,
                                 })
                                 .then((res) => {
                                    this.setState({
                                       readings: res.data,
                                       readingHeaders: res.headers,
                                       loading: false,
                                       pages: Math.ceil(
                                          res.recordsTotal / this.state.pageSize
                                       ),
                                       recordsTotal: res.recordsTotal,
                                       sorted: state.sorted ?? [],
                                       filtered: state.filtered ?? [],
                                    });
                                 })
                                 .catch((error) => {
                                    this.setState({
                                       toggleModalStatusCode: true,
                                       error: error,
                                       loading: false,
                                    });
                                 });
                           } else if (this.state.type === 2) {
                              apiClient.readings
                                 .getByMeter({
                                    meter: st.meterId,
                                    from: st.dateFrom,
                                    to: st.dateTo,
                                    pageSize: state.pageSize,
                                    draw: 0,
                                    page: state.page + 1,
                                    length: state.pageSize,
                                 })
                                 .then((res) => {
                                    this.setState({
                                       readings: res.data,
                                       readingHeaders: res.headers,
                                       loading: false,
                                       pages: Math.ceil(
                                          res.recordsTotal / state.pageSize
                                       ),
                                       recordsTotal: res.recordsTotal,
                                       sorted: state.sorted ?? [],
                                       filtered: state.filtered ?? [],
                                    });
                                 })
                                 .catch((error) => {
                                    this.setState({
                                       toggleModalStatusCode: true,
                                       error: error,
                                       loading: false,
                                    });
                                 });
                           }
                        }
                     }}
                  />
               </CardBody>
            </Card>
         </div>
      );
   }
}

export default Reading;
